<!-- src/components/admin/Admin-UserManager.svelte -->
<script>
  import { onMount } from 'svelte';
  import Modal from '../Modal.svelte';
  
  // Props
  export let users = [];
  export let loading = false;
  export let error = null;
  export let modalOpen = false;
  export let editingUser = null;
  export let newUser = {
    displayName: '',
    email: '',
    isAdmin: false,
    status: 'active'
  };
  
  // Filter state
  let searchQuery = '';
  let roleFilter = 'all';
  let statusFilter = 'all';
  
  // Pagination state
  let currentPage = 1;
  let itemsPerPage = 10;
  let totalItems = 0;
  
  // Event dispatcher
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  
  // Form values - using reactive variables for binding
  let formDisplayName = '';
  let formEmail = '';
  let formIsAdmin = false;
  let formStatus = 'active';
  
  // Activity log state
  let activityLogOpen = false;
  let selectedUserForLog = null;
  let userActivityLog = [];
  let loadingActivityLog = false;
  
  // Add these new variables for form validation
  let emailError = '';
  let displayNameError = '';
  let isSubmitting = false;
  let saveSuccess = false;
  let previousUserData = null;
  
  // Add these new variables for activity log filtering
  let activityFilter = 'all'; // 'all', 'login', 'admin', 'data'
  let activitySearch = '';
  let activityDateRange = 'all'; // 'all', 'today', 'week', 'month'
  
  // Update form values when editingUser changes
  $: {
    if (editingUser) {
      formDisplayName = editingUser.displayName;
      formEmail = editingUser.email;
      formIsAdmin = editingUser.isAdmin;
      formStatus = editingUser.status || 'active';
    } else {
      formDisplayName = newUser.displayName;
      formEmail = newUser.email;
      formIsAdmin = newUser.isAdmin;
      formStatus = newUser.status;
    }
  }
  
  // Computed properties
  $: filteredUsers = users.filter(user => {
    // Filter by search query
    if (searchQuery && !user.displayName.toLowerCase().includes(searchQuery.toLowerCase()) && 
        !user.email.toLowerCase().includes(searchQuery.toLowerCase())) {
      return false;
    }
    
    // Filter by role
    if (roleFilter !== 'all' && ((roleFilter === 'admin' && !user.isAdmin) || 
                                (roleFilter === 'user' && user.isAdmin))) {
      return false;
    }
    
    // Filter by status
    if (statusFilter !== 'all' && user.status !== statusFilter) {
      return false;
    }
    
    return true;
  });
  
  $: totalPages = Math.max(1, Math.ceil(filteredUsers.length / itemsPerPage));
  $: displayedUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  $: totalFilteredUsers = filteredUsers.length;
  
  // State variables
  let saving = false;
  let deleteConfirmOpen = false;
  let userToDelete = null;
  
  // Load users on mount
  onMount(async () => {
    await fetchUsers();
    
    // Add keyboard event listener
    window.addEventListener('keydown', handleKeydown);
    
    return () => {
      // ... existing cleanup ...
      window.removeEventListener('keydown', handleKeydown);
    };
  });
  
  // Methods
  async function fetchUsers() {
    loading = true;
    error = null;
    
    try {
      const response = await fetch('/api/admin/users');
      
      if (!response.ok) {
        throw new Error(`Failed to fetch users: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        users = data.users;
        totalItems = data.total || users.length; // Use total from API or fallback to users length
      } else {
        throw new Error(data.error || 'Failed to fetch users');
      }
    } catch (err) {
      console.error('Error fetching users:', err);
      error = err.message;
    } finally {
      loading = false;
    }
  }
  
  function handleSearch() {
    currentPage = 1; // Reset to first page when searching
  }
  
  function clearFilters() {
    searchQuery = '';
    roleFilter = 'all';
    statusFilter = 'all';
    currentPage = 1;
  }
  
  function editUser(user) {
    // Store previous data for undo functionality
    previousUserData = JSON.parse(JSON.stringify(user));
    
    // Create a copy of the user to edit
    editingUser = JSON.parse(JSON.stringify(user));
    modalOpen = true;
    
    // Reset validation state
    emailError = '';
    displayNameError = '';
    saveSuccess = false;
  }
  
  function createUser() {
    editingUser = null;
    modalOpen = true;
    
    // Scroll to top to ensure modal is visible
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  
  async function saveUser() {
    if (!validateUserForm()) {
      return;
    }
    
    isSubmitting = true;
    
    try {
      const response = await fetch(`/api/admin/users/${editingUser._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editingUser),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update user');
      }
      
      const data = await response.json();
      
      if (data.success) {
        // Update the user in the list
        users = users.map(u => u._id === editingUser._id ? editingUser : u);
        
        // Show success message
        saveSuccess = true;
        
        // Auto-close after 2 seconds
        setTimeout(() => {
          modalOpen = false;
          saveSuccess = false;
        }, 2000);
      } else {
        throw new Error(data.error || 'Failed to update user');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Failed to update user: ' + error.message);
    } finally {
      isSubmitting = false;
    }
  }
  
  function deleteUser(user) {
    userToDelete = user;
    deleteConfirmOpen = true;
    
    // Scroll to top to ensure modal is visible
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  
  async function confirmDelete() {
    if (!userToDelete) return;
    
    loading = true;
    error = null;
    
    try {
      const response = await fetch(`/api/admin/users/${userToDelete._id}`, {
        method: 'DELETE'
      });
      
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to delete user');
      }
      
      // Refresh users list
      await fetchUsers();
      
      // Close modal
      deleteConfirmOpen = false;
      userToDelete = null;
    } catch (err) {
      console.error('Error deleting user:', err);
      error = err.message;
    } finally {
      loading = false;
    }
  }
  
  async function changeRole(user, newIsAdmin) {
    loading = true;
    error = null;
    
    try {
      const response = await fetch(`/api/admin/users/${user._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          isAdmin: newIsAdmin
        })
      });
      
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to update user role');
      }
      
      // Refresh users list
      await fetchUsers();
    } catch (err) {
      console.error('Error updating user role:', err);
      error = err.message;
    } finally {
      loading = false;
    }
  }
  
  async function toggleStatus(user) {
    const newStatus = user.status === 'active' ? 'inactive' : 'active';
    loading = true;
    error = null;
    
    try {
      const response = await fetch(`/api/admin/users/${user._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          status: newStatus
        })
      });
      
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Failed to update user status');
      }
      
      // Refresh users list
      await fetchUsers();
    } catch (err) {
      console.error('Error updating user status:', err);
      error = err.message;
    } finally {
      loading = false;
    }
  }
  
  function changePage(page) {
    // Ensure page is within valid range
    currentPage = Math.max(1, Math.min(page, totalPages));
    
    // Scroll to top of table for better UX
    const tableContainer = document.querySelector('.table-container');
    if (tableContainer) {
      tableContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  
  // Function to view activity log
  async function fetchUserActivityLog(userId) {
    loadingActivityLog = true;
    error = null;
    
    try {
      const response = await fetch(`/api/admin/users/${userId}/activity`);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch activity log: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        userActivityLog = data.activityLog;
      } else {
        throw new Error(data.error || 'Failed to fetch activity log');
      }
    } catch (err) {
      console.error('Error fetching activity log:', err);
      error = err.message;
    } finally {
      loadingActivityLog = false;
    }
  }
  
  function viewActivityLog(user) {
    selectedUserForLog = user;
    activityLogOpen = true;
    fetchUserActivityLog(user._id);
    
    // Scroll to top to ensure modal is visible
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  
  function formatDate(dateString) {
    return new Date(dateString).toLocaleString();
  }
  
  // Add keyboard shortcut handling
  function handleKeydown(event) {
    // Don't handle shortcuts if user is typing in an input/textarea
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
      return;
    }
    
    // Check for modifier keys
    const isCtrlOrCmd = event.ctrlKey || event.metaKey;
    const isShift = event.shiftKey;
    
    // Handle different keyboard shortcuts
    switch (event.key.toLowerCase()) {
      case 'escape':
        // Close modals if open
        if (modalOpen) {
          cancelEdit();
        } else if (activityLogOpen) {
          activityLogOpen = false;
        } else if (deleteConfirmOpen) {
          deleteConfirmOpen = false;
        }
        break;
        
      case 's':
        if (isCtrlOrCmd && modalOpen) {
          event.preventDefault();
          saveUser();
        }
        break;
    }
  }
  
  // Enhanced validation function
  function validateUserForm() {
    let isValid = true;
    
    // Reset errors
    emailError = '';
    displayNameError = '';
    
    // Validate email
    if (!editingUser.email) {
      emailError = 'Email is required';
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(editingUser.email)) {
      emailError = 'Please enter a valid email address';
      isValid = false;
    }
    
    // Validate display name
    if (!editingUser.displayName) {
      displayNameError = 'Display name is required';
      isValid = false;
    } else if (editingUser.displayName.length < 2) {
      displayNameError = 'Display name must be at least 2 characters';
      isValid = false;
    }
    
    return isValid;
  }
  
  // Add undo function
  function undoChanges() {
    if (previousUserData) {
      editingUser = JSON.parse(JSON.stringify(previousUserData));
      saveUser();
    }
  }
  
  // Enhanced cancel edit function
  function cancelEdit() {
    modalOpen = false;
    editingUser = null;
    previousUserData = null;
    emailError = '';
    displayNameError = '';
    saveSuccess = false;
  }
  
  // Add this function to filter activity log
  $: filteredActivityLog = userActivityLog
    .filter(activity => {
      // Apply search filter
      if (activitySearch) {
        const searchLower = activitySearch.toLowerCase();
        const actionMatch = activity.action.toLowerCase().includes(searchLower);
        const detailsMatch = activity.details && 
          JSON.stringify(activity.details).toLowerCase().includes(searchLower);
        
        if (!actionMatch && !detailsMatch) return false;
      }
      
      // Apply type filter
      if (activityFilter !== 'all') {
        if (activityFilter === 'login' && !activity.action.includes('login')) return false;
        if (activityFilter === 'admin' && !activity.action.includes('admin')) return false;
        if (activityFilter === 'data' && !activity.action.includes('data')) return false;
      }
      
      // Apply date range filter
      if (activityDateRange !== 'all') {
        const now = new Date();
        const activityDate = new Date(activity.timestamp);
        
        if (activityDateRange === 'today') {
          if (activityDate.toDateString() !== now.toDateString()) return false;
        } else if (activityDateRange === 'week') {
          const oneWeekAgo = new Date(now);
          oneWeekAgo.setDate(now.getDate() - 7);
          if (activityDate < oneWeekAgo) return false;
        } else if (activityDateRange === 'month') {
          const oneMonthAgo = new Date(now);
          oneMonthAgo.setMonth(now.getMonth() - 1);
          if (activityDate < oneMonthAgo) return false;
        }
      }
      
      return true;
    });
  
  // Add this function to get activity icon
  function getActivityIcon(action) {
    if (action.includes('login')) return 'bi-box-arrow-in-right';
    if (action.includes('admin')) return 'bi-shield-lock';
    if (action.includes('data')) return 'bi-database';
    if (action.includes('create')) return 'bi-plus-circle';
    if (action.includes('update')) return 'bi-pencil';
    if (action.includes('delete')) return 'bi-trash';
    return 'bi-activity';
  }
  
  // Add this function to get activity color
  function getActivityColor(action) {
    if (action.includes('login')) return 'var(--info)';
    if (action.includes('admin')) return 'var(--warning)';
    if (action.includes('data')) return 'var(--success)';
    if (action.includes('create')) return 'var(--success)';
    if (action.includes('update')) return 'var(--primary)';
    if (action.includes('delete')) return 'var(--danger)';
    return 'var(--text-1)';
  }
  
  // Add this function to export activity log
  function exportActivityLog() {
    if (!userActivityLog.length) return;
    
    // Create CSV content
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += 'Action,Timestamp,Details\n';
    
    userActivityLog.forEach(activity => {
      const row = [
        activity.action,
        formatDate(activity.timestamp),
        activity.details ? JSON.stringify(activity.details) : ''
      ];
      csvContent += row.join(',') + '\n';
    });
    
    // Create download link
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `activity_log_${selectedUserForLog.displayName}_${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  // Add this function to get visible page numbers for pagination
  function getVisiblePages(current, total) {
    // Always show first and last page
    const pages = [1, total];
    
    // Show pages around current page
    for (let i = Math.max(2, current - 1); i <= Math.min(total - 1, current + 1); i++) {
      pages.push(i);
    }
    
    // Sort and remove duplicates
    return [...new Set(pages)].sort((a, b) => a - b);
  }
</script>

<div class="user-manager">
  <div class="header">
    <h2>User Management</h2>
    <button 
      class="create-btn"
      on:click={createUser}
      disabled={loading}
    >
      <i class="bi bi-person-plus"></i>
      Create User
    </button>
  </div>
  
  <!-- Error message -->
  {#if error}
    <div class="error-message">
      <i class="bi bi-exclamation-circle"></i>
      {error}
    </div>
  {/if}
  
  <!-- Filters -->
  <div class="filters-section">
    <div class="search-group">
      <div class="search-input">
        <i class="bi bi-search"></i>
        <input 
          type="text"
          bind:value={searchQuery}
          placeholder="Search users..."
          disabled={loading}
          on:input={handleSearch}
        />
      </div>
      
      <div class="filter-selects">
        <select 
          bind:value={roleFilter}
          disabled={loading}
          on:change={handleSearch}
        >
          <option value="all">All Roles</option>
          <option value="admin">Admin</option>
          <option value="user">User</option>
        </select>
        
        <select 
          bind:value={statusFilter}
          disabled={loading}
          on:change={handleSearch}
        >
          <option value="all">All Status</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </div>
    </div>
    
    {#if searchQuery || roleFilter !== 'all' || statusFilter !== 'all'}
      <button 
        class="clear-btn"
        on:click={clearFilters}
        disabled={loading}
      >
        <i class="bi bi-x-circle"></i>
        Clear Filters
      </button>
    {/if}
  </div>
  
  <!-- Users Table -->
  <div class="table-container">
    {#if loading}
      <div class="loading-state">
        <i class="bi bi-arrow-repeat spin"></i>
        <span>Loading users...</span>
      </div>
    {:else if filteredUsers.length === 0}
      <div class="empty-state">
        <i class="bi bi-people"></i>
        <p>No users found</p>
        {#if searchQuery || roleFilter !== 'all' || statusFilter !== 'all'}
          <button 
            class="clear-btn"
            on:click={clearFilters}
          >
            Clear Filters
          </button>
        {/if}
      </div>
    {:else}
      <div class="table-header">
        <div class="table-info">
          <span>Showing {displayedUsers.length} of {totalFilteredUsers} users</span>
          {#if searchQuery || roleFilter !== 'all' || statusFilter !== 'all'}
            <span class="filtered-info">(filtered from {totalItems} total)</span>
          {:else}
            <span class="total-info">(total: {totalItems})</span>
          {/if}
        </div>
      </div>
      
      <table class="user-table">
        <thead>
          <tr>
            <th>User</th>
            <th>Role</th>
            <th>Status</th>
            <th>Last Login</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {#each displayedUsers as user}
            <tr class="user-row {user.status || 'active'}">
              <td class="user-info">
                <div class="user-email">{user.email}</div>
                {#if user.displayName}
                  <div class="user-name">{user.displayName}</div>
                {/if}
              </td>
              <td>
                <button 
                  class="role-badge {user.isAdmin ? 'admin' : 'user'}"
                  on:click={() => changeRole(user, !user.isAdmin)}
                  disabled={loading}
                >
                  {user.isAdmin ? 'Admin' : 'User'}
                </button>
              </td>
              <td>
                <button 
                  class="status-badge {user.status || 'active'}"
                  on:click={() => toggleStatus(user)}
                  disabled={loading}
                >
                  {user.status || 'active'}
                </button>
              </td>
              <td class="last-login">
                {#if user.lastLogin}
                  {new Date(user.lastLogin).toLocaleString()}
                {:else}
                  Never
                {/if}
              </td>
              <td class="user-actions">
                <div class="action-buttons">
                  <button 
                    class="edit-btn"
                    on:click={() => editUser(user)}
                    title="Edit user"
                  >
                    <i class="bi bi-pencil"></i>
                  </button>
                  
                  <button 
                    class="activity-btn"
                    on:click={() => viewActivityLog(user)}
                    title="View activity log"
                  >
                    <i class="bi bi-clock-history"></i>
                  </button>
                  
                  <button 
                    class="delete-btn"
                    on:click={() => {
                      userToDelete = user;
                      deleteConfirmOpen = true;
                    }}
                    title="Delete user"
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
      
      <!-- Pagination -->
      <div class="pagination-container">
        <div class="pagination-info">
          Page {currentPage} of {totalPages} ({totalFilteredUsers} users)
        </div>
        {#if totalPages > 1}
          <div class="pagination">
            <button 
              class="page-btn"
              disabled={currentPage === 1}
              on:click={() => changePage(currentPage - 1)}
            >
              <i class="bi bi-chevron-left"></i>
            </button>
            
            {#each getVisiblePages(currentPage, totalPages) as page, i}
              {#if i > 0 && page - getVisiblePages(currentPage, totalPages)[i-1] > 1}
                <span class="pagination-ellipsis">...</span>
              {/if}
              <button 
                class="page-btn {currentPage === page ? 'active' : ''}"
                on:click={() => changePage(page)}
              >
                {page}
              </button>
            {/each}
            
            <button 
              class="page-btn"
              disabled={currentPage === totalPages}
              on:click={() => changePage(currentPage + 1)}
            >
              <i class="bi bi-chevron-right"></i>
            </button>
          </div>
        {/if}
      </div>
    {/if}
  </div>
</div>

<!-- User Modal -->
{#if modalOpen}
<Modal 
  on:close={cancelEdit}
>
  <div slot="header">
    <h2>{editingUser ? 'Edit User' : 'Create User'}</h2>
  </div>
  
  <div slot="body">
    <div class="edit-form">
      {#if saveSuccess}
        <div class="success-message">
          <i class="bi bi-check-circle"></i>
          <span>User updated successfully!</span>
        </div>
      {/if}
      
      <div class="form-group">
        <label for="edit-displayName">Display Name</label>
        <input 
          type="text" 
          id="edit-displayName"
          bind:value={editingUser.displayName}
          class:error={displayNameError}
          placeholder="Enter display name"
          disabled={isSubmitting}
        />
        {#if displayNameError}
          <div class="error-message">{displayNameError}</div>
        {/if}
      </div>
      
      <div class="form-group">
        <label for="edit-email">Email</label>
        <input 
          type="email" 
          id="edit-email"
          bind:value={editingUser.email}
          class:error={emailError}
          placeholder="Enter email"
          disabled={isSubmitting}
        />
        {#if emailError}
          <div class="error-message">{emailError}</div>
        {/if}
      </div>
      
      <div class="form-group">
        <label for="edit-isAdmin">Admin Status</label>
        <div class="toggle-switch">
          <input 
            type="checkbox" 
            id="edit-isAdmin"
            bind:checked={editingUser.isAdmin}
            disabled={isSubmitting}
          />
          <span class="toggle-slider"></span>
        </div>
        <span class="toggle-label">{editingUser.isAdmin ? 'Admin User' : 'Regular User'}</span>
      </div>
      
      <div class="form-group">
        <label for="edit-status">Status</label>
        <select 
          id="edit-status"
          bind:value={editingUser.status}
          disabled={isSubmitting}
        >
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
          <option value="suspended">Suspended</option>
        </select>
      </div>
      
      <div class="form-actions">
        <button 
          class="cancel-btn" 
          on:click={cancelEdit}
          disabled={isSubmitting}
        >
          Cancel
        </button>
        <button 
          class="save-btn" 
          on:click={saveUser}
          disabled={isSubmitting}
        >
          {#if isSubmitting}
            <i class="bi bi-arrow-repeat spin"></i>
            Saving...
          {:else}
            <i class="bi bi-save"></i>
            Save Changes
          {/if}
        </button>
      </div>
      
      {#if previousUserData}
        <div class="undo-section">
          <button 
            class="undo-btn" 
            on:click={undoChanges}
            disabled={isSubmitting}
          >
            <i class="bi bi-arrow-counterclockwise"></i>
            Undo Changes
          </button>
        </div>
      {/if}
      
      <div class="keyboard-shortcuts">
        <p><kbd>Esc</kbd> Cancel</p>
        <p><kbd>Ctrl</kbd> + <kbd>S</kbd> Save</p>
      </div>
    </div>
  </div>
</Modal>
{/if}

<!-- Delete Confirmation Modal -->
{#if deleteConfirmOpen}
<Modal 
  on:close={() => {
    deleteConfirmOpen = false;
    userToDelete = null;
  }}
>
  <div slot="header">
    <h2>Confirm Deletion</h2>
  </div>
  
  <div slot="body">
    <div class="delete-warning">
      <i class="bi bi-exclamation-triangle"></i>
      <p>This action cannot be undone!</p>
    </div>
    
    <p>Are you sure you want to delete this user?</p>
    <p class="delete-detail">
      <strong>Name:</strong> {userToDelete?.displayName || 'N/A'}<br>
      <strong>Email:</strong> {userToDelete?.email || 'N/A'}<br>
      <strong>Role:</strong> {userToDelete?.isAdmin ? 'Admin' : 'User'}
    </p>
  </div>
  
  <div slot="footer">
    <div class="modal-actions">
      <button 
        class="cancel-btn" 
        on:click={() => {
          deleteConfirmOpen = false;
          userToDelete = null;
        }}
        disabled={loading}
      >
        Cancel
      </button>
      <button 
        class="danger-btn" 
        on:click={confirmDelete}
        disabled={loading}
      >
        {#if loading}
          <i class="bi bi-arrow-repeat spin"></i>
          Deleting...
        {:else}
          <i class="bi bi-trash"></i>
          Delete
        {/if}
      </button>
    </div>
  </div>
</Modal>
{/if}

<!-- Activity Log Modal -->
{#if activityLogOpen}
<Modal 
  on:close={() => {
    activityLogOpen = false;
    selectedUserForLog = null;
    userActivityLog = [];
    activityFilter = 'all';
    activitySearch = '';
    activityDateRange = 'all';
  }}
>
  <div slot="header">
    <h2>Activity Log: {selectedUserForLog?.displayName || 'User'}</h2>
  </div>
  
  <div slot="body">
    <div class="activity-log-container">
      {#if loadingActivityLog}
        <div class="loading-state">
          <i class="bi bi-arrow-repeat spin"></i>
          <span>Loading activity log...</span>
        </div>
      {:else if userActivityLog.length === 0}
        <div class="empty-state">
          <i class="bi bi-clock-history"></i>
          <p>No activity recorded</p>
        </div>
      {:else}
        <div class="activity-filters">
          <div class="filter-group">
            <label for="activity-search">Search</label>
            <div class="search-input">
              <i class="bi bi-search"></i>
              <input 
                type="text" 
                id="activity-search" 
                bind:value={activitySearch} 
                placeholder="Search activities..."
              />
            </div>
          </div>
          
          <div class="filter-group">
            <label for="activity-filter">Activity Type</label>
            <select id="activity-filter" bind:value={activityFilter}>
              <option value="all">All Activities</option>
              <option value="login">Login Activities</option>
              <option value="admin">Admin Actions</option>
              <option value="data">Data Operations</option>
            </select>
          </div>
          
          <div class="filter-group">
            <label for="activity-date">Time Period</label>
            <select id="activity-date" bind:value={activityDateRange}>
              <option value="all">All Time</option>
              <option value="today">Today</option>
              <option value="week">Last 7 Days</option>
              <option value="month">Last 30 Days</option>
            </select>
          </div>
          
          <div class="filter-actions">
            <button 
              class="export-btn" 
              on:click={exportActivityLog}
              disabled={filteredActivityLog.length === 0}
            >
              <i class="bi bi-download"></i>
              Export CSV
            </button>
          </div>
        </div>
        
        <div class="activity-summary">
          <span>Showing {filteredActivityLog.length} of {userActivityLog.length} activities</span>
        </div>
        
        <div class="activity-timeline">
          {#each filteredActivityLog as activity, i}
            <div class="timeline-item">
              <div class="timeline-icon" style="background-color: {getActivityColor(activity.action)}">
                <i class="bi {getActivityIcon(activity.action)}"></i>
              </div>
              <div class="timeline-content">
                <div class="timeline-header">
                  <span class="timeline-action">{activity.action}</span>
                  <span class="timeline-time">{formatDate(activity.timestamp)}</span>
                </div>
                {#if activity.details}
                  <div class="timeline-details">
                    {#each Object.entries(activity.details) as [key, value]}
                      <div class="detail-item">
                        <span class="detail-key">{key}:</span>
                        <span class="detail-value">
                          {#if typeof value === 'object'}
                            <pre>{JSON.stringify(value, null, 2)}</pre>
                          {:else}
                            {value}
                          {/if}
                        </span>
                      </div>
                    {/each}
                  </div>
                {/if}
              </div>
            </div>
          {/each}
        </div>
      {/if}
    </div>
  </div>
</Modal>
{/if}

<style>
  /* Container styles */
  .user-manager {
    max-width: 800px; /* Even more reduced width */
    margin: 0 auto;
    padding: 1.25rem;
    background-color: var(--surface-1);
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    position: relative; /* Add position relative to establish stacking context */
    z-index: 1; /* Lower z-index for the container */
  }
  
  /* Header styles */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .header h2 {
    margin: 0;
    color: var(--text-1);
    font-size: 1.25rem;
  }
  
  .create-btn {
    padding: 0.5rem 0.75rem;
    border: none;
    border-radius: 0.375rem;
    background: var(--primary);
    color: white;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .create-btn:hover:not(:disabled) {
    background: var(--primary-dark);
  }
  
  /* Error message styles */
  .error-message {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem;
    background: var(--danger-light);
    border: 1px solid var(--danger);
    border-radius: 0.375rem;
    color: var(--danger);
    margin-bottom: 1rem;
    font-size: 0.875rem;
  }
  
  /* Filters section styles */
  .filters-section {
    margin-bottom: 1.5rem;
    padding: 1rem;
    background: var(--surface-2);
    border-radius: 0.375rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  
  .search-group {
    display: flex;
    gap: 0.75rem;
    flex: 1;
  }
  
  .search-input {
    flex: 1;
    position: relative;
  }
  
  .search-input i {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-3);
  }
  
  .search-input input {
    width: 100%;
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    border: 1px solid var(--border);
    border-radius: 0.375rem;
    font-size: 0.875rem;
    transition: all 0.2s ease;
  }
  
  .search-input input:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }
  
  .filter-selects {
    display: flex;
    gap: 0.75rem;
  }
  
  .filter-selects select {
    padding: 0.5rem 1.5rem 0.5rem 0.75rem;
    border: 1px solid var(--border);
    border-radius: 0.375rem;
    font-size: 0.875rem;
    background: var(--surface-1);
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .filter-selects select:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }
  
  .clear-btn {
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--border);
    border-radius: 0.375rem;
    background: var(--surface-1);
    color: var(--text-2);
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .clear-btn:hover:not(:disabled) {
    background: var(--surface-2);
    border-color: var(--primary);
    color: var(--primary);
  }
  
  /* Table styles */
  .table-container {
    min-height: 300px;
  }
  
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
  }
  
  .table-info {
    font-size: 0.875rem;
    color: var(--text-3);
  }
  
  .filtered-info, .total-info {
    margin-left: 0.5rem;
    font-style: italic;
  }
  
  .user-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
  }
  
  .user-table th {
    background: var(--surface-2);
    padding: 0.75rem;
    text-align: left;
    font-weight: 500;
    color: var(--text-2);
  }
  
  .user-table td {
    padding: 0.75rem;
    border-bottom: 1px solid var(--border);
  }
  
  .user-row:hover {
    background: var(--surface-2);
  }
  
  .user-row.inactive {
    opacity: 0.7;
  }
  
  .user-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .user-email {
    color: var(--text-1);
    font-weight: 500;
  }
  
  .user-name {
    color: var(--text-3);
    font-size: 0.75rem;
  }
  
  .role-badge, .status-badge {
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    font-size: 0.75rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
  }
  
  .role-badge.admin {
    background: var(--primary-light);
    color: var(--primary-dark);
  }
  
  .role-badge.user {
    background: var(--surface-3);
    color: var(--text-2);
  }
  
  .status-badge.active {
    background: var(--success-light);
    color: var(--success-dark);
  }
  
  .status-badge.inactive {
    background: var(--danger-light);
    color: var(--danger-dark);
  }
  
  .last-login {
    color: var(--text-3);
    font-size: 0.75rem;
  }
  
  .user-actions {
    display: flex;
    gap: 0.25rem;
  }
  
  .action-buttons {
    display: flex;
    gap: 0.25rem;
    justify-content: flex-end;
  }
  
  .action-buttons button {
    padding: 0.375rem;
    border: none;
    border-radius: 0.25rem;
    background: transparent;
    color: var(--text-2);
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .action-buttons button:hover {
    background: var(--surface-2);
    color: var(--text-1);
  }
  
  .edit-btn:hover {
    color: var(--primary);
  }
  
  .activity-btn:hover {
    color: var(--info);
  }
  
  .delete-btn:hover {
    color: var(--danger);
  }
  
  /* Loading and empty states */
  .loading-state, .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    padding: 3rem 0;
    color: var(--text-3);
  }
  
  /* Pagination styles */
  .pagination-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: var(--surface-2);
    border-radius: 0.375rem;
  }
  
  .pagination-info {
    font-size: 0.875rem;
    color: var(--text-2);
    font-weight: 500;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    gap: 0.25rem;
  }
  
  .pagination-ellipsis {
    display: flex;
    align-items: center;
    padding: 0 0.25rem;
    color: var(--text-3);
  }
  
  .page-btn {
    padding: 0.375rem 0.75rem;
    border: 1px solid var(--border);
    border-radius: 0.25rem;
    background: var(--surface-1);
    color: var(--text-2);
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .page-btn:hover:not(:disabled) {
    background: var(--surface-2);
    border-color: var(--primary);
    color: var(--primary);
  }
  
  .page-btn.active {
    background: var(--primary);
    border-color: var(--primary);
    color: white;
  }
  
  .page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .user-manager {
      padding: 1rem;
      max-width: 100%;
    }
    
    .header {
      flex-direction: column;
      gap: 0.75rem;
      align-items: stretch;
    }
    
    .search-group {
      flex-direction: column;
    }
    
    .filter-selects {
      flex-direction: column;
    }
    
    .user-table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
    
    .user-info {
      min-width: 150px;
    }
    
    .user-actions {
      min-width: 80px;
    }
  }
  
  /* Activity log styles */
  .activity-log-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-height: 70vh;
    overflow-y: auto;
    padding: 0.5rem;
  }
  
  .activity-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    background-color: var(--surface-2);
    border-radius: 0.5rem;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  .filter-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
    min-width: 200px;
  }
  
  .filter-group label {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-2);
  }
  
  .search-input {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .search-input i {
    position: absolute;
    left: 0.75rem;
    color: var(--text-3);
  }
  
  .search-input input {
    width: 100%;
    padding: 0.75rem 0.75rem 0.75rem 2.5rem;
    border: 1px solid var(--border);
    border-radius: 0.375rem;
    font-size: 0.875rem;
    background-color: var(--surface-1);
    color: var(--text-1);
  }
  
  .search-input input:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }
  
  .filter-group select {
    padding: 0.75rem;
    border: 1px solid var(--border);
    border-radius: 0.375rem;
    font-size: 0.875rem;
    background-color: var(--surface-1);
    color: var(--text-1);
  }
  
  .filter-actions {
    display: flex;
    align-items: flex-end;
  }
  
  .export-btn {
    padding: 0.75rem 1rem;
    background-color: var(--surface-1);
    color: var(--text-1);
    border: 1px solid var(--border);
    border-radius: 0.375rem;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .export-btn:hover:not(:disabled) {
    background-color: var(--surface-2);
    border-color: var(--primary);
    color: var(--primary);
  }
  
  .export-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .activity-summary {
    font-size: 0.875rem;
    color: var(--text-3);
    text-align: right;
    padding: 0 0.5rem;
  }
  
  .activity-timeline {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem;
  }
  
  .timeline-item {
    display: flex;
    gap: 1rem;
    position: relative;
  }
  
  .timeline-item:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 2rem;
    left: 1.25rem;
    bottom: -1rem;
    width: 2px;
    background-color: var(--border);
  }
  
  .timeline-icon {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-shrink: 0;
  }
  
  .timeline-content {
    flex: 1;
    background-color: var(--surface-2);
    border-radius: 0.5rem;
    padding: 1rem;
  }
  
  .timeline-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .timeline-action {
    font-weight: 600;
    color: var(--text-1);
  }
  
  .timeline-time {
    font-size: 0.75rem;
    color: var(--text-3);
  }
  
  .timeline-details {
    background-color: var(--surface-3);
    border-radius: 0.375rem;
    padding: 0.75rem;
    font-size: 0.875rem;
  }
  
  .detail-item {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
  }
  
  .detail-key {
    font-weight: 500;
    color: var(--text-2);
    min-width: 80px;
  }
  
  .detail-value {
    color: var(--text-1);
    word-break: break-word;
  }
  
  .detail-value pre {
    margin: 0;
    white-space: pre-wrap;
    font-family: monospace;
    font-size: 0.75rem;
  }
  
  .loading-state,
  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    gap: 1rem;
    color: var(--text-3);
  }
  
  .loading-state i,
  .empty-state i {
    font-size: 2rem;
  }
  
  .spin {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Modal styles */
  .edit-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .form-group label {
    font-weight: 500;
    color: var(--text-2);
  }
  
  .form-group input,
  .form-group select {
    padding: 0.75rem;
    border: 1px solid var(--border);
    border-radius: 0.375rem;
    font-size: 0.875rem;
    background-color: var(--surface-1);
    color: var(--text-1);
  }
  
  .form-group input:focus,
  .form-group select:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }
  
  .form-group input.error {
    border-color: var(--danger);
  }
  
  .error-message {
    color: var(--danger);
    font-size: 0.75rem;
  }
  
  .success-message {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem;
    background-color: var(--success-light);
    color: var(--success);
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }
  
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 1.5rem;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--surface-2);
    transition: 0.4s;
    border-radius: 1.5rem;
  }
  
  .toggle-slider:before {
    position: absolute;
    content: "";
    height: 1.125rem;
    width: 1.125rem;
    left: 0.1875rem;
    bottom: 0.1875rem;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .toggle-slider {
    background-color: var(--primary);
  }
  
  input:checked + .toggle-slider:before {
    transform: translateX(1.5rem);
  }
  
  .toggle-label {
    margin-left: 0.5rem;
    font-size: 0.875rem;
    color: var(--text-2);
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .cancel-btn,
  .save-btn,
  .undo-btn {
    padding: 0.75rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .cancel-btn {
    background-color: var(--surface-1);
    color: var(--text-1);
    border: 1px solid var(--border);
  }
  
  .cancel-btn:hover:not(:disabled) {
    background-color: var(--surface-2);
  }
  
  .save-btn {
    background-color: var(--primary);
    color: white;
    border: none;
  }
  
  .save-btn:hover:not(:disabled) {
    background-color: var(--primary-dark);
  }
  
  .undo-btn {
    background-color: var(--surface-1);
    color: var(--text-1);
    border: 1px solid var(--border);
  }
  
  .undo-btn:hover:not(:disabled) {
    background-color: var(--surface-2);
  }
  
  .undo-section {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .keyboard-shortcuts {
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: 1px dashed var(--border);
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    font-size: 0.75rem;
    color: var(--text-3);
  }
  
  .keyboard-shortcuts p {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  
  kbd {
    background-color: var(--surface-2);
    border: 1px solid var(--border);
    border-radius: 0.25rem;
    padding: 0.125rem 0.375rem;
    font-size: 0.75rem;
    font-family: monospace;
  }
  
  .delete-warning {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem;
    background-color: var(--danger-light);
    color: var(--danger);
    border-radius: 0.375rem;
    margin-bottom: 1rem;
  }
  
  .delete-warning i {
    font-size: 1.25rem;
  }
  
  .delete-detail {
    background-color: var(--surface-2);
    padding: 1rem;
    border-radius: 0.375rem;
    margin-top: 1rem;
    font-size: 0.875rem;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
  
  .danger-btn {
    padding: 0.75rem 1rem;
    background-color: var(--danger);
    color: white;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .danger-btn:hover:not(:disabled) {
    background-color: var(--danger-dark);
  }
</style> 