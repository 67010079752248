<script>
    import { onMount, onDestroy, createEventDispatcher } from 'svelte';
    import { selectionStore } from '../stores/selectionStore';
    
    export let imageElement;
    const dispatch = createEventDispatcher();
    
    let selectionBox;
    let isSelecting = false;
    let startX = 0;
    let startY = 0;

    // Track selection state
    $: if ($selectionStore.isSelecting) {
        enableSelection();
    } else {
        disableSelection();
    }

    function enableSelection() {
        if (!selectionBox) {
            createSelectionBox();
        }
        selectionBox.style.display = 'none';
        document.addEventListener('mousedown', handleMouseDown);
    }

    function disableSelection() {
        if (selectionBox) {
            selectionBox.style.display = 'none';
        }
        document.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    }

    function createSelectionBox() {
        selectionBox = document.createElement('div');
        selectionBox.className = 'selection-box';
        imageElement.parentNode.appendChild(selectionBox);
    }

    function handleMouseDown(e) {
        // Only start selection if clicking inside image bounds
        const imageBounds = imageElement.getBoundingClientRect();
        if (e.clientX < imageBounds.left || e.clientX > imageBounds.right ||
            e.clientY < imageBounds.top || e.clientY > imageBounds.bottom) {
            return;
        }

        isSelecting = true;
        startX = e.clientX;
        startY = e.clientY;

        // Position selection box
        selectionBox.style.left = `${startX}px`;
        selectionBox.style.top = `${startY}px`;
        selectionBox.style.width = '0';
        selectionBox.style.height = '0';
        selectionBox.style.display = 'block';

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        e.preventDefault();
    }

    function handleMouseMove(e) {
        if (!isSelecting) return;

        const currentX = e.clientX;
        const currentY = e.clientY;
        const imageBounds = imageElement.getBoundingClientRect();

        // Constrain selection to image bounds
        const x = Math.max(imageBounds.left, Math.min(currentX, imageBounds.right));
        const y = Math.max(imageBounds.top, Math.min(currentY, imageBounds.bottom));

        // Calculate dimensions
        const width = Math.abs(x - startX);
        const height = Math.abs(y - startY);
        const left = Math.min(x, startX);
        const top = Math.min(y, startY);

        // Update selection box
        selectionBox.style.left = `${left}px`;
        selectionBox.style.top = `${top}px`;
        selectionBox.style.width = `${width}px`;
        selectionBox.style.height = `${height}px`;
    }

    function handleMouseUp(e) {
        if (!isSelecting) return;
        isSelecting = false;

        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        const imageBounds = imageElement.getBoundingClientRect();
        const boxBounds = selectionBox.getBoundingClientRect();

        // Minimum size check
        if (boxBounds.width < 20 || boxBounds.height < 20) {
            selectionBox.style.display = 'none';
            return;
        }

        // Calculate relative coordinates as percentages and include image data
        const selection = {
            x: ((boxBounds.left - imageBounds.left) / imageBounds.width) * 100,
            y: ((boxBounds.top - imageBounds.top) / imageBounds.height) * 100,
            width: (boxBounds.width / imageBounds.width) * 100,
            height: (boxBounds.height / imageBounds.height) * 100,
            imageData: {
                src: imageElement.src,
                naturalWidth: imageElement.naturalWidth,
                naturalHeight: imageElement.naturalHeight,
                displayWidth: imageBounds.width,
                displayHeight: imageBounds.height
            }
        };

        selectionStore.setSelection(selection);
        dispatch('selectionComplete', { selection });

        // Hide selection box after a short delay
        setTimeout(() => {
            selectionBox.style.display = 'none';
        }, 200);
    }

    onDestroy(() => {
        disableSelection();
        if (selectionBox && selectionBox.parentNode) {
            selectionBox.parentNode.removeChild(selectionBox);
        }
    });
</script>

<style>
    :global(.selection-box) {
        position: fixed;
        border: 2px solid #2563eb;
        background-color: rgba(37, 99, 235, 0.1);
        pointer-events: none;
        z-index: 1000;
        border-radius: 4px;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
        display: none;
    }

    :global(.selection-box::before) {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        border: 1px dashed rgba(255, 255, 255, 0.8);
        border-radius: 4px;
        pointer-events: none;
    }
</style> 