<script>
  import { onMount, onDestroy } from 'svelte';
  import { slide } from 'svelte/transition';
  
  export let canvasComponent; // Receive the canvasComponent as a prop

  let selectedObject = null;
  let penColor = '#0066FF'; // Default color for pen - blue
  let markerColor = '#FF0000'; // Default color for marker - red
  let highlighterColor = 'rgba(255, 255, 0, 0.5)'; // Default color for highlighter - yellow with 50% opacity
  
  let penWidth = 2; // Default width for pen
  let markerWidth = 5; // Default width for marker
  let highlighterWidth = 10; // Default width for highlighter
  
  // Current active color and width based on selected tool
  let currentColor = '#0066FF';
  let currentWidth = 2;
  let currentTransparency = 0; // Default transparency as percentage (0% = fully opaque)
  
  let isSelecting = false; // Track selection mode
  
  let selectedTool = 'select'; // Default to select tool
  
  // Variable to store the eraser event handler
  let eraseHandler = null;
  
  // Controls visibility of the shared tool settings panel
  let showToolSettings = false;

  // Set up initial state
  onMount(() => {
    if (canvasComponent) {
      setDrawingMode('select');
    }
  });

  // Function to convert hex color to rgba
  function hexToRgba(hex, transparency) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);
    // Convert transparency to opacity (inverse: opacity = 1 - transparency/100)
    const opacity = 1 - (transparency / 100);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  // Function to parse rgba string to get transparency value
  function getRgbaTransparency(rgba) {
    if (!rgba || !rgba.startsWith('rgba')) return 0;
    
    try {
      const parts = rgba.match(/rgba\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*([\d.]+)\s*\)/);
      if (parts && parts.length === 5) {
        const opacity = parseFloat(parts[4]);
        // Convert opacity to transparency (inverse: transparency = (1-opacity) * 100)
        return Math.round((1 - opacity) * 100);
      }
    } catch (err) {
      console.error('Error parsing RGBA value:', err);
    }
    
    return 0;
  }

  // Function to set the drawing mode and brush properties
  function setDrawingMode(mode) {
    if (!canvasComponent) {
      console.error('Canvas component is not available');
      return;
    }

    const canvas = canvasComponent.getCanvas(); // Get the Fabric.js canvas instance
    
    // Remember previous tool
    const previousTool = selectedTool;
    selectedTool = mode;
    
    // Remove current event listeners to avoid duplication
    if (mode !== 'eraser' && eraseHandler) {
      canvas.off('mouse:down', eraseHandler);
      eraseHandler = null;
    }

    // Update the active color and width based on the selected tool
    showToolSettings = true;
    
    switch (mode) {
      case 'select':
        canvas.isDrawingMode = false; // Disable drawing mode for selection
        isSelecting = true; // Enable selection mode
        showToolSettings = false; // Hide tool settings for select mode
        
        // Set cursor
        setCursor(canvas, 'default');
        break;
      case 'pen':
        canvas.isDrawingMode = true;
        canvas.freeDrawingBrush.width = penWidth;
        
        // Apply color with transparency if needed
        if (penColor.startsWith('#')) {
          currentTransparency = 0; // Default: fully opaque
          canvas.freeDrawingBrush.color = penColor;
        } else if (penColor.startsWith('rgba')) {
          currentTransparency = getRgbaTransparency(penColor);
          canvas.freeDrawingBrush.color = penColor;
        } else {
          canvas.freeDrawingBrush.color = penColor;
        }
        
        // Update current color and width controls
        currentColor = penColor.startsWith('rgba') ? '#0066FF' : penColor;
        currentWidth = penWidth;
        
        // Set cursor
        setCursor(canvas, 'pencil');
        break;
      case 'marker':
        canvas.isDrawingMode = true;
        canvas.freeDrawingBrush.width = markerWidth;
        
        // Apply color with transparency if needed
        if (markerColor.startsWith('#')) {
          currentTransparency = 0; // Default: fully opaque
          canvas.freeDrawingBrush.color = markerColor;
        } else if (markerColor.startsWith('rgba')) {
          currentTransparency = getRgbaTransparency(markerColor);
          canvas.freeDrawingBrush.color = markerColor;
        } else {
          canvas.freeDrawingBrush.color = markerColor;
        }
        
        // Update current color and width controls
        currentColor = markerColor.startsWith('rgba') ? '#FF0000' : markerColor;
        currentWidth = markerWidth;
        
        // Set cursor
        setCursor(canvas, 'marker');
        break;
      case 'highlighter':
        canvas.isDrawingMode = true;
        canvas.freeDrawingBrush.width = highlighterWidth;
        canvas.freeDrawingBrush.color = highlighterColor;
        
        // Update current color and width controls
        currentColor = '#FFFF00'; // Yellow base color
        currentWidth = highlighterWidth;
        currentTransparency = 50; // Default 50% transparency for highlighter
        
        // Set cursor
        setCursor(canvas, 'highlighter');
        break;
      case 'eraser':
        // Disable drawing mode for eraser since we'll implement custom erasing
        canvas.isDrawingMode = false;
        showToolSettings = false; // Hide tool settings for eraser
        
        // Set cursor
        setCursor(canvas, 'eraser');
        
        // Set up eraser functionality
        setupEraser(canvas);
        break;
      default:
        canvas.isDrawingMode = false;
        setCursor(canvas, 'default');
    }
    
    canvas.renderAll();
  }

  // Function to update the current tool's color and width when changed in the shared controls
  function updateToolSettings() {
    if (!canvasComponent) return;
    
    const canvas = canvasComponent.getCanvas();
    
    // Create color with transparency if needed
    let colorWithTransparency = currentColor;
    if (currentTransparency > 0) {
      colorWithTransparency = hexToRgba(currentColor, currentTransparency);
    }
    
    // Save the current settings to the appropriate tool
    switch (selectedTool) {
      case 'pen':
        penColor = colorWithTransparency;
        penWidth = currentWidth;
        break;
      case 'marker':
        markerColor = colorWithTransparency;
        markerWidth = currentWidth;
        break;
      case 'highlighter':
        highlighterColor = colorWithTransparency;
        highlighterWidth = currentWidth;
        break;
    }
    
    // Apply the settings to the canvas
    if (canvas.isDrawingMode) {
      canvas.freeDrawingBrush.color = colorWithTransparency;
      canvas.freeDrawingBrush.width = currentWidth;
    }
    
    canvas.renderAll();
  }

  // Function to set cursor style
  function setCursor(canvas, cursorType) {
    let cursorStyle = 'default';
    const canvasEl = canvas.getElement();
    const canvasContainer = canvas.wrapperEl;
    
    // Remove any existing cursor classes
    if (canvasContainer) {
      canvasContainer.className = canvasContainer.className
        .replace(/\bcursor-\S+/g, '');
    }
    
    // Apply cursor based on type
    switch (cursorType) {
      case 'pencil':
        cursorStyle = 'crosshair';
        if (canvasContainer) canvasContainer.classList.add('cursor-pencil');
        break;
      case 'marker':
        cursorStyle = 'crosshair';
        if (canvasContainer) canvasContainer.classList.add('cursor-marker');
        break;
      case 'highlighter':
        cursorStyle = 'crosshair';
        if (canvasContainer) canvasContainer.classList.add('cursor-highlighter');
        break;
      case 'eraser':
        cursorStyle = 'crosshair';
        if (canvasContainer) canvasContainer.classList.add('cursor-eraser');
        break;
      default:
        cursorStyle = 'default';
    }
    
    // Apply the cursor style
    if (canvasContainer) {
      canvasContainer.style.cursor = cursorStyle;
    }
    if (canvasEl) {
      canvasEl.style.cursor = cursorStyle;
    }
    canvas.defaultCursor = cursorStyle;
    canvas.hoverCursor = cursorStyle;
  }

  // Function to set up eraser functionality
  function setupEraser(canvas) {
    // Create eraser handler function
    eraseHandler = function(options) {
      const pointer = canvas.getPointer(options.e);
      const objects = canvas.getObjects();
      
      // Check if any object is under the pointer
      for (let i = objects.length - 1; i >= 0; i--) {
        const object = objects[i];
        if (object.containsPoint(pointer)) {
          canvas.remove(object);
          canvas.requestRenderAll();
          break; // Remove only one object at a time
        }
      }
    };
    
    // Add mouse down handler for eraser
    canvas.on('mouse:down', eraseHandler);
  }

  // Clean up on component destruction
  onDestroy(() => {
    if (canvasComponent) {
      const canvas = canvasComponent.getCanvas();
      if (canvas && eraseHandler) {
        canvas.off('mouse:down', eraseHandler);
      }
    }
  });
</script>

<div class="draw-options">
  <div class="tool-list">
    <!-- SELECT - Moved to the top -->
    <div class="tool-group">
      <button 
        class="tool-card {selectedTool === 'select' ? 'selected' : ''}" 
        on:click={() => setDrawingMode('select')}
      >
        <div class="tool-icon">
          <i class="fas fa-hand-pointer"></i>
        </div>
        <div class="tool-text">
          <span class="tool-name">Select</span>
          <span class="tool-description">Select and move elements</span>
        </div>
        {#if selectedTool === 'select'}
          <div class="active-indicator"></div>
        {/if}
      </button>
    </div>

    <!-- PEN -->
    <div class="tool-group">
      <button 
        class="tool-card {selectedTool === 'pen' ? 'selected' : ''}" 
        on:click={() => setDrawingMode('pen')}
      >
        <div class="tool-icon">
          <i class="fas fa-pencil-alt"></i>
        </div>
        <div class="tool-text">
          <span class="tool-name">Pen</span>
          <span class="tool-description">Draw with precision</span>
        </div>
        {#if selectedTool === 'pen'}
          <div class="active-indicator"></div>
        {/if}
      </button>
    </div>

    <!-- MARKER -->
    <div class="tool-group">
      <button 
        class="tool-card {selectedTool === 'marker' ? 'selected' : ''}" 
        on:click={() => setDrawingMode('marker')}
      >
        <div class="tool-icon">
          <i class="fas fa-marker"></i>
        </div>
        <div class="tool-text">
          <span class="tool-name">Marker</span>
          <span class="tool-description">Mark up your story</span>
        </div>
        {#if selectedTool === 'marker'}
          <div class="active-indicator"></div>
        {/if}
      </button>
    </div>

    <!-- HIGHLIGHTER -->
    <div class="tool-group">
      <button 
        class="tool-card {selectedTool === 'highlighter' ? 'selected' : ''}" 
        on:click={() => setDrawingMode('highlighter')}
      >
        <div class="tool-icon">
          <i class="fas fa-highlighter"></i>
        </div>
        <div class="tool-text">
          <span class="tool-name">Highlighter</span>
          <span class="tool-description">Highlight important parts</span>
        </div>
        {#if selectedTool === 'highlighter'}
          <div class="active-indicator"></div>
        {/if}
      </button>
    </div>

    <!-- ERASER -->
    <div class="tool-group">
      <button 
        class="tool-card {selectedTool === 'eraser' ? 'selected' : ''}" 
        on:click={() => setDrawingMode('eraser')}
      >
        <div class="tool-icon">
          <i class="fas fa-eraser"></i>
        </div>
        <div class="tool-text">
          <span class="tool-name">Eraser</span>
          <span class="tool-description">Erase unwanted parts</span>
        </div>
        {#if selectedTool === 'eraser'}
          <div class="active-indicator"></div>
        {/if}
      </button>
      {#if selectedTool === 'eraser'}
        <div class="tool-settings" transition:slide>
          <div class="settings-row">
            <span>Click on objects to erase them</span>
          </div>
        </div>
      {/if}
    </div>
    
    <!-- SHARED TOOL SETTINGS PANEL -->
    {#if showToolSettings}
      <div class="shared-settings-panel" transition:slide>
        <div class="settings-header">
          <span class="settings-title">{selectedTool.charAt(0).toUpperCase() + selectedTool.slice(1)} Settings</span>
        </div>
        <div class="settings-content">
          <div class="settings-group">
            <label>Weight</label>
            <div class="slider-container">
              <input 
                type="range" 
                min="1" 
                max="20" 
                bind:value={currentWidth} 
                on:input={updateToolSettings} 
              />
              <div class="value-display">{currentWidth}</div>
            </div>
          </div>
          
          <div class="settings-group">
            <label>Transparency</label>
            <div class="slider-container">
              <input 
                type="range" 
                min="0" 
                max="100" 
                bind:value={currentTransparency} 
                on:input={updateToolSettings} 
              />
              <div class="value-display">{currentTransparency}%</div>
            </div>
          </div>
          
          <div class="color-selector">
            <input 
              type="color" 
              bind:value={currentColor} 
              on:input={updateToolSettings} 
              class="color-input"
            />
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  .draw-options {
    height: 100%;
    padding: 20px;
    background: #f8f9fa;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #cbd5e1 transparent;
  }

  .draw-options::-webkit-scrollbar {
    width: 8px;
  }

  .draw-options::-webkit-scrollbar-track {
    background: transparent;
  }

  .draw-options::-webkit-scrollbar-thumb {
    background-color: #cbd5e1;
    border-radius: 4px;
  }

  .tool-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 600px;
    margin: 0 auto;
  }

  .tool-group {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  }

  .tool-card {
    width: 100%;
    background: transparent;
    border: none;
    padding: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 16px;
    text-align: left;
    position: relative;
  }

  .tool-card:hover {
    background: #f8f9fa;
  }

  .tool-card.selected {
    background: #f0f7ff;
  }

  .active-indicator {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background: #007AFF;
    border-radius: 0 4px 4px 0;
  }

  .tool-icon {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    background: #f0f7ff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tool-icon i {
    font-size: 18px;
    color: #007AFF;
  }

  .tool-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .tool-name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }

  .tool-description {
    font-size: 13px;
    color: #666;
    line-height: 1.4;
  }

  .tool-settings {
    padding: 12px 16px;
    background: #f8f9fa;
    border-top: 1px solid #eee;
  }

  .settings-row {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  input[type="color"] {
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    padding: 0;
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  input[type="range"] {
    flex: 1;
    height: 4px;
    -webkit-appearance: none;
    background: #eee;
    border-radius: 2px;
    outline: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: #007AFF;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(33, 150, 243, 0.2);
  }

  .opacity-value, .width-value {
    min-width: 48px;
    font-size: 14px;
    color: #64748b;
    text-align: right;
  }

  /* Shared settings panel */
  .shared-settings-panel {
    margin-top: 16px;
    background: #1c1c1e;
    border-radius: 12px;
    overflow: hidden;
    color: white;
  }

  .settings-header {
    padding: 12px 16px;
    border-bottom: 1px solid #2c2c2e;
  }

  .settings-title {
    font-size: 14px;
    font-weight: 500;
  }

  .settings-content {
    padding: 16px;
  }

  .settings-group {
    margin-bottom: 16px;
  }

  .settings-group label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    color: #8e8e93;
  }

  .slider-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .value-display {
    min-width: 40px;
    text-align: center;
    font-size: 14px;
    background: #2c2c2e;
    padding: 4px 8px;
    border-radius: 6px;
  }

  .color-selector {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .color-input {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
  }

  /* Custom cursor styles */
  :global(.cursor-pencil) {
    cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='%23007AFF' d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z'/></svg>") 0 24, auto !important;
  }

  :global(.cursor-marker) {
    cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='%23333' d='M20.71,7.04c0.39-0.39,0.39-1.02,0-1.41l-2.34-2.34c-0.39-0.39-1.02-0.39-1.41,0l-9.19,9.19L6.1,14.46L3,17.25V21h3.75l2.79-3.1l1.99-1.67l9.19-9.19 M6.42,21l-3.42-3.42L7.9,12.7l3.42,3.42L6.42,21z'/></svg>") 0 24, auto !important;
  }

  :global(.cursor-highlighter) {
    cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='%23FFCC00' d='M15,20.5V18H4.3L3,19L4.9,20.8L5.8,20.5H15M21,5C19.9,5 19,5.9 19,7V9L4.4,17.7L7.2,20.5L18.6,10H21V7C21,5.9 20.1,5 19,5H21z'/></svg>") 0 24, auto !important;
  }

  :global(.cursor-eraser) {
    cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='%23FF6B6B' d='M16.24,3.56L21.19,8.5C21.97,9.29 21.97,10.55 21.19,11.34L12,20.53C10.44,22.09 7.91,22.09 6.34,20.53L2.81,17C2.03,16.21 2.03,14.95 2.81,14.16L13.41,3.56C14.2,2.78 15.46,2.78 16.24,3.56M4.22,15.58L7.76,19.11C8.54,19.9 9.8,19.9 10.59,19.11L14.12,15.58L9.17,10.63L4.22,15.58Z'/></svg>") 0 24, auto !important;
  }
</style>
