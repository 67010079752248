import { get } from 'svelte/store';
import { storyStore } from '../stores/storyStore.js';

function sanitizeFilename(title) {
    // Replace invalid filename characters with underscores and trim whitespace
    return title
        .replace(/[/\\?%*:|"<>]/g, '_')
        .replace(/\s+/g, '_')
        .trim() || 'story';
}

export async function shareStory(jsPDF) {
    console.log('Sharing story... via shareStory()');
    const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [375, 667] // Match canvas dimensions
    });

    try {
        // Get all slides and story title from the storyStore
        const currentStory = get(storyStore);
        const slides = currentStory.slides;
        const filename = sanitizeFilename(currentStory.title);

        // Set some styling constants
        const margin = 20;
        const scriptFontSize = 12;
        doc.setFontSize(scriptFontSize);

        for (let i = 0; i < slides.length; i++) {
            const slide = slides[i];

            // Create a temporary canvas for each slide
            const tempCanvas = document.createElement('canvas');
            tempCanvas.width = 375;
            tempCanvas.height = 667;
            const fabricCanvas = new window.fabric.Canvas(tempCanvas);

            // Add a new page for slides after the first one
            if (i > 0) {
                doc.addPage();
            }

            // Load and render the canvas state
            if (slide.canvasState && slide.canvasState.length > 0) {
                await new Promise((resolve) => {
                    fabricCanvas.loadFromJSON(
                        { version: '5.2.4', objects: slide.canvasState },
                        () => {
                            fabricCanvas.renderAll();
                            resolve();
                        }
                    );
                });

                // Convert the canvas to an image
                const imgData = tempCanvas.toDataURL('image/png');
                
                // Add the image to the PDF
                doc.addImage(imgData, 'PNG', 0, 0, 375, 667);
            }

            // Add the script text if it exists
            if (slide.script) {
                // Add a new page for the script
                doc.addPage();
                
                // Add slide number and "Script" header
                doc.setFontSize(14);
                doc.setFont(undefined, 'bold');
                doc.text(`Slide ${i + 1} Script:`, margin, margin + 10);
                
                // Reset font for script content
                doc.setFontSize(scriptFontSize);
                doc.setFont(undefined, 'normal');
                
                // Split text into lines that fit within page width
                const textLines = doc.splitTextToSize(slide.script, 375 - (margin * 2));
                
                // Add the script text with proper line breaks
                doc.text(textLines, margin, margin + 30);
            }
        }

        // Save the PDF with the story title
        doc.save(`${filename}.pdf`);
        console.log('Story exported to PDF successfully');
    } catch (error) {
        console.error('Error exporting story to PDF:', error);
    }
}
