import { writable, get } from 'svelte/store';
import { storyService } from '../services/storyService.js';
import { generatePreviewForSlide } from '../utils/previewGenerator.js';

// Add debounce utility
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}


function generateSlideId() {
  return `slide-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
}

function ensureSlideHasId(slide) {
  return slide.id ? slide : { ...slide, id: generateSlideId() };
}

// Compression utility
function compressCanvasState(canvasState) {
  if (!canvasState || !Array.isArray(canvasState)) return canvasState;
  
  return canvasState.map(obj => {
    // Create a shallow copy to avoid modifying the original
    const compressed = { ...obj };
    
    // Remove redundant or default properties
    if (compressed.strokeWidth === 1) delete compressed.strokeWidth;
    if (compressed.fill === 'rgb(0,0,0)') delete compressed.fill;
    if (compressed.stroke === 'rgb(0,0,0)') delete compressed.stroke;
    if (compressed.opacity === 1) delete compressed.opacity;
    if (compressed.scaleX === 1) delete compressed.scaleX;
    if (compressed.scaleY === 1) delete compressed.scaleY;
    if (compressed.angle === 0) delete compressed.angle;
    if (compressed.flipX === false) delete compressed.flipX;
    if (compressed.flipY === false) delete compressed.flipY;
    if (compressed.skewX === 0) delete compressed.skewX;
    if (compressed.skewY === 0) delete compressed.skewY;
    
    // For text objects, compress font properties
    if (compressed.type === 'text' || compressed.type === 'textbox') {
      if (compressed.fontFamily === 'Times New Roman') delete compressed.fontFamily;
      if (compressed.fontSize === 40) delete compressed.fontSize;
      if (compressed.fontWeight === 'normal') delete compressed.fontWeight;
      if (compressed.fontStyle === 'normal') delete compressed.fontStyle;
      if (compressed.lineHeight === 1.16) delete compressed.lineHeight;
      if (compressed.textAlign === 'left') delete compressed.textAlign;
      if (compressed.underline === false) delete compressed.underline;
    }
    
    // For images, optimize src if it's a data URL
    if (compressed.type === 'image' && compressed.src?.startsWith('data:')) {
      // Keep track of original dimensions for restoration
      compressed._originalWidth = compressed.width;
      compressed._originalHeight = compressed.height;
      
      // Reduce image quality if it's a data URL
      if (compressed.src.startsWith('data:image/jpeg') || 
          compressed.src.startsWith('data:image/png')) {
        compressed.src = compressed.src.replace(/;base64,/, ';base64,compressed,');
      }
    }
    
    return compressed;
  });
}

// Restore utility for when we need the full state
function restoreCanvasState(compressedState) {
  if (!compressedState || !Array.isArray(compressedState)) return compressedState;
  
  return compressedState.map(obj => {
    const restored = { ...obj };
    
    // Restore default values
    if (!('strokeWidth' in restored)) restored.strokeWidth = 1;
    if (!('fill' in restored)) restored.fill = 'rgb(0,0,0)';
    if (!('stroke' in restored)) restored.stroke = 'rgb(0,0,0)';
    if (!('opacity' in restored)) restored.opacity = 1;
    if (!('scaleX' in restored)) restored.scaleX = 1;
    if (!('scaleY' in restored)) restored.scaleY = 1;
    if (!('angle' in restored)) restored.angle = 0;
    if (!('flipX' in restored)) restored.flipX = false;
    if (!('flipY' in restored)) restored.flipY = false;
    if (!('skewX' in restored)) restored.skewX = 0;
    if (!('skewY' in restored)) restored.skewY = 0;
    
    // Restore text properties
    if (restored.type === 'text' || restored.type === 'textbox') {
      if (!('fontFamily' in restored)) restored.fontFamily = 'Times New Roman';
      if (!('fontSize' in restored)) restored.fontSize = 40;
      if (!('fontWeight' in restored)) restored.fontWeight = 'normal';
      if (!('fontStyle' in restored)) restored.fontStyle = 'normal';
      if (!('lineHeight' in restored)) restored.lineHeight = 1.16;
      if (!('textAlign' in restored)) restored.textAlign = 'left';
      if (!('underline' in restored)) restored.underline = false;
    }
    
    // Restore image properties
    if (restored.type === 'image' && restored.src?.includes(';base64,compressed,')) {
      restored.src = restored.src.replace(';base64,compressed,', ';base64,');
      if (restored._originalWidth) {
        restored.width = restored._originalWidth;
        delete restored._originalWidth;
      }
      if (restored._originalHeight) {
        restored.height = restored._originalHeight;
        delete restored._originalHeight;
      }
    }
    
    return restored;
  });
}

// Initialize with empty state and proper slide structure
const initialState = {
  _id: null, // Database ID
  bigQuestion: '',
  title: 'Untitled Story',
  slides: [],
  template: 'none',
  isDirty: false, // Track if there are unsaved changes
  isSaving: false, // Track if a save is in progress
  lastPreviewUpdate: null
};

const storyStore = writable(initialState);

const selectedSlideStore = writable(0);

export async function generateCanvasPNG(canvasState) {
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = 375; // Set canvas width
  tempCanvas.height = 667; // Set canvas height
  const fabricCanvas = new window.fabric.Canvas(tempCanvas);

  // Load the canvas state into the Fabric.js canvas
  await new Promise((resolve) => {
      fabricCanvas.loadFromJSON(
          { version: '5.2.4', objects: canvasState },
          () => {
              fabricCanvas.renderAll();
              resolve();
          }
      );
  });

  // Convert the canvas to a PNG data URL
  return tempCanvas.toDataURL('image/png');
}

async function uploadSlidePreviews() {
  const currentStory = get(storyStore);
  const slides = currentStory.slides;

  for (let i = 0; i < slides.length; i++) {
      const slide = slides[i];

      if (slide.canvasState && slide.canvasState.length > 0) {
          try {
              // Generate a PNG Blob from the canvas state
              const base64Data = await generateCanvasPNG(slide.canvasState);
              const blob = dataURLToBlob(base64Data);

              // Request a pre-signed URL from the server
              const filename = `slide-${i + 1}-${Date.now()}`;
              const presignedResponse = await fetch('/api/presigned-url', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ filename }),
              });

              if (!presignedResponse.ok) {
                  throw new Error(`Failed to get pre-signed URL for slide ${i + 1}`);
              }

              const { presignedUrl, fileUrl } = await presignedResponse.json();

              // Upload the Blob to S3 using the pre-signed URL
              await fetch(presignedUrl, {
                  method: 'PUT',
                  body: blob,
                  headers: { 'Content-Type': 'image/png' },
              });

              console.log(`Slide ${i + 1} uploaded successfully:`, fileUrl);

              // Update the slide's previewImage with the S3 URL
              storyStore.update((state) => {
                  const updatedSlides = [...state.slides];
                  updatedSlides[i].previewImage = fileUrl;
                  return { ...state, slides: updatedSlides };
              });
          } catch (error) {
              console.error(`Error uploading slide ${i + 1}:`, error);
          }
      } else {
          console.warn(`Slide ${i + 1} has no canvasState, skipping upload.`);
      }
  }
}

// Helper function to convert Base64 to Blob
function dataURLToBlob(dataURL) {
  const byteString = atob(dataURL.split(',')[1]);
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  const buffer = new ArrayBuffer(byteString.length);
  const uintArray = new Uint8Array(buffer);

  for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([buffer], { type: mimeString });
}

export async function testUploadSlidePreviews() {
  try {
      console.log('Starting test for uploading slide previews...');
      await uploadSlidePreviews(); // Call the function to upload slides

      const currentStory = get(storyStore); // Get the updated story state
      currentStory.slides.forEach((slide, index) => {
          console.log(`Slide ${index + 1} previewImage URL:`, slide.previewImage);
      });

      console.log('Test completed successfully.');
  } catch (error) {
      console.error('Error during test:', error);
  }
}

const storeFunctions = {
  subscribe: storyStore.subscribe,

  // Create a new story in the database
  createStory: async () => {
    try {
      const currentState = get(storyStore);
      
      // If we already have a story ID, just save the current state
      if (currentState._id) {
        console.log('Story already exists, updating instead of creating');
        return await storeFunctions.saveStory();
      }

      storyStore.update(state => ({ ...state, isSaving: true }));
      
      // Ensure we have required data
      if (!currentState.template || !currentState.slides?.length) {
        throw new Error('Cannot create story without template and slides');
      }
      
      // Create new story object with all slide properties
      const storyData = {
        title: currentState.title || 'Untitled Story',
        bigQuestion: currentState.bigQuestion || '',
        template: currentState.template,
        slides: currentState.slides.map(slide => ({
          id: slide.id,
          title: slide.title,
          text: slide.text,
          evidence: slide.evidence,
          images: slide.images,
          canvasState: slide.canvasState,
          previewImage: slide.previewImage,
          script: slide.script,
          objects: slide.objects,
          selectedObject: slide.selectedObject,
          mediaObjects: slide.mediaObjects
        }))
      };
      
      const response = await storyService.createStory(storyData);
      console.log('Story created successfully:', response);
      
      // Update store with the new story data including the _id
      storyStore.update(state => ({
        ...state,
        _id: response._id,
        isDirty: false,
        isSaving: false
      }));
      
      return response;
    } catch (error) {
      console.error('Error creating story:', error);
      storyStore.update(state => ({ ...state, isSaving: false }));
      throw error;
    }
  },
  
  // Save the current story to the database
  saveStory: async () => {
    try {
      const currentState = get(storyStore);
      
      // If no ID, create a new story
      if (!currentState._id) {
        return await storeFunctions.createStory();
      }
      
      storyStore.update(state => ({ ...state, isSaving: true }));
      
      // Prepare the story data, ensuring all slide properties are included
      const storyData = {
        title: currentState.title,
        bigQuestion: currentState.bigQuestion,
        template: currentState.template,
        slides: currentState.slides.map(slide => ({
          id: slide.id,
          title: slide.title,
          text: slide.text,
          evidence: slide.evidence,
          images: slide.images,
          canvasState: slide.canvasState,
          previewImage: slide.previewImage,
          script: slide.script,
          objects: slide.objects,
          selectedObject: slide.selectedObject,
          mediaObjects: slide.mediaObjects
        }))
      };
      
      // Update the story in the database
      const updatedStory = await storyService.updateStory(currentState._id, storyData);
      
      // Update the store
      storyStore.update(state => ({
        ...state,
        isDirty: false,
        isSaving: false
      }));
      
      console.log('Story saved successfully:', updatedStory);
      return updatedStory;
    } catch (error) {
      console.error('Failed to save story:', error);
      storyStore.update(state => ({ ...state, isSaving: false }));
      return null;
    }
  },
  
  // Load a story from the database
  loadStory: async (id) => {
    try {
      const story = await storyService.getStory(id);
      if (!story) {
        console.error('Story not found');
        return null;
      }

      // Generate previews for slides that don't have one
      const updatedSlides = await Promise.all(story.slides.map(async (slide) => {
        if (!slide.previewImage && slide.canvasState) {
          try {
            const preview = await generatePreviewForSlide(slide.canvasState);
            return { ...slide, previewImage: preview };
          } catch (err) {
            console.error('Error generating preview for slide:', err);
            return slide;
          }
        }
        return slide;
      }));

      const updatedStory = {
        ...story,
        slides: updatedSlides,
        isDirty: false,
        isSaving: false,
        lastPreviewUpdate: Date.now()
      };

      storyStore.set(updatedStory);
      return updatedStory;
    } catch (error) {
      console.error('Error loading story:', error);
      return null;
    }
  },

  updateBigQuestion(question) {
    storyStore.update(currentStory => ({
      ...currentStory,
      bigQuestion: question,
      title: `Untitled: ${question}`,
      isDirty: true
    }));
  },

  getBigQuestion() {
    return get(storyStore).bigQuestion;
  },

  addSlide(newSlide = {}) {
    return new Promise(resolve => {
      storyStore.update(currentStory => {
        const completeSlide = {
          id: newSlide.id || generateSlideId(),
          title: newSlide.title || '',
          text: newSlide.text || '',
          evidence: Array.isArray(newSlide.evidence) ? newSlide.evidence : [],
          images: Array.isArray(newSlide.images) ? newSlide.images : [],
          canvasState: Array.isArray(newSlide.canvasState) ? newSlide.canvasState : [],
          previewImage: newSlide.previewImage || '',
          script: newSlide.script || '',
          objects: Array.isArray(newSlide.objects) ? newSlide.objects : [],
          selectedObject: newSlide.selectedObject || null,
          mediaObjects: Array.isArray(newSlide.mediaObjects) ? newSlide.mediaObjects : []
        };
        
        const updatedSlides = [...currentStory.slides, completeSlide];
        return { ...currentStory, slides: updatedSlides, isDirty: true };
      });
      resolve();
    });
  },

  addEvidenceToSlide(selectedSlideIndex, evidence) {
    storyStore.update(currentStory => {
      const updatedSlides = [...currentStory.slides];
      updatedSlides[selectedSlideIndex].evidence.push(evidence);
      console.log(`Adding evidence to slide at index ${selectedSlideIndex}:`, evidence);
      return { ...currentStory, slides: updatedSlides, isDirty: true };
    });
  },

  addImageToSlide(selectedSlideIndex, image) {
    storyStore.update(currentStory => {
      const updatedSlides = [...currentStory.slides];
      updatedSlides[selectedSlideIndex].images.push({ url: image.url, alt: image.alt || 'Slide Image' });
      console.log(`Adding image to slide at index ${selectedSlideIndex}`);
      return { ...currentStory, slides: updatedSlides, isDirty: true };
    });
  },

  updateSlideText(selectedSlideIndex, newText) {
    storyStore.update(currentStory => {
      const updatedSlides = [...currentStory.slides];
      updatedSlides[selectedSlideIndex].text = newText;
      console.log(`Updating text for slide at index ${selectedSlideIndex}`);
      return { ...currentStory, slides: updatedSlides, isDirty: true };
    });
  },

  getSlide(selectedSlideIndex) {
    const currentStory = get(storyStore);
    if (!currentStory?.slides || selectedSlideIndex < 0 || selectedSlideIndex >= currentStory.slides.length) {
      return {
        id: generateSlideId(),
        title: '',
        text: '',
        evidence: [],
        images: [],
        canvasState: [],
        previewImage: '',
        script: '',
        objects: [],
        selectedObject: null,
        mediaObjects: []
      };
    }
    return currentStory.slides[selectedSlideIndex];
  },

  updateCanvasState(selectedSlideIndex, canvasState) {
    console.log('Updating canvas state');
    storyStore.update(currentStory => {
      // Create a new slide if it doesn't exist
      if (!currentStory.slides[selectedSlideIndex]) {
        const newSlide = {
          id: generateSlideId(),
          title: '',
          text: '',
          evidence: [],
          images: [],
          canvasState: [],
          script: '',
          previewImage: '',
          objects: [],
          selectedObject: null,
          mediaObjects: []
        };
        currentStory.slides[selectedSlideIndex] = newSlide;
      }

      const updatedSlides = [...currentStory.slides];
      // Compress the canvas state before storing
      updatedSlides[selectedSlideIndex].canvasState = compressCanvasState(canvasState);
      console.log('Updating canvas state for slide:', selectedSlideIndex);
      return { ...currentStory, slides: updatedSlides, isDirty: true };
    });
  },

  // Add a method to get the full canvas state when needed
  getFullCanvasState(selectedSlideIndex) {
    const currentStory = get(storyStore);
    const slide = currentStory.slides[selectedSlideIndex];
    if (!slide) return null;
    return restoreCanvasState(slide.canvasState);
  },

  updateSlideScript(selectedSlideIndex, newScript) {
    storyStore.update(currentStory => {
      const updatedSlides = [...currentStory.slides];
      updatedSlides[selectedSlideIndex].script = newScript;
      console.log(`Updating script for slide at index ${selectedSlideIndex}`);
      return { ...currentStory, slides: updatedSlides, isDirty: true };
    });
  },

  updateSlidePreview: debounce((selectedSlideIndex, preview) => {
    storyStore.update(currentStory => {
      const updatedSlides = [...currentStory.slides];
      
      // Only update if the preview has actually changed
      if (updatedSlides[selectedSlideIndex]?.previewImage !== preview) {
        updatedSlides[selectedSlideIndex].previewImage = preview;
        
        // Mark as dirty only if preview actually changed
        return { 
          ...currentStory, 
          slides: updatedSlides, 
          isDirty: true,
          lastPreviewUpdate: Date.now() // Track when preview was last updated
        };
      }
      
      return currentStory;
    });
  }, 500), // Debounce preview updates by 500ms

  // Add a new method to batch update previews
  batchUpdatePreviews(previewUpdates) {
    storyStore.update(currentStory => {
      const updatedSlides = [...currentStory.slides];
      let hasChanges = false;

      previewUpdates.forEach(({ index, preview }) => {
        if (updatedSlides[index] && updatedSlides[index].previewImage !== preview) {
          updatedSlides[index].previewImage = preview;
          hasChanges = true;
        }
      });

      if (hasChanges) {
        return {
          ...currentStory,
          slides: updatedSlides,
          isDirty: true,
          lastPreviewUpdate: Date.now()
        };
      }

      return currentStory;
    });
  },

  // Add a method to check if preview needs updating
  shouldUpdatePreview(index) {
    const currentState = get(storyStore);
    const slide = currentState.slides[index];
    
    if (!slide) return false;
    
    // Check if preview is missing or old
    const noPreview = !slide.previewImage;
    const previewTooOld = currentState.lastPreviewUpdate && 
      (Date.now() - currentState.lastPreviewUpdate > 5000); // 5 seconds threshold
    
    return noPreview || previewTooOld;
  },
  
  setSelectedSlide(index) {
    selectedSlideStore.set(index);
  },

  getSelectedSlide() {
    return get(selectedSlideStore);
  },

  subscribeToSelectedSlide: selectedSlideStore.subscribe,

  clearAllSlides: async () => {
    storyStore.update(store => {
      store.slides = [];
      return store;
    });
    selectedSlideStore.set(-1);
    return Promise.resolve();
  },

  // Helper function to update slide titles with correct numbering
  updateSlideTitles() {
    storyStore.update(currentStory => {
      const updatedSlides = [...currentStory.slides].map((slide, index) => {
        // Only update titles that are default numbered titles
        if (slide.title && slide.title.match(/^Slide \d+$/)) {
          return { ...slide, title: `Slide ${index + 1}` };
        }
        return slide;
      });
      
      return { ...currentStory, slides: updatedSlides };
    });
  },

  removeSlide(index) {
    storyStore.update(currentStory => {
      const updatedSlides = [...currentStory.slides];
      updatedSlides.splice(index, 1);
      
      // If we removed the last slide, add an empty one
      if (updatedSlides.length === 0) {
        updatedSlides.push({ 
          id: generateSlideId(), // Ensure the slide has a unique ID
          title: 'Slide 1', // Give it a default title
          text: '', 
          evidence: [], 
          images: [], 
          canvasState: [],
          previewImage: '',
          script: '',
          objects: [],
          selectedObject: null,
          mediaObjects: []
        });
      }
      
      // Update selected slide if needed
      if (index >= updatedSlides.length) {
        selectedSlideStore.set(updatedSlides.length - 1);
      }

      // Optionally, update the story in the database to persist changes
      // This ensures the deletion is saved immediately
      setTimeout(() => {
        storeFunctions.updateSlideTitles(); // Update slide titles to maintain correct numbering
        storeFunctions.saveStory();
      }, 0);
      
      return { ...currentStory, slides: updatedSlides, isDirty: true };
    });
  },

  updateSlideTitle(index, newTitle) {
    storyStore.update(currentStory => {
      const updatedSlides = [...currentStory.slides];
      if (!updatedSlides[index].title) {
        updatedSlides[index] = { ...updatedSlides[index], title: '' };
      }
      updatedSlides[index].title = newTitle;
      return { ...currentStory, slides: updatedSlides, isDirty: true };
    });
  },

  reorderSlides(newSlides) {
    try {
      if (!Array.isArray(newSlides)) {
        throw new Error('newSlides must be an array');
      }

      // Ensure each slide has all required properties with default values
      const completeSlides = newSlides.map((slide = {}, index) => ({
        id: slide.id || generateSlideId(),
        title: slide.title || '',
        text: slide.text || '',
        evidence: Array.isArray(slide.evidence) ? slide.evidence : [],
        images: Array.isArray(slide.images) ? slide.images : [],
        canvasState: Array.isArray(slide.canvasState) ? slide.canvasState : [],
        previewImage: slide.previewImage || '', // Preserve existing preview
        script: slide.script || '',
        objects: Array.isArray(slide.objects) ? slide.objects : [],
        selectedObject: slide.selectedObject || null,
        mediaObjects: Array.isArray(slide.mediaObjects) ? slide.mediaObjects : []
      }));

      // Log preview status for each slide
      completeSlides.forEach((slide, index) => {
        if (!slide.previewImage) {
          console.log(`Slide ${index + 1} is missing a preview image`);
        }
      });

      storyStore.update(currentStory => ({
        ...currentStory,
        slides: completeSlides,
        isDirty: true,
        lastPreviewUpdate: Date.now() // Update timestamp when reordering
      }));

      return Promise.resolve();
    } catch (error) {
      console.error('Error reordering slides:', error);
      return Promise.reject(error);
    }
  },

  updateTitle(newTitle) {
    storyStore.update(currentStory => ({
      ...currentStory,
      title: newTitle,
      isDirty: true
    }));
  },

  updateTemplate: async (templateId) => {
    storyStore.update(state => ({
      ...state,
      template: templateId,
      isDirty: true
    }));
    
    // If this is the first time setting a template, create the story
    const currentState = get(storyStore);
    if (!currentState._id && templateId && templateId !== 'none') {
      return await storeFunctions.createStory();
    }
    return null;
  },

  applyTemplate: async (templateId, templateElements) => {
    if (!templateElements || !Array.isArray(templateElements)) {
      console.warn('Invalid template elements');
      return;
    }
    
    const newSlides = templateElements.map((element, index) => ({
      id: `slide-${Date.now()}-${index}`,
      title: element.slideTitle || `Slide ${index + 1}`,
      text: element.helpText || '',
      evidence: [],
      images: [],
      canvasState: [],
      script: '',
      objects: [],
      selectedObject: null,
      mediaObjects: []
    }));
    
    await storeFunctions.reorderSlides(newSlides);
    
    // Save the story with the new slides
    return await storeFunctions.saveStory();
  },

  reset() {
    storyStore.set({
      _id: null,
      bigQuestion: '',
      title: 'Untitled Story',
      slides: [],
      template: 'none',
      isDirty: false,
      isSaving: false,
      lastPreviewUpdate: null
    });
  },

  updateTemplate(templateId) {
    storyStore.update(state => ({
      ...state,
      template: templateId,
      isDirty: true
    }));
  }

};

export { storeFunctions as storyStore };