<!-- src/components/admin/Admin-DataManager.svelte -->
<script>
  import { onMount } from 'svelte';
  import Modal from '../Modal.svelte';
  import { fade } from 'svelte/transition';
  
  // Props
  export let evidenceItems = [];
  export let articleItems = [];
  export let currentTab = 'evidence';
  export let loading = false;
  export let syncing = false;
  export let syncResults = null;
  export let syncError = null;
  
  // Filter state
  export let sourceUrlFilter = '';
  export let topicFilter = '';
  export let showCustomOnly = false;
  export let appliedFilters = false;
  
  // Pagination state
  export let currentPage = 1;
  export let itemsPerPage = 20;
  export let totalItems = 0;
  
  // Delete confirmation state
  let deleteConfirmOpen = false;
  let deleteItemType = '';
  let deleteItemId = '';
  let deleteItemSourceURL = '';
  let deleteAllConfirm = false;
  
  // Image modal state
  let imageModalOpen = false;
  let selectedImage = null;
  
  // Event dispatcher
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  
  // Dropdown state
  let filterDropdownOpen = false;
  let actionDropdownOpen = false;
  
  // View state
  let viewMode = 'grid'; // 'grid' or 'list'
  
  // Computed properties
  $: filteredItems = currentTab === 'evidence' ? evidenceItems : articleItems;
  $: totalPages = Math.max(1, Math.ceil(totalItems / itemsPerPage));
  $: {
    // Ensure current page is valid
    if (currentPage > totalPages) {
      currentPage = Math.max(1, totalPages);
    }
  }
  $: startIndex = (currentPage - 1) * itemsPerPage;
  $: endIndex = Math.min(startIndex + itemsPerPage, totalItems);
  $: displayedItems = filteredItems;
  $: itemCount = totalItems;
  
  // Methods
  function handleTabChange(tab) {
    currentTab = tab;
    currentPage = 1; // Reset to first page when changing tabs
    dispatch('tabChange', { tab });
  }
  
  function handleSync() {
    syncResults = null; // Clear previous results
    dispatch('sync', { type: currentTab });
  }
  
  function handleFilter() {
    appliedFilters = sourceUrlFilter.trim() !== '' || topicFilter.trim() !== '' || showCustomOnly;
    currentPage = 1; // Reset to first page when applying filters
    dispatch('filter', { 
      sourceUrl: sourceUrlFilter.trim(),
      topic: topicFilter.trim(),
      showCustomOnly
    });
  }
  
  function handleClearFilters() {
    sourceUrlFilter = '';
    topicFilter = '';
    showCustomOnly = false;
    appliedFilters = false;
    currentPage = 1;
    dispatch('clearFilters');
  }
  
  function handleDeleteFiltered() {
    openDeleteConfirm(currentTab, '', sourceUrlFilter);
  }
  
  function handleDeleteAll() {
    // Dispatch the deleteAll event directly instead of opening a confirmation dialog
    dispatch('deleteAll', { 
      type: currentTab,
      action: 'deleteAll' // Add an explicit action type
    });
  }
  
  function handleDeleteItem(item) {
    console.log('Deleting item:', item);
    // Check if the item has an _id property
    if (!item._id) {
      console.error('Item does not have an _id property:', item);
      return;
    }
    // Dispatch the deleteItem event directly instead of opening a confirmation dialog
    dispatch('deleteItem', { 
      type: currentTab, 
      item: { 
        id: item._id, 
        sourceURL: item.sourceURL || '' 
      }
    });
  }
  
  function handlePageChange(page) {
    if (page >= 1 && page <= totalPages) {
      currentPage = page;
      dispatch('pageChange', { page });
    }
  }
  
  // Delete confirmation functions
  function openDeleteConfirm(type, id, sourceURL) {
    deleteItemType = type;
    deleteItemId = id;
    deleteItemSourceURL = sourceURL;
    deleteAllConfirm = false;
    deleteConfirmOpen = true;
  }
  
  function openDeleteAllConfirm(type) {
    deleteItemType = type;
    deleteItemId = '';
    deleteItemSourceURL = '';
    deleteAllConfirm = true;
    deleteConfirmOpen = true;
  }
  
  function closeDeleteConfirm() {
    deleteConfirmOpen = false;
    deleteItemType = '';
    deleteItemId = '';
    deleteItemSourceURL = '';
    deleteAllConfirm = false;
  }
  
  function confirmDelete() {
    if (deleteAllConfirm) {
      // For delete all operations, dispatch a specific event with a clear action type
      dispatch('deleteAll', { 
        type: deleteItemType,
        action: 'deleteAll' // Add an explicit action type
      });
    } else {
      // For single item deletion
      dispatch('deleteItem', { 
        type: deleteItemType, 
        item: { 
          id: deleteItemId, 
          sourceURL: deleteItemSourceURL 
        }
      });
    }
    closeDeleteConfirm();
  }
  
  // Format date helper
  function formatDate(dateString) {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    });
  }
  
  // Truncate text helper
  function truncateText(text, maxLength = 100) {
    if (!text) return '';
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  }

  // Open image modal
  function openImageModal(imageUrl, imageAlt) {
    selectedImage = { url: imageUrl, alt: imageAlt };
    imageModalOpen = true;
  }

  // Close image modal
  function closeImageModal() {
    imageModalOpen = false;
    selectedImage = null;
  }

  // Sync results cleanup
  let syncResultsTimer;
  $: if (syncResults) {
    // Only start the cleanup timer if we're not syncing
    if (!syncing) {
      if (syncResultsTimer) clearTimeout(syncResultsTimer);
      syncResultsTimer = setTimeout(() => {
        syncResults = null;
      }, 5000); // Increased to 5 seconds for better visibility
    }
  }

  // Clear sync results when component mounts
  onMount(() => {
    syncResults = null; // Clear any existing sync results
    return () => {
      if (syncResultsTimer) clearTimeout(syncResultsTimer);
    };
  });

  $: {
    // Debug log for items
    if (displayedItems.length > 0) {
      console.log('First item structure:', displayedItems[0]);
      // Check if the item has an _id property
      if (!displayedItems[0]._id) {
        console.error('Item does not have an _id property:', displayedItems[0]);
      }
    }
  }

  // Helper function to count active filters
  function getActiveFilterCount() {
    let count = 0;
    if (sourceUrlFilter.trim()) count++;
    if (topicFilter.trim()) count++;
    if (showCustomOnly) count++;
    return count;
  }

  // Close dropdowns when clicking outside
  function handleClickOutside(event) {
    const filterDropdown = document.querySelector('.filter-dropdown');
    const actionDropdown = document.querySelector('.action-dropdown');
    
    if (filterDropdown && !filterDropdown.contains(event.target)) {
      filterDropdownOpen = false;
    }
    
    if (actionDropdown && !actionDropdown.contains(event.target)) {
      actionDropdownOpen = false;
    }
  }
  
  onMount(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });
</script>

<div class="content-section">
  <!-- Management Header -->
  <div class="management-header">
    <div class="header-main">
      <div class="tab-buttons">
        <button 
          class="folder-tab {currentTab === 'evidence' ? 'active' : ''}"
          on:click={() => handleTabChange('evidence')}
        >
          <i class="bi bi-folder-fill"></i>
          Evidence
          <span class="item-count">({evidenceItems.length})</span>
        </button>
        <button 
          class="folder-tab {currentTab === 'articles' ? 'active' : ''}"
          on:click={() => handleTabChange('articles')}
        >
          <i class="bi bi-folder-fill"></i>
          Articles
          <span class="item-count">({articleItems.length})</span>
        </button>
      </div>
      
      <div class="header-actions">
        <div class="view-options">
          <button 
            class="view-btn {viewMode === 'grid' ? 'active' : ''}"
            on:click={() => viewMode = 'grid'}
            title="Grid View"
          >
            <i class="bi bi-grid-3x3"></i>
          </button>
          <button 
            class="view-btn {viewMode === 'list' ? 'active' : ''}"
            on:click={() => viewMode = 'list'}
            title="List View"
          >
            <i class="bi bi-list-ul"></i>
          </button>
        </div>
        
        <button 
          class="sync-btn {syncing ? 'syncing' : ''}"
          on:click={handleSync}
          disabled={syncing}
        >
          <i class="bi {syncing ? 'bi-arrow-repeat spin' : 'bi-arrow-repeat'}"></i>
          {syncing ? 'Syncing...' : 'Sync'}
        </button>
      </div>
    </div>
    
    <div class="header-tools">
      <div class="filter-dropdown">
        <button 
          class="filter-trigger"
          on:click={() => filterDropdownOpen = !filterDropdownOpen}
          class:active={filterDropdownOpen}
        >
          <i class="bi bi-funnel"></i>
          Filters
          {#if appliedFilters}
            <span class="filter-badge">{getActiveFilterCount()}</span>
          {/if}
        </button>
        
        {#if filterDropdownOpen}
          <div class="filter-panel" transition:fade>
            <div class="filter-inputs">
              <div class="filter-group">
                <label for="sourceUrl">Source URL</label>
                <input 
                  type="text" 
                  id="sourceUrl"
                  bind:value={sourceUrlFilter}
                  placeholder="Filter by source URL"
                  disabled={loading}
                />
              </div>
              
              <div class="filter-group">
                <label for="topic">Topic</label>
                <input 
                  type="text" 
                  id="topic"
                  bind:value={topicFilter}
                  placeholder="Filter by topic"
                  disabled={loading}
                />
              </div>
              
              {#if currentTab === 'evidence'}
                <div class="filter-group checkbox-group">
                  <label class="checkbox-label">
                    <input 
                      type="checkbox" 
                      bind:checked={showCustomOnly}
                      disabled={loading}
                    />
                    <span>Show Custom Only</span>
                  </label>
                </div>
              {/if}
            </div>
            
            <div class="filter-actions">
              <button 
                class="apply-btn"
                on:click={handleFilter}
                disabled={loading}
              >
                <i class="bi bi-check-lg"></i>
                Apply Filters
              </button>
              
              {#if appliedFilters}
                <button 
                  class="clear-btn"
                  on:click={handleClearFilters}
                  disabled={loading}
                >
                  <i class="bi bi-x-lg"></i>
                  Clear Filters
                </button>
              {/if}
            </div>
          </div>
        {/if}
      </div>
      
      <div class="action-dropdown">
        <button 
          class="action-trigger"
          on:click={() => actionDropdownOpen = !actionDropdownOpen}
          class:active={actionDropdownOpen}
        >
          <i class="bi bi-three-dots-vertical"></i>
          Actions
        </button>
        
        {#if actionDropdownOpen}
          <div class="action-panel" transition:fade>
            <button 
              class="action-btn"
              on:click={() => {
                handleDeleteFiltered();
                actionDropdownOpen = false;
              }}
              disabled={loading || !appliedFilters}
            >
              <i class="bi bi-trash"></i>
              Delete Filtered
            </button>
            
            <button 
              class="action-btn danger"
              on:click={() => {
                handleDeleteAll();
                actionDropdownOpen = false;
              }}
              disabled={loading || filteredItems.length === 0}
            >
              <i class="bi bi-trash"></i>
              Delete All
            </button>
          </div>
        {/if}
      </div>
    </div>
  </div>
  
  <!-- Items Summary -->
  <div class="items-summary">
    Showing {startIndex + 1} - {Math.min(endIndex, itemCount)} of {itemCount} items
  </div>
  
  <!-- Sync Results -->
  {#if syncResults}
    <div class="sync-results {syncError ? 'error' : 'success'}">
      <i class="bi {syncError ? 'bi-x-circle-fill' : 'bi-check-circle-fill'}"></i>
      <div class="sync-message">
        {#if syncError}
          <p class="error-text">{syncError}</p>
        {:else}
          <p>Sync completed successfully!</p>
          <div class="sync-stats">
            <span>Synced: {syncResults?.stats?.inserted ?? 0}</span>
            <span>Updated: {syncResults?.stats?.updated ?? 0}</span>
            {#if syncResults?.stats?.skipped > 0}
              <span class="skipped">Skipped: {syncResults?.stats?.skipped ?? 0}</span>
            {/if}
            <span>Errors: {syncResults?.stats?.errors ?? 0}</span>
          </div>
          {#if syncResults?.skippedItems?.length > 0}
            <div class="skipped-details">
              <p class="skipped-title">Skipped Items:</p>
              <ul class="skipped-list">
                {#each syncResults.skippedItems as item}
                  <li>
                    <span class="skip-reason">Duplicate URL:</span>
                    <a href={item.originalURL} target="_blank" rel="noopener noreferrer" class="skip-url">
                      {item.originalURL}
                    </a>
                  </li>
                {/each}
              </ul>
            </div>
          {/if}
        {/if}
      </div>
    </div>
  {/if}
  
  <!-- Items Container -->
  <div class="items-container">
    {#if loading}
      <div class="loading-state">
        <i class="bi bi-arrow-repeat spin"></i>
        <span>Loading items...</span>
      </div>
    {:else if filteredItems.length === 0}
      <div class="empty-state">
        <i class="bi bi-inbox"></i>
        <p>No {currentTab} found</p>
        {#if appliedFilters}
          <button 
            class="clear-btn"
            on:click={handleClearFilters}
          >
            Clear Filters
          </button>
        {/if}
      </div>
    {:else}
      <div class="items-container {viewMode}">
        {#each displayedItems as item}
          <div class="item-card">
            <div class="item-header">
              <h3 class="item-title">
                {#if currentTab === 'evidence'}
                  {item.label || 'No Label'}
                {:else}
                  {item.articleTitle || 'Untitled Article'}
                {/if}
              </h3>
              <div class="item-actions">
                <button 
                  class="delete-item-btn"
                  on:click={() => handleDeleteItem(item)}
                  title="Delete item"
                >
                  <i class="bi bi-trash"></i>
                </button>
                <span class="item-date">
                  {#if currentTab === 'evidence'}
                    {formatDate(item.createdAt)}
                  {:else}
                    {item.articleDate ? formatDate(new Date(item.articleDate)) : 'No date'}
                  {/if}
                </span>
              </div>
            </div>
            
            <div class="item-content">
              {#if currentTab === 'evidence'}
                {#if item.mediaURL}
                  <div class="chart-preview">
                    <img 
                      src={item.mediaURL} 
                      alt={item.description || 'Chart preview'} 
                      class="preview-image"
                      loading="lazy"
                      on:click={() => openImageModal(item.mediaURL, item.description || 'Chart preview')}
                    />
                  </div>
                {/if}
                <p class="item-description">{truncateText(item.description, 120)}</p>
              {:else}
                {#if item.articleContent}
                  <p class="item-excerpt">{truncateText(item.articleContent, 280)}</p>
                {:else}
                  <p class="item-excerpt no-content">No content available</p>
                {/if}
              {/if}
              
              <div class="item-meta">
                <span class="source-url" title={item.sourceURL}>
                  <i class="bi bi-link-45deg"></i>
                  {truncateText(item.sourceURL || 'No source URL', 40)}
                </span>
                {#if currentTab === 'evidence' && item.topicTagStatic}
                  <span class="topic">
                    <i class="bi bi-tag"></i>
                    {item.topicTagStatic}
                  </span>
                {:else if item.topicTagStatic}
                  <span class="topic">
                    <i class="bi bi-tag"></i>
                    {item.topicTagStatic}
                  </span>
                {/if}
                {#if item.isCustomAdd}
                  <span class="custom-badge">
                    <i class="bi bi-pencil-square"></i>
                    Custom
                  </span>
                {/if}
              </div>
            </div>
          </div>
        {/each}
      </div>
      
      <!-- Pagination -->
      {#if itemCount > 0}
        <div class="pagination">
          <button 
            class="page-btn"
            disabled={currentPage === 1}
            on:click={() => handlePageChange(currentPage - 1)}
          >
            <i class="bi bi-chevron-left"></i>
          </button>
          
          {#each Array(totalPages) as _, i}
            {@const page = i + 1}
            <button 
              class="page-btn {currentPage === page ? 'active' : ''}"
              on:click={() => handlePageChange(page)}
            >
              {page}
            </button>
          {/each}
          
          <button 
            class="page-btn"
            disabled={currentPage === totalPages}
            on:click={() => handlePageChange(currentPage + 1)}
          >
            <i class="bi bi-chevron-right"></i>
          </button>
        </div>
      {/if}
    {/if}
  </div>
</div>

<!-- Image Modal -->
{#if imageModalOpen && selectedImage}
<Modal 
  on:close={closeImageModal}
  fullscreen={true}
>
  <div slot="header">
    <h2>Image Preview</h2>
  </div>
  
  <div slot="body">
    <div class="image-modal-container">
      <img 
        src={selectedImage.url} 
        alt={selectedImage.alt} 
        class="full-size-image"
      />
    </div>
  </div>
  
  <div slot="footer">
    <div class="modal-actions">
      <button 
        class="close-btn" 
        on:click={closeImageModal}
      >
        Close
      </button>
      <a 
        href={selectedImage.url} 
        target="_blank" 
        rel="noopener noreferrer" 
        class="open-btn"
      >
        <i class="bi bi-box-arrow-up-right"></i>
        Open in New Tab
      </a>
    </div>
  </div>
</Modal>
{/if}

<!-- Delete Confirmation Modal -->
{#if deleteConfirmOpen}
<Modal 
  on:close={closeDeleteConfirm}
>
  <div slot="header">
    <h2>Confirm Deletion</h2>
  </div>
  
  <div slot="body">
    <div class="delete-warning">
      <i class="bi bi-exclamation-triangle"></i>
      <p>This action cannot be undone!</p>
    </div>
    
    {#if deleteAllConfirm}
      <p>Are you sure you want to delete <strong>ALL {deleteItemType === 'evidence' ? 'evidence items' : 'articles'}</strong> from the temporary collection?</p>
      <p class="delete-detail">This will permanently remove all {deleteItemType === 'evidence' ? 'evidence items' : 'articles'} from the temporary collection only. The main collection will not be affected.</p>
    {:else if deleteItemSourceURL}
      <p>Are you sure you want to delete all {deleteItemType === 'evidence' ? 'evidence items' : 'articles'} from this source URL in the temporary collection?</p>
      <p class="delete-detail"><strong>Source URL:</strong> {deleteItemSourceURL}</p>
      <p class="delete-detail">Note: This will only affect items in the temporary collection.</p>
    {:else if deleteItemId}
      <p>Are you sure you want to delete this {deleteItemType === 'evidence' ? 'evidence item' : 'article'} from the temporary collection?</p>
      <p class="delete-detail"><strong>ID:</strong> {deleteItemId}</p>
      <p class="delete-detail">Note: This will only affect the item in the temporary collection.</p>
    {/if}
  </div>
  
  <div slot="footer">
    <div class="confirm-actions">
      <button 
        class="cancel-btn" 
        on:click={closeDeleteConfirm}
      >
        Cancel
      </button>
      <button 
        class="danger-btn" 
        on:click={confirmDelete}
      >
        <i class="bi bi-trash"></i> {deleteAllConfirm ? 'Delete All' : 'Delete'}
      </button>
    </div>
  </div>
</Modal>
{/if}

<style>
  /* Content section styles */
  .content-section {
    background: #ffffff;
    padding: 1.5rem;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  /* Management header styles */
  .management-header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .header-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
  
  .header-actions {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .header-tools {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding-top: 0.75rem;
    border-top: 1px solid #e2e8f0;
  }
  
  .tab-buttons {
    display: flex;
    gap: 0.25rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    padding: 0.25rem;
    background: #f8fafc;
  }
  
  .folder-tab {
    position: relative;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.375rem;
    background: transparent;
    color: #4b5563;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    min-width: 120px;
  }
  
  .folder-tab i {
    font-size: 1rem;
  }
  
  .folder-tab:hover:not(.active) {
    background: #f1f5f9;
    color: #2563eb;
  }
  
  .folder-tab.active {
    background: #2563eb;
    color: #ffffff;
  }
  
  .item-count {
    font-size: 0.8rem;
    opacity: 0.8;
  }
  
  /* View options styles */
  .view-options {
    display: flex;
    gap: 0.25rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    padding: 0.25rem;
    background: #f8fafc;
  }
  
  .view-btn {
    padding: 0.5rem;
    border: none;
    border-radius: 0.375rem;
    background: transparent;
    color: #4b5563;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
  }
  
  .view-btn:hover:not(.active) {
    background: #f1f5f9;
    color: #2563eb;
  }
  
  .view-btn.active {
    background: #2563eb;
    color: #ffffff;
  }
  
  /* Updated items container styles */
  .items-container.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.25rem;
    margin-bottom: 1.5rem;
  }
  
  .items-container.list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .items-container.list .item-card {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 1rem;
    padding: 0.75rem;
    align-items: start;
  }
  
  .items-container.list .item-header {
    margin-bottom: 0;
    min-width: 200px;
  }
  
  .items-container.list .item-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .items-container.list .chart-preview {
    width: 120px;
    height: 80px;
    margin: 0;
  }
  
  .items-container.list .preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.25rem;
  }
  
  .items-container.list .item-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid #e2e8f0;
  }
  
  .items-container.list .item-description,
  .items-container.list .item-excerpt {
    font-size: 0.9rem;
    line-height: 1.4;
    margin: 0;
  }
  
  .items-container.list .item-title {
    font-size: 0.95rem;
    margin-bottom: 0.25rem;
  }
  
  .items-container.list .item-date {
    font-size: 0.8rem;
    color: #64748b;
  }
  
  .items-container.list .item-actions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-end;
  }
  
  /* Responsive adjustments for list view */
  @media (max-width: 768px) {
    .items-container.list .item-card {
      grid-template-columns: 1fr;
      gap: 0.75rem;
    }

    .items-container.list .item-header {
      min-width: auto;
    }

    .items-container.list .chart-preview {
      width: 100%;
      height: 120px;
    }

    .items-container.list .item-actions {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }
  
  .items-summary {
    font-size: 0.9rem;
    color: #64748b;
    margin-bottom: 1rem;
  }
  
  .sync-btn {
    padding: 0.75rem 1.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #2563eb;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .sync-btn:hover:not(:disabled) {
    background: #eff6ff;
    border-color: #2563eb;
  }
  
  .sync-btn.syncing {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Filters section styles */
  .filter-trigger, .action-trigger {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #4b5563;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .filter-trigger:hover:not(.active), .action-trigger:hover:not(.active) {
    background: #f8fafc;
    border-color: #2563eb;
    color: #2563eb;
  }
  
  .filter-trigger.active, .action-trigger.active {
    background: #2563eb;
    border-color: #2563eb;
    color: #ffffff;
  }
  
  .filter-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1.5rem;
    height: 1.5rem;
    padding: 0 0.5rem;
    background: #ffffff;
    border-radius: 1rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: #2563eb;
  }
  
  .filter-trigger.active .filter-badge {
    background: #ffffff;
    color: #2563eb;
  }
  
  .filter-panel {
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    min-width: 300px;
    padding: 1.25rem;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 10;
  }
  
  .filter-inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .filter-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .filter-group label {
    font-size: 0.9rem;
    color: #4b5563;
    font-weight: 500;
  }
  
  .filter-group input {
    padding: 0.75rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    font-size: 0.95rem;
    transition: all 0.2s ease;
  }
  
  .filter-group input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }
  
  .filter-actions {
    display: flex;
    gap: 0.75rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #e2e8f0;
  }
  
  .apply-btn {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.5rem;
    background: #2563eb;
    color: #ffffff;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .apply-btn:hover:not(:disabled) {
    background: #1d4ed8;
  }
  
  .clear-btn {
    padding: 0.75rem 1.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #4b5563;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .clear-btn:hover:not(:disabled) {
    background: #f1f5f9;
    border-color: #2563eb;
    color: #2563eb;
  }
  
  /* Bulk actions styles */
  .action-panel {
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    min-width: 200px;
    padding: 0.5rem;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 10;
  }
  
  .action-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    padding: 0.75rem 1rem;
    border: none;
    border-radius: 0.375rem;
    background: transparent;
    color: #4b5563;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .action-btn:hover:not(:disabled) {
    background: #f8fafc;
    color: #2563eb;
  }
  
  .action-btn.danger:hover:not(:disabled) {
    background: #fef2f2;
    color: #dc2626;
  }
  
  .action-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Items container styles */
  .items-container {
    min-height: 300px;
  }
  
  .loading-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 3rem 0;
    color: #4b5563;
  }
  
  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 3rem 0;
    color: #4b5563;
  }
  
  /* Grid layout for items */
  .items-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.25rem;
    margin-bottom: 1.5rem;
  }
  
  .item-card {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    padding: 1.25rem;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .item-card:hover {
    border-color: #2563eb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transform: translateY(-2px);
  }
  
  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.75rem;
    gap: 1rem;
  }
  
  .item-title {
    font-size: 1rem;
    color: #1e293b;
    font-weight: 600;
    margin: 0;
    line-height: 1.4;
    flex: 1;
  }
  
  .item-date {
    font-size: 0.85rem;
    color: #64748b;
    white-space: nowrap;
  }
  
  .item-content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    flex: 1;
  }
  
  .chart-preview {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    border-radius: 0.25rem;
    overflow: hidden;
    border: 1px solid #e2e8f0;
  }
  
  .preview-image {
    width: 100%;
    height: auto;
    max-height: 180px;
    object-fit: contain;
    display: block;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .preview-image:hover {
    transform: scale(1.02);
  }
  
  .item-description {
    font-size: 0.9rem;
    color: #4b5563;
    line-height: 1.5;
    margin: 0;
  }
  
  .item-excerpt {
    font-size: 0.95rem;
    color: #4b5563;
    line-height: 1.6;
    margin: 0;
    padding: 1rem;
    background: #f8fafc;
    border-radius: 0.5rem;
    border: 1px solid #e2e8f0;
  }
  
  .item-excerpt.no-content {
    color: #94a3b8;
    font-style: italic;
  }
  
  .custom-badge {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem 0.5rem;
    background: #e0f2fe;
    color: #0369a1;
    border-radius: 0.375rem;
    font-size: 0.75rem;
    font-weight: 500;
  }
  
  .item-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    font-size: 0.85rem;
    color: #64748b;
    margin-top: auto;
    padding-top: 1rem;
    border-top: 1px solid #e2e8f0;
  }
  
  .source-url, .topic {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.5rem;
    background: #f1f5f9;
    border-radius: 0.375rem;
    transition: all 0.2s ease;
  }
  
  .source-url:hover {
    background: #e2e8f0;
  }
  
  .item-date {
    font-size: 0.85rem;
    color: #64748b;
    white-space: nowrap;
    padding: 0.25rem 0.5rem;
    background: #f1f5f9;
    border-radius: 0.375rem;
  }
  
  /* Pagination styles */
  .pagination {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1.5rem;
  }
  
  .page-btn {
    padding: 0.5rem 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    background: #ffffff;
    color: #4b5563;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .page-btn:hover:not(:disabled) {
    background: #f8fafc;
    border-color: #2563eb;
    color: #2563eb;
  }
  
  .page-btn.active {
    background: #2563eb;
    border-color: #2563eb;
    color: #ffffff;
  }
  
  .page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  /* Image modal styles */
  .image-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  
  .full-size-image {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }
  
  .close-btn {
    padding: 0.75rem 1.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #4b5563;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .close-btn:hover {
    background: #f1f5f9;
    border-color: #2563eb;
    color: #2563eb;
  }
  
  .open-btn {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.5rem;
    background: #2563eb;
    color: #ffffff;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
  }
  
  .open-btn:hover {
    background: #1d4ed8;
  }
  
  /* Animation */
  .spin {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Updated dropdown positioning */
  .filter-dropdown, .action-dropdown {
    position: relative;
  }

  .filter-panel, .action-panel {
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    min-width: 300px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 10;
  }

  .action-panel {
    right: 0;
    left: auto;
    min-width: 200px;
  }

  /* More compact list view */
  .items-container.list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .items-container.list .item-card {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 1rem;
    padding: 0.75rem;
    align-items: start;
  }

  .items-container.list .item-header {
    margin-bottom: 0;
    min-width: 200px;
  }

  .items-container.list .item-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .items-container.list .chart-preview {
    width: 120px;
    height: 80px;
    margin: 0;
  }

  .items-container.list .preview-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.25rem;
  }

  .items-container.list .item-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid #e2e8f0;
  }

  .items-container.list .item-description,
  .items-container.list .item-excerpt {
    font-size: 0.9rem;
    line-height: 1.4;
    margin: 0;
  }

  .items-container.list .item-title {
    font-size: 0.95rem;
    margin-bottom: 0.25rem;
  }

  .items-container.list .item-date {
    font-size: 0.8rem;
    color: #64748b;
  }

  .items-container.list .item-actions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-end;
  }

  /* Responsive adjustments for list view */
  @media (max-width: 768px) {
    .items-container.list .item-card {
      grid-template-columns: 1fr;
      gap: 0.75rem;
    }

    .items-container.list .item-header {
      min-width: auto;
    }

    .items-container.list .chart-preview {
      width: 100%;
      height: 120px;
    }

    .items-container.list .item-actions {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }

  /* Ensure dropdowns stay above other content */
  .filter-dropdown, .action-dropdown {
    z-index: 20;
  }

  .filter-panel, .action-panel {
    z-index: 21;
  }

  .item-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .delete-item-btn {
    padding: 0.5rem;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    background: transparent;
    color: #64748b;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .delete-item-btn:hover {
    color: #dc2626;
    background: #fef2f2;
    border-color: #dc2626;
  }
  
  .skipped {
    color: #f59e0b; /* Amber color for skipped items */
  }
  
  .skipped-details {
    margin-top: 0.75rem;
    padding: 0.75rem;
    background: #fff7ed;
    border: 1px solid #fed7aa;
    border-radius: 0.5rem;
  }
  
  .skipped-title {
    font-size: 0.9rem;
    font-weight: 500;
    color: #92400e;
    margin-bottom: 0.5rem;
  }
  
  .skipped-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.85rem;
  }
  
  .skipped-list li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0;
    color: #92400e;
  }
  
  .skip-reason {
    font-weight: 500;
  }
  
  .skip-url {
    color: #2563eb;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
  }
  
  .skip-url:hover {
    text-decoration: underline;
  }
</style> 