<script>
  import { navigate } from "svelte-routing";
  import { storyStore } from "../stores/storyStore.js";
  import HeaderBar from "../components/HeaderBar.svelte";
  import { fade } from "svelte/transition";
  import Sortable from "sortablejs";
  import StoryCanvas from "../components/StoryCanvas.svelte";
  import { onMount, onDestroy } from "svelte";
  import { getContext } from "svelte";
  import { get } from "svelte/store";
  import { authStore } from '../stores/authStore.js';
  import { searchEvidence } from '../stores/evidenceStore.js';
  import { storyService } from '../services/storyService.js';

  // Get the selectedSlideIndex context
  const selectedSlideIndex = getContext("selectedSlideIndex");

  // Add templates array
  const templates = [
    {
      id: "none",
      title: "Blank Story",
      description: "Start with a clean slate. Create your own story structure from scratch.",
      color: "#9CA3AF",
      elements: [
        {
          slideTitle: "Untitled Slide",
          helpText: "This is your blank canvas! Add your own content to tell your story."
        }
      ],
      isDefault: true,
    },
    {
      id: "trueForWho",
      title: "True for Who?",
      description: "Explore how data affects different groups of people differently",
      color: "#60A5FA",
      elements: [
        {
          slideTitle: "What are we looking at?",
          helpText: "What's the main idea or fact you want to explore?"
        },
        {
          slideTitle: "Who's in our data?",
          helpText: "Which groups of people does your data talk about? For example: different ages, places, or backgrounds."
        },
        {
          slideTitle: "How is it different?",
          helpText: "Show how the numbers or results are different for each group. Use charts or graphs to make it clear!"
        },
        {
          slideTitle: "Why do these differences matter?",
          helpText: "Explain why these differences are important and what they mean for different people."
        }
      ]
    },
    {
      id: "takeAStand",
      title: "Take a Stand",
      description: "Use data to support your point of view",
      color: "#34D399",
      elements: [
        {
          slideTitle: "What do you believe?",
          helpText: "What's your main point? Tell us what you think is true or what should be done."
        },
        {
          slideTitle: "What proves your point?",
          helpText: "Share the facts, numbers, or examples that show why your idea makes sense."
        },
        {
          slideTitle: "What might others say?",
          helpText: "What would someone who disagrees with you say? Think about other points of view."
        },
        {
          slideTitle: "Why should people care?",
          helpText: "Explain why your idea matters and how it could make things better."
        }
      ]
    },
    {
      id: "whatCanBe",
      title: "What Can Be?",
      description: "Imagine and plan for a better future",
      color: "#F472B6",
      elements: [
        {
          slideTitle: "What's happening now?",
          helpText: "Describe the current situation using facts and numbers. What's working and what isn't?"
        },
        {
          slideTitle: "What could change?",
          helpText: "What are some different ways things could get better? Use data to show possible improvements."
        },
        {
          slideTitle: "How do we get there?",
          helpText: "What steps would we need to take? Think about what needs to change to make things better."
        },
        {
          slideTitle: "What should we do?",
          helpText: "Which solution do you think is best? Explain why this is the path we should take."
        }
      ]
    }
  ];

  // Initialize modal state based on slides
  let showTemplateModal = false;
  let showConfirmModal = false;
  let pendingTemplateId = null;
  let isLoadingStory = true; // Start with loading state true

  // get slides from store with error handling
  $: slides = $storyStore?.slides || [];
  // Show template modal only when we're sure we want to show it
  $: {
    const isNewStory = !$storyStore._id;
    const hasNoSlides = slides.length === 0;
    const shouldShowTemplate = !isLoadingStory && isNewStory && hasNoSlides;
    
    // Only update showTemplateModal if we're not loading and conditions are met
    if (!isLoadingStory) {
      showTemplateModal = shouldShowTemplate;
    }
  }
  let gridContainer;

  // Add state to track active slide editing
  let isEditingSlide = false;

  let selectedTemplate = null;
  let isCreatingStory = false;

  let isEditingTitle = false;
  let titleInput = '';

  onMount(async () => {
    // Check URL for story ID first
    const urlParams = new URLSearchParams(window.location.search);
    const storyId = urlParams.get('story');
    
    // Reset selected slide index when entering grid view
    selectedSlideIndex.set(undefined);
    isEditingSlide = false;

    try {
      if (storyId) {
        // Loading existing story
        console.log('Loading story from URL parameter:', storyId);
        await storyStore.loadStory(storyId);
      } else {
        // Check if we already have a story in progress
        const currentStory = $storyStore;
        if (currentStory._id) {
          selectedTemplate = currentStory.template;
          updateUrlWithStoryId(currentStory._id);
        }
      }
    } catch (error) {
      console.error('Error loading story:', error);
    } finally {
      // Always set loading to false when we're done initializing
      isLoadingStory = false;
    }

    if (gridContainer) {
      // Initialize Sortable with updated configuration
      new Sortable(gridContainer, {
        animation: 150,
        ghostClass: "sortable-ghost",
        dragClass: "sortable-drag",
        handle: ".drag-indicator",
        onEnd: (evt) => {
          // Get the new order of slides
          const newSlides = Array.from(gridContainer.children).map((el) => {
            const slideId = el.dataset.id;
            // Find the full slide object from the current slides array
            return slides.find((s) => s.id === slideId);
          }).filter(Boolean); // Filter out any undefined values

          // Only update if we have all slides
          if (newSlides.length === slides.length) {
            console.log('Reordering slides:', newSlides);
            storyStore.reorderSlides(newSlides);
             
          } else {
            console.error('Some slides were lost during reordering');
          }
        },
      });
    }
  });

  // Helper function to update URL with story ID without triggering navigation
  function updateUrlWithStoryId(storyId) {
    if (!storyId) return;
    
    const url = new URL(window.location);
    url.searchParams.set('story', storyId);
    window.history.replaceState({}, '', url);
    console.log('Updated URL with story ID:', storyId);
  }

  function handleTitleChange(index, event) {
    const newTitle = event.target.value;
    storyStore.updateSlideTitle(index, newTitle);
  }

  function getDefaultTitle(index) {
    return `Slide ${index + 1}`;
  }


  function addNewSlide() {
    const currentSlides = $storyStore?.slides || [];
    const newSlide = {
      id: `slide-${Date.now()}-${currentSlides.length}`,
      title: getDefaultTitle(currentSlides.length),
      text: "",
      evidence: [],
      images: [],
      canvasState: [],
      script: "",
      objects: [],
      selectedObject: null,
      mediaObjects: [],
    };
    storyStore.addSlide(newSlide);
  }

  function removeSlide(index, event) {
    event.stopPropagation();
    if (confirm("Are you sure you want to delete this slide?")) {
      try {
        // Remove the slide
        storyStore.removeSlide(index);
        
        // Optionally show a toast or notification
        console.log(`Slide ${index + 1} deleted successfully`);
        
        // If we're working with a saved story, make sure the URL is updated
        if ($storyStore._id) {
          updateUrlWithStoryId($storyStore._id);
        }
      } catch (error) {
        console.error('Error deleting slide:', error);
        alert('There was a problem deleting the slide. Please try again.');
      }
    }
  }

  async function handleTemplateSelect(template) {
    try {
      // First update the template and slides using store methods
      storyStore.updateTemplate(template.id);
      
      // Create slides from template
      const newSlides = template.elements.map((element, index) => ({
        id: `slide-${Date.now()}-${index}`,
        title: element.slideTitle || `Slide ${index + 1}`,
        text: element.helpText || '',
        evidence: [],
        images: [],
        canvasState: [],
        script: '',
        objects: [],
        selectedObject: null,
        mediaObjects: []
      }));

      // Update slides using existing store method
      await storyStore.reorderSlides(newSlides);

      // Create the story only once
      const story = await storyStore.createStory();
      console.log('Story created successfully:', story);
      
      // Update URL with the new story ID
      if (story && story._id) {
        updateUrlWithStoryId(story._id);
      }
      
      showTemplateModal = false;
    } catch (error) {
      console.error('Error handling template selection:', error);
    }
  }

  function replaceEntireStory(templateId) {
    // Find the template with the given ID
    const template = templates.find(t => t.id === templateId);
    if (template) {
      handleTemplateSelect(template);
    }
    showConfirmModal = false;
  }

  function cancelTemplateApplication() {
    showConfirmModal = false;
    pendingTemplateId = null;
  }

  function editSlide(index) {
    selectedSlideIndex.set(index);
    isEditingSlide = true;
    
    // Include story ID in navigation to tool page
    const storyId = $storyStore._id;
    if (storyId) {
      navigate(`/tool?slide=${index}&story=${storyId}`);
    } else {
      navigate(`/tool?slide=${index}`);
    }
  }


  function startEditingTitle() {
    titleInput = $storyStore.title;
    isEditingTitle = true;
  }

  function saveTitle() {
    if (titleInput.trim()) {
      storyStore.updateTitle(titleInput.trim());
    }
    isEditingTitle = false;
  }

  function handleTitleKeydown(event) {
    if (event.key === 'Enter') {
      saveTitle();
    } else if (event.key === 'Escape') {
      isEditingTitle = false;
      titleInput = $storyStore.title;
    }
  }

  function navigateToSlide(index) {
    const currentStory = get(storyStore);
    if (!currentStory._id) {
      console.error('Cannot navigate to slide: No story ID found');
      return;
    }

    // Update URL with both story ID and slide index
    const url = new URL(window.location);
    url.searchParams.set('story', currentStory._id);
    url.searchParams.set('slide', index.toString());
    
    // Use replaceState to update URL without triggering navigation
    window.history.replaceState({}, '', url);
    
    // Navigate to slide view
    navigate(`/slide?story=${currentStory._id}&slide=${index}`);
  }
 
</script>

<div class="page-container">
  <HeaderBar />
  
  <div class="main-content">
    <div class="page-header">
      <div class="title-container">
        <label class="title-label">Story Title</label>
        {#if isEditingTitle}
          <div class="title-edit-container">
            <input 
              type="text" 
              class="title-input" 
              bind:value={titleInput} 
              on:keydown={handleTitleKeydown}
              on:blur={saveTitle}
              autofocus
            />
            <button class="edit-action-btn" on:click={saveTitle}>
              <i class="bi bi-check"></i>
            </button>
          </div>
        {:else}
          <button class="title-display" on:click={startEditingTitle}>
            <h1 class="story-title">{$storyStore.title}</h1>
            <i class="bi bi-pencil edit-icon"></i>
          </button>
        {/if}
      </div>
    </div>

    <div class="grid-container" transition:fade>
      {#if slides.length > 0}
        <section bind:this={gridContainer} class="slides-grid">
          {#each slides as slide, index (slide.id)}
            <div class="slide-card" data-id={slide.id}>
              <!-- Drag Handle -->
              <div class="drag-indicator" title="Drag to reorder">
                <i class="fas fa-grip-lines"></i>
              </div>

              <!-- Card Content -->
              <div class="slide-content">
                <div class="slide-main">
                  <div class="slide-preview">
                    <div class="svg-container">
                      {#if slide.previewImage}
                        {@html slide.previewImage}
                      {:else}
                        <div class="empty-preview">
                          <i class="fas fa-image"></i>
                          <span>No preview</span>
                        </div>
                      {/if}
                    </div>
                  </div>
                  <div class="slide-info">
                    <div class="slide-header">
                      <div class="slide-number">#{index + 1}</div>
                      <input
                        class="slide-title"
                        type="text"
                        placeholder="Enter slide title..."
                        value={slide.title}
                        on:input={(e) => handleTitleChange(index, e)}
                      />
                    </div>

                    <div class="script-preview">
                      <label class="script-label">
                        <i class="fas fa-comment-alt"></i>
                        <span>Script</span>
                      </label>
                      <textarea
                        class={`slide-script ${!slide.script ? "empty" : ""}`}
                        placeholder={slide.text || "Add your speaking notes here..."}
                        value={slide.script || ""}
                        on:input={(e) => storyStore.updateSlideScript(index, e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="slide-actions">
                  <button
                    class="action-btn edit-btn"
                    on:click={() => editSlide(index)}
                  >
                    <i class="fas fa-edit"></i>
                    Edit Slide
                  </button>
                  <button
                    class="action-btn delete-btn"
                    on:click={(e) => removeSlide(index, e)}
                    title="Delete slide"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          {/each}
          
          <!-- Add new slide card -->
          <button class="slide-card add-slide-card" on:click={addNewSlide}>
            <div class="add-slide-content">
              <div class="add-icon">
                <i class="fas fa-plus"></i>
              </div>
              <span class="add-text">Add New Slide</span>
            </div>
          </button>
        </section>
      {/if}
    </div>
  </div>
</div>

<!-- Add the template selection modal -->
{#if showTemplateModal}
  <div class="modal-backdrop" transition:fade>
    <div class="template-modal">
      <div class="template-modal-header">
        <h2>Choose a Story Template</h2>
        <p class="modal-description">Select a template to get started with your story</p>
      </div>
      
      <div class="template-grid">
        {#each templates as template}
          <button
            class="template-card {template.isDefault ? 'default' : ''}"
            on:click={() => handleTemplateSelect(template)}
          >
            <div class="template-card-content">
              <div class="template-header">
                <h3>
                  {template.title}
                  {#if template.isDefault}
                    <span class="default-badge">Default</span>
                  {/if}
                </h3>
              </div>
              <p>{template.description}</p>
              <div class="template-footer">
                <span class="slide-count">
                  {template.id === "none"
                    ? "1 blank slide"
                    : `${template.elements.length} slides`}
                </span>
              </div>
            </div>
          </button>
        {/each}
      </div>
    </div>
  </div>
{/if}

{#if showConfirmModal}
  <div class="modal-backdrop" on:click={cancelTemplateApplication}></div>
  <div class="modal-wrapper">
    <div class="modal-container">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Apply Template</h5>
          <button class="modal-close" on:click={cancelTemplateApplication}>
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <p class="modal-description">
            How would you like to apply this template?
          </p>
          <div class="modal-options">
            <button
              class="modal-option"
              on:click={() => {
                const template = templates.find(t => t.id === pendingTemplateId);
                if (template) {
                  handleTemplateSelect(template);
                }
                showConfirmModal = false;
              }}
            >
              <div class="option-icon">
                <i class="fas fa-plus"></i>
              </div>
              <div class="option-content">
                <span class="option-title">Add to Existing Story</span>
                <span class="option-description"
                  >Keep current slides and add template slides at the end</span
                >
              </div>
            </button>

            <button
              class="modal-option warning"
              on:click={() => replaceEntireStory(pendingTemplateId)}
            >
              <div class="option-icon">
                <i class="fas fa-sync-alt"></i>
              </div>
              <div class="option-content">
                <span class="option-title">Replace Entire Story</span>
                <span class="option-description"
                  >Remove all current slides and create new ones</span
                >
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<!-- Add this to your template, right after the closing tag of the template modal -->
{#if isCreatingStory}
  <div class="loading-overlay">
    <div class="spinner"></div>
    <p>Creating your story...</p>
  </div>
{/if}

<style>
  .page-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: #f9fafb;
  }

  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .grid-container {
    flex: 1;
    padding: 1.5rem 2rem;
    overflow-y: auto;
  }

  .slides-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    gap: 1.25rem;
    width: 100%;
  }

  .slide-card {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: all 0.2s ease;
    height: 320px;
    border: 1px solid #e5e7eb;
  }

  .slide-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07);
    transform: translateY(-2px);
  }

  .drag-indicator {
    padding: 0.5rem;
    background: #f3f4f6;
    cursor: grab;
    text-align: center;
    color: #9ca3af;
    border-bottom: 1px solid #e5e7eb;
    font-size: 0.75rem;
  }

  .slide-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0.75rem;
  }

  .slide-main {
    display: flex;
    flex: 1;
    gap: 1rem;
  }

  .slide-preview {
    width: 120px;
    height: 200px;
    background: #f8fafc;
    border-radius: 0.5rem;
    overflow: hidden;
    border: 1px solid #e5e7eb;
    flex-shrink: 0;
  }

  .svg-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .svg-container svg {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .empty-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #94a3b8;
    gap: 0.5rem;
    text-align: center;
    height: 100%;
  }

  .empty-preview i {
    font-size: 1.5rem;
  }

  .empty-preview span {
    font-size: 0.75rem;
  }

  .slide-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .slide-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .slide-number {
    font-size: 0.85rem;
    font-weight: 500;
    color: #6b7280;
    background: #f3f4f6;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    flex-shrink: 0;
  }

  .slide-title {
    flex: 1;
    min-width: 0;
    border: 1px solid transparent;
    padding: 0.35rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    font-weight: 500;
    background: transparent;
    transition: all 0.2s ease;
  }

  .slide-title:hover {
    border-color: #e5e7eb;
    background: #fcfcfd;
  }

  .slide-title:focus {
    outline: none;
    border-color: #2563eb;
    background: #fff;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.08);
  }

  .script-preview {
    background: #f9fafb;
    padding: 0.75rem;
    border-radius: 0.5rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .script-label {
    display: flex;
    align-items: center;
    gap: 0.35rem;
    font-size: 0.75rem;
    color: #6b7280;
    margin-bottom: 0.35rem;
    font-weight: 500;
  }

  .script-label i {
    font-size: 0.8rem;
    opacity: 0.8;
  }

  .slide-script {
    margin: 0;
    font-size: 0.85rem;
    color: #4b5563;
    line-height: 1.5;
    flex: 1;
    width: 100%;
    border: none;
    background: transparent;
    resize: none;
    transition: all 0.2s ease;
    overflow-y: auto;
  }

  .slide-script:focus {
    outline: none;
    background: #fcfcfd;
  }

  .slide-script.empty {
    color: #9ca3af;
    font-style: italic;
  }

  .slide-actions {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-top: 0.75rem;
  }

  .action-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.35rem;
    padding: 0.5rem 0.75rem;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.85rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .edit-btn {
    flex: 1;
    background: #f0f7ff;
    color: #2563eb;
    border: 1px solid #d1e2ff;
    font-weight: 500;
  }

  .edit-btn:hover {
    background: #e0efff;
  }

  .delete-btn {
    padding: 0.5rem;
    background: #f9fafb;
    color: #9ca3af;
    border: 1px solid #e5e7eb;
  }

  .delete-btn:hover {
    background: #fee2e2;
    color: #dc2626;
    border-color: #fecaca;
  }

  .add-slide-card {
    border: 2px dashed #d1d5db;
    background: white;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 240px;
    padding: 1.5rem;
  }

  .add-slide-card:hover {
    border-color: #2563eb;
    background: #f8fafc;
  }

  .add-slide-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    color: #6b7280;
    transition: color 0.2s ease;
  }

  .add-slide-card:hover .add-slide-content {
    color: #2563eb;
  }

  .add-icon {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background: #f3f4f6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    transition: all 0.2s ease;
  }

  .add-slide-card:hover .add-icon {
    background: #e0f2fe;
  }

  .add-text {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .page-header {
    padding: 1rem 2rem;
    border-bottom: 1px solid #e5e7eb;
    background: white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  }

  .title-container {
    max-width: 800px;
    margin: 0 auto;
  }

  .title-label {
    font-size: 0.8rem;
    color: #6b7280;
    margin-bottom: 0.25rem;
    display: block;
  }

  .title-edit-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .title-input {
    flex: 1;
    font-size: 1.25rem;
    font-weight: 600;
    color: #1f2937;
    border: none;
    border-bottom: 2px solid #2563eb;
    background: transparent;
    padding: 0.25rem 0;
    width: 100%;
  }

  .title-input:focus {
    outline: none;
  }

  .title-display {
    width: 100%;
    padding: 0.25rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .title-display:hover {
    border-bottom-color: #e5e7eb;
  }

  .story-title {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: #1f2937;
  }

  .edit-icon {
    color: #9ca3af;
    font-size: 0.875rem;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  .title-display:hover .edit-icon {
    opacity: 1;
  }

  .edit-action-btn {
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    color: #2563eb;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .edit-action-btn:hover {
    color: #1d4ed8;
  }

  /* Modal styles */
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .template-modal {
    background: white;
    border-radius: 0.75rem;
    width: 90%;
    max-width: 900px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 1.5rem;
    box-shadow: 
      0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .template-modal-header {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .template-modal-header h2 {
    font-size: 1.5rem;
    color: #1f2937;
    margin-bottom: 0.5rem;
  }

  .modal-description {
    color: #6b7280;
    font-size: 0.875rem;
  }

  .template-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }

  .template-card {
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 1rem;
    text-align: left;
    cursor: pointer;
    transition: all 0.2s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .template-card:hover {
    border-color: #2563eb;
    background: #f8fafc;
  }

  .template-card.default {
    border-color: #2563eb;
    background: #f0f7ff;
  }

  .template-header {
    margin-bottom: 0.5rem;
  }

  .template-header h3 {
    font-size: 1rem;
    color: #1f2937;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .default-badge {
    background: #2563eb;
    color: white;
    font-size: 0.75rem;
    padding: 0.125rem 0.375rem;
    border-radius: 0.25rem;
  }

  .template-card p {
    color: #6b7280;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    flex: 1;
  }

  .template-footer {
    margin-top: auto;
  }

  .slide-count {
    font-size: 0.75rem;
    color: #6b7280;
    background: #f3f4f6;
    padding: 0.125rem 0.5rem;
    border-radius: 0.75rem;
  }

  .modal-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    width: 90%;
    max-width: 500px;
  }

  .modal-container {
    background: white;
    border-radius: 0.75rem;
    box-shadow:
      0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .modal-content {
    padding: 1.5rem;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .modal-title {
    font-size: 1.25rem;
    color: #1f2937;
    margin: 0;
  }

  .modal-close {
    background: none;
    border: none;
    color: #6b7280;
    cursor: pointer;
    padding: 0.25rem;
    border-radius: 0.25rem;
    transition: all 0.2s ease;
  }

  .modal-close:hover {
    background: #f3f4f6;
    color: #ef4444;
  }

  .modal-options {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .modal-option {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    background: none;
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: left;
  }

  .modal-option:hover {
    border-color: #2563eb;
    background: #f8fafc;
  }

  .modal-option.warning:hover {
    border-color: #ef4444;
    background: #fef2f2;
  }

  .option-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    background: #f3f4f6;
    border-radius: 0.375rem;
    color: #6b7280;
    flex-shrink: 0;
  }

  .modal-option:hover .option-icon {
    background: #e5e7eb;
    color: #2563eb;
  }

  .modal-option.warning:hover .option-icon {
    color: #ef4444;
  }

  .option-content {
    flex: 1;
  }

  .option-title {
    display: block;
    font-weight: 500;
    color: #1f2937;
    margin-bottom: 0.25rem;
  }

  .option-description {
    display: block;
    font-size: 0.875rem;
    color: #6b7280;
  }

  /* Loading overlay */
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }

  .spinner {
    width: 3rem;
    height: 3rem;
    border: 3px solid #e5e7eb;
    border-top-color: #2563eb;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
</style>
