<script>
    import { onDestroy, onMount } from 'svelte';
    import { evidenceStore } from '../stores/evidenceStore';
    import { storyStore } from '../stores/storyStore.js';
    import { authStore } from '../stores/authStore.js';
    import { generateResponse, testAIConfig, testAnthropicConnection, ERROR_TYPES } from '../services/aiService';
    import { initializeChat as initializeChatSession, getChatHistory, addMessage as addChatMessage, addWelcomeMessage } from '../services/chatService';
    import { logAgentActivity, getAgentActivityLogsByChatThread } from '../services/agentActivityService';
    import { writable, get } from 'svelte/store';
    import { slide } from 'svelte/transition';
    import { AI_CONFIG } from '../../backend/config/aiConfig';
    import { chartAgentInstructions } from '../stores/agentInstructionsStore';
    export let key = 0; // Add this prop
  
    // Add debug mode for production troubleshooting
    const DEBUG_MODE = window.location.hostname === 'datacreative.app';
    
    // Constants
    const SENDER_USER = 'user';
    const SENDER_AI = 'ai';
    let currentSuggestions = [];
    
    // Custom instructions for this specific agent
    const AGENT_INSTRUCTIONS = AI_CONFIG.agentInstructions.chartAnalysis;
    
    // Quick actions
    const QUICK_ACTIONS = [
        { id: 'identify-parts', text: 'Help me understand the parts of this graph' },
        { id: 'find-patterns', text: 'Help me find patterns in the data' },
        { id: 'connect-argument', text: 'Help me connect this to my argument' }
    ];

    // Initialize state variables
    let currentInstructions;
    let editableInstructions;
    let authState = { user: null };
    let storyState = { _id: null };
    let evidenceState = { currentEvidence: null };
    let messages = [];
    let userInput = '';
    let isLoading = false;
    let chatBox; // Reference to chat box element
    let loadInterval; // Define loadInterval
    let currentContext = null;
    let isTyping = false;
    let showQuickActions = false; // Start with suggestions visible
    let currentChat = null;
    let isInitialized = false;
    let isInitializing = false;
    let hasInitializedChat = false; // Add new flag to track if chat has been initialized
    let errorMessage = null; // Add error message variable

    // Connection status
    let connectionStatus = {
        checking: false,
        connected: false,
        lastChecked: null,
        error: null,
        errorType: null
    };

    let connectionCheckInterval = null;

    // Track consecutive connection failures
    let connectionFailures = 0;

    // Function to test API connection
    async function checkApiConnection() {
        if (connectionStatus.checking) return;
        
        // Don't attempt connection check if we don't have authentication
        if (!authState?.user?.uid) {
            console.log('Skipping API connection check - user not authenticated yet');
            return false;
        }
        
        connectionStatus = {
            ...connectionStatus,
            checking: true
        };
        
        try {
            if (DEBUG_MODE) {
                console.log('DEBUG: Checking Anthropic API connection...');
            }
            
            const result = await testAnthropicConnection();
            
            connectionStatus = {
                checking: false,
                connected: result.success,
                lastChecked: new Date(),
                error: result.success ? null : result.error,
                errorType: result.success ? null : result.errorType
            };
            
            if (DEBUG_MODE) {
                console.log('DEBUG: Connection status updated:', connectionStatus);
            }
            
            // Only show firewall error if we've tried multiple times
            if (!result.success && result.errorType === ERROR_TYPES.FIREWALL) {
                // Only set error message if we've had multiple consecutive failures
                if (connectionFailures >= 2) {
                    errorMessage = 'The AI service is unavailable because it appears to be blocked by your school network or firewall. Please contact your teacher or IT administrator for assistance.';
                } else {
                    // Increment failure counter but don't show error yet
                    connectionFailures++;
                    console.log(`Connection failure ${connectionFailures}/2 - waiting for additional confirmation`);
                }
            } else if (result.success) {
                // Reset failure counter on success
                connectionFailures = 0;
            }
            
            return result.success;
        } catch (error) {
            console.error('Error checking API connection:', error);
            
            let errorType = ERROR_TYPES.UNKNOWN;
            
            // Handle 404 error specially (endpoint not found)
            if (error.message && error.message.includes('404')) {
                console.warn('Connection test endpoint not found - server may need to be restarted');
                // Don't show error to user in this case
                connectionStatus = {
                    checking: false,
                    connected: true, // Assume connected for better UX
                    lastChecked: new Date(),
                    error: null,
                    errorType: null
                };
                return true;
            }
            
            connectionStatus = {
                checking: false,
                connected: false,
                lastChecked: new Date(),
                error: error.message,
                errorType
            };
            
            return false;
        }
    }

    // Set up periodic connection checking
    function setupConnectionChecking() {
        // Delay initial check to ensure app is fully loaded
        setTimeout(() => {
            checkApiConnection();
            
            // Set up interval for rechecking (every 2 minutes)
            connectionCheckInterval = setInterval(() => {
                checkApiConnection();
            }, 2 * 60 * 1000); // Check every 2 minutes
        }, 3000); // Wait 3 seconds before first check
    }

    // Clean up interval on component destroy
    onDestroy(() => {
        if (connectionCheckInterval) {
            clearInterval(connectionCheckInterval);
        }
    });

    // And subscribe to the stores
    chartAgentInstructions.subscribe(value => {
        currentInstructions = value;
        editableInstructions = value;
    });

    const unsubscribeAuth = authStore.subscribe(state => {
        authState = state || { user: null };
        console.log('Auth state updated:', {
            hasUser: !!authState?.user,
            hasUserId: !!authState?.user?.uid,
            userId: authState?.user?.uid
        });
        initializeChatIfReady();
    });

    // Update the reactive statement to prevent multiple initializations
    $: {
        key; // Create dependency on key
        if (!isInitializing && !hasInitializedChat) {
            // Initialize chat if we have the required data
            if (storyState?._id && evidenceState?.currentEvidence?._id && authState?.user?.uid) {
                initializeChatIfReady();
            }
        }
    }

    // Update initializeChatIfReady to prevent multiple initialization attempts
    async function initializeChatIfReady() {
        // Prevent multiple initialization attempts
        if (isInitializing || hasInitializedChat) {
            console.log('Chat initialization already in progress or completed');
            return;
        }

        const hasStoryId = !!storyState?._id;
        const hasEvidenceId = !!evidenceState?.currentEvidence?._id;
        const hasUserId = !!authState?.user?.uid;
        const hasExistingMessages = messages.length > 0;

        console.log('Checking chat initialization conditions:', {
            hasStoryId,
            hasEvidenceId,
            hasUserId,
            hasExistingMessages,
            isInitializing,
            hasInitializedChat
        });

        if (hasStoryId && hasEvidenceId && hasUserId && !hasExistingMessages) {
            isInitializing = true;
            try {
                await initializeChat();
            } finally {
                isInitializing = false;
            }
        }
    }

    // Update initializeChat to use the new addWelcomeMessage function
    async function initializeChat() {
        try {
            console.log('Starting chat initialization...');
            
            // Set loading state
            isInitializing = true;

            // Get current evidence and story IDs
            const currentEvidence = get(evidenceStore);
            const currentStory = get(storyStore);

            // Debug the evidence and story state to see what we're working with
            console.log('Current evidence state:', currentEvidence);
            console.log('Current story state:', currentStory);

            if (DEBUG_MODE) {
                console.log('DEBUG: Evidence store:', currentEvidence);
                console.log('DEBUG: Story store:', currentStory);
                console.log('DEBUG: Auth state:', authState);
            }

            if (!currentEvidence?.currentEvidence?._id || !currentStory?._id || !authState?.user?.uid) {
                console.log('Missing required data for chat initialization');
                if (DEBUG_MODE) {
                    console.error('DEBUG: Missing required data for chat initialization');
                    console.error('DEBUG: Evidence ID:', currentEvidence?.currentEvidence?._id);
                    console.error('DEBUG: Story ID:', currentStory?._id);
                    console.error('DEBUG: User ID:', authState?.user?.uid);
                }
                
                // Don't set error message here - just log and return
                return;
            }

            const evidenceId = currentEvidence.currentEvidence._id;
            const storyId = currentStory._id;
            const userId = authState.user.uid;

            console.log('Initializing chat with IDs:', { 
                userId, 
                storyId: typeof storyId === 'object' ? storyId.toString() : storyId, 
                evidenceId: typeof evidenceId === 'object' ? evidenceId.toString() : evidenceId 
            });

            // Initialize chat session - ensure we're passing strings if needed
            currentChat = await initializeChatSession({
                userId,
                storyId: typeof storyId === 'object' ? storyId.toString() : storyId,
                chatType: 'chart-analysis',
                evidenceId: typeof evidenceId === 'object' ? evidenceId.toString() : evidenceId
            });

            console.log('Chat session initialized:', currentChat);

            // Get chat history
            const history = await getChatHistory({
                userId,
                storyId: typeof storyId === 'object' ? storyId.toString() : storyId,
                chatType: 'chart-analysis',
                evidenceId: typeof evidenceId === 'object' ? evidenceId.toString() : evidenceId
            });

            // Ensure history is an array
            const messageHistory = Array.isArray(history) ? history : [];
            console.log(`Retrieved ${messageHistory.length} messages from chat history`);

            // Process chat history
            if (messageHistory.length > 0) {
                // Chat history exists, populate the UI with the messages
                messages = messageHistory.map(msg => ({
                    id: msg._id || generateMessageId(),
                    content: msg.content,
                    sender: msg.sender,
                    timestamp: msg.timestamp || new Date(),
                    suggestions: msg.suggestions || []
                }));
                
                // Set the current suggestions from the last AI message
                const lastAiMessage = messageHistory
                    .filter(msg => msg.sender === 'ai')
                    .pop();
                    
                if (lastAiMessage && lastAiMessage.suggestions) {
                    currentSuggestions = lastAiMessage.suggestions;
                }
                
                console.log('Chat history loaded successfully');
            } else {
                // No chat history, create welcome message
                console.log('No chat history found, creating welcome message');
                
                const welcomeContent = "Hi there! I'm here to help you analyze this chart. What would you like to know about it?";
                
                // Create welcome message for UI
                const welcomeMessage = {
                    id: generateMessageId(),
                    content: welcomeContent,
                    sender: 'ai',
                    timestamp: new Date(),
                    suggestions: [
                        "What does this chart show?",
                        "What are the main trends?",
                        "How can I interpret this data?"
                    ]
                };
                
                // Add to UI
                messages = [welcomeMessage];
                currentSuggestions = welcomeMessage.suggestions;
                
                // Save to database
                if (currentChat?._id) {
                    try {
                        await addChatMessage(currentChat._id, {
                            content: welcomeContent,
                            sender: 'ai',
                            timestamp: new Date(),
                            suggestions: welcomeMessage.suggestions
                        });
                        
                        // Log AI message activity
                        await logAgentActivity({
                            chatThreadId: currentChat._id,
                            userId,
                            storyId,
                            evidenceId,
                            activityType: 'message_received',
                            details: {
                                messageContent: welcomeContent,
                                messageType: 'welcome'
                            }
                        });
                        
                        console.log('Welcome message saved to database');
                    } catch (error) {
                        console.error('Error saving welcome message:', error);
                    }
                }
            }

            // Set initialization flag
            hasInitializedChat = true;
            console.log('Chat initialization completed successfully');

        } catch (error) {
            console.error('Error initializing chat:', error);
            // Show detailed error information
            console.error('Error details:', error.stack, error.message);
            // Show error to user
            errorMessage = 'Failed to initialize chat. Please try refreshing the page.';
            // Reset the initialization flag so we can try again
            hasInitializedChat = false;
            isInitializing = false;
        }
    }

    // Update store subscriptions to be more reactive
    const unsubscribeStory = storyStore.subscribe(state => {
        storyState = state || { _id: null };
        console.log('Story state updated:', {
            hasStoryId: !!storyState?._id,
            storyId: storyState?._id
        });
        // Only initialize if we have all required data and haven't initialized yet
        if (!hasInitializedChat && storyState?._id && evidenceState?.currentEvidence?._id && authState?.user?.uid) {
            initializeChatIfReady();
        }
    });

    // Update the evidence store subscription to properly handle deselection
    const unsubscribeEvidence = evidenceStore.subscribe(state => {
        const previousEvidenceId = evidenceState?.currentEvidence?._id;
        evidenceState = state || { currentEvidence: null };
        
        console.log('Evidence state updated:', {
            hasEvidence: !!evidenceState?.currentEvidence,
            hasEvidenceId: !!evidenceState?.currentEvidence?._id,
            evidenceId: evidenceState?.currentEvidence?._id,
            previousEvidenceId
        });

        // Only reset if evidence is deselected or changed
        if (!evidenceState?.currentEvidence || 
            (previousEvidenceId && previousEvidenceId !== evidenceState?.currentEvidence?._id)) {
            messages = [];
            currentChat = null;
            currentSuggestions = [];
            hasInitializedChat = false;
            console.log('Chat state reset due to evidence change or deselection');
        } else if (evidenceState?.currentEvidence && !hasInitializedChat && storyState?._id && authState?.user?.uid) {
            // Only initialize if we have all required data
            initializeChatIfReady();
        }
    });

    // Message handling functions
    function generateMessageId() {
        return `msg-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    }

    function addMessage(text, sender, suggestions = []) {
        // Ensure text is a string and not undefined
        const messageText = typeof text === 'string' ? text : 'Invalid message format';
                       
        const newMessage = {
            id: generateMessageId(),
            content: messageText, // Use content as the primary message field
            text: messageText,    // Keep text for backward compatibility
            sender,
            suggestions,
            timestamp: new Date()
        };
        
        messages = [...messages, newMessage];
    
        // If this is an AI message, also update currentSuggestions for display
        if (sender === SENDER_AI) {
            currentSuggestions = suggestions;
        }
    
        // Ensure chat scrolls to bottom
        setTimeout(scrollToBottom, 100);
    }

    // Settings state
    let showSettings = false;
    
    function toggleSettings() {
        showSettings = !showSettings;
    }

    function saveInstructions() {
        chartAgentInstructions.set(editableInstructions);
        messages = []; 
        showSettings = false;
    }

    function resetInstructions() {
        chartAgentInstructions.reset();
        messages = []; 
        showSettings = false;
    }

    // Update the sendMessage function to check connection before sending
    async function sendMessage(userMessage = null) {
        const messageText = userMessage || userInput.trim();
        if (!messageText) return;

        // Check if we have the required data for sending a message
        if (!authState?.user?.uid || !storyState?._id || !evidenceState?.currentEvidence?._id) {
            addMessage('Sorry, I cannot respond right now. The application is still loading or missing required data. Please try refreshing the page.', 'ai');
            console.error('Cannot send message - missing required data:', {
                hasUser: !!authState?.user?.uid,
                hasStoryId: !!storyState?._id,
                hasEvidenceId: !!evidenceState?.currentEvidence?._id
            });
            return;
        }

        // Auto-initialize chat if needed
        if (!hasInitializedChat && !isInitializing) {
            isInitializing = true;
            try {
                await initializeChat();
            } catch (error) {
                console.error('Failed to initialize chat:', error);
                addMessage('Sorry, I encountered an error initializing the chat. Please try refreshing the page.', 'ai');
                isInitializing = false;
                return;
            }
            isInitializing = false;
        }

        // Check connection with automatic retry
        let isConnected = true;
        
        // If connection is unknown or was previously failed, check again
        if (!connectionStatus.lastChecked || 
            (connectionStatus.lastChecked && !connectionStatus.connected)) {
            
            console.log('Checking connection before sending message...');
            isConnected = await checkApiConnection();
            
            // If first check fails, try once more after a short delay
            if (!isConnected) {
                console.log('First connection check failed, retrying after delay...');
                await new Promise(resolve => setTimeout(resolve, 2000)); // Wait 2 seconds
                isConnected = await checkApiConnection();
            }
            
            if (!isConnected) {
                addMessage('Sorry, I cannot respond right now. The AI service appears to be unavailable. Please try again in a moment.', 'ai');
                return;
            }
        }

        userInput = '';
        isLoading = true;
        
        // Create user message object
        const userMessageObj = {
            id: generateMessageId(),
            content: messageText,
            sender: 'user',
            timestamp: new Date()
        };
        
        // Add to UI
        addMessage(messageText, 'user');

        // Save user message to database if we have a chat session
        if (currentChat?._id) {
            try {
                await addChatMessage(currentChat._id, {
                    content: messageText,
                    sender: 'user',
                    timestamp: new Date()
                });

                // Log user message activity
                await logAgentActivity({
                    chatThreadId: currentChat._id,
                    userId: authState.user.uid,
                    storyId: storyState._id,
                    evidenceId: evidenceState.currentEvidence._id,
                    activityType: 'message_sent',
                    details: {
                        messageContent: messageText
                    }
                });
            } catch (error) {
                console.error('Error saving user message:', error);
                if (DEBUG_MODE) {
                    console.error('DEBUG: Error saving user message:', error);
                    console.error('DEBUG: Error stack:', error.stack);
                }
            }
        }

        // Add temporary loading message
        const loadingMessage = 'Thinking...';
        addMessage(loadingMessage, 'ai');

        try {
            const context = {
                currentEvidence: evidenceState.currentEvidence,
                bigQuestion: storyState?.bigQuestion || '',
                instructions: currentInstructions
            };

            if (DEBUG_MODE) {
                console.log('DEBUG: Sending message with context:', context);
                console.log('DEBUG: Current instructions:', currentInstructions);
            }

            const prompt = `
                ${currentInstructions}
                
                Context:
                - Question: "${context.bigQuestion}"
                - Evidence: ${context.currentEvidence?.description || 'No description'}
                
                User message: ${messageText}
            `;

            if (DEBUG_MODE) {
                console.log('DEBUG: Prompt being sent to AI:', prompt);
            }

            const response = await generateResponse(prompt, context);
            
            if (DEBUG_MODE) {
                console.log('DEBUG: AI response received:', response);
            }
            
            // Remove loading message
            messages = messages.filter(m => m.text !== loadingMessage);
            
            // Create AI message object
            const aiMessageObj = {
                id: generateMessageId(),
                content: response.text,
                sender: 'ai',
                timestamp: new Date(),
                suggestions: response.suggestions || []
            };
            
            // Add to UI
            addMessage(response.text, 'ai', response.suggestions || []);
            
            // Save AI message to database if we have a chat session
            if (currentChat?._id) {
                try {
                    await addChatMessage(currentChat._id, {
                        content: response.text,
                        sender: 'ai',
                        timestamp: new Date(),
                        suggestions: response.suggestions || []
                    });

                    // Log AI message activity
                    await logAgentActivity({
                        chatThreadId: currentChat._id,
                        userId: authState.user.uid,
                        storyId: storyState._id,
                        evidenceId: evidenceState.currentEvidence._id,
                        activityType: 'message_received',
                        details: {
                            messageContent: response.text,
                            suggestions: response.suggestions || []
                        }
                    });
                } catch (error) {
                    console.error('Error saving AI message:', error);
                    if (DEBUG_MODE) {
                        console.error('DEBUG: Error saving AI message:', error);
                        console.error('DEBUG: Error stack:', error.stack);
                    }
                }
            }
        } catch (error) {
            console.error('Error getting AI response:', error);
            if (DEBUG_MODE) {
                console.error('DEBUG: Error getting AI response:', error);
                console.error('DEBUG: Error stack:', error.stack);
                console.error('DEBUG: Error name:', error.name);
                console.error('DEBUG: Error message:', error.message);
            }
            
            const errorMessage = {
                id: generateMessageId(),
                content: 'Sorry, I encountered an error. Please try again.',
                sender: 'ai',
                timestamp: new Date()
            };
            messages = [...messages, errorMessage];
            if (currentChat && currentChat._id) {
                try {
                    await addChatMessage(currentChat._id, errorMessage);
                } catch (saveError) {
                    console.error('Error saving error message:', saveError);
                    if (DEBUG_MODE) {
                        console.error('DEBUG: Error saving error message:', saveError);
                        console.error('DEBUG: Error stack:', saveError.stack);
                    }
                }
            }
        } finally {
            isLoading = false;
        }
    }

  
    function loader(uniqueId) {
      const messageIndex = messages.length - 1; // Get the index of the last AI message
      messages[messageIndex].text = " "; // Set loading message
      loadInterval = setInterval(() => {
        messages[messageIndex].text += '.';
        if (messages[messageIndex].text === '....') {
          messages[messageIndex].text = ' ';
        }
      }, 300);
    }
  
    function typeText(messageIndex, text) {
        let index = 0;
        const interval = setInterval(() => {
            if (index < text.length) {
                messages[messageIndex].text += text.charAt(index);
                messages = messages; // Trigger Svelte reactivity
                index++;
            } else {
                clearInterval(interval);
            }
        }, 20);
    }

    // Add quick action handler
    async function handleQuickAction(action) {
        if (isLoading) return; // Prevent multiple requests
        userInput = action.text;
        await sendMessage();
    }

    function handleSuggestionClick(option) {
    const messageText = typeof option === 'string' ? option : option.text || 'Invalid option';
    sendMessage(messageText);
}

    // Add auto-scroll function
    function scrollToBottom() {
        if (chatBox) {
            setTimeout(() => {
                chatBox.scrollTo({
                    top: chatBox.scrollHeight,
                    behavior: 'smooth'
                });
            }, 100);
        }
    }

    // Cleanup on component destruction
    onDestroy(() => {
        unsubscribeStory();
        unsubscribeEvidence();
        unsubscribeAuth();
        if (loadInterval) clearInterval(loadInterval);
        
        // Log chat closure if we have a chat session
        if (currentChat) {
            logAgentActivity({
                chatThreadId: currentChat._id,
                userId: authState?.user?.uid,
                storyId: storyState?._id,
                evidenceId: evidenceState?.currentEvidence?._id,
                activityType: 'chat_closed',
                details: {
                    messageCount: messages.length
                }
            }).catch(error => {
                console.error('Error logging chat closure:', error);
            });
        }
    });

    onMount(async () => {
        isInitialized = true;
        try {
            if (DEBUG_MODE) {
                console.log('DEBUG: Testing AI config on mount');
            }
            
            // Test general config
            const config = await testAIConfig();
            console.log('AI Config loaded:', config);
            
            // Wait a moment before checking connection to ensure all stores are loaded
            setTimeout(() => {
                // Only check connection if we have authentication
                if (authState?.user?.uid) {
                    setupConnectionChecking();
                } else {
                    // Set up a watcher that will check connection once auth is loaded
                    const unsubscribeAuth = authStore.subscribe(state => {
                        if (state?.user?.uid && !connectionCheckInterval) {
                            setupConnectionChecking();
                            unsubscribeAuth();
                        }
                    });
                }
            }, 1000);
        } catch (error) {
            console.error('Failed to load AI config:', error);
            if (DEBUG_MODE) {
                console.error('DEBUG: Failed to load AI config:', error);
                console.error('DEBUG: Error stack:', error.stack);
            }
        }
    });

    // Listen for askAgent events
    onMount(() => {
        window.addEventListener('askAgent', handleAskAgent);
        return () => {
            window.removeEventListener('askAgent', handleAskAgent);
        };
    });

    function handleAskAgent(event) {
        const { question, context } = event.detail;
        currentContext = context;
        // Add the question to messages with unique ID
        messages = [...messages, {
            id: generateMessageId(),
            text: question,
            sender: 'user'
        }];
        handleResponse(question, context);
    }

    async function handleResponse(question, context) {
    const loadingId = generateMessageId();
    messages = [...messages, { 
        id: loadingId,
        text: " ",
        sender: 'ai'
    }];
    
    try {
        const { region, description, imageData } = context;

        // Validation logic remains the same...

        const prompt = `
            Question about chart region: ${question}

            Chart Description: ${description}
            
            Selected Region:
            - Position: (${boundedRegion.x.toFixed(1)}%, ${boundedRegion.y.toFixed(1)}%)
            - Size: ${boundedRegion.width.toFixed(1)}% × ${boundedRegion.height.toFixed(1)}%
            
            Image Details:
            - Full Image URL: ${imageData.src}
            - Dimensions: ${imageData.naturalWidth}x${imageData.naturalHeight}

            Please analyze this specific region of the chart and explain what it shows. Do not make up information. You are a data analyst and educator. Use informal ryan reynolds style.
            
            Format your response as JSON with a message and suggestions for next steps:
            {
              "message": "Your analysis of the chart region",
              "suggestions": [
                "Would you like to know more about this specific trend?",
                "Should we compare this to another part of the chart?",
                "Would you like to understand what might be causing this pattern?"
              ]
            }
        `;
        
        const response = await generateResponse(prompt, context);

        messages = messages.map(msg => 
    msg.id === loadingId 
        ? { ...msg, text: response.text, suggestions: response.suggestions || [] }
        : msg
);
        
        // Store the suggestions for display
        currentSuggestions = response.suggestions || [];
    } catch (error) {
        console.error(error);
        messages = messages.map(msg => 
            msg.id === loadingId 
                ? { ...msg, text: "Something went wrong" }
                : msg
        );
        currentSuggestions = [];
    }
}

    // Add error boundary
    let hasError = false;
    function handleError(error) {
        console.error('Chat component error:', error);
        hasError = true;
        messages = [...messages, {
            id: generateMessageId(),
            text: "Something went wrong. Please refresh the page.",
            sender: SENDER_AI
        }];
    }

    // Add debug logging
    $: {
        if (evidenceState?.currentEvidence) {
            console.log('Current evidence updated:', evidenceState.currentEvidence);
        }
    }

    $: {
        if (messages.length > 0) {
            console.log('Messages updated:', messages);
        }
    }

</script>
<div class="chat-container">
    <!-- Show connection error only after multiple failures -->
    {#if !connectionStatus.connected && connectionStatus.lastChecked && connectionFailures >= 2}
        <div class="connection-status-bar" class:firewall={connectionStatus.errorType === 'firewall'}>
            <div class="connection-icon">
                <i class="fas fa-exclamation-triangle"></i>
            </div>
            <div class="connection-message">
                {#if connectionStatus.errorType === 'firewall'}
                    <strong>School Firewall Detected:</strong> The AI service appears to be blocked by your school network. Please inform your teacher.
                {:else if connectionStatus.errorType === 'network'}
                    <strong>Network Issue:</strong> Cannot connect to the AI service. Please check your internet connection.
                {:else if connectionStatus.errorType === 'auth'}
                    <strong>Authentication Issue:</strong> Cannot authenticate with the AI service.
                {:else}
                    <strong>Connection Problem:</strong> Cannot connect to AI service. {connectionStatus.error}
                {/if}
            </div>
            <button class="retry-connection" on:click={checkApiConnection}>
                {connectionStatus.checking ? 'Checking...' : 'Retry Connection'}
            </button>
        </div>
    {/if}

    <div class="chat-box" bind:this={chatBox}>
      {#if hasError}
        <div class="error-message">
          Something went wrong. Please refresh the page.
        </div>
      {/if}
      
      {#if errorMessage}
        <div class="error-message">
          {errorMessage}
          <button class="retry-button" on:click={() => { errorMessage = null; hasInitializedChat = false; initializeChatIfReady(); checkApiConnection(); }}>
            Retry
          </button>
        </div>
      {/if}
      
      <!-- Show loading state when initializing -->
      {#if isInitializing && messages.length === 0}
        <div class="loading-container">
          <div class="loading-spinner"></div>
          <p>Initializing AI assistant...</p>
        </div>
      {:else if !authState?.user?.uid || !storyState?._id || !evidenceState?.currentEvidence?._id}
        <div class="loading-container">
          <div class="info-icon">
            <i class="fas fa-info-circle"></i>
          </div>
          <p>AI assistant is waiting for required data to load. Please ensure you're logged in and have selected a piece of evidence.</p>
        </div>
      {:else if messages.length === 0 && !isInitializing}
        <div class="loading-container">
          <div class="loading-spinner"></div>
          <p>Loading chart assistant...</p>
        </div>
      {/if}
      
      {#each messages as message (message.id)}
        <div class="message {message.sender}" class:loading={message.text === 'Thinking...'}>
          <div class="avatar {message.sender}">
            {#if message.sender === 'ai'}
              <svg viewBox="0 0 24 24" class="icon">
                <path fill="currentColor" d="M12 2a2 2 0 0 1 2 2c0 .74-.4 1.39-1 1.73V7h1a7 7 0 0 1 7 7h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1H2a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h1a7 7 0 0 1 7-7h1V5.73c-.6-.34-1-.99-1-1.73a2 2 0 0 1 2-2Z"/>
              </svg>
            {:else}
              <svg viewBox="0 0 24 24" class="icon">
                <path fill="currentColor" d="M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4Z"/>
              </svg>
            {/if}
          </div>
          <div class="bubble">
            {message.content || message.text || 'Error: Missing message content'}
            {#if message.sender === 'ai' && message.suggestions?.length}
              <div class="suggestion-buttons">
                {#each message.suggestions as suggestion}
                  <button class="suggestion-btn" on:click={() => handleSuggestionClick(suggestion)}>
                    {suggestion}
                  </button>
                {/each}
              </div>
            {/if}
          </div>
        </div>
      {/each}
    </div>

    <!-- Input controls - disable while initializing -->
    <div class="chat-controls">
      <button
        class="quick-actions-toggle"
        on:click={() => showQuickActions = !showQuickActions}
        aria-label={showQuickActions ? 'Hide suggestions' : 'Show suggestions'}
        disabled={isInitializing || !hasInitializedChat}
      >
        <i class="fas fa-lightbulb"></i>
        <span>Suggestions</span>
        <i class="fas fa-chevron-{showQuickActions ? 'up' : 'down'}"></i>
      </button>
  
      {#if showQuickActions}
        <div class="quick-actions" transition:slide>
          {#each QUICK_ACTIONS as action}
            <button
              class="quick-action-btn"
              on:click={() => handleQuickAction(action)}
              disabled={isInitializing || !hasInitializedChat}
            >
              {action.text}
            </button>
          {/each}
        </div>
      {/if}
  
      <div class="input-group">
        <input
          type="text"
          bind:value={userInput}
          placeholder={isInitializing ? "Initializing..." : "Type your message..."}
          on:keydown={(e) => e.key === 'Enter' && !e.shiftKey && !isInitializing && hasInitializedChat && sendMessage()}
          disabled={isInitializing || !hasInitializedChat}
        />
        <button
          class="settings-button"
          on:click={toggleSettings}
          title="AI Settings"
          disabled={isInitializing}
        >
          <i class="fas fa-cog"></i>
        </button>
        <button
          on:click={sendMessage}
          class="send-btn"
          disabled={!userInput.trim() || isInitializing || !hasInitializedChat}
        >
          <i class="fas fa-paper-plane"></i>
        </button>
      </div>
    </div>
  </div>
  
<!-- Add this before the closing style tag -->
{#if showSettings}
    <div class="settings-modal" on:click|self={() => showSettings = false}>
        <div class="settings-content">
            <h3>AI Instructions</h3>
            <textarea
                bind:value={editableInstructions}
                rows="10"
                placeholder="Enter custom instructions for the AI..."
            ></textarea>
            <div class="settings-actions">
                <button class="reset-button" on:click={resetInstructions}>
                    Reset to Default
                </button>
                <button class="save-button" on:click={saveInstructions}>
                    Save Changes
                </button>
            </div>
        </div>
    </div>
{/if}

<style>
    .chat-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: white;
        max-height: 100%; /* Ensure container doesn't overflow */
    }

    .chat-box {
        flex: 1;
        overflow-y: auto;
        padding: 16px;
        scroll-behavior: smooth;
        min-height: 0; /* Important for Firefox */
        display: flex;
        flex-direction: column;
    }

    .chat-controls {
        border-top: 1px solid #eee;
        padding: 8px 8px 10px 8px;
        background: white;
    }

    .quick-actions-toggle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 12px;
        background: transparent;
        border: none;
        color: #64748b;
        font-size: 0.85rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;
        margin-bottom: 4px;
    }

    .quick-actions-toggle:hover {
        color: #1e293b;
        background: #f1f5f9;
        border-radius: 6px;
    }

    .quick-actions-toggle i {
        font-size: 0.8rem;
    }

    .quick-actions-toggle i:first-child {
        color: #eab308; /* yellow-500 */
        margin-right: 6px;
    }

    .quick-actions {
        display: flex;
        gap: 6px;
        margin-bottom: 8px;
        flex-wrap: wrap;
        padding: 0 4px;
    }

    .quick-action-btn {
        background-color: #f3f4f6;
        color: #4b5563;
        border: none;
        border-radius: 16px;
        padding: 6px 12px; /* Reduce padding */
        font-size: 0.85rem; /* Smaller font */
        cursor: pointer;
        transition: all 0.2s ease;
        white-space: nowrap;
    }

    .quick-action-btn:hover {
        background-color: #e5e7eb;
        color: #1f2937;
    }

    .input-group {
        display: flex;
        gap: 6px;
        padding: 6px;
        background-color: #f9fafb;
        border-radius: 8px;
        align-items: center;
    }

    .input-group input {
        flex: 1;
        padding: 8px 12px; /* Reduce padding */
        border: none;
        border-radius: 6px;
        background-color: white;
        font-size: 0.9rem; /* Smaller font */
        color: #1f2937;
    }

    .input-group input:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
    }

    .send-btn {
        background-color: #2563eb;
        color: white;
        border: none;
        border-radius: 8px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .send-btn:hover {
        background-color: #1d4ed8;
    }

    .send-btn:disabled {
        background-color: #9ca3af;
        cursor: not-allowed;
    }

    .message {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;
        gap: 8px;
    }

    .message.user {
        flex-direction: row-reverse;
    }

    .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        padding: 6px;
    }

    .avatar.ai {
        background-color: #e5e7eb;
    }

    .avatar.user {
        background-color: #dbeafe;
    }

    .icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .suggestion-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 8px;
    }
    
    .suggestion-btn {
        background-color: #f3f4f6;
        color: #4b5563;
        border: none;
        border-radius: 16px;
        padding: 10px 16px;
        font-size: 0.9rem;
        cursor: pointer;
        transition: background-color 0.2s ease, transform 0.1s ease;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    }
    
    .suggestion-btn:hover {
        background-color: #e5e7eb;
        color: #1f2937;
    }
    
    .suggestion-btn:active {
        background-color: #1e3a8a;
        transform: scale(0.95);
    }

    .bubble {
        padding: 12px 16px;
        border-radius: 16px;
        max-width: 80%;
        line-height: 1.6;
        font-size: 0.95rem;
        white-space: pre-wrap; /* Preserve line breaks */
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    }

    .message.user .bubble {
        background-color: #2563eb;
        color: white;
        border-bottom-right-radius: 4px;
        margin-right: 8px;
    }

    .message.ai .bubble {
        background-color: #f3f4f6;
        color: #1f2937;
        border-bottom-left-radius: 4px;
        margin-left: 8px;
        /* Add subtle emphasis to the last line */
        padding-bottom: 16px; /* Extra padding at bottom */
    }

    /* Add emphasis to the question at the end */
    .message.ai .bubble strong {
        color: #1f2937;
        font-weight: 500;
    }

    /* Custom scrollbar */
    .chat-box::-webkit-scrollbar {
        width: 6px;
    }

    .chat-box::-webkit-scrollbar-track {
        background: transparent;
    }

    .chat-box::-webkit-scrollbar-thumb {
        background-color: #d1d5db;
        border-radius: 3px;
    }

    .chat-box::-webkit-scrollbar-thumb:hover {
        background-color: #9ca3af;
    }

    /* Add this to ensure messages are properly spaced */
    .chat-box > :last-child {
        margin-bottom: 0;
    }

    .settings-button {
        background: none;
        border: none;
        color: #6b7280;
        padding: 8px;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.2s;
    }

    .settings-button:hover {
        background: #f3f4f6;
        color: #2563eb;
    }

    .settings-modal {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }

    .settings-content {
        background: white;
        padding: 24px;
        border-radius: 12px;
        width: 90%;
        max-width: 600px;
        max-height: 90vh;
        overflow-y: auto;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }

    .settings-content h3 {
        margin: 0 0 16px 0;
        color: #1e293b;
        font-size: 1.25rem;
    }

    .settings-content textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        font-family: inherit;
        font-size: 0.95rem;
        line-height: 1.5;
        resize: vertical;
        margin-bottom: 16px;
    }

    .settings-content textarea:focus {
        outline: none;
        border-color: #2563eb;
        box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
    }

    .settings-actions {
        display: flex;
        gap: 12px;
        justify-content: flex-end;
    }

    .settings-actions button {
        padding: 8px 16px;
        border-radius: 6px;
        font-size: 0.95rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .reset-button {
        background: #f1f5f9;
        border: 1px solid #e2e8f0;
        color: #64748b;
    }

    .reset-button:hover {
        background: #e2e8f0;
        color: #1e293b;
    }

    .save-button {
        background: #2563eb;
        border: 1px solid #2563eb;
        color: white;
    }

    .save-button:hover {
        background: #1d4ed8;
    }

    .message.loading .bubble {
        background-color: #f3f4f6;
        color: #6b7280;
        animation: pulse 1.5s infinite;
    }

    @keyframes pulse {
        0% { opacity: 0.6; }
        50% { opacity: 1; }
        100% { opacity: 0.6; }
    }

    .error-message {
        color: #ef4444;
        padding: 12px;
        margin: 12px;
        background-color: #fee2e2;
        border-radius: 8px;
        text-align: center;
    }
    
    .retry-button {
        margin-top: 8px;
        padding: 6px 12px;
        background-color: #ef4444;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 0.9rem;
        transition: background-color 0.2s;
    }
    
    .retry-button:hover {
        background-color: #dc2626;
    }

    /* Update connection status styles */
    .connection-status-bar {
        display: flex;
        align-items: center;
        padding: 10px 16px;
        background-color: #fef2f2;
        border-bottom: 1px solid #fee2e2;
        color: #b91c1c;
        gap: 12px;
    }

    .connection-status-bar.firewall {
        background-color: #fef2f2;
        color: #b91c1c;
        border-left: 4px solid #ef4444;
    }

    .connection-icon {
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        color: #ef4444;
    }

    .connection-message {
        flex: 1;
        font-size: 0.9rem;
        line-height: 1.4;
    }

    .connection-message strong {
        display: block;
        margin-bottom: 2px;
    }

    .retry-connection {
        background-color: #ef4444;
        color: white;
        border: none;
        border-radius: 6px;
        padding: 6px 12px;
        font-size: 0.85rem;
        cursor: pointer;
        transition: background-color 0.2s;
        white-space: nowrap;
        font-weight: 500;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05);
    }

    .retry-connection:hover {
        background-color: #dc2626;
    }

    /* Add loading styles */
    .loading-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 48px 24px;
        text-align: center;
        color: #6b7280;
    }

    .loading-container p {
        margin-top: 16px;
        font-size: 0.95rem;
        max-width: 400px;
    }

    .loading-spinner {
        width: 40px;
        height: 40px;
        border: 3px solid #e5e7eb;
        border-radius: 50%;
        border-top-color: #3b82f6;
        animation: spin 1s linear infinite;
    }

    .info-icon {
        font-size: 24px;
        color: #3b82f6;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }

    /* Disabled button styles */
    button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .input-group input:disabled {
        background-color: #f9fafb;
        color: #9ca3af;
    }
</style>
  