<script>
  import { onMount } from 'svelte';
  import { fade, fly } from 'svelte/transition';
  import { toast } from '../stores/toastStore.js';
</script>

{#if $toast.toasts.length > 0}
  <div class="toast-container">
    {#each $toast.toasts as notification (notification.id)}
      <div 
        class="toast-notification {notification.type}" 
      >
        <div class="toast-icon">
          {#if notification.type === 'success'}
            <i class="bi bi-check-circle-fill"></i>
          {:else if notification.type === 'error'}
            <i class="bi bi-exclamation-circle-fill"></i>
          {:else if notification.type === 'warning'}
            <i class="bi bi-exclamation-triangle-fill"></i>
          {:else}
            <i class="bi bi-info-circle-fill"></i>
          {/if}
        </div>
        <div class="toast-message">{notification.message}</div>
        <button 
          class="toast-close" 
          on:click={() => toast.removeToast(notification.id)}
          aria-label="Close notification"
        >
          <i class="bi bi-x"></i>
        </button>
      </div>
    {/each}
  </div>
{/if}

<style>
  .toast-container {
    position: fixed;
    top: 70px; /* Position below the header */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 9999;
    pointer-events: none;
    width: 100%;
    max-width: 400px;
  }

  .toast-notification {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    animation: slide-in 0.3s ease forwards;
    pointer-events: auto;
  }

  .toast-notification.success {
    border-left: 4px solid #10b981;
  }

  .toast-notification.error {
    border-left: 4px solid #ef4444;
  }

  .toast-notification.warning {
    border-left: 4px solid #f59e0b;
  }

  .toast-notification.info {
    border-left: 4px solid #3b82f6;
  }

  .toast-icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }

  .toast-icon i {
    font-size: 18px;
  }

  .success .toast-icon i {
    color: #10b981;
  }

  .error .toast-icon i {
    color: #ef4444;
  }

  .warning .toast-icon i {
    color: #f59e0b;
  }

  .info .toast-icon i {
    color: #3b82f6;
  }

  .toast-message {
    flex: 1;
    font-size: 14px;
    color: #1e293b;
    line-height: 1.4;
  }

  .toast-close {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: #64748b;
    cursor: pointer;
    margin-left: 8px;
    padding: 0;
    border-radius: 50%;
    transition: all 0.2s ease;
  }

  .toast-close:hover {
    background: #f1f5f9;
    color: #334155;
  }

  @keyframes slide-in {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
</style> 