import { get } from 'svelte/store';
import { storyStore } from '../stores/storyStore.js';

// Constants for Google API scopes
const SCOPES = 'https://www.googleapis.com/auth/presentations https://www.googleapis.com/auth/drive.file';

/**
 * Initializes the Google API client and checks authorization
 * @returns {Promise<boolean>} Whether the API is initialized and authorized
 */
export async function initGoogleSlidesAPI() {
    // Check if we have the required API keys
    if (!process.env.GOOGLE_API_KEY || !process.env.GOOGLE_CLIENT_ID) {
        console.error('Missing Google API credentials in environment variables');
        // Log what we have for debugging
        console.log('Available credentials:', {
            hasApiKey: !!process.env.GOOGLE_API_KEY,
            clientIdPrefix: process.env.GOOGLE_CLIENT_ID ? 
                `${process.env.GOOGLE_CLIENT_ID.substring(0, 8)}...` : null,
            currentOrigin: window.location.origin
        });
        return false;
    }

    // Log the origin we're using
    console.log('Current origin:', window.location.origin);
    console.log('Client ID prefix:', process.env.GOOGLE_CLIENT_ID.substring(0, 8) + '...');

    try {
        // Load the Google Identity Services script
        await loadGoogleIdentityScript();

        // Initialize Google Identity Services
        google.accounts.id.initialize({
            client_id: process.env.GOOGLE_CLIENT_ID,
            callback: handleCredentialResponse,
            auto_select: false,
            cancel_on_tap_outside: true
        });

        // Load the Google API client library
        await loadGapiScript();
        
        // Initialize the Google API client
        await window.gapi.client.init({
            apiKey: process.env.GOOGLE_API_KEY,
            discoveryDocs: ['https://slides.googleapis.com/$discovery/rest?version=v1']
        });

        return true;
    } catch (error) {
        // Special handling for common initialization errors
        if (error?.error === 'idpiframe_initialization_failed') {
            console.error('Google API initialization failed. Origin might not be authorized in Google Cloud Console:', window.location.origin);
            console.error('Please add this origin to your Google Cloud Console project under APIs & Services > Credentials');
            console.error('Full error details:', error.details);
        }
        throw error;
    }
}

// Function to load the Google Identity Services script
async function loadGoogleIdentityScript() {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
    });
}

// Function to load the Google API client library
async function loadGapiScript() {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/api.js';
        script.async = true;
        script.defer = true;
        script.onload = () => {
            // Load the client library after the script loads
            window.gapi.load('client', resolve);
        };
        script.onerror = reject;
        document.head.appendChild(script);
    });
}

// Callback function for handling Google Identity Services credential response
function handleCredentialResponse(response) {
    if (response.credential) {
        // Send the credential to your backend
        fetch('/auth/google/callback', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ credential: response.credential })
        })
        .then(res => res.json())
        .then(data => {
            if (data.success) {
                window.postMessage('authentication-successful', '*');
            }
        })
        .catch(error => {
            console.error('Error handling Google credential:', error);
        });
    }
}

/**
 * Checks if the user is signed in and authorized for Slides
 * @returns {Promise<boolean>} Whether the user is signed in
 */
export async function isSignedInToGoogle() {
    if (!window.gapi?.auth2) return false;
    
    try {
        const authInstance = window.gapi.auth2.getAuthInstance();
        return authInstance.isSignedIn.get();
    } catch (error) {
        console.error('Error checking Google sign-in status:', error);
        return false;
    }
}

/**
 * Prompts the user to sign in to Google
 * @returns {Promise<boolean>} Whether sign-in was successful
 */
export async function signInToGoogle() {
    try {
        // Try to clear any existing tokens by first logging out
        try {
            await fetch('/auth/google/logout', { 
                method: 'GET',
                credentials: 'include' // Important for cookies
            });
            console.log('Cleared previous Google authorization');
        } catch (logoutErr) {
            // Just log the error and continue
            console.warn('Could not clear previous authorization:', logoutErr);
        }
        
        // Open a popup window for Google auth with elevated permissions
        const width = 600;
        const height = 600;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;
        
        // Add a timestamp to avoid caching issues
        const timestamp = Date.now();
        
        // Use the special drive endpoint that requests the necessary scopes
        const popup = window.open(
            `/auth/google/drive?t=${timestamp}`, // Add timestamp to avoid caching
            'googleDriveAuth',
            `width=${width},height=${height},left=${left},top=${top},popup=1`
        );
        
        // Check if popup was blocked
        if (!popup || popup.closed || typeof popup.closed === 'undefined') {
            throw new Error('Popup was blocked! Please allow popups for this site and try again.');
        }
        
        // Return a promise that resolves when the popup completes
        return new Promise((resolve) => {
            // Listen for message from popup
            const messageHandler = (event) => {
                if (event.data === 'authentication-successful') {
                    // Authentication was successful with the right permissions
                    window.removeEventListener('message', messageHandler);
                    console.log('Received authentication success message from popup');
                    resolve(true);
                }
            };
            
            window.addEventListener('message', messageHandler);
            
            // Also check for popup being closed
            const checkClosed = setInterval(() => {
                if (popup.closed) {
                    clearInterval(checkClosed);
                    window.removeEventListener('message', messageHandler);
                    console.log('Auth popup was closed');
                    resolve(false);
                }
            }, 500);
            
            // Add a timeout to clean up if something goes wrong
            setTimeout(() => {
                clearInterval(checkClosed);
                window.removeEventListener('message', messageHandler);
                if (!popup.closed) {
                    popup.close();
                }
                console.log('Auth timeout reached');
                resolve(false);
            }, 60000); // 1 minute timeout
        });
    } catch (error) {
        console.error('Error signing in to Google:', error);
        return false;
    }
}

/**
 * Creates a Google Slides presentation from the current story
 * @returns {Promise<{success: boolean, presentationUrl: string|null, error: string|null}>}
 */
export async function exportToGoogleSlides() {
    // Get the current story data
    const currentStory = get(storyStore);
    const title = currentStory.title || 'Untitled Story';
    const slides = currentStory.slides || [];
    
    if (!slides.length) {
        return {
            success: false,
            presentationUrl: null,
            error: 'No slides to export'
        };
    }
    
    try {
        // First, check if the user needs to authenticate with Google
        // This will open a popup for Google auth with the necessary permissions
        const signInResult = await signInToGoogle();
        if (!signInResult) {
            return {
                success: false,
                presentationUrl: null,
                error: 'User authentication failed'
            };
        }
        
        // Verify the token is valid
        try {
            const verifyResponse = await fetch('/api/google/verify', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include' // Important for sending cookies
            });
            
            if (!verifyResponse.ok) {
                const errorData = await verifyResponse.json();
                console.error('Google token verification failed:', errorData);
                
                // Try one more authentication attempt
                const retrySignIn = await signInToGoogle();
                if (!retrySignIn) {
                    return {
                        success: false,
                        presentationUrl: null,
                        error: 'Google authorization failed. Please try again.'
                    };
                }
            } else {
                const verifyData = await verifyResponse.json();
                console.log('Google token verification successful:', verifyData);
            }
        } catch (verifyError) {
            console.error('Error verifying Google token:', verifyError);
            // Continue anyway, the actual API call will fail if token is invalid
        }
        
        // Log before processing slides
        console.log(`Processing ${slides.length} slides for export...`);
        
        // Prepare slides data for server-side processing with proper error handling
        const slidesData = await Promise.all(slides.map(async (slide, index) => {
            try {
                let imageData = null;
                
                // If we have canvas state, render it to an image
                if (slide.canvasState && slide.canvasState.length > 0) {
                    try {
                        // Create a temporary canvas to render the slide
                        const tempCanvas = document.createElement('canvas');
                        tempCanvas.width = 375;
                        tempCanvas.height = 667;
                        const fabricCanvas = new window.fabric.Canvas(tempCanvas);
                        
                        // Load the canvas state with timeout to avoid hanging
                        await Promise.race([
                            new Promise((resolve) => {
                                fabricCanvas.loadFromJSON(
                                    { version: '5.2.4', objects: slide.canvasState },
                                    () => {
                                        fabricCanvas.renderAll();
                                        resolve();
                                    }
                                );
                            }),
                            new Promise((_, reject) => 
                                setTimeout(() => reject(new Error('Canvas loading timeout')), 5000)
                            )
                        ]);
                        
                        // Adaptive quality compression based on object count to reduce size
                        let quality = 0.9; // Start with high quality
                        const objectCount = slide.canvasState.length;
                        
                        // Reduce quality based on number of objects 
                        if (objectCount > 20) {
                            quality = 0.5; // Heavy compression for complex slides
                        } else if (objectCount > 10) {
                            quality = 0.7; // Medium compression
                        } else if (objectCount > 5) {
                            quality = 0.8; // Light compression
                        }
                        
                        // Convert to image data URL - with quality compression
                        imageData = tempCanvas.toDataURL('image/jpeg', quality);
                        
                        // Check if the data URL is too large (> 5MB)
                        if (imageData.length > 5 * 1024 * 1024) {
                            console.warn(`Slide ${index} image is very large (${(imageData.length/1024/1024).toFixed(2)}MB), reducing quality`);
                            
                            // Progressive quality reduction
                            const qualities = [0.7, 0.5, 0.3, 0.2];
                            for (const q of qualities) {
                                imageData = tempCanvas.toDataURL('image/jpeg', q);
                                if (imageData.length <= 5 * 1024 * 1024) {
                                    console.log(`Reduced image quality to ${q} to fit size limits`);
                                    break;
                                }
                            }
                            
                            // If still too large, try reducing canvas size
                            if (imageData.length > 5 * 1024 * 1024) {
                                console.warn(`Image still too large, reducing dimensions`);
                                const resizeCanvas = document.createElement('canvas');
                                resizeCanvas.width = 300; // Reduced width
                                resizeCanvas.height = 533; // Reduced height (maintain aspect ratio)
                                const ctx = resizeCanvas.getContext('2d');
                                ctx.drawImage(tempCanvas, 0, 0, 375, 667, 0, 0, 300, 533);
                                imageData = resizeCanvas.toDataURL('image/jpeg', 0.5);
                                
                                // If still too big, use extreme compression
                                if (imageData.length > 5 * 1024 * 1024) {
                                    imageData = resizeCanvas.toDataURL('image/jpeg', 0.1);
                                }
                            }
                        }
                    } catch (canvasError) {
                        console.error(`Error rendering canvas for slide ${index}:`, canvasError);
                        // Continue without image data
                    }
                } else if (slide.previewImage) {
                    // Use existing preview image if available
                    // Check if it's an external URL and compress if needed
                    if (slide.previewImage.startsWith('http')) {
                        try {
                            // First ensure the URL is CORS compatible
                            const corsUrl = ensureCorsCompatibleUrl(slide.previewImage);
                            imageData = await compressExternalImage(corsUrl);
                            console.log(`Compressed external image for slide ${index}`);
                        } catch (err) {
                            console.warn(`Could not compress image for slide ${index}, using proxied URL:`, err);
                            // Still use the proxied URL even if compression fails
                            imageData = ensureCorsCompatibleUrl(slide.previewImage);
                        }
                    } else {
                        imageData = slide.previewImage;
                    }
                }
                
                return {
                    index,
                    imageData,
                    script: slide.script || ''
                };
            } catch (slideError) {
                console.error(`Error processing slide ${index}:`, slideError);
                // Return a minimal slide to prevent the entire export from failing
                return {
                    index,
                    imageData: null,
                    script: slide.script || ''
                };
            }
        }));
        
        console.log(`Prepared ${slidesData.length} slides for server`);
        
        // Calculate and log the total payload size
        let totalSize = 0;
        const slidesSizeInfo = slidesData.map(s => {
            const imageSize = s.imageData ? s.imageData.length : 0;
            totalSize += imageSize;
            return {
                index: s.index,
                imageSize: imageSize ? `${(imageSize / 1024 / 1024).toFixed(2)}MB` : 'No image',
                hasScript: !!s.script
            };
        });
        
        console.log(`Total payload size: ${(totalSize / 1024 / 1024).toFixed(2)}MB`);
        console.log('Slides size breakdown:', slidesSizeInfo);
        
        // If total size is too large, warn the user
        if (totalSize > 50 * 1024 * 1024) {
            console.warn('WARNING: Total payload size exceeds 50MB and may fail to upload');
        }
        
        // Call our server API to create slides using the stored tokens
        const response = await fetch('/api/google/slides', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title,
                slides: slidesData
            })
        });
        
        // Check for specific error status codes
        if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            console.error('Server error response:', response.status, errorData);
            
            // Handle specific error types
            if (response.status === 401) {
                return {
                    success: false,
                    presentationUrl: null,
                    error: 'Your Google authorization has expired. Please try again.'
                };
            } else if (response.status === 413) {
                return {
                    success: false,
                    presentationUrl: null,
                    error: 'The presentation data is too large. Try reducing the number of slides or image quality.'
                };
            }
            
            throw new Error(errorData.error || errorData.message || `Server error: ${response.status}`);
        }
        
        const data = await response.json();
        
        return {
            success: true,
            presentationUrl: data.presentationUrl,
            error: null
        };
    } catch (error) {
        console.error('Error exporting to Google Slides:', error);
        return {
            success: false,
            presentationUrl: null,
            error: error.message || 'Failed to export to Google Slides'
        };
    }
}

/**
 * Creates a Google Slides presentation with the provided data
 * @param {Object} data - The data to use for creating the slides
 * @returns {Promise<Object>} - Result of the operation
 */
export async function createGoogleSlides(data) {
    try {
        // Check if we're signed in to Google
        const isSignedIn = await isSignedInToGoogle();
        if (!isSignedIn) {
            // Try to sign in
            const signInResult = await signInToGoogle();
            if (!signInResult) {
                throw new Error('Failed to sign in to Google');
            }
        }
        
        // Create a new presentation
        const response = await window.gapi.client.slides.presentations.create({
            title: data.title || 'My Presentation'
        });
        
        const presentationId = response.result.presentationId;
        
        // Add slides based on the data
        if (data.slides && data.slides.length > 0) {
            // Create requests for each slide
            const requests = data.slides.map((slide, index) => {
                return {
                    createSlide: {
                        objectId: `slide_${index}`,
                        insertionIndex: index,
                        slideLayoutReference: {
                            predefinedLayout: 'TITLE_AND_BODY'
                        }
                    }
                };
            });
            
            // Execute the requests
            await window.gapi.client.slides.presentations.batchUpdate({
                presentationId: presentationId,
                requests: requests
            });
            
            // Add content to each slide
            for (let i = 0; i < data.slides.length; i++) {
                const slide = data.slides[i];
                const slideId = `slide_${i}`;
                
                // Add title
                if (slide.title) {
                    await window.gapi.client.slides.presentations.batchUpdate({
                        presentationId: presentationId,
                        requests: [{
                            updateTextStyle: {
                                objectId: slideId,
                                textRange: {
                                    type: 'ALL'
                                },
                                fields: 'fontSize',
                                textStyle: {
                                    fontSize: {
                                        magnitude: 24,
                                        unit: 'PT'
                                    }
                                }
                            }
                        }]
                    });
                    
                    await window.gapi.client.slides.presentations.batchUpdate({
                        presentationId: presentationId,
                        requests: [{
                            replaceAllText: {
                                containsText: {
                                    text: 'Title',
                                    matchCase: true
                                },
                                replaceText: slide.title,
                                pageObjectIds: [slideId]
                            }
                        }]
                    });
                }
                
                // Add body content
                if (slide.content) {
                    await window.gapi.client.slides.presentations.batchUpdate({
                        presentationId: presentationId,
                        requests: [{
                            replaceAllText: {
                                containsText: {
                                    text: 'Subtitle',
                                    matchCase: true
                                },
                                replaceText: slide.content,
                                pageObjectIds: [slideId]
                            }
                        }]
                    });
                }
            }
        }
        
        // Return the presentation ID and a link to view it
        return {
            success: true,
            presentationId: presentationId,
            viewUrl: `https://docs.google.com/presentation/d/${presentationId}/edit`
        };
    } catch (error) {
        console.error('Error creating Google Slides:', error);
        return {
            success: false,
            error: error.message || 'Failed to create Google Slides presentation'
        };
    }
}

// Function to create a compressed version of an external image
async function compressExternalImage(imageUrl) {
    try {
        // Create an image element to load the image
        const img = new Image();
        
        // Wait for the image to load
        await new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
            img.crossOrigin = 'anonymous'; // Needed for CORS images
            img.src = imageUrl;
        });
        
        // Create a canvas to compress the image
        const canvas = document.createElement('canvas');
        
        // Calculate new dimensions (max 600px width or height while preserving aspect ratio)
        let width = img.width;
        let height = img.height;
        const maxDimension = 600;
        
        if (width > height && width > maxDimension) {
            height = Math.round(height * (maxDimension / width));
            width = maxDimension;
        } else if (height > maxDimension) {
            width = Math.round(width * (maxDimension / height));
            height = maxDimension;
        }
        
        // Set canvas dimensions
        canvas.width = width;
        canvas.height = height;
        
        // Draw the image on the canvas with the new dimensions
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        
        // Convert to compressed JPEG data URL (quality 0.8)
        return canvas.toDataURL('image/jpeg', 0.8);
    } catch (error) {
        console.error('Error compressing external image:', error);
        // Return original URL if compression fails
        return imageUrl;
    }
}

// Function to handle URLs that might have CORS issues
function ensureCorsCompatibleUrl(url) {
    // If it's already a data URL, return it as is
    if (url.startsWith('data:')) {
        return url;
    }
    
    // If it's from our own domain, return it as is
    if (url.startsWith(window.location.origin)) {
        return url;
    }
    
    // For all other URLs, proxy through our backend proxy endpoint
    // This assumes you have a proxy endpoint at '/api/proxy/image'
    const proxyUrl = `/api/proxy/image?url=${encodeURIComponent(url)}`;
    return proxyUrl;
} 