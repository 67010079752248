// API Configuration
export const API_CONFIG = {
    // Get the base API URL based on the current domain and environment
    getBaseUrl: () => {
        const hostname = window.location.hostname;
        let environment = 'production'; // Default to production
        
        console.log('Current hostname:', hostname);
        
        // Determine environment based on hostname
        if (hostname === 'localhost' || hostname === '127.0.0.1') {
            environment = 'local';
            console.log('Using local environment');
            return 'http://localhost:3000';
        } else if (hostname.includes('data-remixer-dev-server.onrender.com')) {
            environment = 'development';
            console.log('Using development environment');
            return 'https://data-remixer-dev-server.onrender.com';
        } else if (hostname.includes('datacreative.app') || hostname.includes('www.datacreative.app')) {
            environment = 'production';
            console.log('Using production environment');
            return 'https://www.datacreative.app';
        }
        
        // Default fallback
        console.log('Using default API URL (production)');
        return 'https://www.datacreative.app';
    }
};

// Export a singleton instance of the base URL
export const API_BASE_URL = API_CONFIG.getBaseUrl();

// Add debug logging for API configuration
console.log('API Base URL configured as:', API_BASE_URL); 