<script>
    import { evidenceStore as evidenceStoreObj, saveEvidence, removeSavedEvidence, searchEvidence } from "../stores/evidenceStore";
    import { promptStore } from "../stores/promptStore";
    import { navigate } from "svelte-routing";
    import { onMount, onDestroy, createEventDispatcher, getContext } from 'svelte';
    import { get } from 'svelte/store';
    import { storyStore } from '../stores/storyStore.js';
    import StoryMenuEvidenceDetail from './StoryMenu-EvidenceDetail.svelte';
    import EvidenceModal from './EvidenceModal.svelte';
    import { fade } from 'svelte/transition';
    import { bigQuestion } from '../stores/promptStore';
    import { authStore } from '../stores/authStore.js';

    // Check if user is admin and add debug logging
    $: {
        console.log('Auth store state:', $authStore);
        console.log('User object:', $authStore?.user);
        console.log('Is admin?', $authStore?.user?.isAdmin);
    }
    $: isAdmin = $authStore?.user?.isAdmin || false;

    let searchQuery = "";
    let currentPage = 1;
    const itemsPerPage = 10;
    let selectedEvidenceIds = new Set();
    let isLoading = false;
    let deleteConfirmOpen = false;
    let evidenceToDelete = null;

    const dispatch = createEventDispatcher();
    const selectedSlideIndex = getContext('selectedSlideIndex');
    let evidence = [];
    let savedEvidence = [];

    let previousQuestion = $bigQuestion;
    let currentTopic = null;
    let previousTopic = null;

    let showModal = false;
    let selectedEvidence = null;
    let updateModalOpen = false;
    let evidenceToUpdate = null;
    let updateForm = {
        label: '',
        description: ''
    };

    let selectedTab = 'all';

    // Add guide tab state
    let activeTab = selectedTab;

    let loadingProgress = {
        total: 0,
        loaded: 0,
        isLoading: false
    };

    // Add showMenu state
    let showMenu = null; // Will store the ID of evidence with open menu

    // Add showSearchTips state
    let showSearchTips = false;

    // Add this function in the script section after the other let declarations
    let searchTextarea;

    // Add this function after the other function declarations
    function autoResize() {
        if (!searchTextarea) return;
        // Reset height to auto to get the correct scrollHeight
        searchTextarea.style.height = 'auto';
        // Set new height based on scrollHeight
        searchTextarea.style.height = searchTextarea.scrollHeight + 'px';
    }

    // Subscribe to both evidence arrays and promptStore
    const unsubscribeEvidence = evidenceStoreObj.subscribe(store => {
        console.log('Evidence store updated:', {
            evidenceCount: store.evidence?.length || 0,
            savedCount: store.savedEvidence?.length || 0,
            loadingProgress: store.loadingProgress
        });
        evidence = store.evidence || [];
        savedEvidence = store.savedEvidence || [];
        loadingProgress = store.loadingProgress;
    });

    // Subscribe to promptStore to get topic changes
    const unsubscribePrompt = promptStore.subscribe(store => {
        if (store.bigQuestion) {
            const question = store.bigQuestion;
            if (question && question !== previousQuestion) {
                console.log('Big question changed, searching with:', question);
                previousQuestion = question;
                searchQuery = question; // Set the search query to the big question
                console.log('Updated search query:', searchQuery);
                isLoading = true;
                // Perform unrestricted search
                searchEvidence(question)
                    .catch(error => {
                        console.error("Error searching evidence:", error);
                    })
                    .finally(() => {
                        isLoading = false;
                    });
            }
        }
    });

    onDestroy(() => {
        unsubscribeEvidence();
        unsubscribePrompt();
        // Remove click outside listener
        document.removeEventListener('click', handleClickOutside);
    });

    onMount(() => {
        // Add click outside listener
        document.addEventListener('click', handleClickOutside);

        // Get current prompt details and initialize search
        const currentStore = get(promptStore);
        const initialQuestion = currentStore.bigQuestion || currentStore.selectedPromptDetails?.bigQuestion;
        
        if (initialQuestion) {
            console.log('Initial search with question:', initialQuestion);
            searchQuery = initialQuestion;
            isSearching = true;
            // Perform unrestricted search
            searchEvidence(initialQuestion)
                .catch(error => {
                    console.error("Error searching evidence:", error);
                })
                .finally(() => {
                    isSearching = false;
                });
        }
    });

    // Just use the evidence array directly since filtering happens on server
    $: paginatedEvidenceItems = evidence.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    // Update totalPages calculation to use evidence length instead of filtered
    $: totalPages = Math.ceil(evidence.length / itemsPerPage);

    // Add loading state for search
    let isSearching = false;

    function handleEvidenceClick(evidence, event) {
        if (event) {
            event.stopPropagation(); // Only call if event exists
        }
        selectedEvidence = evidence;
        showModal = true;
    }

    function handleAnalyzeClick(evidence, event) {
        event.stopPropagation();
        console.log('Evidence being passed to modal:', evidence); // Debug log
        selectedEvidence = evidence;
        showModal = true;
    }

    function closeModal() {
        showModal = false;
        selectedEvidence = null;
    }

    $: selectedCount = selectedEvidenceIds.size;

    function nextPage() {
      if (currentPage * itemsPerPage < evidence.length) {
        currentPage += 1;
      }
    }

    function prevPage() {
      if (currentPage > 1) {
        currentPage -= 1;
      }
    }

    // Update search handler to perform unrestricted searches
    async function handleSearch(event) {
        // Check if it's an Enter key press or search button click
        if (event.type === 'click' || (event.type === 'keydown' && event.key === 'Enter')) {
            event.preventDefault(); // Prevent form submission
            if (!searchQuery.trim()) return; // Don't search empty queries
            
            try {
                isSearching = true;
                currentPage = 1;
                // Perform unrestricted search
                await searchEvidence(searchQuery.trim());
            } catch (error) {
                console.error('Search failed:', error);
            } finally {
                isSearching = false;
            }
        }
    }

    $: visiblePages = getVisiblePages(currentPage, totalPages);
    
    function getVisiblePages(current, total) {
        if (total <= 7) {
            return Array.from({length: total}, (_, i) => i + 1);
        }
        
        let pages = [];
        
        // Always show first page
        pages.push(1);
        
        if (current <= 4) {
            // Near start: show 1, 2, 3, 4, 5, ..., total
            pages.push(2, 3, 4, 5);
        } else if (current >= total - 3) {
            // Near end: show 1, ..., total-4, total-3, total-2, total-1, total
            pages.push('...', total - 4, total - 3, total - 2, total - 1);
        } else {
            // In middle: show 1, ..., current-2, current-1, current, current+1, current+2, ..., total
            pages.push('...', current - 2, current - 1, current, current + 1, current + 2);
        }
        
        if (pages[pages.length - 1] !== total) {
            if (pages[pages.length - 1] !== total - 1) {
                pages.push('...');
            }
            pages.push(total);
        }
        
        return pages;
    }

    function handleAddEvidence(evidence, event) {
        event.stopPropagation();
        dispatch('addImageToCanvas', { 
            evidence,
            type: 'evidence'
        });
    }

    function handleSaveEvidence(evidence, event) {
        event.stopPropagation();
        if (!evidence) return;
        
        const isAlreadySaved = savedEvidence.some(e => e._id === evidence._id);
        if (isAlreadySaved) {
            removeSavedEvidence(evidence._id);
        } else {
            saveEvidence(evidence);
        }
    }

    // Handle clicking evidence item
    function onEvidenceClick(evidence) {
        handleEvidenceClick(evidence);
    }

    // Add unsaveEvidence function
    function unsaveEvidence(evidence) {
        // Close menu after action
        showMenu = null;
        // Remove from saved evidence
        removeSavedEvidence(evidence._id);
    }

    // Add click outside handler to close menu
    function handleClickOutside(event) {
        if (showMenu !== null && !event.target.closest('.evidence-menu')) {
            showMenu = null;
        }
    }

    // Add this to the script section where other subscriptions are
    $: if (searchQuery) {
        // Use setTimeout to ensure DOM is ready
        setTimeout(autoResize, 0);
    }

    // Add delete function
    async function deleteEvidence(evidence) {
        console.log('Attempting to delete evidence:', evidence);
        try {
            const response = await fetch('/api/admin/evidence', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: evidence._id
                })
            });

            console.log('Delete response status:', response.status);
            const responseText = await response.text();
            console.log('Delete response text:', responseText);

            if (!response.ok) {
                throw new Error(`Failed to delete evidence: ${responseText}`);
            }

            let result;
            try {
                result = JSON.parse(responseText);
            } catch (e) {
                console.error('Error parsing response:', e);
                throw new Error('Invalid response from server');
            }

            console.log('Delete result:', result);

            if (result.success) {
                // Update local state
                evidenceStoreObj.update(store => {
                    console.log('Updating store state after delete');
                    const newEvidence = store.evidence.filter(e => e._id !== evidence._id);
                    const newSavedEvidence = store.savedEvidence.filter(e => e._id !== evidence._id);
                    return {
                        ...store,
                        evidence: newEvidence,
                        savedEvidence: newSavedEvidence
                    };
                });

                // Close the delete confirmation modal
                closeDeleteConfirm();
            } else {
                throw new Error(result.message || 'Failed to delete evidence');
            }
        } catch (error) {
            console.error('Error deleting evidence:', error);
            alert('Failed to delete evidence. Please try again.');
        }
    }

    // Add delete confirmation functions
    function openDeleteConfirm(evidence) {
        console.log('Opening delete confirm for evidence:', evidence);
        evidenceToDelete = evidence;
        deleteConfirmOpen = true;
    }

    function closeDeleteConfirm() {
        console.log('Closing delete confirm');
        deleteConfirmOpen = false;
        evidenceToDelete = null;
    }

    // Add update evidence function
    async function updateEvidence() {
        try {
            const response = await fetch('/api/admin/evidence', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: evidenceToUpdate._id,
                    label: updateForm.label,
                    description: updateForm.description
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to update evidence');
            }

            // Update the local state
            evidence = evidence.map(item => {
                if (item._id === evidenceToUpdate._id) {
                    return {
                        ...item,
                        label: updateForm.label,
                        description: updateForm.description
                    };
                }
                return item;
            });

            // Close the modal and reset form
            closeUpdateModal();
            
            // Show success message
            alert('Evidence updated successfully');
        } catch (error) {
            console.error('Error updating evidence:', error);
            alert(error.message || 'Failed to update evidence');
        }
    }

    // Add update modal functions
    function openUpdateModal(evidence) {
        evidenceToUpdate = evidence;
        updateForm = {
            label: evidence.label || '',
            description: evidence.description || ''
        };
        updateModalOpen = true;
    }

    function closeUpdateModal() {
        updateModalOpen = false;
        evidenceToUpdate = null;
        updateForm = {
            label: '',
            description: ''
        };
    }
</script>

<div class="evidence-container">
    <div class="top-container">
        <div class="tabs-container">
            <div class="left-tabs">
                <button 
                    class="tab-button" 
                    class:active={activeTab === 'all'}
                    on:click={() => activeTab = 'all'}
                >
                    <i class="bi bi-search"></i>
                    Search
                </button>
                <button 
                    class="tab-button" 
                    class:active={activeTab === 'saved'}
                    on:click={() => activeTab = 'saved'}
                >
                    <i class="bi bi-bookmark"></i>
                    Saved
                </button>
            </div>
            <button 
                class="tab-button guide-tab" 
                class:active={activeTab === 'guide'}
                on:click={() => activeTab = 'guide'}
            >
                <i class="bi bi-lightbulb"></i>
                Guide Me
            </button>
        </div>

        {#if activeTab === 'guide'}
            <div class="guide-section">
                <div class="guide-content">
                    <div class="guide-iframe-container">
                        <iframe 
                            src="https://docs.google.com/document/d/e/2PACX-1vSZF6f11LfrMZ8b4yD-4q_o-yd-ItdP_G9NfbkkQn7jHpegmmZG7j5vWa4N73nJ-lyFOkSZAGaKGky8/pub?embedded=true"
                            class="guide-iframe"
                            frameborder="0"
                            allowfullscreen
                            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                            on:load={() => {
                                // Hide loading indicator when iframe loads
                                const loadingEl = document.querySelector('.guide-loading');
                                if (loadingEl) loadingEl.style.display = 'none';
                            }}
                            on:error={() => {
                                // Show error message if iframe fails to load
                                const loadingEl = document.querySelector('.guide-loading');
                                if (loadingEl) {
                                    loadingEl.innerHTML = `
                                        <i class="bi bi-exclamation-triangle"></i>
                                        <span>Failed to load guide. Please try refreshing the page.</span>
                                    `;
                                }
                            }}
                        ></iframe>
                        <div class="guide-loading">
                            <i class="bi bi-arrow-repeat spinner"></i>
                            <span>Loading guide...</span>
                        </div>
                    </div>
                </div>
            </div>
        {:else if activeTab === 'saved'}
            <div class="scrollable-content">
                {#if loadingProgress.isLoading || isSearching}
                    <div class="loading-container">
                        <div class="loading-content">
                            {#if loadingProgress.total > 0}
                                <div class="progress-bar">
                                    <div 
                                        class="progress-fill" 
                                        style="width: {(loadingProgress.loaded / loadingProgress.total) * 100}%"
                                    ></div>
                                </div>
                                <div class="progress-text">
                                    Loading data charts: {loadingProgress.loaded} of {loadingProgress.total}
                                </div>
                            {:else}
                                <div class="loading-spinner">
                                    <i class="bi bi-arrow-repeat spinner"></i>
                                </div>
                            {/if}
                        </div>
                    </div>
                {:else if isLoading}
                    <div class="loading-container">
                        <div class="loading-spinner">
                            <i class="bi bi-arrow-repeat spinner"></i>
                        </div>
                    </div>
                {:else}
                    {#if savedEvidence.length === 0}
                        <div class="empty-state">
                            <p>
                                You have no data charts saved. Use this icon <i class="fas fa-bookmark"></i> to save a data chart.
                            </p>
                        </div>
                    {:else}
                        <div class="evidence-list">
                            {#each savedEvidence as evidence}
                                <div 
                                    class="evidence-item"
                                    on:click={() => onEvidenceClick(evidence)}
                                >
                                    <div class="image-container">
                                        <img src={evidence.s3URL} alt={evidence.label} />
                                    </div>
                                    <div class="content-wrapper">
                                        <div class="evidence-label">{evidence.label}</div>
                                        <div class="evidence-menu">
                                            <button 
                                                class="menu-button"
                                                on:click|stopPropagation={() => showMenu = evidence._id}
                                            >
                                                ⋮
                                            </button>
                                            
                                            {#if showMenu === evidence._id}
                                                <div class="menu-dropdown">
                                                    <button 
                                                        class="menu-item"
                                                        on:click|stopPropagation={() => unsaveEvidence(evidence)}
                                                    >
                                                        Remove from saved
                                                    </button>
                                                    {#if isAdmin}
                                                        <button 
                                                            class="menu-item delete-item"
                                                            on:click|stopPropagation={() => openDeleteConfirm(evidence)}
                                                        >
                                                            <i class="bi bi-trash"></i>
                                                            Delete Evidence
                                                        </button>
                                                    {/if}
                                                </div>
                                            {/if}
                                        </div>
                                    </div>
                                </div>
                            {/each}
                        </div>
                    {/if}
                {/if}
            </div>

            {#if selectedTab === 'all' && (evidence.length > itemsPerPage || (!isSearching && evidence.length > 0))}
                <!-- Update pagination wrapper to include results counter -->
                <div class="pagination-container">
                    <div class="pagination-wrapper">
                        {#if !isSearching && evidence.length > 0}
                            <div class="results-counter">
                                Found {evidence.length} data chart{evidence.length === 1 ? '' : 's'}
                            </div>
                        {/if}
                        {#if evidence.length > itemsPerPage}
                            <div class="pagination">
                                <button 
                                    class="page-btn" 
                                    disabled={currentPage === 1}
                                    on:click={prevPage}
                                    title="Previous page"
                                >
                                    <i class="bi bi-chevron-left"></i>
                                </button>

                                {#each visiblePages as page}
                                    {#if page === '...'}
                                        <span class="ellipsis">•••</span>
                                    {:else}
                                        <button 
                                            class="page-btn {currentPage === page ? 'active' : ''}"
                                            on:click={() => currentPage = page}
                                        >
                                            {page}
                                        </button>
                                    {/if}
                                {/each}

                                <button 
                                    class="page-btn"
                                    disabled={currentPage === totalPages}
                                    on:click={nextPage}
                                    title="Next page"
                                >
                                    <i class="bi bi-chevron-right"></i>
                                </button>
                            </div>
                        {/if}
                    </div>
                </div>
            {/if}
        {:else}
            <div class="search-bar">
                <div class="search-container">
                    <div class="input-container">
                        <i class="bi bi-search search-icon"></i>
                        <form on:submit|preventDefault={handleSearch}>
                            <textarea
                                bind:this={searchTextarea}
                                type="text"
                                placeholder="Search data charts... (Use AND, OR, NOT for boolean search)"
                                bind:value={searchQuery}
                                on:input={autoResize}
                                on:keydown={e => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        handleSearch(e);
                                    }
                                    // Trigger resize on backspace/delete too
                                    if (e.key === 'Backspace' || e.key === 'Delete') {
                                        setTimeout(autoResize, 0);
                                    }
                                }}
                                class="search-input"
                                disabled={isSearching}
                                rows="1"
                            ></textarea>
                            <button 
                                type="button" 
                                class="search-tips-button" 
                                title="Search Tips"
                                on:click={() => showSearchTips = !showSearchTips}
                            >
                                <i class="bi bi-question-circle"></i>
                            </button>
                            {#if searchQuery}
                                <button 
                                    type="button"
                                    class="clear-button" 
                                    on:click={() => {
                                        searchQuery = '';
                                        // Reset height when clearing
                                        setTimeout(autoResize, 0);
                                    }} 
                                    aria-label="Clear search"
                                >
                                    <i class="bi bi-x-lg"></i>
                                </button>
                            {/if}
                        </form>
                        {#if showSearchTips}
                            <div class="search-tips-dropdown" transition:fade>
                                <h4>Search Strategies</h4>
                                <div class="search-tips-content">
                                    <section class="search-tip-section">
                                        <h5>Boolean Operators</h5>
                                        <ul>
                                            <li><code>AND</code>: Match both terms (e.g. <span class="example">"climate AND temperature"</span>)</li>
                                            <li><code>OR</code>: Match either term (e.g. <span class="example">"rain OR precipitation"</span>)</li>
                                            <li><code>NOT</code>: Exclude term (e.g. <span class="example">"climate NOT humidity"</span>)</li>
                                        </ul>
                                    </section>

                                    <section class="search-tip-section">
                                        <h5>Exact Phrase Matching</h5>
                                        <ul>
                                            <li><code>"..."</code>: Match exact phrase (e.g. <span class="example">"global warming"</span>)</li>
                                        </ul>
                                    </section>

                                    <section class="search-tip-section">
                                        <h5>Grouping Terms</h5>
                                        <ul>
                                            <li><code>()</code>: Group terms (e.g. <span class="example">"(rain OR snow) AND temperature"</span>)</li>
                                        </ul>
                                    </section>

                                    <section class="search-tip-section">
                                        <h5>Wildcards</h5>
                                        <ul>
                                            <li><code>*</code>: Match any characters (e.g. <span class="example">"eco*"</span> matches ecology, economics, ecosystem, etc.)</li>
                                        </ul>
                                    </section>

                                    <section class="search-tip-section">
                                        <h5>Complex Example</h5>
                                        <div class="complex-example">
                                            <code class="example">"climate change" AND (impact* OR effect*) NOT economic</code>
                                            <p class="example-description">Finds data charts about climate change impacts or effects, excluding economic contexts.</p>
                                        </div>
                                    </section>
                                </div>
                                <button class="close-tips" on:click={() => showSearchTips = false}>
                                    <i class="bi bi-x"></i>
                                </button>
                            </div>
                        {/if}
                    </div>
                </div>
            </div>

            <div class="scrollable-content">
                {#if loadingProgress.isLoading || isSearching}
                    <div class="loading-container">
                        <div class="loading-content">
                            {#if loadingProgress.total > 0}
                                <div class="progress-bar">
                                    <div 
                                        class="progress-fill" 
                                        style="width: {(loadingProgress.loaded / loadingProgress.total) * 100}%"
                                    ></div>
                                </div>
                                <div class="progress-text">
                                    Loading data charts: {loadingProgress.loaded} of {loadingProgress.total}
                                </div>
                            {:else}
                                <div class="loading-spinner">
                                    <i class="bi bi-arrow-repeat spinner"></i>
                                </div>
                            {/if}
                        </div>
                    </div>
                {:else if isLoading}
                    <div class="loading-container">
                        <div class="loading-spinner">
                            <i class="bi bi-arrow-repeat spinner"></i>
                        </div>
                    </div>
                {:else if evidence.length === 0 && searchQuery}
                    <div class="no-results">
                        <i class="bi bi-emoji-frown"></i>
                        <p>No data charts found matching "{searchQuery}"</p>
                        <button 
                            class="try-again-button"
                            on:click={() => {
                                searchQuery = '';
                                setTimeout(autoResize, 0);
                            }}
                        >
                            Clear search
                        </button>
                    </div>
                {:else}
                    <div class="evidence-list">
                        {#each paginatedEvidenceItems as evidence, i (evidence._id)}
                            <div 
                                class="evidence-item"
                                on:click={(e) => handleAnalyzeClick(evidence, e)}
                                style="animation-delay: {i * 50}ms"
                            >
                                <button 
                                    class="bookmark-button {savedEvidence?.some(e => e._id === evidence._id) ? 'saved' : ''}" 
                                    on:click={(e) => handleSaveEvidence(evidence, e)}
                                    title={savedEvidence?.some(e => e._id === evidence._id) ? 'Remove Data Chart' : 'Save Data Chart'}
                                >
                                    <i class="bi bi-bookmark{savedEvidence?.some(e => e._id === evidence._id) ? '-fill' : ''}"></i>
                                </button>
                                <div class="image-container">
                                    <img src={evidence.s3URL} alt={evidence.description} />
                                </div>
                                <div class="content-wrapper">
                                    <div class="evidence-label">
                                        {evidence.description}
                                    </div>
                                    <div class="evidence-menu">
                                        <button 
                                            class="menu-button"
                                            on:click|stopPropagation={() => showMenu = evidence._id}
                                        >
                                            ⋮
                                        </button>
                                        
                                        {#if showMenu === evidence._id}
                                            <div class="menu-dropdown">
                                                {#if isAdmin}
                                                    <button 
                                                        class="menu-item"
                                                        on:click|stopPropagation={() => openUpdateModal(evidence)}
                                                    >
                                                        <i class="bi bi-pencil"></i>
                                                        Edit Evidence
                                                    </button>
                                                    <button 
                                                        class="menu-item delete-item"
                                                        on:click|stopPropagation={() => openDeleteConfirm(evidence)}
                                                    >
                                                        <i class="bi bi-trash"></i>
                                                        Delete Evidence
                                                    </button>
                                                {/if}
                                            </div>
                                        {/if}
                                    </div>
                                </div>
                            </div>
                        {/each}
                    </div>
                {/if}
            </div>

            {#if selectedTab === 'all' && (evidence.length > itemsPerPage || (!isSearching && evidence.length > 0))}
                <!-- Update pagination wrapper to include results counter -->
                <div class="pagination-container">
                    <div class="pagination-wrapper">
                        {#if !isSearching && evidence.length > 0}
                            <div class="results-counter">
                                Found {evidence.length} data chart{evidence.length === 1 ? '' : 's'}
                            </div>
                        {/if}
                        {#if evidence.length > itemsPerPage}
                            <div class="pagination">
                                <button 
                                    class="page-btn" 
                                    disabled={currentPage === 1}
                                    on:click={prevPage}
                                    title="Previous page"
                                >
                                    <i class="bi bi-chevron-left"></i>
                                </button>

                                {#each visiblePages as page}
                                    {#if page === '...'}
                                        <span class="ellipsis">•••</span>
                                    {:else}
                                        <button 
                                            class="page-btn {currentPage === page ? 'active' : ''}"
                                            on:click={() => currentPage = page}
                                        >
                                            {page}
                                        </button>
                                    {/if}
                                {/each}

                                <button 
                                    class="page-btn"
                                    disabled={currentPage === totalPages}
                                    on:click={nextPage}
                                    title="Next page"
                                >
                                    <i class="bi bi-chevron-right"></i>
                                </button>
                            </div>
                        {/if}
                    </div>
                </div>
            {/if}
        {/if}
    </div>
</div>

<style>
    .evidence-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    .fixed-header {
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
        padding: 12px 12px 0 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .tabs-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        background: white;
        padding: 0.5rem 1rem;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    }

    .left-tabs {
        display: flex;
        gap: 1rem;
    }

    .tab-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        border: none;
        background: none;
        color: #64748b;
        font-size: 0.875rem;
        font-weight: 500;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        transition: all 0.2s ease;
        position: relative;
    }

    .tab-button.active {
        color: #2563eb;
        border-bottom-color: #2563eb;
        background: #eff6ff;
    }

    .tab-button:hover {
        color: #2563eb;
        background: #f8fafc;
    }

    .guide-tab {
        font-size: 0.8125rem;
        padding: 0.5rem 0.75rem;
        background: #f8fafc;
        border-radius: 6px;
        border-bottom: none;
    }

    .guide-tab:hover {
        background: #f1f5f9;
        color: #2563eb;
    }

    .guide-tab.active {
        background: #eff6ff;
        color: #2563eb;
        font-weight: 600;
    }

    .scrollable-content {
        flex: 1;
        overflow-y: auto;
        padding: 20px;
        margin-bottom: 120px; /* Increased from 80px to 120px to provide more space for pagination */
        overflow-x: visible;
    }

    .pagination-container {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
        padding: 12px;
        z-index: 100;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pagination-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
        justify-content: center;
        width: 100%;
        max-width: 800px;
    }

    .results-counter {
        color: #64748b;
        font-size: 0.875rem;
        white-space: nowrap;
    }

    .pagination {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 4px;
        background: #f8fafc;
        border-radius: 8px;
    }

    .page-btn {
        min-width: 32px;
        height: 32px;
        border: none;
        background: transparent;
        color: #64748b;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease;
        border-radius: 6px;
        padding: 0 8px;
    }

    .page-btn:hover:not(:disabled):not(.active) {
        background-color: #e2e8f0;
        color: #0f172a;
    }

    .page-btn:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .page-btn.active {
        background-color: white;
        color: #2563eb;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }

    .ellipsis {
        color: #94a3b8;
        padding: 0 4px;
        min-width: 32px;
        text-align: center;
        font-size: 14px;
    }

    /* Add styles for the page info */
    .page-info {
        color: #64748b;
        font-size: 13px;
        margin-left: 8px;
    }

    /* Update save button when evidence is saved */
    .save-button.saved {
        color: #007bff;
        background-color: #e6f0ff;
    }

    .search-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        width: 100%;
    }

    .search-container {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
    }

    .input-container {
        position: relative;
        width: 100%;
        min-height: 48px; /* Match textarea min-height */
    }

    .search-input {
        width: 100%;
        padding: 0.875rem 3.5rem;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        font-size: 0.9375rem;
        transition: all 0.2s;
        background: white;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        resize: none;
        overflow: hidden;
        min-height: 48px;
        max-height: 150px; /* Increased max height */
        line-height: 1.5;
        font-family: inherit;
        display: block;
    }

    .search-input:focus {
        outline: none;
        border-color: #2563eb;
        box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
    }

    .search-input::placeholder {
        color: #94a3b8;
    }

    /* Ensure icons stay vertically centered relative to the first line */
    .search-icon,
    .search-tips-button,
    .clear-button {
        position: absolute;
        top: 0.875rem; /* Match textarea padding */
    }

    .search-icon {
        left: 1rem;
        color: #64748b;
        font-size: 0.875rem;
        pointer-events: none;
        z-index: 1;
    }

    .search-tips-button {
        right: 2.5rem;
        background: none;
        border: none;
        color: #64748b;
        padding: 0.25rem;
        cursor: pointer;
        transition: color 0.2s;
        z-index: 1;
    }

    .search-tips-button:hover {
        color: #2563eb;
    }

    .clear-button {
        position: absolute;
        right: 0.75rem;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        color: #64748b;
        padding: 0.25rem;
        cursor: pointer;
        transition: color 0.2s;
        z-index: 1;
    }

    .clear-button:hover {
        color: #ef4444;
    }

    /* Hide scrollbar but keep functionality */
    .search-input {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */
    }

    .search-input::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera */
    }

    .search-tips-dropdown {
        position: absolute;
        top: calc(100% + 0.5rem);
        left: 0;
        right: 0;
        background: white;
        border-radius: 8px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        padding: 1rem;
        z-index: 50;
        border: 1px solid #e2e8f0;
        max-height: 400px;
        overflow-y: auto;
    }

    .search-tips-dropdown h4 {
        margin: 0 0 0.75rem 0;
        color: #1e293b;
        font-size: 0.875rem;
        font-weight: 600;
        border-bottom: 1px solid #e2e8f0;
        padding-bottom: 0.5rem;
    }

    .search-tips-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .search-tip-section h5 {
        margin: 0 0 0.5rem 0;
        color: #475569;
        font-size: 0.8125rem;
        font-weight: 600;
    }

    .search-tips-dropdown ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .search-tips-dropdown li {
        margin-bottom: 0.5rem;
        color: #475569;
        font-size: 0.8125rem;
        line-height: 1.4;
        display: flex;
        align-items: flex-start;
        gap: 0.25rem;
    }

    .search-tips-dropdown code {
        background: #f1f5f9;
        padding: 0.125rem 0.25rem;
        border-radius: 4px;
        color: #2563eb;
        font-size: 0.75rem;
        font-family: monospace;
        white-space: nowrap;
    }

    .search-tips-dropdown .example {
        color: #0f766e;
        font-style: italic;
    }

    .complex-example {
        background: #f8fafc;
        padding: 0.75rem;
        border-radius: 6px;
        border-left: 3px solid #0f766e;
    }

    .example-description {
        margin: 0.5rem 0 0 0;
        font-size: 0.75rem;
        color: #475569;
        line-height: 1.4;
    }

    .close-tips {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        background: none;
        border: none;
        color: #64748b;
        padding: 0.25rem;
        cursor: pointer;
        transition: color 0.2s;
    }

    .close-tips:hover {
        color: #ef4444;
    }

    .counter {
        margin-bottom: 20px;
        font-size: 16px;
    }
    .evidence-list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
        animation: fadeIn 0.3s ease-in;
        max-height: calc(100vh - 200px);
    }
    .evidence-item {
        display: flex;
        flex-direction: column;
        background: white;
        border: 1px solid #e6e6e6;
        border-radius: 12px;
        overflow: visible;
        width: 260px;
        transition: all 0.2s ease;
        cursor: pointer;
        position: relative;
    }

    /* Add hover state container for bookmark */
    .evidence-item:hover .bookmark-button {
        opacity: 1;
        transform: translateY(0);
    }

    /* Update bookmark button styles */
    .bookmark-button {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 10;
        background: rgba(0, 0, 0, 0.6);
        border: none;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease;
        opacity: 0;
        transform: translateY(-4px);
    }

    /* Always show if saved */
    .bookmark-button.saved {
        opacity: 1;
        transform: translateY(0);
        background: rgba(0, 0, 0, 0.75);
    }

    .bookmark-button i {
        font-size: 1.1rem;
        color: white;
        transition: transform 0.2s ease;
    }

    .bookmark-button:hover {
        background: rgba(0, 0, 0, 0.75);
        transform: translateY(0);
    }

    .bookmark-button:active {
        transform: translateY(0) scale(0.95);
    }

    .bookmark-button:hover i {
        transform: scale(1.1);
    }

    /* Add save feedback animation */
    @keyframes savePopIn {
        0% { transform: scale(1); }
        50% { transform: scale(1.2); }
        100% { transform: scale(1); }
    }

    .bookmark-button.saved i {
        animation: savePopIn 0.3s ease;
    }

    /* Add tooltip */
    .bookmark-button::after {
        content: attr(title);
        position: absolute;
        bottom: -32px;
        right: 0;
        background: rgba(0, 0, 0, 0.8);
        color: white;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
        white-space: nowrap;
        opacity: 0;
        transform: translateY(-4px);
        transition: all 0.2s ease;
        pointer-events: none;
    }

    .bookmark-button:hover::after {
        opacity: 1;
        transform: translateY(0);
    }

    .image-container {
        width: 100%;
        height: 160px;
        position: relative;
        overflow: hidden;
    }

    .image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
    }

    .evidence-item:hover {
        transform: translateY(-4px);
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
    }

    .evidence-item:hover .image-container img {
        transform: scale(1.05);
    }

    .content-wrapper {
        padding: 16px;
        min-height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #ffffff;
        position: relative;
        overflow: visible;
    }
    .evidence-label {
        font-size: 14px;
        color: #1a1a1a;
        line-height: 1.5;
        font-weight: 500;
    }
    .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
    }
    .action-buttons {
        display: flex;
        gap: 8px;
        align-items: center;
    }
    .action-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        font-size: 13px;
        font-weight: 500;
        border-radius: 8px;
        transition: all 0.2s ease;
        cursor: pointer;
        background: transparent;
        border: 1px solid #e2e8f0;
    }
    .save-button {
        width: 36px;
        color: #94a3b8;
    }
    .save-button:hover {
        border-color: #64748b;
        color: #64748b;
    }
    .save-button.saved {
        background: #f1f5f9;
        color: #0f172a;
        border-color: #e2e8f0;
    }
    .analyze-button {
        flex: 1;
        padding: 0 14px;
        gap: 6px;
        color: #64748b;
        background: #f8fafc;
    }
    .analyze-button i {
        font-size: 12px;
    }
    .analyze-button:hover {
        background: #f1f5f9;
        color: #0f172a;
    }
    .analyze-button:hover i {
        color: #007bff;
    }
    .analyze-button:active {
        transform: translateY(1px);
    }
    .action-button:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(15, 23, 42, 0.1);
    }
    .action-button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    .add-button {
        color: #007bff;
    }
    .evidence-detail {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .breadcrumb {
        padding: 10px;
        border-bottom: 1px solid #ddd;
    }
    .back-button {
        background: none;
        border: none;
        color: #007bff;
        cursor: pointer;
        padding: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
    }
    .back-button:hover {
        color: #0056b3;
    }
    .detail-content {
        padding: 20px;
        flex: 1;
        overflow-y: auto;
    }
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
    .empty-state {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        color: #666;
        text-align: center;
        padding: 20px;
    }

    .empty-state i {
        color: #007bff;
        margin: 0 4px;
    }

    .evidence-menu {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 100;
    }

    .menu-button {
        background: none;
        border: none;
        font-size: 1.2em;
        cursor: pointer;
        padding: 4px 8px;
        color: #64748b;
        border-radius: 4px;
    }

    .menu-button:hover {
        background: rgba(0, 0, 0, 0.05);
        color: #333;
    }

    .menu-dropdown {
        position: absolute;
        right: 0;
        top: 100%;
        background: white;
        border: 1px solid #ddd;
        border-radius: 6px;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        z-index: 101;
        min-width: 160px;
        margin-top: 4px;
    }

    .menu-item {
        width: 100%;
        padding: 8px 16px;
        border: none;
        background: none;
        text-align: left;
        cursor: pointer;
        white-space: nowrap;
        color: #333;
        font-size: 14px;
        transition: all 0.2s ease;
    }

    .menu-item:hover {
        background: #f5f5f5;
    }

    /* make evidence items look clickable */
    .evidence-item {
        cursor: pointer;
    }

    .evidence-item:hover {
        background: #f5f5f5;
    }

    /* Restore modal styles */
    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1050;
    }

    .modal-container {
        background: white;
        border-radius: 8px;
        width: 95%;
        height: 95%;
        max-width: 1400px;
        position: relative;
        overflow: hidden;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }

    /* Update content wrapper to accommodate absolute positioned menu */
    .content-wrapper {
        padding: 16px;
        min-height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #ffffff;
        position: relative;
        overflow: visible;
    }

    .evidence-label {
        padding-right: 32px; /* Make space for menu button */
    }

    .search-button {
        position: absolute;
        right: 32px; /* Leave space for clear button */
        top: 50%;
        transform: translateY(-50%);
        background: #2563eb;
        color: white;
        border: none;
        border-radius: 6px;
        padding: 6px 12px;
        font-size: 13px;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .search-button:hover {
        background: #1d4ed8;
    }

    /* Update input styles to accommodate the search button */
    .input-container input {
        padding-right: 80px; /* Space for both buttons */
    }

    .spinner-container {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #64748b;
    }

    .loading-spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
    }

    .loading-spinner i {
        font-size: 2rem;
        color: #2563eb;
    }

    .spinner {
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
    }

    .guide-section {
        padding: 1rem;
        background: #f8fafc;
        height: calc(100vh - 200px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior: contain;
        will-change: transform;
    }

    .guide-content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .guide-iframe-container {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 800px;
        background: white;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    .guide-iframe {
        width: 100%;
        height: 100%;
        border: none;
        background: white;
    }

    .guide-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        color: #64748b;
        font-size: 0.875rem;
        background: rgba(255, 255, 255, 0.9);
        padding: 1rem;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }

    .guide-loading .spinner {
        font-size: 1.5rem;
    }

    .guide-loading .bi-exclamation-triangle {
        font-size: 1.5rem;
        color: #ef4444;
    }

    @media (max-width: 768px) {
        .guide-section {
            padding: 0.5rem;
        }
    }

    .no-results {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3rem 1rem;
        text-align: center;
        color: #64748b;
        gap: 1rem;
    }

    .no-results i {
        font-size: 2rem;
        color: #94a3b8;
    }

    .no-results p {
        font-size: 0.9375rem;
        margin: 0;
    }

    .try-again-button {
        padding: 0.5rem 1rem;
        background: #2563eb;
        color: white;
        border: none;
        border-radius: 6px;
        font-size: 0.875rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .try-again-button:hover {
        background: #1d4ed8;
        transform: translateY(-1px);
    }

    .delete-item {
        color: #ef4444;
    }

    .delete-item:hover {
        background: #fee2e2;
    }

    .delete-confirm {
        max-width: 400px;
        padding: 24px;
    }

    .delete-confirm-content {
        text-align: center;
    }

    .delete-confirm-content h3 {
        margin: 0 0 16px;
        color: #1e293b;
    }

    .delete-confirm-content p {
        margin: 0 0 24px;
        color: #64748b;
    }

    .delete-confirm-actions {
        display: flex;
        gap: 12px;
        justify-content: center;
    }

    .cancel-button {
        padding: 8px 16px;
        background: #f1f5f9;
        border: none;
        border-radius: 6px;
        color: #64748b;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .cancel-button:hover {
        background: #e2e8f0;
    }

    .delete-button {
        padding: 8px 16px;
        background: #ef4444;
        border: none;
        border-radius: 6px;
        color: white;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .delete-button:hover {
        background: #dc2626;
    }

    .update-modal {
        max-width: 500px;
        padding: 24px;
    }

    .update-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .update-form h3 {
        margin: 0;
        color: #1e293b;
        font-size: 1.25rem;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .form-group label {
        font-weight: 500;
        color: #475569;
        font-size: 0.875rem;
    }

    .form-group input,
    .form-group textarea {
        padding: 8px 12px;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        font-size: 0.875rem;
        transition: all 0.2s ease;
    }

    .form-group input:focus,
    .form-group textarea:focus {
        outline: none;
        border-color: #2563eb;
        box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
    }

    .form-actions {
        display: flex;
        gap: 12px;
        justify-content: flex-end;
        margin-top: 8px;
    }

    .save-button {
        padding: 8px 16px;
        background: #2563eb;
        border: none;
        border-radius: 6px;
        color: white;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .save-button:hover {
        background: #1d4ed8;
    }
</style>

{#if selectedEvidence && showModal}
    <div 
        class="modal-backdrop" 
        transition:fade 
        on:click={closeModal}
    >
        <div 
            class="modal-container"
            on:click|stopPropagation
        >
            <pre style="display: none;">{JSON.stringify(selectedEvidence, null, 2)}</pre>
            <EvidenceModal 
                evidence={selectedEvidence}
                isOpen={showModal}
                closeModal={closeModal}
                on:addToCanvas={(event) => dispatch('addImageToCanvas', event.detail)}
            />
        </div>
    </div>
{/if}

{#if deleteConfirmOpen}
    <div 
        class="modal-backdrop" 
        transition:fade 
        on:click={closeDeleteConfirm}
    >
        <div 
            class="modal-container delete-confirm"
            on:click|stopPropagation
        >
            <div class="delete-confirm-content">
                <h3>Delete Evidence</h3>
                <p>Are you sure you want to delete this evidence item? This action cannot be undone.</p>
                <div class="delete-confirm-actions">
                    <button 
                        class="cancel-button"
                        on:click={closeDeleteConfirm}
                    >
                        Cancel
                    </button>
                    <button 
                        class="delete-button"
                        on:click={() => deleteEvidence(evidenceToDelete)}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
{/if}

{#if updateModalOpen}
    <div 
        class="modal-backdrop" 
        transition:fade 
        on:click={closeUpdateModal}
    >
        <div 
            class="modal-container update-modal"
            on:click|stopPropagation
        >
            <div class="update-form">
                <h3>Edit Evidence</h3>
                <div class="form-group">
                    <label for="label">Title</label>
                    <input 
                        type="text" 
                        id="label"
                        bind:value={updateForm.label}
                        placeholder="Enter title"
                    />
                </div>
                <div class="form-group">
                    <label for="description">Description</label>
                    <textarea 
                        id="description"
                        bind:value={updateForm.description}
                        placeholder="Enter description"
                        rows="4"
                    ></textarea>
                </div>
                <div class="form-actions">
                    <button 
                        class="cancel-button"
                        on:click={closeUpdateModal}
                    >
                        Cancel
                    </button>
                    <button 
                        class="save-button"
                        on:click={() => updateEvidence()}
                    >
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    </div>
{/if}
  
  