<script>
  import { slide } from 'svelte/transition';
  import { createEventDispatcher, onMount } from 'svelte';
  const dispatch = createEventDispatcher();

  export let canvasComponent;
  export let selectedObject;
  export let selectedObjectType;
  export let evidenceData;

  let showOrderingMenu = false;
  let showBorderMenu = false;
  let showOpacityMenu = false;
  let opacity = 0.6;

  function handleObjectAction(action, object) {
    dispatch('objectAction', { action, object });
  }

  function handleOrderingAction(action) {
    const canvas = canvasComponent.getCanvas();
    const activeObject = canvas.getActiveObject();
    if (!activeObject) return;

    switch(action) {
      case 'front':
        activeObject.bringToFront();
        break;
      case 'forward':
        activeObject.bringForward();
        break;
      case 'backward':
        activeObject.sendBackwards();
        break;
      case 'back':
        activeObject.sendToBack();
        break;
    }
    canvas.discardActiveObject();
    canvas.requestRenderAll();
    showOrderingMenu = false;
  }

  function handleClickOutside(event) {
    // Handle ordering menu
    if (showOrderingMenu && 
        !event.target.closest('.ordering-menu') && 
        !event.target.closest('.order-btn')) {
      showOrderingMenu = false;
    }
    
    // Handle border menu
    if (showBorderMenu && 
        !event.target.closest('.border-menu') && 
        !event.target.closest('.border-style-btn')) {
      showBorderMenu = false;
    }

    // Handle opacity menu
    if (showOpacityMenu && 
        !event.target.closest('.opacity-menu') && 
        !event.target.closest('.opacity-btn')) {
      showOpacityMenu = false;
    }
  }

  // Update opacity
  function updateOpacity(event) {
    const canvas = canvasComponent.getCanvas();
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      opacity = parseFloat(event.target.value);
      activeObject.set('opacity', opacity);
      canvas.requestRenderAll();
    }
  }

  // Handle object selection
  function handleObjectSelection(obj) {
    if (!obj) return;
    opacity = obj.opacity || 0.6;
    console.log('Selected object:', { type: selectedObjectType, object: selectedObject });
  }

  $: if (selectedObject) {
    console.log('Selected object updated:', { 
      type: selectedObjectType, 
      object: selectedObject,
      isShape: selectedObjectType === "rect" || 
               selectedObjectType === "circle" || 
               selectedObjectType === "triangle" || 
               selectedObjectType === "line"
    });
  }

  // Add window click listener
  onMount(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  });
</script>

<div class="submenu-controls">
  <button 
    class="submenu-btn"
    on:click={() => canvasComponent.undoCanvasState()}
    data-tooltip="Undo"
  >
    <i class="fas fa-undo"></i>
  </button>

  {#if selectedObject}
    <div class="control-group">
      <button
        class="submenu-btn order-btn"
        on:click={() => showOrderingMenu = !showOrderingMenu}
        data-tooltip="Layer Order"
      >
        <i class="fas fa-layer-group"></i>
        <i class="fas fa-chevron-down" style="font-size: 8px; margin-left: 2px;"></i>
      </button>
      
      {#if showOrderingMenu}
        <div class="ordering-menu">
          <button
            class="ordering-item"
            on:click={() => handleOrderingAction('front')}
          >
            <i class="fas fa-layer-group"></i>
            <i class="fas fa-arrow-up" style="font-size: 10px;"></i>
            <span>Bring to Front</span>
          </button>
          <button
            class="ordering-item"
            on:click={() => handleOrderingAction('forward')}
          >
            <i class="fas fa-arrow-up"></i>
            <span>Bring Forward</span>
          </button>
          <button
            class="ordering-item"
            on:click={() => handleOrderingAction('backward')}
          >
            <i class="fas fa-arrow-down"></i>
            <span>Send Backward</span>
          </button>
          <button
            class="ordering-item"
            on:click={() => handleOrderingAction('back')}
          >
            <i class="fas fa-layer-group"></i>
            <i class="fas fa-arrow-down" style="font-size: 10px;"></i>
            <span>Send to Back</span>
          </button>
        </div>
      {/if}
    </div>

    {#if selectedObjectType === "text"}
      <div class="control-group">
        <button
          class="submenu-btn"
          on:click={() => canvasComponent.toggleBold()}
          title="Bold"
        >
          <i class="fas fa-bold"></i>
        </button>
        <input
          type="color"
          class="color-picker"
          on:input={(e) => canvasComponent.setTextColor(e.target.value)}
          title="Text Color"
        />
        <select
          class="font-size-select"
          on:change={(e) => canvasComponent.setFontSize(e.target.value)}
        >
          {#each [12, 14, 16, 18, 24, 32, 48] as size}
            <option value={size}>{size}px</option>
          {/each}
        </select>
      </div>
    {/if}

    {#if selectedObjectType === "image"}
      <div class="control-group">
        <button
          class="submenu-btn"
          on:click={() => canvasComponent.cropImage()}
          title="Crop"
        >
          <i class="fas fa-crop"></i>
        </button>
        <input
          type="range"
          min="0"
          max="20"
          class="border-width"
          on:input={(e) => canvasComponent.setBorderWidth(e.target.value)}
          title="Border Width"
        />
      </div>
    {/if}

    {#if ["rect", "circle", "triangle", "line"].includes(selectedObjectType)}
      <div class="control-group">
        {#if selectedObjectType !== "line"}
          <input
            type="color"
            class="color-picker"
            value={selectedObject.fill}
            on:input={(e) => {
              selectedObject.set('fill', e.target.value);
              canvasComponent.getCanvas().requestRenderAll();
            }}
            title="Fill Color"
          />
        {/if}
        <div class="border-color-picker" title="Border Color">
          <input
            type="color"
            class="color-picker-hidden"
            value={selectedObject.stroke}
            on:input={(e) => {
              selectedObject.set('stroke', e.target.value);
              canvasComponent.getCanvas().requestRenderAll();
            }}
          />
          <div class="border-preview" style="border-color: {selectedObject.stroke}"></div>
        </div>
        <div class="border-style-container">
          <button 
            class="border-style-btn"
            on:click={() => showBorderMenu = !showBorderMenu}
            title="Border Style"
          >
            <div class="border-line"></div>
          </button>
          
          {#if showBorderMenu}
            <div class="border-menu" 
                 on:click|stopPropagation
                 transition:slide={{ duration: 100 }}>
              <div class="border-option">
                <label>Border Width</label>
                <div class="slider-row">
                  <input
                    type="range"
                    min="0"
                    max="20"
                    value={selectedObject.strokeWidth}
                    class="border-width"
                    on:input={(e) => {
                      selectedObject.set('strokeWidth', parseInt(e.target.value));
                      canvasComponent.getCanvas().requestRenderAll();
                    }}
                  />
                  <span class="value-label">{selectedObject.strokeWidth}px</span>
                </div>
              </div>
            </div>
          {/if}
        </div>
        <div class="opacity-container">
          <button 
            class="opacity-btn"
            on:click={() => showOpacityMenu = !showOpacityMenu}
            title="Opacity"
          >
            <div class="checkerboard"></div>
          </button>
          
          {#if showOpacityMenu}
            <div class="opacity-menu" 
                 on:click|stopPropagation
                 transition:slide={{ duration: 100 }}>
              <div class="opacity-option">
                <label>Opacity</label>
                <div class="slider-row">
                  <input
                    type="range"
                    min="0.1"
                    max="1"
                    step="0.1"
                    value={selectedObject.opacity}
                    class="opacity-slider"
                    on:input={(e) => {
                      selectedObject.set('opacity', parseFloat(e.target.value));
                      canvasComponent.getCanvas().requestRenderAll();
                    }}
                  />
                  <span class="value-label">{Math.round(selectedObject.opacity * 100)}%</span>
                </div>
              </div>
            </div>
          {/if}
        </div>
      </div>
    {/if}

    <div class="control-group">
      <button
        class="submenu-btn delete-btn"
        on:click={() => handleObjectAction("delete", selectedObject)}
        title="Delete"
      >
        <i class="fas fa-trash"></i>
      </button>

      {#if evidenceData}
        <button
          class="analyze-btn"
          on:click={() => handleObjectAction("analyze", evidenceData)}
          title="Analyze Chart"
        >
          <i class="bi bi-bar-chart-line"></i>
          <span class="btn-text">Analyze</span>
        </button>
      {/if}
    </div>
  {/if}
</div>

<style>
  .submenu-controls {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: white;
    height: 100%;
  }

  .control-group {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px;
    border-radius: 6px;
    background: #f8fafc;
    position: relative;
  }

  .border-color-picker {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    background: transparent;
  }

  .color-picker-hidden {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .border-preview {
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border: 2px solid;
    border-radius: 2px;
    background: transparent;
    pointer-events: none;
  }

  .submenu-btn {
    height: 32px;
    width: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    color: #64748b;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .submenu-btn:hover {
    background: #e2e8f0;
    color: #334155;
  }

  .delete-btn:hover {
    background: #fee2e2;
    color: #dc2626;
  }

  .analyze-btn {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0 12px;
    height: 32px;
    background: #f0f7ff;
    color: #2563eb;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .analyze-btn:hover {
    background: #e0f2fe;
  }

  .color-picker {
    width: 32px;
    height: 32px;
    padding: 2px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .border-width {
    width: 80px;
    height: 4px;
    -webkit-appearance: none;
    background: #e2e8f0;
    border-radius: 2px;
    outline: none;
    padding: 0;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    background: #3b82f6;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  input[type="range"]::-webkit-slider-thumb:hover {
    transform: scale(1.2);
  }

  .ordering-menu,
  .border-menu,
  .opacity-menu {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 4px;
    background: white;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .ordering-menu {
    min-width: 160px;
    padding: 4px;
  }

  .border-menu,
  .opacity-menu {
    padding: 12px;
    min-width: 200px;
  }

  .ordering-item {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 8px;
    border: none;
    background: none;
    color: #64748b;
    font-size: 12px;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s ease;
  }

  .ordering-item:hover {
    background: #f8fafc;
    color: #334155;
  }

  .ordering-item span {
    flex: 1;
    text-align: left;
  }

  .order-btn {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  /* Tooltip styles */
  .submenu-btn[data-tooltip]::after,
  .color-picker[data-tooltip]::after,
  .border-color-picker[data-tooltip]::after,
  .border-width[data-tooltip]::after,
  .border-style-btn[data-tooltip]::after,
  .opacity-btn[data-tooltip]::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: -28px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px 8px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 11px;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all 0.15s ease;
    z-index: 1000;
    pointer-events: none;
  }

  .submenu-btn:hover[data-tooltip]::after,
  .color-picker:hover[data-tooltip]::after,
  .border-color-picker:hover[data-tooltip]::after,
  .border-width:hover[data-tooltip]::after,
  .border-style-btn:hover[data-tooltip]::after,
  .opacity-btn:hover[data-tooltip]::after {
    opacity: 1;
    visibility: visible;
    bottom: -24px;
  }

  /* Add a small delay to prevent accidental tooltip shows */
  .submenu-btn[data-tooltip],
  .color-picker[data-tooltip],
  .border-color-picker[data-tooltip],
  .border-width[data-tooltip],
  .border-style-btn[data-tooltip],
  .opacity-btn[data-tooltip] {
    position: relative;
  }

  /* Ensure tooltips don't show when menus are open */
  .submenu-btn:has(+ .ordering-menu)[data-tooltip]::after,
  .border-style-btn:has(+ .border-menu)[data-tooltip]::after,
  .opacity-btn:has(+ .opacity-menu)[data-tooltip]::after {
    display: none;
  }

  .border-style-container {
    position: relative;
  }

  .border-style-btn {
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #f8fafc;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .border-style-btn:hover {
    background: #e2e8f0;
  }

  .border-line {
    width: 16px;
    height: 2px;
    background: #64748b;
  }

  .border-option {
    margin-bottom: 8px;
  }

  .border-option:last-child {
    margin-bottom: 0;
  }

  .border-option label {
    display: block;
    font-size: 12px;
    color: #64748b;
    margin-bottom: 8px;
  }

  .slider-row {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .value-label {
    min-width: 36px;
    font-size: 12px;
    color: #64748b;
    text-align: right;
  }

  .opacity-container {
    position: relative;
  }

  .opacity-btn {
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #f8fafc;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;
  }

  .opacity-btn:hover {
    background: #e2e8f0;
  }

  .checkerboard {
    width: 16px;
    height: 16px;
    background-image: linear-gradient(45deg, #64748b 25%, transparent 25%),
                      linear-gradient(-45deg, #64748b 25%, transparent 25%),
                      linear-gradient(45deg, transparent 75%, #64748b 75%),
                      linear-gradient(-45deg, transparent 75%, #64748b 75%);
    background-size: 8px 8px;
    background-position: 0 0, 0 4px, 4px -4px, -4px 0px;
  }

  .opacity-option {
    margin-bottom: 8px;
  }

  .opacity-option:last-child {
    margin-bottom: 0;
  }

  .opacity-option label {
    display: block;
    font-size: 12px;
    color: #64748b;
    margin-bottom: 8px;
  }

  .opacity-slider {
    width: 100%;
    height: 4px;
    -webkit-appearance: none;
    background: #e2e8f0;
    border-radius: 2px;
    outline: none;
    padding: 0;
  }

  .opacity-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    background: #3b82f6;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .opacity-slider::-webkit-slider-thumb:hover {
    transform: scale(1.2);
  }
</style> 