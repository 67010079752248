<script>
  import { onMount, onDestroy } from 'svelte';
  import { authStore } from '../stores/authStore.js';
  import { navigate } from 'svelte-routing';
  import HeaderBar from '../components/HeaderBar.svelte';
  import Modal from '../components/Modal.svelte';
  import AdminBreadcrumb from '../components/AdminBreadcrumb.svelte';
  
  // Check if user is admin
  $: isAdmin = $authStore?.user?.isAdmin || false;
  
  // Management state
  let managementTab = 'evidence'; // 'evidence' or 'articles'
  let evidenceItems = [];
  let articleItems = [];
  let evidencePagination = { page: 1, limit: 10, total: 0, totalPages: 0 };
  let articlesPagination = { page: 1, limit: 10, total: 0, totalPages: 0 };
  let filterSourceURL = '';
  let filterTopic = '';
  let isLoading = false;
  let deleteConfirmOpen = false;
  let deleteItemType = '';
  let deleteItemId = '';
  let deleteItemSourceURL = '';
  let deleteAllConfirm = false;
  
  onMount(() => {
    // Load initial data
    loadEvidenceItems();
  });
  
  async function loadEvidenceItems() {
    isLoading = true;
    try {
      const { page, limit } = evidencePagination;
      let url = `/api/admin/evidence?page=${page}&limit=${limit}`;
      
      if (filterSourceURL) {
        url += `&sourceURL=${encodeURIComponent(filterSourceURL)}`;
      }
      
      if (filterTopic) {
        url += `&topic=${encodeURIComponent(filterTopic)}`;
      }
      
      const response = await fetch(url);
      const data = await response.json();
      
      if (data.success) {
        evidenceItems = data.data.items;
        evidencePagination = data.data.pagination;
      } else {
        console.error('Failed to load evidence items');
      }
    } catch (error) {
      console.error('Error loading evidence:', error);
    } finally {
      isLoading = false;
    }
  }
  
  async function loadArticleItems() {
    isLoading = true;
    try {
      const { page, limit } = articlesPagination;
      let url = `/api/admin/articles?page=${page}&limit=${limit}`;
      
      if (filterSourceURL) {
        url += `&sourceURL=${encodeURIComponent(filterSourceURL)}`;
      }
      
      if (filterTopic) {
        url += `&topic=${encodeURIComponent(filterTopic)}`;
      }
      
      const response = await fetch(url);
      const data = await response.json();
      
      if (data.success) {
        articleItems = data.data.items;
        articlesPagination = data.data.pagination;
      } else {
        console.error('Failed to load article items');
      }
    } catch (error) {
      console.error('Error loading articles:', error);
    } finally {
      isLoading = false;
    }
  }
  
  function changeTab(tab) {
    managementTab = tab;
    if (tab === 'evidence') {
      loadEvidenceItems();
    } else {
      loadArticleItems();
    }
  }
  
  function applyFilters() {
    if (managementTab === 'evidence') {
      evidencePagination.page = 1;
      loadEvidenceItems();
    } else {
      articlesPagination.page = 1;
      loadArticleItems();
    }
  }
  
  function clearFilters() {
    filterSourceURL = '';
    filterTopic = '';
    applyFilters();
  }
  
  function changePage(tab, page) {
    if (tab === 'evidence') {
      evidencePagination.page = page;
      loadEvidenceItems();
    } else {
      articlesPagination.page = page;
      loadArticleItems();
    }
  }
  
  function openDeleteConfirm(type, id, sourceURL) {
    deleteItemType = type;
    deleteItemId = id;
    deleteItemSourceURL = sourceURL;
    deleteAllConfirm = false;
    deleteConfirmOpen = true;
  }
  
  function openDeleteAllConfirm(type) {
    deleteItemType = type;
    deleteItemId = '';
    deleteItemSourceURL = '';
    deleteAllConfirm = true;
    deleteConfirmOpen = true;
  }
  
  function closeDeleteConfirm() {
    deleteConfirmOpen = false;
    deleteItemType = '';
    deleteItemId = '';
    deleteItemSourceURL = '';
    deleteAllConfirm = false;
  }
  
  async function confirmDelete() {
    try {
      const endpoint = deleteItemType === 'evidence' ? 
        '/api/admin/evidence' : 
        '/api/admin/articles';
      
      const body = {};
      
      if (deleteItemId) {
        body.id = deleteItemId;
      } else if (deleteItemSourceURL) {
        body.sourceURL = deleteItemSourceURL;
      } else if (deleteAllConfirm) {
        body.deleteAll = true;
      }
      
      const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      
      const data = await response.json();
      
      if (data.success) {
        // Reload the data
        if (deleteItemType === 'evidence') {
          loadEvidenceItems();
        } else {
          loadArticleItems();
        }
        
        // Close the confirm dialog
        closeDeleteConfirm();
      } else {
        alert(`Error: ${data.message || 'Failed to delete item(s)'}`);
      }
    } catch (error) {
      console.error('Error deleting item(s):', error);
      alert(`Error: ${error.message || 'Failed to delete item(s)'}`);
    }
  }
</script>

<!-- Include header bar -->
<HeaderBar />

<!-- Add breadcrumb -->
<AdminBreadcrumb 
  current="Manage Evidence & Articles" 
  parent="Admin Console"
  parentPath="/"
/>

<div class="manage-page">
  <div class="header">
    <h1>Manage Evidence & Articles</h1>
    <p class="subtitle">View, filter, and delete evidence and articles in the database</p>
  </div>
  
  <div class="manage-container">
    <div class="tabs">
      <button 
        class={managementTab === 'evidence' ? 'active' : ''}
        on:click={() => changeTab('evidence')}
      >
        <i class="bi bi-bar-chart"></i> Evidence Items
      </button>
      <button 
        class={managementTab === 'articles' ? 'active' : ''}
        on:click={() => changeTab('articles')}
      >
        <i class="bi bi-file-text"></i> Articles
      </button>
    </div>
    
    <div class="filters">
      <div class="filter-group">
        <label>Filter by:</label>
        <input 
          type="text"
          bind:value={filterSourceURL}
          placeholder="Source URL"
        >
        <input 
          type="text"
          bind:value={filterTopic}
          placeholder="Topic"
        >
        <button class="filter-btn" on:click={applyFilters}>
          <i class="bi bi-search"></i> Apply Filters
        </button>
        <button class="filter-btn secondary" on:click={clearFilters}>
          <i class="bi bi-x-circle"></i> Clear
        </button>
      </div>
    </div>
    
    <div class="bulk-actions">
      <button 
        class="danger-btn" 
        on:click={() => {
          if(filterSourceURL) {
            openDeleteConfirm(managementTab === 'evidence' ? 'evidence' : 'articles', '', filterSourceURL);
          } else {
            alert('Please enter a Source URL to delete by source');
          }
        }}
      >
        <i class="bi bi-trash"></i> Delete Filtered by Source
      </button>
      <button 
        class="danger-btn" 
        on:click={() => openDeleteAllConfirm(managementTab === 'evidence' ? 'evidence' : 'articles')}
      >
        <i class="bi bi-trash"></i> Delete All {managementTab === 'evidence' ? 'Evidence' : 'Articles'}
      </button>
    </div>
    
    <div class="items-container">
      {#if isLoading}
        <div class="loading">
          <div class="spinner"></div>
          <p>Loading items...</p>
        </div>
      {:else if (managementTab === 'evidence' && evidenceItems.length === 0) || (managementTab === 'articles' && articleItems.length === 0)}
        <div class="no-items">
          <i class="bi bi-emoji-frown"></i>
          <p>No items found. Try clearing filters or adding new content.</p>
        </div>
      {:else}
        <div class="items-list">
          {#if managementTab === 'evidence'}
            {#each evidenceItems as item}
              <div class="item">
                <div class="item-header">
                  <div class="item-title">{item.description || 'No description'}</div>
                  <div class="item-actions">
                    <button class="delete-btn" on:click={() => openDeleteConfirm('evidence', item._id, '')}>
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
                <div class="item-details">
                  <div class="detail">
                    <span class="label">Source:</span>
                    <a href={item.sourceURL} target="_blank" rel="noopener noreferrer">{item.sourceURL}</a>
                  </div>
                  <div class="detail">
                    <span class="label">Topic:</span>
                    <span>{item.topicTagStatic || 'None'}</span>
                  </div>
                  {#if item.mediaURL}
                    <div class="item-preview">
                      <img src={item.mediaURL} alt={item.description || 'Evidence'} />
                    </div>
                  {/if}
                </div>
              </div>
            {/each}
          {:else}
            {#each articleItems as item}
              <div class="item">
                <div class="item-header">
                  <div class="item-title">{item.articleTitle || 'No title'}</div>
                  <div class="item-actions">
                    <button class="delete-btn" on:click={() => openDeleteConfirm('articles', item._id, '')}>
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
                <div class="item-details">
                  <div class="detail">
                    <span class="label">Source:</span>
                    <a href={item.sourceURL} target="_blank" rel="noopener noreferrer">{item.sourceURL}</a>
                  </div>
                  <div class="detail">
                    <span class="label">Date:</span>
                    <span>{item.articleDate || 'Not specified'}</span>
                  </div>
                  <div class="detail">
                    <span class="label">Topic:</span>
                    <span>{item.topicTagStatic || 'None'}</span>
                  </div>
                  <div class="article-preview">
                    <div class="preview-heading">Article Preview:</div>
                    <div class="preview-content">{item.articleContent?.substring(0, 200)}...</div>
                  </div>
                </div>
              </div>
            {/each}
          {/if}
        </div>
      {/if}
    </div>
    
    <div class="pagination">
      {#if managementTab === 'evidence' && evidencePagination.totalPages > 1}
        <div class="pagination-info">
          Page {evidencePagination.page} of {evidencePagination.totalPages} 
          ({evidencePagination.total} items)
        </div>
        <div class="pagination-controls">
          <button 
            class="page-btn"
            disabled={evidencePagination.page === 1} 
            on:click={() => changePage('evidence', evidencePagination.page - 1)}
          >
            <i class="bi bi-chevron-left"></i>
          </button>
          
          {#each Array(evidencePagination.totalPages) as _, i}
            {#if i + 1 === evidencePagination.page || i + 1 === 1 || i + 1 === evidencePagination.totalPages || (i + 1 === evidencePagination.page - 1 || i + 1 === evidencePagination.page + 1)}
              <button 
                class={`page-btn ${evidencePagination.page === i + 1 ? 'active' : ''}`}
                on:click={() => changePage('evidence', i + 1)}
              >
                {i + 1}
              </button>
            {:else if i + 1 === evidencePagination.page - 2 || i + 1 === evidencePagination.page + 2}
              <span class="page-ellipsis">...</span>
            {/if}
          {/each}
          
          <button 
            class="page-btn"
            disabled={evidencePagination.page === evidencePagination.totalPages} 
            on:click={() => changePage('evidence', evidencePagination.page + 1)}
          >
            <i class="bi bi-chevron-right"></i>
          </button>
        </div>
      {:else if managementTab === 'articles' && articlesPagination.totalPages > 1}
        <div class="pagination-info">
          Page {articlesPagination.page} of {articlesPagination.totalPages} 
          ({articlesPagination.total} items)
        </div>
        <div class="pagination-controls">
          <button 
            class="page-btn"
            disabled={articlesPagination.page === 1} 
            on:click={() => changePage('articles', articlesPagination.page - 1)}
          >
            <i class="bi bi-chevron-left"></i>
          </button>
          
          {#each Array(articlesPagination.totalPages) as _, i}
            {#if i + 1 === articlesPagination.page || i + 1 === 1 || i + 1 === articlesPagination.totalPages || (i + 1 === articlesPagination.page - 1 || i + 1 === articlesPagination.page + 1)}
              <button 
                class={`page-btn ${articlesPagination.page === i + 1 ? 'active' : ''}`}
                on:click={() => changePage('articles', i + 1)}
              >
                {i + 1}
              </button>
            {:else if i + 1 === articlesPagination.page - 2 || i + 1 === articlesPagination.page + 2}
              <span class="page-ellipsis">...</span>
            {/if}
          {/each}
          
          <button 
            class="page-btn"
            disabled={articlesPagination.page === articlesPagination.totalPages} 
            on:click={() => changePage('articles', articlesPagination.page + 1)}
          >
            <i class="bi bi-chevron-right"></i>
          </button>
        </div>
      {/if}
    </div>
    
    <div class="back-container">
      <button class="back-btn" on:click={() => navigate('/')}>
        <i class="bi bi-arrow-left"></i> Back to Home
      </button>
    </div>
  </div>
</div>

<!-- Delete Confirmation Modal -->
{#if deleteConfirmOpen}
<Modal 
  on:close={closeDeleteConfirm}
>
  <div slot="header">
    <h2>Confirm Deletion</h2>
  </div>
  
  <div slot="body">
    <div class="delete-warning">
      <i class="bi bi-exclamation-triangle"></i>
      <p>This action cannot be undone!</p>
    </div>
    
    {#if deleteAllConfirm}
      <p>Are you sure you want to delete <strong>ALL {deleteItemType === 'evidence' ? 'evidence items' : 'articles'}</strong> from the database?</p>
      <p class="delete-detail">This will permanently remove all {deleteItemType === 'evidence' ? 'evidence items' : 'articles'} from the system.</p>
    {:else if deleteItemSourceURL}
      <p>Are you sure you want to delete all {deleteItemType === 'evidence' ? 'evidence items' : 'articles'} from this source URL?</p>
      <p class="delete-detail"><strong>Source URL:</strong> {deleteItemSourceURL}</p>
    {:else if deleteItemId}
      <p>Are you sure you want to delete this {deleteItemType === 'evidence' ? 'evidence item' : 'article'}?</p>
      <p class="delete-detail"><strong>ID:</strong> {deleteItemId}</p>
    {/if}
  </div>
  
  <div slot="footer">
    <div class="confirm-actions">
      <button 
        class="cancel-btn" 
        on:click={closeDeleteConfirm}
      >
        Cancel
      </button>
      <button 
        class="danger-btn" 
        on:click={confirmDelete}
      >
        <i class="bi bi-trash"></i> {deleteAllConfirm ? 'Delete All' : 'Delete'}
      </button>
    </div>
  </div>
</Modal>
{/if}

<style>
  .manage-page {
    padding: 1.5rem;
    max-width: 100%;
    margin: 0 auto;
  }

  .header {
    margin-bottom: 2rem;
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 1rem;
  }

  .header h1 {
    font-size: 2rem;
    font-weight: 700;
    color: #1e293b;
    margin: 0;
  }

  .subtitle {
    color: #64748b;
    margin: 0.5rem 0 0 0;
    font-size: 1.1rem;
  }

  .manage-container {
    background: white;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 180px); /* Adjust height to fit admin console */
  }

  .tabs {
    display: flex;
    border-bottom: 1px solid #e2e8f0;
  }

  .tabs button {
    padding: 1rem 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    background: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .tabs button.active {
    color: #2563eb;
    border-bottom-color: #2563eb;
    background: #f8fafc;
  }

  .tabs button:hover:not(.active) {
    background: #f1f5f9;
  }

  .filters {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e2e8f0;
    background: #f8fafc;
  }

  .filter-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 0.75rem;
    align-items: end;
  }

  .filter-group label {
    font-weight: 500;
    color: #475569;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
    display: block;
  }

  .filter-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #cbd5e1;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    transition: all 0.2s ease;
  }

  .filter-group input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
  }

  .filter-actions {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;
    grid-column: 1 / -1;
    margin-top: 0.5rem;
  }

  .filter-btn {
    padding: 0.5rem 0.75rem;
    background: #2563eb;
    color: white;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    transition: all 0.2s ease;
    white-space: nowrap;
  }

  .filter-btn.secondary {
    background: #64748b;
  }

  .filter-btn:hover {
    transform: translateY(-1px);
  }

  .filter-btn.secondary:hover {
    background: #475569;
  }

  .bulk-actions {
    padding: 0.75rem 1rem;
    display: flex;
    gap: 0.75rem;
    border-bottom: 1px solid #e2e8f0;
    background: #f8fafc;
    align-items: center;
    justify-content: flex-end;
  }

  .danger-btn {
    padding: 0.5rem 0.75rem;
    background: #ef4444;
    color: white;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    transition: all 0.2s ease;
    white-space: nowrap;
  }

  .danger-btn:hover {
    background: #dc2626;
    transform: translateY(-1px);
  }

  .danger-btn i {
    font-size: 1rem;
  }

  .items-container {
    padding: 1rem;
    flex: 1;
    overflow-y: auto;
    min-height: 0; /* Allow container to shrink */
  }

  .loading, .no-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 2rem;
    color: #64748b;
  }

  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #2563eb;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .no-items i {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #94a3b8;
  }

  .items-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    padding: 0.5rem;
  }

  .item {
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    overflow: hidden;
    background: white;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
  }

  .item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: #f8fafc;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e2e8f0;
    gap: 0.5rem;
  }

  .item-title {
    font-weight: 500;
    color: #1e293b;
    flex: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .item-actions {
    display: flex;
    gap: 0.5rem;
  }

  .delete-btn {
    padding: 0.25rem 0.5rem;
    background: #ef4444;
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 0.75rem;
    transition: background-color 0.2s;
  }

  .delete-btn:hover {
    background: #dc2626;
  }

  .item-details {
    padding: 1rem;
    font-size: 0.875rem;
  }

  .detail {
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  .label {
    font-weight: 500;
    color: #475569;
  }

  .item-preview {
    margin-top: 0.75rem;
  }

  .item-preview img {
    max-width: 100%;
    max-height: 300px;
    border-radius: 0.25rem;
    border: 1px solid #e2e8f0;
  }

  .article-preview {
    margin-top: 0.75rem;
    padding: 0.75rem;
    background: #f8fafc;
    border-radius: 0.25rem;
    border: 1px solid #e2e8f0;
  }

  .preview-heading {
    font-weight: 500;
    color: #475569;
    margin-bottom: 0.5rem;
  }

  .preview-content {
    white-space: pre-line;
    color: #64748b;
    line-height: 1.5;
  }

  .pagination {
    padding: 1rem;
    border-top: 1px solid #e2e8f0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
  }

  .pagination-info {
    font-size: 0.875rem;
    color: #64748b;
  }

  .pagination-controls {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .page-btn {
    padding: 0.25rem 0.5rem;
    min-width: 2rem;
    background: #f1f5f9;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-btn.active {
    background: #2563eb;
    color: white;
    border-color: #2563eb;
  }

  .page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .page-ellipsis {
    padding: 0 0.25rem;
    color: #64748b;
  }

  .back-container {
    padding: 1rem;
    border-top: 1px solid #e2e8f0;
    display: flex;
    justify-content: flex-start;
  }

  .back-btn {
    padding: 0.5rem 0.75rem;
    background: #64748b;
    color: white;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    transition: background-color 0.2s;
  }

  .back-btn:hover {
    background: #475569;
  }

  .delete-warning {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    background-color: #fee2e2;
    border-radius: 0.375rem;
    margin-bottom: 1rem;
  }
  
  .delete-warning i {
    font-size: 1.5rem;
    color: #dc2626;
  }
  
  .delete-warning p {
    font-weight: 500;
    color: #b91c1c;
    margin: 0;
  }
  
  .delete-detail {
    font-size: 0.875rem;
    color: #4b5563;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: #f9fafb;
    border-radius: 0.25rem;
  }
  
  .confirm-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
  }
  
  .cancel-btn {
    padding: 0.5rem 0.75rem;
    background: #64748b;
    color: white;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 0.875rem;
    transition: background-color 0.2s;
  }
  
  .cancel-btn:hover {
    background: #475569;
  }
</style> 