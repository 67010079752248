<script>
  import { onMount, onDestroy, createEventDispatcher } from 'svelte';
  import { getContext } from 'svelte';
  import { canvasStore } from '../stores/canvasStore.js';
  import { get } from 'svelte/store';

  const selectedSlideIndex = getContext('selectedSlideIndex');
  const storyStore = getContext('storyStore');

  let fabric;
  let canvas;
  let canvasStates = [];
  let selectedObject = null;
  let isImageLoading = false;
  let clipboard = null; // For copy/paste operations

  const dispatch = createEventDispatcher();

  // Initialize Fabric.js canvas on component mount
  onMount(() => {
    fabric = window.fabric;
    if (!fabric) {
      console.error('Fabric.js not loaded');
      return;
    }

    canvas = new fabric.Canvas('story-canvas', {
      height: 667,
      width: 375,
      backgroundColor: 'white',
    });

    console.log('Canvas initialized:', canvas);

    dispatch('canvasInstance', canvas);
    canvasStore.update(store => ({ ...store, canvas }));

    // Load the initial slide content
    loadSlideContent($selectedSlideIndex);

    // Event listeners for canvas changes
    canvas.on('object:added', saveCanvasState);
    canvas.on('object:modified', saveCanvasState);
    canvas.on('object:removed', saveCanvasState);
    canvas.on('selection:created', handleObjectSelected);
    canvas.on('selection:cleared', handleSelectionCleared);
    canvas.on('selection:updated', handleObjectSelected);

    // Add keyboard event listeners
    window.addEventListener('keydown', handleKeyDown);
  });

  // Cleanup on component destroy
  onDestroy(() => {
    if (canvas) {
      canvas.dispose();
      console.log('Canvas disposed');
    }
    // Remove keyboard event listener
    window.removeEventListener('keydown', handleKeyDown);
  });

  // Handle object selection
  function handleObjectSelected(event) {
    if (event.selected && event.selected.length > 0) {
      const obj = event.selected[0];
      selectedObject = obj;
      
      // Determine the type of object
      const objectType = getObjectType(obj);
      
      // Get evidence data from metadata if it exists
      const metadata = obj.metadata || {};
      const evidenceData = metadata.isEvidence ? metadata.evidenceData : null;
      
      console.log('Object selected:', {
        type: objectType,
        isEvidence: metadata.isEvidence,
        evidenceData: evidenceData,
        object: obj
      });
      
      // Ensure the object is the active object
      canvas.setActiveObject(obj);
      
      dispatch('updateSubMenu', { 
        selectedObject: obj, 
        objectType, 
        evidenceData,
        metadata 
      });
    } else {
      selectedObject = null;
      canvas.discardActiveObject();
      console.log('Selection cleared');
      dispatch('updateSubMenu', { 
        selectedObject: null, 
        objectType: null, 
        evidenceData: null,
        metadata: null 
      });
    }
  }

  function getObjectType(obj) {
    if (obj.type === 'text' || obj.type === 'i-text') return 'text';
    if (obj.type === 'image') return 'image';
    if (obj.type === 'circle') return 'circle';
    if (obj.type === 'rect') return 'rect';
    if (obj.type === 'triangle') return 'triangle';
    if (obj.type === 'line') return 'line';
    if (obj.type === 'path') return 'drawing';
    return 'other';
  }

  // Handle selection cleared
  function handleSelectionCleared() {
    selectedObject = null;
    canvas.discardActiveObject();
    console.log('Selection cleared');
    dispatch('updateSubMenu', { selectedObject: null, objectType: null, evidenceData: null });
  }

  // Reactive statement to watch for slide index changes
  $: {
    if ($selectedSlideIndex !== undefined) {
      console.log('Slide changed to:', $selectedSlideIndex);
      loadSlideContent($selectedSlideIndex);
    }
  }

  /**
   * Load content for the selected slide
   * @param {number} index - The index of the selected slide
   */
  function loadSlideContent(slideIndex) {
    if (!canvas) {
      console.warn('Canvas not initialized');
      return;
    }
    
    if (slideIndex === undefined || slideIndex === null) {
      console.warn('Invalid slide index:', slideIndex);
      return;
    }
    
    const slides = $storyStore?.slides || [];
    
    if (!slides || slides.length === 0) {
      console.warn('No slides available');
      return;
    }
    
    if (slideIndex >= slides.length) {
      console.warn(`Slide index ${slideIndex} out of bounds (max: ${slides.length - 1})`);
      return;
    }
    
    const currentSlide = slides[slideIndex];
    console.log('Current slide state:', currentSlide);
    
    if (!currentSlide) {
      console.warn('Invalid slide at index:', slideIndex);
      return;
    }
    
    // Clear the canvas
    canvas.clear();
    
    // Load canvas state if it exists
    if (currentSlide.canvasState && currentSlide.canvasState.length > 0) {
      try {
        // Get the full (uncompressed) canvas state
        const fullState = storyStore.getFullCanvasState(slideIndex);
        canvas.loadFromJSON({ objects: fullState }, () => {
          canvas.renderAll();
          console.log('Canvas state loaded');
        });
      } catch (error) {
        console.error('Error loading canvas state:', error);
      }
    }
  }

  /**
   * Save the current canvas state to the store
   */
  function saveCanvasState() {
    console.log('Saving canvas state');
    const index = get(selectedSlideIndex);
    
    // Ensure we have a valid index
    if (index === undefined || index < 0) {
      console.warn('Invalid slide index, cannot save canvas state');
      return;
    }

    // Expanded list of properties to save
    const propertiesToSave = [
      'selectable',
      'hasControls',
      'text',
      'fontFamily',
      'fontSize',
      'fontWeight',
      'fontStyle',
      'lineHeight',
      'underline',
      'overline',
      'linethrough',
      'textAlign',
      'fill',
      'stroke',
      'strokeWidth',
      'left',
      'top',
      'width',
      'height',
      'scaleX',
      'scaleY',
      'angle',
      'opacity',
      'src',
      'crossOrigin',
      'metadata'
    ];

    const canvasJSON = canvas.toJSON(propertiesToSave);
    console.log('Saving canvas state with objects:', canvasJSON.objects);

    canvasStates.push(canvasJSON); // Store the canvas state
    if (canvasStates.length > 50) {
      canvasStates.shift(); // Remove the oldest state if we have more than 50
    }

    try {
      if (canvasJSON.objects && Array.isArray(canvasJSON.objects)) {
        // The compression will happen in the store
        storyStore.updateCanvasState(index, canvasJSON.objects);
        console.log('Saved canvas state for slide:', index);
      } else {
        storyStore.updateCanvasState(index, []);
        console.log('Cleared canvas state for slide:', index);
      }
    } catch (error) {
      console.error('Error saving canvas state:', error);
    }
  }


  export function undoCanvasState() {
    if (canvasStates.length > 1) {
      // Remove the current state
      canvasStates.pop();
      // Get the previous state
      const previousState = canvasStates[canvasStates.length - 1];
      // Load the previous state
      canvas.loadFromJSON(previousState, () => {
        canvas.renderAll();
        console.log('Canvas state reverted to previous state');
      });
    } else {
      console.warn('No more states to undo');
    }
  }

  export function saveCanvasPreview() {
    return new Promise((resolve, reject) => {
      const index = get(selectedSlideIndex);
      if (index !== undefined && index >= 0) {
        try {
          // First, get the current canvas dimensions
          const originalWidth = canvas.width;
          const originalHeight = canvas.height;
          const scaleFactor = 0.5; // Generate a smaller preview for better performance
          
          // Create a temporary canvas for the preview
          const tempCanvas = new fabric.Canvas(null, {
            width: originalWidth * scaleFactor,
            height: originalHeight * scaleFactor
          });
          
          // Clone all objects from the main canvas
          canvas.getObjects().forEach(obj => {
            const clonedObj = fabric.util.object.clone(obj);
            // Scale the object for the preview
            clonedObj.scaleX = obj.scaleX * scaleFactor;
            clonedObj.scaleY = obj.scaleY * scaleFactor;
            clonedObj.left = obj.left * scaleFactor;
            clonedObj.top = obj.top * scaleFactor;
            tempCanvas.add(clonedObj);
          });
          
          // Generate optimized SVG
          const svgData = tempCanvas.toSVG({
            viewBox: {
              x: 0,
              y: 0,
              width: originalWidth * scaleFactor,
              height: originalHeight * scaleFactor
            },
            width: originalWidth * scaleFactor,
            height: originalHeight * scaleFactor,
            suppressPreamble: false,
            preserveAspectRatio: 'xMidYMid meet'
          });
          
          // Clean up temporary canvas
          tempCanvas.dispose();
          
          // Optimize SVG by removing unnecessary attributes and whitespace
          const optimizedSvg = svgData
            .replace(/\s+/g, ' ') // Reduce whitespace
            .replace(/>\s+</g, '><') // Remove whitespace between tags
            .replace(/\s+>/g, '>') // Remove trailing whitespace in tags
            .replace(/\s+\/>/g, '/>') // Clean self-closing tags
            .replace(/<svg/, `<svg width="${originalWidth * scaleFactor}" height="${originalHeight * scaleFactor}"`);
          
          // Save the optimized preview
          storyStore.updateSlidePreview(index, optimizedSvg);
          
          console.log('Preview saved for slide:', index);
          resolve();
        } catch (err) {
          console.error('Error saving preview:', err);
          reject(err);
        }
      } else {
        const error = new Error('Invalid slide index, cannot save SVG preview');
        console.warn(error.message);
        reject(error);
      }
    });
  }

  /**
   * Add text to the canvas
   * @param {string} text - The text to add
   * @param {object} options - Additional options for the text object
   */
  export function addTextToCanvas(text, options) {
    console.log(`Adding text to canvas: ${text}`, options);
    const textObject = new fabric.IText(text, { ...options, selectable: true });
    canvas.add(textObject);
    canvas.setActiveObject(textObject);
    saveCanvasState();
  }

  /**
   * Add an image to the canvas from evidence object
   * @param {object} evidence - The evidence object containing image URLs
   */
  export function addImageToCanvas(imageData) {
    console.log('Adding image to canvas:', imageData);
    isImageLoading = true;
    
    // Determine if this is an evidence image by checking for required properties
    const isEvidenceImage = imageData.evidence && imageData.evidence.s3URL;
    const imageUrl = isEvidenceImage ? imageData.evidence.s3URL : imageData.url;
    
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        imageUrl, 
        function(img) {
          if (img) {
            // Get canvas dimensions
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;
            const maxHeight = canvasHeight * 0.7; // 70% of canvas height
            
            // Calculate aspect ratios
            const imageRatio = img.width / img.height;
            const maxWidthFromHeight = maxHeight * imageRatio;
            
            let finalWidth, finalHeight;
            
            // If width when constrained by height is less than canvas width
            if (maxWidthFromHeight <= canvasWidth) {
              // Use max height as constraint
              finalHeight = maxHeight;
              finalWidth = maxWidthFromHeight;
            } else {
              // Use canvas width as constraint
              finalWidth = canvasWidth;
              finalHeight = canvasWidth / imageRatio;
            }
            
            // Calculate scale factors
            const scaleX = finalWidth / img.width;
            const scaleY = finalHeight / img.height;
            
            // Calculate centered position
            const left = (canvasWidth - finalWidth) / 2;
            const top = (canvasHeight - finalHeight) / 2;
            
            // Set image properties
            img.set({
              left: left,
              top: top,
              scaleX: scaleX,
              scaleY: scaleY,
              selectable: true,
              metadata: {
                isEvidence: isEvidenceImage,
                evidenceData: isEvidenceImage ? imageData.evidence : null,
                source: isEvidenceImage ? 'evidence' : 'external'
              }
            });
            
            canvas.add(img);
            canvas.setActiveObject(img);
            canvas.renderAll();
            saveCanvasState();
            isImageLoading = false;
            resolve(img);
          } else {
            isImageLoading = false;
            reject(new Error('Failed to load image'));
          }
        },
        {
          crossOrigin: 'anonymous'
        }
      );
    });
  }

  /**
   * Delete the currently selected object from the canvas
   */
  export function deleteSelectedObject() {
    if (selectedObject) {
      canvas.remove(selectedObject); // Remove the selected object from the canvas
      selectedObject = null; // Clear the reference
      saveCanvasState(); // Save the updated canvas state
      dispatch('updateSubMenu', { selectedObject: null }); // Update the submenu
    }
  }

  /**
   * Get the current canvas instance
   * @returns {object} - The Fabric.js canvas instance
   */
  export function getCanvas() {
    return canvas;
  }

  /**
   * Add an external image to the canvas by URL
   * @param {string} imageUrl - The URL of the image to add
   */
  export async function addExternalImageToCanvas(imageUrl) {
    console.log(`Adding external image to canvas: ${imageUrl}`);
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        imageUrl,
        function(img) {
          if (img) {
            // Get canvas dimensions
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;
            const maxHeight = canvasHeight * 0.7; // 70% of canvas height
            
            // Calculate aspect ratios
            const imageRatio = img.width / img.height;
            const maxWidthFromHeight = maxHeight * imageRatio;
            
            let finalWidth, finalHeight;
            
            // If width when constrained by height is less than canvas width
            if (maxWidthFromHeight <= canvasWidth) {
              // Use max height as constraint
              finalHeight = maxHeight;
              finalWidth = maxWidthFromHeight;
            } else {
              // Use canvas width as constraint
              finalWidth = canvasWidth;
              finalHeight = canvasWidth / imageRatio;
            }
            
            // Calculate scale factors
            const scaleX = finalWidth / img.width;
            const scaleY = finalHeight / img.height;
            
            // Calculate centered position
            const left = (canvasWidth - finalWidth) / 2;
            const top = (canvasHeight - finalHeight) / 2;
            
            // Set image properties
            img.set({
              left: left,
              top: top,
              scaleX: scaleX,
              scaleY: scaleY,
              selectable: true
            });
            
            canvas.add(img);
            canvas.setActiveObject(img);
            canvas.renderAll();
            saveCanvasState();
            resolve(img);
          } else {
            reject(new Error('Failed to load image'));
          }
        },
        {
          crossOrigin: 'anonymous'
        }
      );
    });
  }

  export function bringForward() {
    if (selectedObject) {
      canvas.bringForward(selectedObject);
      saveCanvasState();
    }
  }

  export function sendBackward() {
    if (selectedObject) {
      canvas.sendBackward(selectedObject);
      saveCanvasState();
    }
  }

  export function bringToFront() {
    if (selectedObject) {
      canvas.bringToFront(selectedObject);
      saveCanvasState();
    }
  }

  export function sendToBack() {
    if (selectedObject) {
      canvas.sendToBack(selectedObject);
      saveCanvasState();
    }
  }

  export function toggleBold() {
    if (selectedObject && selectedObject.type.includes('text')) {
      selectedObject.set('fontWeight', selectedObject.fontWeight === 'bold' ? 'normal' : 'bold');
      canvas.renderAll();
      saveCanvasState();
    }
  }

  export function setTextColor(color) {
    if (selectedObject && selectedObject.type.includes('text')) {
      selectedObject.set('fill', color);
      canvas.renderAll();
      saveCanvasState();
    }
  }

  export function setFontSize(size) {
    if (selectedObject && selectedObject.type.includes('text')) {
      selectedObject.set('fontSize', parseInt(size));
      canvas.renderAll();
      saveCanvasState();
    }
  }

  export function setBorderWidth(width) {
    if (selectedObject && selectedObject.type === 'image') {
      selectedObject.set({
        strokeWidth: parseInt(width),
        stroke: width > 0 ? '#000000' : null // Add black border if width > 0
      });
      canvas.renderAll();
      saveCanvasState();
    }
  }

  export function cropImage() {
    if (!selectedObject || selectedObject.type !== 'image') return;
    
    const img = selectedObject;
    const imgBounds = img.getBoundingRect();
    const originalImage = img.getElement();
    const originalScale = {
      x: img.scaleX,
      y: img.scaleY,
      width: img.width,
      height: img.height,
      left: img.left,
      top: img.top
    };
    let isCropping = true;
    
    // Disable all canvas events and selection
    canvas.discardActiveObject();
    canvas.forEachObject(obj => {
      obj.selectable = false;
      obj.evented = false;
    });
    
    // Create overlay that covers entire canvas
    const overlay = new fabric.Rect({
      left: 0,
      top: 0,
      width: canvas.width,
      height: canvas.height,
      fill: 'rgba(0, 0, 0, 0.5)',
      selectable: false,
      evented: false,
      name: 'cropOverlay'
    });

    // Initialize crop rectangle at current image bounds
    const rect = new fabric.Rect({
      left: imgBounds.left,
      top: imgBounds.top,
      width: imgBounds.width,
      height: imgBounds.height,
      fill: 'transparent',
      stroke: '#ffffff',
      strokeWidth: 2,
      name: 'cropRect',
      cornerColor: '#ffffff',
      cornerStrokeColor: '#ffffff',
      transparentCorners: false,
      cornerSize: 14,
      cornerStyle: 'circle',
      lockRotation: true,
      hasRotatingPoint: false,
      selectable: true,
      evented: true,
      borderDashArray: [0, 0],
      borderScaleFactor: 2
    });

    // Add grid lines for rule of thirds
    const gridLines = [];
    const addGridLine = (coords) => {
      const line = new fabric.Line(coords, {
        stroke: 'rgba(255, 255, 255, 0.5)',
        strokeWidth: 1,
        selectable: false,
        evented: false,
        name: 'gridLine'
      });
      gridLines.push(line);
      canvas.add(line);
    };

    // Function to update grid lines
    function updateGridLines() {
      const bounds = rect.getBoundingRect();
      gridLines.forEach(line => canvas.remove(line));
      gridLines.length = 0;

      // Vertical lines
      for (let i = 1; i < 3; i++) {
        addGridLine([
          bounds.left + (bounds.width * i / 3), bounds.top,
          bounds.left + (bounds.width * i / 3), bounds.top + bounds.height
        ]);
      }
      // Horizontal lines
      for (let i = 1; i < 3; i++) {
        addGridLine([
          bounds.left, bounds.top + (bounds.height * i / 3),
          bounds.left + bounds.width, bounds.top + (bounds.height * i / 3)
        ]);
      }
    }

    // Create controls container with improved styling
    const controlsContainer = document.createElement('div');
    controlsContainer.className = 'crop-controls';
    controlsContainer.style.cssText = `
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      z-index: 9999;
    `;
    
    controlsContainer.innerHTML = `
      <button class="crop-btn cancel-crop" title="Cancel">
        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.75 6.75L17.25 17.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M17.25 6.75L6.75 17.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      </button>
      <button class="crop-btn apply-crop" title="Apply">
        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.5 12.75L9.5 17.75L19.5 6.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    `;

    // Add elements
    canvas.add(overlay);
    canvas.add(rect);
    canvas.setActiveObject(rect);
    updateGridLines();
    document.querySelector('.canvas-container2').appendChild(controlsContainer);

    // Update grid lines when crop rectangle changes
    rect.on('moving', updateGridLines);
    rect.on('scaling', updateGridLines);

    // Modify constraint handling to allow expanding
    function constrainCropRect(e) {
      if (e.target !== rect) return;
      
      const rectBounds = rect.getBoundingRect();
      const imgBounds = img.getBoundingRect();
      let modified = false;
      
      // Minimum size (20% of original)
      const minWidth = originalScale.width * 0.2;
      const minHeight = originalScale.height * 0.2;
      
      // Maximum size (original image dimensions)
      const maxWidth = originalScale.width;
      const maxHeight = originalScale.height;

      if (rectBounds.width < minWidth) {
        rect.scaleX = rect.scaleX * (minWidth / rectBounds.width);
        modified = true;
      }
      if (rectBounds.height < minHeight) {
        rect.scaleY = rect.scaleY * (minHeight / rectBounds.height);
        modified = true;
      }

      // Allow expanding up to original image bounds
      const originalBounds = {
        left: originalScale.left,
        top: originalScale.top,
        width: originalScale.width * originalScale.x,
        height: originalScale.height * originalScale.y
      };

      const newLeft = Math.max(originalBounds.left, Math.min(rectBounds.left, originalBounds.left + originalBounds.width - rectBounds.width));
      const newTop = Math.max(originalBounds.top, Math.min(rectBounds.top, originalBounds.top + originalBounds.height - rectBounds.height));
      
      if (newLeft !== rectBounds.left) {
        rect.left = newLeft;
        modified = true;
      }
      if (newTop !== rectBounds.top) {
        rect.top = newTop;
        modified = true;
      }

      if (modified) {
        rect.setCoords();
        updateGridLines();
      }
    }

    // Handle clicking outside crop area
    canvas.on('mouse:down', function(e) {
      if (isCropping && (!e.target || (e.target !== rect && e.target !== overlay))) {
        cancelCrop();
      }
    });

    // Button handlers
    const applyButton = controlsContainer.querySelector('.apply-crop');
    const cancelButton = controlsContainer.querySelector('.cancel-crop');

    applyButton.addEventListener('click', () => {
      applyCrop();
      cleanup();
    });

    cancelButton.addEventListener('click', cancelCrop);

    // Modify the applyCrop function to handle expanded crops
    function applyCrop() {
      const scaleX = originalScale.width / (originalScale.width * originalScale.x);
      const scaleY = originalScale.height / (originalScale.height * originalScale.y);

      // Calculate crop dimensions relative to original image
      const cropX = Math.round((rect.left - originalScale.left) * scaleX);
      const cropY = Math.round((rect.top - originalScale.top) * scaleY);
      const cropWidth = Math.round(rect.width * rect.scaleX * scaleX);
      const cropHeight = Math.round(rect.height * rect.scaleY * scaleY);

      // Create temporary canvas
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      tempCanvas.width = cropWidth;
      tempCanvas.height = cropHeight;

      // Draw the cropped portion from original image
      tempCtx.drawImage(
        originalImage,
        cropX, cropY, cropWidth, cropHeight,
        0, 0, cropWidth, cropHeight
      );

      // Create new image from cropped canvas
      const cropped = new Image();
      cropped.src = tempCanvas.toDataURL('image/png');

      cropped.onload = function() {
        const croppedImg = new fabric.Image(cropped, {
          left: rect.left,
          top: rect.top,
          scaleX: img.scaleX,
          scaleY: img.scaleY,
          selectable: true,
          evented: true,
          metadata: img.metadata // Preserve metadata
        });
        
        // Store original image data in metadata for future crops
        croppedImg.metadata = {
          ...croppedImg.metadata,
          originalImage: {
            element: originalImage,
            width: originalScale.width,
            height: originalScale.height,
            scaleX: originalScale.x,
            scaleY: originalScale.y,
            left: originalScale.left,
            top: originalScale.top
          }
        };

        canvas.remove(img);
        canvas.add(croppedImg);
        canvas.setActiveObject(croppedImg);
        saveCanvasState();
      };
    }

    function cancelCrop() {
      if (!isCropping) return;
      isCropping = false;
      cleanup();
    }

    function cleanup() {
      canvas.off('mouse:down');
      canvas.off('object:moving', constrainCropRect);
      canvas.off('object:scaling', constrainCropRect);
      canvas.remove(overlay);
      canvas.remove(rect);
      controlsContainer.remove();
      
      canvas.forEachObject(obj => {
        obj.selectable = true;
        obj.evented = true;
      });
      
      canvas.setActiveObject(img);
      canvas.renderAll();
    }

    // Add double-click handler to crop rectangle
    rect.on('mousedblclick', function() {
      applyCrop();
      cleanup();
    });
  }

  // Handle keyboard shortcuts
  function handleKeyDown(event) {
    // Don't handle shortcuts if user is typing in an input/textarea
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
      return;
    }

    // Check for modifier keys
    const isCtrlOrCmd = event.ctrlKey || event.metaKey;
    const isShift = event.shiftKey;
    const isAlt = event.altKey;

    // Handle different keyboard shortcuts
    switch (event.key.toLowerCase()) {
      case 'delete':
      case 'backspace':
        if (selectedObject) {
          deleteSelectedObject();
        }
        break;

      case 'c':
        if (isCtrlOrCmd) {
          event.preventDefault();
          copySelectedObject();
        }
        break;

      case 'v':
        if (isCtrlOrCmd) {
          event.preventDefault();
          pasteObject();
        }
        break;

      case 'x':
        if (isCtrlOrCmd) {
          event.preventDefault();
          cutSelectedObject();
        }
        break;

      case 'z':
        if (isCtrlOrCmd) {
          event.preventDefault();
          if (isShift) {
            // Redo (Ctrl+Shift+Z or Cmd+Shift+Z)
            redoCanvasState();
          } else {
            // Undo (Ctrl+Z or Cmd+Z)
            undoCanvasState();
          }
        }
        break;

      case 'y':
        if (isCtrlOrCmd) {
          event.preventDefault();
          // Redo (Ctrl+Y or Cmd+Y)
          redoCanvasState();
        }
        break;

      case 'a':
        if (isCtrlOrCmd) {
          event.preventDefault();
          selectAllObjects();
        }
        break;

      case 'escape':
        // Deselect all objects
        canvas.discardActiveObject();
        break;

      case 'arrowleft':
        if (selectedObject) {
          event.preventDefault();
          moveSelectedObject(-1, 0);
        }
        break;

      case 'arrowright':
        if (selectedObject) {
          event.preventDefault();
          moveSelectedObject(1, 0);
        }
        break;

      case 'arrowup':
        if (selectedObject) {
          event.preventDefault();
          moveSelectedObject(0, -1);
        }
        break;

      case 'arrowdown':
        if (selectedObject) {
          event.preventDefault();
          moveSelectedObject(0, 1);
        }
        break;

      case '=':
        if (isCtrlOrCmd) {
          event.preventDefault();
          zoomIn();
        }
        break;

      case '-':
        if (isCtrlOrCmd) {
          event.preventDefault();
          zoomOut();
        }
        break;

      case '0':
        if (isCtrlOrCmd) {
          event.preventDefault();
          resetZoom();
        }
        break;
    }
  }

  // Helper functions for keyboard shortcuts
  function copySelectedObject() {
    if (selectedObject) {
      clipboard = selectedObject.toJSON();
      console.log('Object copied to clipboard');
    }
  }

  function pasteObject() {
    if (clipboard) {
      fabric.util.enlivenObjects([clipboard], (enlivedObjects) => {
        const obj = enlivedObjects[0];
        // Offset the pasted object slightly
        obj.set({
          left: obj.left + 20,
          top: obj.top + 20
        });
        canvas.add(obj);
        canvas.setActiveObject(obj);
        saveCanvasState();
      });
    }
  }

  function cutSelectedObject() {
    if (selectedObject) {
      copySelectedObject();
      deleteSelectedObject();
    }
  }

  function selectAllObjects() {
    const objects = canvas.getObjects();
    if (objects.length > 0) {
      const selection = new fabric.ActiveSelection(objects, { canvas: canvas });
      canvas.setActiveSelection(selection);
      canvas.requestRenderAll();
    }
  }

  function moveSelectedObject(deltaX, deltaY) {
    if (selectedObject) {
      selectedObject.set({
        left: selectedObject.left + deltaX,
        top: selectedObject.top + deltaY
      });
      canvas.renderAll();
      saveCanvasState();
    }
  }

  function zoomIn() {
    const zoom = canvas.getZoom();
    canvas.setZoom(zoom * 1.1);
    canvas.renderAll();
  }

  function zoomOut() {
    const zoom = canvas.getZoom();
    canvas.setZoom(zoom / 1.1);
    canvas.renderAll();
  }

  function resetZoom() {
    canvas.setZoom(1);
    canvas.renderAll();
  }

  function redoCanvasState() {
    // TODO: Implement redo functionality
    console.log('Redo not implemented yet');
  }

  export function generatePreviewForSlide(canvasState) {
    return new Promise((resolve, reject) => {
      try {
        // Create a temporary canvas
        const tempCanvas = new fabric.Canvas(null, {
          width: 375,
          height: 667
        });
        
        // Load the canvas state
        if (canvasState && canvasState.length > 0) {
          tempCanvas.loadFromJSON({ objects: canvasState }, () => {
            const scaleFactor = 0.5;
            
            // Create another temp canvas for the scaled version
            const previewCanvas = new fabric.Canvas(null, {
              width: 375 * scaleFactor,
              height: 667 * scaleFactor
            });
            
            // Clone and scale all objects
            tempCanvas.getObjects().forEach(obj => {
              const clonedObj = fabric.util.object.clone(obj);
              clonedObj.scaleX = obj.scaleX * scaleFactor;
              clonedObj.scaleY = obj.scaleY * scaleFactor;
              clonedObj.left = obj.left * scaleFactor;
              clonedObj.top = obj.top * scaleFactor;
              previewCanvas.add(clonedObj);
            });
            
            // Generate optimized SVG
            const svgData = previewCanvas.toSVG({
              viewBox: {
                x: 0,
                y: 0,
                width: 375 * scaleFactor,
                height: 667 * scaleFactor
              },
              width: 375 * scaleFactor,
              height: 667 * scaleFactor,
              suppressPreamble: false,
              preserveAspectRatio: 'xMidYMid meet'
            });
            
            // Clean up
            tempCanvas.dispose();
            previewCanvas.dispose();
            
            // Optimize SVG
            const optimizedSvg = svgData
              .replace(/\s+/g, ' ')
              .replace(/>\s+</g, '><')
              .replace(/\s+>/g, '>')
              .replace(/\s+\/>/g, '/>')
              .replace(/<svg/, `<svg width="${375 * scaleFactor}" height="${667 * scaleFactor}"`);
            
            resolve(optimizedSvg);
          });
        } else {
          // If no canvas state, return empty string
          resolve('');
        }
      } catch (err) {
        console.error('Error generating preview:', err);
        reject(err);
      }
    });
  }
</script>

<div class="canvas-container2">
  {#if isImageLoading}
    <div class="loading-overlay">
      <div class="spinner"></div>
    </div>
  {/if}
  <canvas id="story-canvas"></canvas>
</div>

<style>
  .canvas-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }

  #story-canvas {
    border: 1px solid #ccc;
    width: 375px;
    height: 667px;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .canvas-container2 {
    position: relative;
  }

  .canvas-container2 .crop-controls {
    position: absolute;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(12px);
    padding: 8px;
    border-radius: 12px;
    display: flex;
    gap: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .canvas-container2 .crop-controls .crop-btn {
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    background: transparent;
    color: white;
    padding: 0;
  }

  .canvas-container2 .crop-controls .crop-btn svg {
    width: 18px;
    height: 18px;
    opacity: 0.9;
    transition: all 0.2s ease;
  }

  .canvas-container2 .crop-controls .cancel-crop:hover {
    background: rgba(239, 68, 68, 0.15);
    color: rgb(239, 68, 68);
  }

  .canvas-container2 .crop-controls .apply-crop:hover {
    background: rgba(34, 197, 94, 0.15);
    color: rgb(34, 197, 94);
  }

  .canvas-container2 .crop-controls .crop-btn:hover svg {
    opacity: 1;
    transform: scale(1.1);
  }

  /* Update tooltip styles */
  .canvas-container2 .crop-controls .crop-btn:hover::after {
    content: attr(title);
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.85);
    color: white;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 11px;
    font-weight: 500;
    white-space: nowrap;
    letter-spacing: 0.3px;
  }

  /* Remove previous animation styles */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-4px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .canvas-container2 .crop-controls {
    animation: fadeIn 0.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
</style>
