<script>
    import { onMount } from 'svelte';
    import { storyStore } from '../stores/storyStore.js';
    import { get } from 'svelte/store';
    import { shareStory } from '../services/exportService.js';

    export let isOpen = false;
    export let onClose;

    let slides = [];
    let renderedSlides = [];
    let currentSlideIndex = 0;
    let hasContent = false;
    let slideTitle = '';
    let jsPDF;

    onMount(async () => {
        jsPDF = window.jspdf.jsPDF;

        const currentStory = get(storyStore);
        slides = currentStory.slides;

        // Check if any slide has content
        hasContent = slides.some(slide => slide && ((slide.canvasState && slide.canvasState.length > 0) || slide.script));

        // Render each slide and store the data URL if there is content
        if (hasContent) {
            renderedSlides = await Promise.all(slides.map(async (slide) => {
                if (slide) {
                    const dataUrl = await renderCanvas(slide);
                    return { ...slide, dataUrl };
                }
                return slide;
            }));
        }

        // Set the initial slide title
        updateSlideTitle();
    });

    function closeModal() {
        if (onClose) {
            onClose();
        }
    }

    function nextSlide() {
        if (currentSlideIndex < renderedSlides.length - 1) {
            currentSlideIndex++;
            updateSlideTitle();
        }
    }

    function prevSlide() {
        if (currentSlideIndex > 0) {
            currentSlideIndex--;
            updateSlideTitle();
        }
    }

    function updateSlideTitle() {
        if (slides[currentSlideIndex]) {
            slideTitle = slides[currentSlideIndex].title || `Slide ${currentSlideIndex + 1}`;
        }
    }

    async function renderCanvas(slide) {
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = 375;
        tempCanvas.height = 667;
        const fabricCanvas = new window.fabric.Canvas(tempCanvas);

        if (slide.canvasState && slide.canvasState.length > 0) {
            await new Promise((resolve) => {
                fabricCanvas.loadFromJSON(
                    { version: '5.2.4', objects: slide.canvasState },
                    () => {
                        fabricCanvas.renderAll();
                        resolve();
                    }
                );
            });
        }

        return tempCanvas.toDataURL('image/png');
    }

    async function handleDownloadPDF() {
        if (jsPDF) {
            await shareStory(jsPDF); // Use the existing shareStory logic
        } else {
            console.error('jsPDF is not loaded');
        }
    }
</script>

{#if isOpen}
    <div class="modal-overlay" on:click={closeModal}>
        <div class="modal-content" on:click|stopPropagation>
            <button class="close-button" on:click={closeModal}>×</button>
            <h2>PDF Preview</h2>
            {#if hasContent}
                <div class="slide-header">
                    <button class="nav-arrow" on:click={prevSlide} disabled={currentSlideIndex === 0}>&larr;</button>
                    <h3 class="title-display">{slideTitle}</h3>
                    <button class="nav-arrow" on:click={nextSlide} disabled={currentSlideIndex === renderedSlides.length - 1}>&rarr;</button>
                </div>
                {#if renderedSlides.length > 0}
                    <div class="slide-container">
                        <div class="slide-image">
                            {#if renderedSlides[currentSlideIndex]}
                                <img src={renderedSlides[currentSlideIndex].dataUrl} alt="Slide Image" />
                            {/if}
                        </div>
                        <div class="slide-script">
                            {#if renderedSlides[currentSlideIndex] && renderedSlides[currentSlideIndex].script}
                                <p>{renderedSlides[currentSlideIndex].script}</p>
                            {/if}
                        </div>
                    </div>
                {/if}
                <div class="button-container">
                    <button class="download-button" on:click={handleDownloadPDF}>
                        Download PDF
                    </button>
                </div>
            {:else}
                <p>Please add content to your canvas or script before exporting a PDF.</p>
            {/if}
        </div>
    </div>
{/if}

<style>

.modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2000;
    }

    .modal-content {
        background: white;
        padding: 20px;
        border-radius: 8px;
        width: 80%;
        max-width: 1000px;
        height: 90vh;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow-y: auto; /* Allows scrolling if content is too long */
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .slide-navigation {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .slide-navigation button {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .slide-container {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        overflow-y: auto;
    }

    .slide-image {
        flex: 1;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #ccc; /* Add vertical line */
        padding-right: 20px; /* Add spacing between the line and content */
    }

    .slide-image img {
        max-width: 56%;
        height: auto;
        display: block;
    }

    .slide-script {
        flex: 1;
        max-height: 300px;
        overflow-y: auto;
        display: flex;
        align-items: flex-start;
        padding-left: 20px; /* Add spacing between the line and script */
    }

    .slide-script p {
        margin: 0;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: auto; /* Pushes it to the bottom */
        padding-top: 10px;
        padding-bottom: 10px; /* Ensures spacing at bottom */
        background: white; /* Ensures visibility if modal scrolls */
    }

    .generate-button {
        background: #007bff;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 8px;
        cursor: pointer;
    }

    .volume-button {
        background: none;
        border: none;
        cursor: pointer;
        margin-right: 10px;
    }

    .volume-button img {
        width: 24px;
        height: 24px;
    }

    .slide-title {
        margin-bottom: 20px;
        text-align: center;
    }

    .title-input {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 5px;
        width: 80%;
    }

    .edit-icon {
        font-size: 1rem;
        color: #888;
    }

    .edit-icon:hover {
        color: #555;
    }

    .slide-header {
        display: flex;
        align-items: center;
        justify-content: center; /* Center the title and arrows */
        gap: 10px; /* Add spacing between the arrows and the title */
        margin-bottom: 20px;
    }

    .nav-arrow {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        padding: 0 5px; /* Reduce padding to bring arrows closer to the title */
    }

    .nav-arrow:disabled {
        color: #ccc;
        cursor: not-allowed;
    }

    .title-display {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
    }
    .download-button {
        background: #007bff;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 8px;
        cursor: pointer;
    }

    .download-button:hover {
        background: #0056b3;
    }
</style>