import { writable } from 'svelte/store';

function createSelectionStore() {
    const { subscribe, set, update } = writable({
        isSelecting: false,
        currentSelection: null,
        selectedEvidence: null
    });

    return {
        subscribe,
        startSelection: (evidence) => update(state => ({
            ...state,
            isSelecting: true,
            selectedEvidence: evidence,
            currentSelection: null
        })),
        setSelection: (selection) => update(state => ({
            ...state,
            currentSelection: selection
        })),
        clearSelection: () => update(state => ({
            ...state,
            isSelecting: false,
            currentSelection: null
        })),
        reset: () => set({
            isSelecting: false,
            currentSelection: null,
            selectedEvidence: null
        })
    };
}

export const selectionStore = createSelectionStore(); 