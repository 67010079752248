<script>
    import { onMount, onDestroy } from 'svelte';
    import { fade } from 'svelte/transition';
    import { initGoogleSlidesAPI, exportToGoogleSlides } from '../services/slidesService.js';
    import { get } from 'svelte/store';
    import { storyStore } from '../stores/storyStore.js';
    import { createGoogleSlides } from '../services/slidesService';
    
    export let isOpen = false;
    export let onClose = () => {};
    export let data = null;
    
    let isLoading = false;
    let isExporting = false;
    let error = null;
    let googleAPIReady = false;
    let exportResult = null;
    
    // State flags for the UI
    let showPermissionStep = false;
    let showExportStep = false;
    let showSuccessStep = false;
    
    onMount(async () => {
        try {
            // Initialize Google API
            await initGoogleSlidesAPI();
        } catch (err) {
            console.error('Error initializing Google API:', err);
            error = err;
        } finally {
            // Always show the permission step so users can retry authentication
            showPermissionStep = true;
        }

        // Listen for authentication success message
        window.addEventListener('message', (event) => {
            if (event.data === 'authentication-successful') {
                handleAuthSuccess();
            }
        });
    });
    
    function handleAuthSuccess() {
        showPermissionStep = false;
        createSlides();
    }
    
    async function createSlides() {
        if (!data) {
            error = new Error('No data provided for slides creation');
            return;
        }

        isLoading = true;
        error = null;

        try {
            await createGoogleSlides(data);
            onClose();
        } catch (err) {
            console.error('Error creating slides:', err);
            error = err;
        } finally {
            isLoading = false;
        }
    }
    
    // Function to dynamically load the Google API script
    async function loadGoogleAPIScript() {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = 'https://apis.google.com/js/api.js';
            script.async = true;
            script.defer = true;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
        });
    }
    
    // Function to start the export process
    async function handleExport() {
        showPermissionStep = false;
        showExportStep = true;
        isExporting = true;
        error = null;
        
        try {
            // Show detailed status updates
            const currentStory = get(storyStore);
            const slideCount = currentStory.slides?.length || 0;
            console.log(`Starting export of ${slideCount} slides to Google Slides...`);
            
            exportResult = await exportToGoogleSlides();
            
            if (exportResult.success) {
                console.log('Export successful:', exportResult.presentationUrl);
                showExportStep = false;
                showSuccessStep = true;
            } else {
                console.error('Export failed:', exportResult.error);
                
                // Format the error for display - ensure it's a string
                if (exportResult.error === null || exportResult.error === undefined) {
                    error = 'Export failed for an unknown reason.';
                } else if (typeof exportResult.error === 'object') {
                    // Handle error object
                    error = exportResult.error.message || 'Export failed for an unknown reason.';
                } else {
                    // Convert to string explicitly to be safe
                    error = String(exportResult.error);
                }
                
                // Handle specific error types with more helpful messages
                if (error.includes('too large') || error.includes('size') || error.includes('entity')) {
                    error = 'The presentation data is too large. Try reducing the number of slides or simplifying your content.';
                } else if (error.includes('quota')) {
                    error = 'Google API quota exceeded. Please try again later.';
                }
                
                // Return to permission step if there was an auth error
                if (error.includes('auth') || error.includes('permission') || error.includes('sign') || 
                    error.includes('token') || error.includes('expired')) {
                    showPermissionStep = true;
                    showExportStep = false;
                }
            }
        } catch (err) {
            // Ensure error is a string
            let errorMessage = 'Unknown error occurred during export';
            
            if (err) {
                if (typeof err === 'string') {
                    errorMessage = err;
                } else if (err.message) {
                    errorMessage = err.message;
                } else {
                    try {
                        errorMessage = String(err);
                    } catch (e) {
                        // If all else fails
                        errorMessage = 'Failed to export (could not format error message)';
                    }
                }
            }
            
            console.error('Error during export:', errorMessage, err);
            error = `Export failed: ${errorMessage}`;
            
            // Check for payload size errors
            if (errorMessage.includes('too large') || errorMessage.includes('entity') || 
                errorMessage.includes('payload') || errorMessage.includes('size')) {
                error = 'The presentation data is too large. Try reducing the number of slides or simplifying your content.';
            }
            
            // Return to permission step if there was an auth error
            if (errorMessage.includes('auth') || errorMessage.includes('permission') || 
                errorMessage.includes('sign') || errorMessage.includes('token') || 
                errorMessage.includes('expired')) {
                showPermissionStep = true;
                showExportStep = false;
            }
        } finally {
            isExporting = false;
        }
    }
    
    // Function to open the exported slides in a new tab
    function openExportedSlides() {
        if (exportResult?.presentationUrl) {
            window.open(exportResult.presentationUrl, '_blank');
        }
    }
    
    // Reset the modal state when it's closed
    function handleClose() {
        isLoading = false;
        isExporting = false;
        error = null;
        exportResult = null;
        showPermissionStep = true;
        showExportStep = false;
        showSuccessStep = false;
        onClose();
    }
    
    // Add alternative implementation
    async function handleDirectAuth() {
        try {
            isLoading = true;
            error = null;
            
            // Use our backend auth route directly
            const width = 600;
            const height = 600;
            const left = window.innerWidth / 2 - width / 2;
            const top = window.innerHeight / 2 - height / 2;
            
            // Open the auth window with the drive permissions endpoint
            console.log('Opening Google Drive auth popup...');
            const popup = window.open(
                '/auth/google/drive',
                'googleDriveAuth',
                `width=${width},height=${height},left=${left},top=${top},popup=1`
            );
            
            if (!popup || popup.closed || typeof popup.closed === 'undefined') {
                throw new Error('Popup was blocked! Please allow popups for this site and try again.');
            }
            
            // Wait for the popup to complete
            const authResult = await new Promise((resolve, reject) => {
                // Add a timeout for popup auth
                const authTimeout = setTimeout(() => {
                    window.removeEventListener('message', messageHandler);
                    reject(new Error('Authentication timed out. Please try again.'));
                }, 60000);
                
                // Listen for message from popup
                const messageHandler = (event) => {
                    if (event.data === 'authentication-successful') {
                        clearTimeout(authTimeout);
                        window.removeEventListener('message', messageHandler);
                        resolve(true);
                    }
                };
                
                window.addEventListener('message', messageHandler);
                
                // Also check for popup being closed
                const checkClosed = setInterval(() => {
                    if (popup.closed) {
                        clearInterval(checkClosed);
                        clearTimeout(authTimeout);
                        window.removeEventListener('message', messageHandler);
                        reject(new Error('Authentication window was closed before completion. Please try again.'));
                    }
                }, 1000);
            });
            
            if (authResult) {
                // If authentication was successful, proceed with export
                handleExport();
            }
        } catch (err) {
            console.error('Auth error:', err);
            error = err.message || 'Authentication failed. Please try again.';
            isLoading = false;
        }
    }
</script>

{#if isOpen}
    <div class="modal-backdrop" on:click={handleClose}>
        <div class="modal-content" on:click|stopPropagation>
            <div class="modal-header">
                <h2>Export to Google Slides</h2>
                <button class="close-btn" on:click={handleClose}>×</button>
            </div>
            
            {#if showPermissionStep}
                <div class="modal-body" transition:fade>
                    <div class="permission-step">
                        <h3>Connect to Google Slides</h3>
                        <p>To export your data to Google Slides, please sign in with your Google account.</p>
                        
                        <!-- Add Google Sign-In button container -->
                        <div class="google-signin-container">
                            <button 
                                class="google-signin-button" 
                                on:click={handleDirectAuth}
                            >
                                <img 
                                    src="https://developers.google.com/identity/images/g-logo.png" 
                                    alt="Google logo"
                                />
                                <span>Continue with Google</span>
                            </button>
                        </div>
                        
                        {#if error}
                            <div class="error-message">
                                <i class="bi bi-exclamation-triangle"></i>
                                <span>{error.message || error}</span>
                                
                                {#if typeof error === 'string' && (error.includes('too large') || error.includes('size'))}
                                    <div class="error-help">
                                        <p>Try the following:</p>
                                        <ul>
                                            <li>Reduce the number of slides in your presentation</li>
                                            <li>Simplify complex canvas elements</li>
                                            <li>Remove unnecessary images or reduce their size</li>
                                        </ul>
                                    </div>
                                {:else if error.message && (error.message.includes('too large') || error.message.includes('size'))}
                                    <div class="error-help">
                                        <p>Try the following:</p>
                                        <ul>
                                            <li>Reduce the number of slides in your presentation</li>
                                            <li>Simplify complex canvas elements</li>
                                            <li>Remove unnecessary images or reduce their size</li>
                                        </ul>
                                    </div>
                                {/if}
                                
                                {#if (typeof error === 'string' && (error.includes('auth') || error.includes('permission') || error.includes('sign') || error.includes('token') || error.includes('expired'))) || (error.message && (error.message.includes('auth') || error.message.includes('permission') || error.message.includes('sign') || error.message.includes('token') || error.message.includes('expired')))}
                                    <button class="retry-btn" on:click={handleDirectAuth}>
                                        <i class="bi bi-arrow-clockwise"></i>
                                        Retry Authentication
                                    </button>
                                {/if}
                            </div>
                        {/if}
                    </div>
                </div>
            {/if}
            
            {#if showExportStep}
                <div class="modal-body" transition:fade>
                    <div class="export-step">
                        <div class="loading-spinner"></div>
                        <p>Creating your Google Slides presentation...</p>
                        <div class="export-steps">
                            <div class="step">
                                <div class="step-icon">
                                    <i class="bi bi-check-circle"></i>
                                </div>
                                <div class="step-text">
                                    <p>Preparing slides data</p>
                                </div>
                            </div>
                            <div class="step {isExporting ? 'active' : ''}">
                                <div class="step-icon">
                                    <i class="bi bi-arrow-repeat"></i>
                                </div>
                                <div class="step-text">
                                    <p>Creating presentation</p>
                                </div>
                            </div>
                        </div>
                        {#if error}
                            <div class="error-message">
                                <i class="bi bi-exclamation-triangle"></i>
                                <span>{error}</span>
                                
                                <!-- Content-specific help based on error message -->
                                {#if typeof error === 'string'}
                                    {#if error.includes('too large') || error.includes('size')}
                                        <div class="error-help">
                                            <p>Try the following:</p>
                                            <ul>
                                                <li>Reduce the number of slides in your presentation</li>
                                                <li>Simplify complex canvas elements</li>
                                                <li>Remove unnecessary images or reduce their size</li>
                                            </ul>
                                        </div>
                                    {/if}
                                    
                                    {#if error.includes('auth') || error.includes('permission') || error.includes('sign') || error.includes('token') || error.includes('expired')}
                                        <button class="retry-btn" on:click={handleDirectAuth}>
                                            <i class="bi bi-arrow-clockwise"></i>
                                            Retry Authentication
                                        </button>
                                    {/if}
                                {/if}
                            </div>
                        {/if}
                    </div>
                </div>
            {/if}
            
            {#if showSuccessStep}
                <div class="modal-body" transition:fade>
                    <div class="success-step">
                        <i class="bi bi-check-circle success-icon"></i>
                        <h3>Export Successful!</h3>
                        <p>Your presentation has been created in Google Slides.</p>
                        <button class="primary-btn" on:click={openExportedSlides}>
                            Open in Google Slides
                        </button>
                    </div>
                </div>
            {/if}
        </div>
    </div>
{/if}

<style>
    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    
    .modal-content {
        background: white;
        border-radius: 8px;
        width: 90%;
        max-width: 500px;
        max-height: 90vh;
        overflow-y: auto;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
    
    .modal-header {
        padding: 1rem;
        border-bottom: 1px solid #e5e7eb;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .modal-header h2 {
        margin: 0;
        font-size: 1.25rem;
        color: #111827;
    }
    
    .close-btn {
        background: none;
        border: none;
        font-size: 1.5rem;
        color: #6b7280;
        cursor: pointer;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        transition: all 0.2s;
    }
    
    .close-btn:hover {
        background: #f3f4f6;
        color: #111827;
    }
    
    .modal-body {
        padding: 2rem;
    }
    
    .permission-step, .export-step, .success-step {
        text-align: center;
    }
    
    .permission-step h3, .success-step h3 {
        margin: 0 0 1rem 0;
        font-size: 1.5rem;
        color: #111827;
    }
    
    .permission-step p {
        color: #6b7280;
        margin-bottom: 2rem;
    }
    
    /* Google Sign-In button styles */
    .google-signin-container {
        display: flex;
        justify-content: center;
        margin: 1.5rem 0;
    }
    
    .google-signin-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        background: white;
        border: 1px solid #dadce0;
        border-radius: 4px;
        padding: 8px 16px;
        font-family: 'Google Sans', Roboto, Arial, sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #3c4043;
        cursor: pointer;
        transition: all 0.2s;
        height: 40px;
        min-width: 220px;
    }
    
    .google-signin-button:hover {
        background: #f8f9fa;
        border-color: #dadce0;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }
    
    .google-signin-button img {
        width: 18px;
        height: 18px;
    }
    
    .error-message {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #dc2626;
        background: #fee2e2;
        padding: 0.75rem;
        border-radius: 4px;
        margin-top: 1rem;
        font-size: 0.875rem;
    }
    
    .loading-spinner {
        border: 3px solid #f3f3f3;
        border-radius: 50%;
        border-top: 3px solid #3b82f6;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
        margin: 0 auto 1rem auto;
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    
    .success-icon {
        font-size: 3rem;
        color: #10b981;
        margin-bottom: 1rem;
    }
    
    .primary-btn {
        background: #2563eb;
        color: white;
        border: none;
        padding: 0.75rem 1.5rem;
        border-radius: 4px;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.2s;
    }
    
    .primary-btn:hover {
        background: #1d4ed8;
    }
    
    .export-steps {
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    .step {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    
    .step-icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .step.active .step-icon i {
        animation: spin 1s linear infinite;
    }
    
    @keyframes spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
    }
    
    .error-help {
        margin-top: 10px;
        padding: 10px;
        background-color: rgba(255, 0, 0, 0.05);
        border-radius: 4px;
    }
    
    .error-help p {
        font-weight: bold;
        margin-bottom: 5px;
    }
    
    .error-help ul {
        margin-left: 20px;
    }
    
    .retry-btn {
        display: inline-flex;
        align-items: center;
        gap: 5px;
        margin-top: 10px;
        padding: 8px 12px;
        background-color: #f1f1f1;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
    }
    
    .retry-btn:hover {
        background-color: #e1e1e1;
    }
    
    .retry-btn i {
        font-size: 16px;
    }
</style> 