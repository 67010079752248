<script>
    import { evidenceStore as evidenceStoreObj, saveEvidence, removeSavedEvidence, searchEvidence } from "../stores/evidenceStore";
    import { promptStore } from "../stores/promptStore";
    import { navigate } from "svelte-routing";
    import { onMount, onDestroy, createEventDispatcher, getContext } from 'svelte';
    import { get } from 'svelte/store';
    import { storyStore } from '../stores/storyStore.js';
    import StoryMenuEvidenceDetail from './StoryMenu-EvidenceDetail.svelte';
    import EvidenceModal from './EvidenceModal.svelte';
    import { fade } from 'svelte/transition';
    import { modeStore } from "../lib/stores/modeStore";

    let searchQuery = "";
    let currentPage = 1;
    const itemsPerPage = 10;
    let selectedEvidenceIds = new Set();
    let isLoading = false;

    const dispatch = createEventDispatcher();
    const selectedSlideIndex = getContext('selectedSlideIndex');
    let evidence = [];
    let savedEvidence = [];

    const bigQuestion = getContext('bigQuestion');
    let previousQuestion = $bigQuestion;
    let currentTopic = null;
    let previousTopic = null;

    let showModal = false;
    let selectedEvidence = null;

    let loadingProgress = {
        total: 0,
        loaded: 0,
        isLoading: false
    };

    // Subscribe to both evidence arrays and promptStore
    const unsubscribeEvidence = evidenceStoreObj.subscribe(store => {
        console.log('Evidence store updated:', {
            evidenceCount: store.evidence?.length || 0,
            savedCount: store.savedEvidence?.length || 0,
            loadingProgress: store.loadingProgress
        });
        evidence = store.evidence || [];
        savedEvidence = store.savedEvidence || [];
        loadingProgress = store.loadingProgress;
    });

    // Subscribe to promptStore to get topic changes
    const unsubscribePrompt = promptStore.subscribe(store => {
        const newTopic = store.selectedPromptDetails?.topic;
        if (newTopic !== currentTopic) {
            previousTopic = currentTopic;
            currentTopic = newTopic;
            
            // Update to use searchEvidence instead
            if (previousTopic !== null && currentTopic) {
                isLoading = true;
                searchEvidence(currentTopic)
                    .catch(error => {
                        console.error("Error searching evidence:", error);
                    })
                    .finally(() => {
                        isLoading = false;
                    });
            }
        }
    });

    onDestroy(() => {
        unsubscribeEvidence();
        unsubscribePrompt();
    });

    onMount(() => {
        // Only search on initial mount if we have a topic and no evidence
        const currentStore = get(promptStore);
        const currentEvidence = get(evidenceStoreObj);
        
        if (currentStore.selectedPromptDetails?.topic && 
            (!currentEvidence.evidence || currentEvidence.evidence.length === 0)) {
            currentTopic = currentStore.selectedPromptDetails.topic;
            isLoading = true;
            searchEvidence(currentTopic)
                .catch(error => {
                    console.error("Error searching evidence:", error);
                })
                .finally(() => {
                    isLoading = false;
                });
        }
    });

    // Watch for changes to bigQuestion
    $: if ($bigQuestion !== previousQuestion && currentTopic) {
        console.log('Big question changed - searching new evidence');
        previousQuestion = $bigQuestion;
        isLoading = true;
        searchEvidence(currentTopic)
            .catch(error => {
                console.error("Error searching evidence:", error);
            })
            .finally(() => {
                isLoading = false;
            });
    }

    $: filteredEvidenceItems = evidence.filter(
      (evidence) =>
        evidence.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

    $: paginatedEvidenceItems = filteredEvidenceItems.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    // Add this new reactive statement for filtering saved evidence
    $: filteredSavedEvidence = savedEvidence.filter(
        (evidence) =>
            evidence.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            evidence.label?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Update pagination for saved evidence
    $: paginatedSavedEvidence = filteredSavedEvidence.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // Update total pages calculation
    $: totalPages = Math.ceil(filteredSavedEvidence.length / itemsPerPage);

    function handleEvidenceClick(evidence, event) {
        if (event) {
            event.stopPropagation(); // Only call if event exists
        }
        selectedEvidence = evidence;
        showModal = true;
    }

    function handleAnalyzeClick(evidence, event) {
        event.stopPropagation();
        console.log('Evidence being passed to modal:', evidence); // Debug log
        selectedEvidence = evidence;
        showModal = true;
    }

    function closeModal() {
        showModal = false;
        selectedEvidence = null;
    }

    $: selectedCount = selectedEvidenceIds.size;

    function nextPage() {
      if (currentPage * itemsPerPage < filteredEvidenceItems.length) {
        currentPage += 1;
      }
    }

    function prevPage() {
      if (currentPage > 1) {
        currentPage -= 1;
      }
    }

    function handleInput() {
        // This function can be used to handle any additional logic on input change
    }

    function clearSearch() {
        searchQuery = ""; // Clear the search query
    }

    $: visiblePages = getVisiblePages(currentPage, totalPages);
    
    function getVisiblePages(current, total) {
        // Show all pages if 5 or fewer
        if (total <= 5) {
            return Array.from({length: total}, (_, i) => i + 1);
        }
        
        let pages = [];
        
        // Always show first page
        pages.push(1);
        
        if (current <= 3) {
            // Near start: show 1, 2, 3, ..., total
            pages.push(2, 3);
        } else if (current >= total - 2) {
            // Near end: show 1, ..., total-2, total-1, total
            pages.push('...', total - 2, total - 1);
        } else {
            // In middle: show 1, ..., current-1, current, current+1, ..., total
            pages.push('...', current - 1, current, current + 1);
        }
        
        // Add ellipsis and last page if not already included
        if (pages[pages.length - 1] !== total) {
            if (pages[pages.length - 1] !== total - 1) {
                pages.push('...');
            }
            pages.push(total);
        }
        
        return pages;
    }

    function getProxiedImageUrl(originalUrl) {
        // Use current origin instead of hardcoded URL
        const baseUrl = window.location.origin;
        return `${baseUrl}/api/proxy/image?url=${encodeURIComponent(originalUrl)}`;
    }

    function handleAddEvidence(evidence, event) {
        event.stopPropagation();
        
        try {
            // Create a proxied URL for the evidence image
            const proxiedUrl = getProxiedImageUrl(evidence.s3URL);
            
            dispatch('addImageToCanvas', { 
                evidence: {
                    ...evidence,
                    s3URL: proxiedUrl // Replace the original URL with proxied URL
                },
                type: 'evidence'
            });
        } catch (error) {
            console.error('Error adding evidence to canvas:', error);
            // You might want to show a user-friendly error message here
        }
    }

    function handleSaveEvidence(evidence, event) {
        event.stopPropagation();
        if (!evidence) return;
        
        const isAlreadySaved = savedEvidence.some(e => e._id === evidence._id);
        if (isAlreadySaved) {
            removeSavedEvidence(evidence._id);
        } else {
            saveEvidence(evidence);
        }
    }

    // Add this function to handle mode switching
    function handleSwitchMode() {
        modeStore.set('researcher');
        navigate('/researcher');
    }
</script>

<div class="evidence-container">
    {#if !showModal}
        <div class="fixed-header">
            <div class="search-bar">
                <div class="input-container">
                    <input
                        type="text"
                        placeholder="Search saved evidence..."
                        bind:value={searchQuery}
                        on:input={handleInput}
                    />
                    <button class="clear-button" on:click={clearSearch} hidden={!searchQuery}>×</button>
                </div>
            </div>
        </div>

        <div class="scrollable-content">
            {#if loadingProgress.isLoading}
                <div class="loading-container">
                    <div class="loading-content">
                        <div class="stage-text">
                            {loadingProgress.stage}
                        </div>
                        {#if loadingProgress.total > 0}
                            <div class="progress-bar">
                                <div 
                                    class="progress-fill" 
                                    style="width: {(loadingProgress.loaded / loadingProgress.total) * 100}%"
                                ></div>
                            </div>
                            <div class="progress-text">
                                Loading evidence: {loadingProgress.loaded} of {loadingProgress.total}
                            </div>
                        {:else}
                            <div class="spinner"></div>
                        {/if}
                    </div>
                </div>
            {:else if isLoading}
                <div class="loading-container">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            {:else}
                {#if savedEvidence.length === 0}
                    <div class="empty-state">
                        <div class="empty-state-content">
                            <p class="empty-state-message">
                                Switch to Researcher Mode to discover and save charts for your story
                            </p>
                            <button 
                                class="switch-mode-button"
                                on:click={handleSwitchMode}
                            >
                                <i class="bi bi-arrow-repeat"></i>
                                Switch to Researcher Mode
                            </button>
                        </div>
                    </div>
                {:else}
                    <div class="evidence-list">
                        {#each paginatedSavedEvidence as evidence (evidence._id)}
                            <div class="evidence-item" on:click={(e) => handleAddEvidence(evidence, e)}>
                                <div class="image-container">
                                    <img src={getProxiedImageUrl(evidence.s3URL)} alt={evidence.label} />
                                    <div class="image-overlay">
                                        <button class="add-button" on:click={(e) => handleAddEvidence(evidence, e)}>
                                            <i class="bi bi-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="content-wrapper">
                                    <div class="evidence-label">
                                        {#if evidence.description?.length > 100}
                                            {evidence.description.slice(0, 100)}...
                                        {:else}
                                            {evidence.description}
                                        {/if}
                                    </div>
                                    <div class="action-buttons">
                                        <button 
                                            class="action-button save-button saved" 
                                            on:click={(e) => handleSaveEvidence(evidence, e)}
                                            title="Remove from Saved"
                                        >
                                            <i class="bi bi-bookmark-fill"></i>
                                        </button>
                                        <button 
                                            class="action-button analyze-button" 
                                            on:click={(e) => handleAnalyzeClick(evidence, e)}
                                            title="Analyze Chart"
                                        >
                                            <i class="bi bi-bar-chart-line"></i>
                                            <span>Analyze</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        {/each}
                    </div>
                {/if}
            {/if}
        </div>

        {#if filteredSavedEvidence.length > itemsPerPage}
            <div class="pagination-wrapper">
                <div class="pagination">
                    <button 
                        class="page-btn" 
                        disabled={currentPage === 1}
                        on:click={prevPage}
                    >
                        <i class="fas fa-chevron-left"></i>
                    </button>

                    {#each visiblePages as page}
                        {#if page === '...'}
                            <span class="ellipsis">...</span>
                        {:else}
                            <button 
                                class="page-btn {currentPage === page ? 'active' : ''}"
                                on:click={() => currentPage = page}
                                disabled={typeof page === 'string'}
                            >
                                {page}
                            </button>
                        {/if}
                    {/each}

                    <button 
                        class="page-btn"
                        disabled={currentPage === totalPages}
                        on:click={nextPage}
                    >
                        <i class="fas fa-chevron-right"></i>
                    </button>
                </div>
            </div>
        {/if}
    {/if}
</div>

<style>
    .evidence-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        overflow: hidden;
        width: 253px;
    }

    .fixed-header {
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
        padding: 8px;
        flex-shrink: 0;
    }

    .scrollable-content {
        flex: 1;
        overflow-y: auto;
        padding: 0 8px;
    }

    .search-bar {
        margin-bottom: 8px;
    }

    .input-container {
        position: relative;
        width: 237px; /* 253px - (8px * 2) padding */
    }

    input {
        width: 100%;
        height: 36px;
        padding: 8px 32px 8px 10px;
        border: 1px solid #e5e7eb;
        border-radius: 6px;
        font-size: 13px;
    }

    .evidence-list {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding-bottom: 8px;
    }

    .evidence-item {
        width: 237px; /* 253px - (8px * 2) padding */
        display: flex;
        flex-direction: column;
        background: white;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        overflow: hidden;
        transition: all 0.2s ease;
        cursor: pointer;
    }

    .image-container {
        width: 237px;
        height: 133px; /* Maintain 16:9 aspect ratio */
        position: relative;
        overflow: hidden;
    }

    .image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
    }

    .image-overlay {
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.2s ease;
    }

    .image-overlay .add-button {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        border: none;
        border-radius: 4px;
        color: #1f2937;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .image-overlay .add-button:hover {
        background: #f3f4f6;
        transform: scale(1.05);
    }

    .evidence-item:hover .image-overlay {
        opacity: 1;
    }

    .content-wrapper {
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background: white;
    }

    .evidence-label {
        font-size: 13px;
        color: #1f2937;
        line-height: 1.4;
        font-weight: 500;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .action-buttons {
        display: flex;
        gap: 6px;
        align-items: center;
    }

    .action-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        font-size: 13px;
        font-weight: 500;
        border-radius: 6px;
        transition: all 0.2s ease;
        cursor: pointer;
        background: transparent;
        border: 1px solid #e2e8f0;
    }

    .save-button {
        width: 32px;
        color: #94a3b8;
    }

    .analyze-button {
        flex: 1;
        padding: 0 12px;
        gap: 6px;
        color: #64748b;
        background: #f8fafc;
    }

    /* Hover states */
    .evidence-item:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    }

    .evidence-item:hover .image-container img {
        transform: scale(1.05);
    }

    .save-button:hover {
        border-color: #64748b;
        color: #64748b;
    }

    .save-button.saved {
        background: #f1f5f9;
        color: #0f172a;
        border-color: #e2e8f0;
    }

    .analyze-button:hover {
        background: #f1f5f9;
        color: #0f172a;
    }

    /* Loading states */
    .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
    }

    /* Empty state */
    .empty-state {
        padding: 24px 8px;
        text-align: center;
    }

    .empty-state-content {
        max-width: 237px;
        margin: 0 auto;
    }

    .empty-state-message {
        color: #64748b;
        font-size: 13px;
        line-height: 1.5;
        margin-bottom: 12px;
    }

    /* Pagination */
    .pagination-wrapper {
        padding: 8px;
        border-top: 1px solid #e5e7eb;
        flex-shrink: 0;
        background: white;
        position: sticky;
        bottom: 0;
    }

    .pagination {
        display: flex;
        justify-content: center;
        gap: 4px;
    }

    .page-btn {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e5e7eb;
        border-radius: 6px;
        background: white;
        color: #64748b;
        font-size: 13px;
        cursor: pointer;
    }

    .page-btn.active {
        background: #2563eb;
        color: white;
        border-color: #2563eb;
    }

    .ellipsis {
        color: #6c757d;
        padding: 0 4px;
        min-width: 32px;
        text-align: center;
    }

    .switch-mode-button {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        padding: 10px 16px;
        background-color: #f8fafc;
        border: 1px solid #e2e8f0;
        border-radius: 8px;
        color: #0f172a;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .switch-mode-button:hover {
        background-color: #f1f5f9;
        border-color: #cbd5e1;
    }

    .switch-mode-button:active {
        transform: translateY(1px);
    }

    .switch-mode-button i {
        font-size: 16px;
        color: #3b82f6;
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1050;
        padding: 20px;
    }

    .modal-container {
        background: white;
        border-radius: 8px;
        width: 95%;
        height: 90vh;
        max-width: 1400px;
        position: relative;
        overflow: hidden;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        max-height: calc(100vh - 40px);
        margin: auto;
    }
</style>

{#if selectedEvidence && showModal}
    <div 
        class="modal-backdrop" 
        transition:fade 
        on:click={closeModal}
    >
        <div 
            class="modal-container"
            on:click|stopPropagation
        >
            <pre style="display: none;">{JSON.stringify(selectedEvidence, null, 2)}</pre>
            <EvidenceModal 
                evidence={selectedEvidence}
                isOpen={showModal}
                closeModal={closeModal}
                on:addToCanvas={(event) => dispatch('addImageToCanvas', event.detail)}
            />
        </div>
    </div>
{/if}
  