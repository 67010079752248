<script>
    import { onDestroy, onMount } from 'svelte';
    import { get } from 'svelte/store';
    import { shareStory } from '../services/exportService.js';
    import { storyStore } from '../stores/storyStore.js';
    import SettingsModal from './SettingsModal.svelte';
    import { navigate, Link } from 'svelte-routing';
    import { getContext } from 'svelte';
    import { authStore } from "../stores/authStore.js";
    import VideoPreviewModal from './VideoPreviewModal.svelte';
    import PDFPreviewModal from './PDFPreviewModal.svelte';
    import { fade } from 'svelte/transition';
    import GoogleSlidesModal from './GoogleSlidesModal.svelte';

    let title = '';
    let isEditing = false;
    let jsPDF;
    let classroomNickname = '';
    let showSettings = false;
    let isResearchMode = window.location.pathname === '/researcher';
    let showUserMenu = false;
    let showDropdown = false;
    let showVideoModal = false;
    let showPDFModal = false;
    let showGoogleSlidesModal = false;
    let showToast = false;
    let toastMessage = '';
    let toastType = 'info'; // 'info', 'success', or 'error'
    let toastTimer;
    let isExiting = false; // Track exit state

    // Get the selected slide index context
    const selectedSlideIndex = getContext("selectedSlideIndex");

    // Subscribe to storyStore to get title
    const unsubscribe = storyStore.subscribe(state => {
        title = state.title;
    });

    onMount(() => {
        jsPDF = window.jspdf.jsPDF;
        
        const urlParams = new URLSearchParams(window.location.search);
        const room = urlParams.get('room');
        if (room) {
            classroomNickname = room;
        }
        
        // Add a global error handler to catch and log any errors during navigation
        const originalOnError = window.onerror;
        window.onerror = function(message, source, lineno, colno, error) {
            console.error("Global error caught:", { message, source, lineno, colno, error });
            
            // Still call the original handler if it exists
            if (originalOnError) {
                return originalOnError.apply(this, arguments);
            }
            
            return false;
        };
        
        // Listen for beforeunload events
        window.addEventListener('beforeunload', (event) => {
            console.log("beforeunload event triggered", {
                isDirty: get(storyStore).isDirty,
                isExiting
            });
            
            // If we're in the middle of exiting programmatically, don't show the confirmation
            if (isExiting) {
                return;
            }
            
            // If there are unsaved changes, show a confirmation dialog
            if (get(storyStore).isDirty) {
                event.preventDefault();
                event.returnValue = ''; // This is needed for older browsers
                return ''; // This is needed for older browsers
            }
        });
    });

    // Toast notification function
    function showToastNotification(message, type = 'info', duration = 3000) {
        const id = `toast-${Date.now()}`;
        
        // Create toast element
        const toastElement = document.createElement('div');
        toastElement.id = id;
        toastElement.className = `toast-notification ${type}`;
        
        // Set inner HTML with message and close button
        toastElement.innerHTML = `
            <div class="toast-content">
                <span class="toast-message">${message}</span>
            </div>
            <button class="toast-close">&times;</button>
        `;
        
        // Add to container or create one if it doesn't exist
        let toastContainer = document.querySelector('.toast-container');
        if (!toastContainer) {
            toastContainer = document.createElement('div');
            toastContainer.className = 'toast-container';
            document.body.appendChild(toastContainer);
        }
        
        toastContainer.appendChild(toastElement);
        
        // Add event listener to close button
        const closeButton = toastElement.querySelector('.toast-close');
        if (closeButton) {
            closeButton.addEventListener('click', () => {
                toastElement.classList.add('toast-hiding');
                setTimeout(() => {
                    if (toastElement.parentNode) {
                        toastElement.parentNode.removeChild(toastElement);
                    }
                }, 300);
            });
        }
        
        // Auto remove after duration
        setTimeout(() => {
            if (document.getElementById(id)) {
                toastElement.classList.add('toast-hiding');
                setTimeout(() => {
                    if (toastElement.parentNode) {
                        toastElement.parentNode.removeChild(toastElement);
                    }
                }, 300);
            }
        }, duration);
        
        return id;
    }

    function startEditing() {
        isEditing = true;
    }

    function handleKeydown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            saveTitle();
        } else if (e.key === 'Escape') {
            cancelEditing();
        }
    }

    function handleTitleInput(event) {
        title = event.target.value;
    }

    function saveTitle() {
        isEditing = false;
        const newTitle = title.trim();
        
        if (newTitle === '') {
            const currentState = get(storyStore);
            title = `Untitled: ${currentState.bigQuestion}`;
        }
        
        storyStore.updateTitle(title);
    }

    function cancelEditing() {
        isEditing = false;
        const currentState = get(storyStore);
        title = currentState.title;
    }

    async function handleShareStory() {
        if (jsPDF) {
            await shareStory(jsPDF);
        } else {
            console.error('jsPDF is not loaded');
        }
    }

    function toggleSettings(event) {
        // Prevent event bubbling
        if (event) {
            event.stopPropagation();
        }
        showSettings = !showSettings;
    }

    function toggleMode() {
        if (!isResearchMode) {
            // If we're editing a slide, maintain that context when going to research mode
            const currentSlideIndex = new URLSearchParams(window.location.search).get('slide');
            const storyId = new URLSearchParams(window.location.search).get('story');
            
            if (currentSlideIndex !== null) {
                // Store the current slide index before navigating
                selectedSlideIndex.set(parseInt(currentSlideIndex));
            }
            navigate('/researcher');
        } else {
            // When going back to create mode, check if we were editing a slide
            const slideIndex = get(selectedSlideIndex);
            const storyId = $storyStore?._id;
            
            if (slideIndex !== undefined && slideIndex >= 0) {
                if (storyId) {
                    navigate(`/tool?slide=${slideIndex}&story=${storyId}`);
                } else {
                    navigate(`/tool?slide=${slideIndex}`);
                }
            } else {
                if (storyId) {
                    navigate(`/grid?story=${storyId}`);
                } else {
                    navigate('/grid');
                }
            }
        }
        isResearchMode = !isResearchMode;
    }

    function toggleUserMenu() {
        showUserMenu = !showUserMenu;
    }

    function handleClickOutside(event) {
        if (showUserMenu && !event.target.closest('.user-menu-container')) {
            showUserMenu = false;
        }
    }

    async function handleSignOut() {
        try {
            // Close the user menu
            showUserMenu = false;
            
            // Reset the story store
            storyStore.reset();
            
            // First, call the logout endpoint
            await fetch('/auth/logout');
            
            // Then manually redirect to home page
            window.location.href = '/';
        } catch (error) {
            console.error('Error signing out:', error);
            // Fallback navigation
            window.location.href = '/';
        }
    }

    async function handleExitStory() {
        if (isExiting) {
            // Prevent multiple exit attempts
            console.log('Already in exit process. Ignoring.');
            return;
        }
        
        isExiting = true;
        
        console.log('Starting exit process...');
        console.log('Current story state:', 
            $storyStore?.isDirty ? 'Dirty' : 'Clean', 
            $storyStore?._id ? `ID: ${$storyStore._id}` : 'No ID',
            `Title: ${$storyStore?.title}`, 
            `Slides: ${$storyStore?.slides?.length}`
        );
        
        try {
            // Only try to save if there are unsaved changes and the story has an ID
            if ($storyStore?.isDirty && $storyStore?._id) {
                console.log('Story is dirty. Attempting to save...');
                // Show toast notification for saving process (longer duration)
                showToastNotification("Saving your story...", "info", 10000);
                
                // Delay a bit to give the UI time to update
                await new Promise(resolve => setTimeout(resolve, 300));
                
                try {
                    // Save the story
                    const result = await storyStore.saveStory();
                    
                    if (result) {
                        console.log('Save successful. Showing success toast.');
                        showToastNotification("Saved successfully", "success", 2000);
                    } else {
                        console.log('Save failed or returned no result.');
                        showToastNotification("Could not save your story", "error", 2000);
                        // Wait a bit longer to show the error
                        await new Promise(resolve => setTimeout(resolve, 2000));
                    }
                } catch (error) {
                    console.error('Error during save:', error);
                    showToastNotification("Error saving your story", "error", 2000);
                    // Wait a bit longer to show the error
                    await new Promise(resolve => setTimeout(resolve, 2000));
                }
            } else {
                console.log('Story is clean or has no ID. No need to save.');
            }
            
            // Small delay to ensure toast is visible before moving on
            await new Promise(resolve => setTimeout(resolve, 1000));
            
            console.log('Resetting story store...');
            // Reset the store 
            storyStore.reset();
            
            console.log('Navigating to homepage...');
            // Navigate to the main homepage
            navigate('/', { replace: true });
            
        } catch (error) {
            console.error('Error during exit process:', error);
            showToastNotification("Error during exit: " + error.message, "error", 3000);
        } finally {
            isExiting = false;
        }
    }

    function toggleDropdown() {
        showDropdown = !showDropdown;
    }

    async function handleShareVideo() {
        showDropdown = false;
        showVideoModal = true;
        console.log('Video modal toggled:', showVideoModal); // Add this line
    }

    async function handleSharePDF() {
        showDropdown = false;
        showPDFModal = true; // Open the PDF preview modal

        // if (jsPDF) {
        //     await shareStory(jsPDF);
        // } else {
        //     console.error('jsPDF is not loaded');
        // }
    }

    function closePDFModal() {
        showPDFModal = false; // Close the PDF preview modal
    }

    function closeVideoModal() {
        showVideoModal = false;
    }

    // Temporarily disabled Google Slides feature
    // async function handleShareGoogleSlides() {
    //     showDropdown = false;
    //     showGoogleSlidesModal = true;
    // }

    function closeGoogleSlidesModal() {
        showGoogleSlidesModal = false;
    }

    onDestroy(() => {
        unsubscribe();
        showSettings = false;
        
        // Clear toast timer if exists
        if (toastTimer) {
            clearTimeout(toastTimer);
        }
    });

    // Add click outside handler for settings modal
    function handleGlobalClick(event) {
        if (showSettings && 
            !event.target.closest('.settings-btn') && 
            !event.target.closest('.settings-modal')) {
            showSettings = false;
        }
        
        // Keep existing click outside handler for user menu
        if (showUserMenu && !event.target.closest('.user-menu-container')) {
            showUserMenu = false;
        }
    }

    // Function to manually trigger a save
    function handleManualSave() {
        if ($storyStore.isDirty && !$storyStore.isSaving) {
            storyStore.saveStory();
            showToastNotification('Saving changes...', 'info');
        }
    }
</script>

<svelte:window on:click={handleClickOutside} />

<header class="header">
    <div class="left-section">
        <button class="exit-button" on:click={handleExitStory} disabled={isExiting}>
            {#if isExiting}
                <div class="spinner-sm"></div>
                <span>Exiting...</span>
            {:else}
                <i class="bi bi-arrow-left"></i>
                <span>Exit Story</span>
            {/if}
        </button>
    </div>
    
    <div class="center-section">
        <button 
            class="mode-toggle" 
            class:active={isResearchMode} 
            on:click={toggleMode}
        >
            <div class="toggle-option" class:active={!isResearchMode}>
                <i class="bi bi-pencil-square"></i>
                <span>Create</span>
            </div>
            <div class="toggle-option" class:active={isResearchMode}>
                <i class="bi bi-search"></i>
                <span>Research</span>
            </div>
        </button>
    </div>
    
    <div class="right-section">
        <!-- Save status indicator -->
        <div class="save-status">
            {#if $storyStore.isSaving}
                <span class="status-indicator saving">
                    <i class="bi bi-cloud-arrow-up-fill"></i>
                    <span class="status-text">Saving...</span>
                </span>
            {:else if $storyStore.isDirty}
                <span class="status-indicator unsaved" on:click={handleManualSave} title="Click to save changes">
                    <i class="bi bi-circle-fill"></i>
                    <span class="status-text">Unsaved</span>
                </span>
            {:else}
                <span class="status-indicator saved">
                    <i class="bi bi-check-circle-fill"></i>
                    <span class="status-text">Saved</span>
                </span>
            {/if}
        </div>
        
        {#if classroomNickname}
            <div class="classroom-badge">
                <i class="bi bi-people-fill"></i>
                <span>{classroomNickname}</span>
            </div>
        {/if}
        
        <div class="dropdown">
            <button on:click={toggleDropdown} class="btn btn-secondary">
                <i class="fas fa-share-alt"></i> Share
            </button>
            {#if showDropdown}
            <div class="dropdown-menu">
                <button on:click={handleShareVideo} class="dropdown-item">Video</button>
                <button on:click={handleSharePDF} class="dropdown-item">PDF</button>
                <!-- Temporarily disabled Google Slides option -->
                <button class="dropdown-item disabled" title="Temporarily unavailable">
                    <i class="bi bi-google me-2"></i> Google Slides
                    <span class="coming-soon-badge">Coming Soon</span>
                </button>
            </div>
            {/if}
        </div>
        
        <button class="header-btn settings-btn" on:click={toggleSettings}>
            <i class="bi bi-gear"></i>
        </button>
        
        <!-- User menu -->
        <div class="user-menu-container">
            {#if $authStore.isLoading}
                <div class="auth-loading">
                    <div class="spinner-sm"></div>
                </div>
            {:else if $authStore.isAuthenticated}
                <button 
                    class="user-button" 
                    on:click={toggleUserMenu}
                    aria-label="User menu"
                    aria-expanded={showUserMenu}
                    aria-controls="user-dropdown-menu"
                    id="user-menu-button"
                >
                    {#if $authStore.user?.photoURL}
                        <img 
                            src={$authStore.user.photoURL} 
                            alt={`Profile picture of ${$authStore.user.displayName}`}
                            class="user-avatar"
                            referrerpolicy="no-referrer"
                            crossorigin="anonymous"
                            loading="lazy"
                            onerror="this.style.display='none'; this.nextElementSibling.style.display='flex';"
                        />
                        <div class="user-avatar-placeholder" style="display: none;">
                            {$authStore.user?.displayName?.charAt(0) || 'U'}
                        </div>
                    {:else if $authStore.user?.profileImage}
                        <img 
                            src={$authStore.user.profileImage} 
                            alt={`Profile picture of ${$authStore.user.displayName}`}
                            class="user-avatar"
                            referrerpolicy="no-referrer"
                            crossorigin="anonymous"
                            loading="lazy"
                            onerror="this.style.display='none'; this.nextElementSibling.style.display='flex';"
                        />
                        <div class="user-avatar-placeholder" style="display: none;">
                            {$authStore.user?.displayName?.charAt(0) || 'U'}
                        </div>
                    {:else}
                        <div class="user-avatar-placeholder">
                            {$authStore.user?.displayName?.charAt(0) || 'U'}
                        </div>
                    {/if}
                </button>
                
                {#if showUserMenu}
                    <div 
                        class="user-menu" 
                        transition:fade={{ duration: 150 }}
                        role="menu"
                        id="user-dropdown-menu"
                        aria-labelledby="user-menu-button"
                    >
                        <div class="user-info">
                            <div class="user-avatar-large">
                                {#if $authStore.user?.photoURL}
                                    <img 
                                        src={$authStore.user.photoURL} 
                                        alt={`Profile picture of ${$authStore.user.displayName}`}
                                        class="user-avatar-img"
                                        referrerpolicy="no-referrer"
                                        crossorigin="anonymous"
                                        loading="lazy"
                                        onerror="this.style.display='none'; this.nextElementSibling.style.display='flex';"
                                    />
                                    <div class="user-avatar-placeholder" style="display: none;">
                                        {$authStore.user?.displayName?.charAt(0) || 'U'}
                                    </div>
                                {:else if $authStore.user?.profileImage}
                                    <img 
                                        src={$authStore.user.profileImage} 
                                        alt={`Profile picture of ${$authStore.user.displayName}`}
                                        class="user-avatar-img"
                                        referrerpolicy="no-referrer"
                                        crossorigin="anonymous"
                                        loading="lazy"
                                        onerror="this.style.display='none'; this.nextElementSibling.style.display='flex';"
                                    />
                                    <div class="user-avatar-placeholder" style="display: none;">
                                        {$authStore.user?.displayName?.charAt(0) || 'U'}
                                    </div>
                                {:else}
                                    <div class="user-avatar-placeholder">
                                        {$authStore.user?.displayName?.charAt(0) || 'U'}
                                    </div>
                                {/if}
                            </div>
                            <div class="user-details">
                                <p class="user-name" id="user-dropdown-name">{$authStore.user.displayName}</p>
                                <p class="user-email" id="user-dropdown-email">{$authStore.user.email}</p>
                            </div>
                        </div>
                        <div class="menu-divider" role="separator"></div>
                        <button 
                            class="menu-item" 
                            on:click={handleSignOut}
                            role="menuitem"
                        >
                            <i class="fas fa-sign-out-alt" aria-hidden="true"></i>
                            <span>Sign Out</span>
                        </button>
                    </div>
                {/if}
            {:else}
                <button class="login-button" on:click={() => authStore.loginWithGoogle()}>
                    <i class="fab fa-google"></i>
                    <span>Sign in</span>
                </button>
            {/if}
        </div>
    </div>
</header>

{#if showSettings}
    <SettingsModal on:close={() => showSettings = false} />
{/if}

{#if showVideoModal}
    <VideoPreviewModal 
        isOpen={showVideoModal}
        onClose={closeVideoModal}
    />
{/if}

{#if showPDFModal}
    <PDFPreviewModal 
        isOpen={showPDFModal}
        onClose={closePDFModal}
    />
{/if}

{#if showGoogleSlidesModal}
    <GoogleSlidesModal 
        isOpen={showGoogleSlidesModal}
        onClose={closeGoogleSlidesModal}
    />
{/if}

<style>
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        background: white;
        border-bottom: 1px solid #e5e7eb;
        height: 56px;
        box-sizing: border-box;
    }
    
    .left-section {
        display: flex;
        align-items: center;
        min-width: 200px;
    }
    
    .logo-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: inherit;
        gap: 0.5rem;
    }
    
    .logo {
        height: 32px;
        width: auto;
    }
    
    .logo-text {
        font-weight: 600;
        font-size: 1.1rem;
        color: #1a1a1a;
    }
    
    .right-section {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 200px;
        gap: 1rem;
    }
    
    .classroom-badge {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 4px 10px;
        background: #f3f4f6;
        border-radius: 16px;
        font-size: 14px;
        color: #4b5563;
    }
    
    .header-btn {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 6px 12px;
        background: none;
        border: 1px solid #e5e7eb;
        border-radius: 6px;
        font-size: 14px;
        color: #4b5563;
        cursor: pointer;
        transition: all 0.2s ease;
    }
    
    .header-btn:hover {
        background: #f9fafb;
        border-color: #d1d5db;
        color: #1f2937;
    }
    
    .settings-btn {
        padding: 6px;
    }
    
    .user-menu-container {
        position: relative;
    }
    
    .user-button {
        width: 36px;
        height: 36px;
        padding: 0;
        border: none;
        background: none;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.2s ease;
    }
    
    .user-button:hover {
        opacity: 0.8;
    }
    
    .user-avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .user-avatar-placeholder {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2563eb;
        color: white;
        font-weight: 600;
    }
    
    .user-menu {
        position: absolute;
        top: calc(100% + 8px);
        right: 0;
        width: 280px;
        background: white;
        border-radius: 12px;
        box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.05);
        overflow: hidden;
        z-index: 1500;
    }
    
    .user-info {
        padding: 16px;
        display: flex;
        align-items: center;
        gap: 12px;
    }
    
    .user-avatar-large {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        border: 2px solid #e5e7eb;
    }
    
    .user-avatar-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .user-details {
        flex: 1;
        min-width: 0; /* Prevents text overflow issues */
    }
    
    .user-name {
        font-size: 15px;
        font-weight: 600;
        color: #1f2937;
        margin: 0 0 4px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .user-email {
        font-size: 13px;
        color: #6b7280;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .menu-divider {
        height: 1px;
        background: #e5e7eb;
        margin: 0;
    }
    
    .menu-item {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        padding: 12px 16px;
        border: none;
        background: none;
        text-align: left;
        font-size: 14px;
        color: #4b5563;
        cursor: pointer;
        transition: all 0.2s ease;
    }
    
    .menu-item:hover {
        background: #f3f4f6;
        color: #1f2937;
    }
    
    .menu-item i {
        font-size: 16px;
        width: 20px;
        text-align: center;
        color: #6b7280;
    }
    
    .login-button {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 6px 12px;
        background: #2563eb;
        color: white;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.2s ease;
    }
    
    .login-button:hover {
        background: #1d4ed8;
    }
    
    .auth-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
    }
    
    .spinner-sm {
        width: 16px;
        height: 16px;
        border: 2px solid rgba(59, 130, 246, 0.3);
        border-radius: 50%;
        border-top-color: #3b82f6;
        animation: spin 1s linear infinite;
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .exit-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        background: none;
        border: 1px solid #e5e7eb;
        border-radius: 6px;
        color: #4b5563;
        font-size: 0.875rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .exit-button:hover:not(:disabled) {
        background: #f3f4f6;
        border-color: #d1d5db;
        color: #1f2937;
    }
    
    .exit-button:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

    .exit-button i {
        font-size: 1rem;
    }

    .center-section {
        display: flex;
        justify-content: center;
        flex: 1;
    }

    .mode-toggle {
        display: flex;
        align-items: center;
        background: #f3f4f6;
        border: 1px solid #e5e7eb;
        border-radius: 9999px;
        padding: 0.25rem;
        gap: 0.25rem;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .toggle-option {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.375rem 1rem;
        border-radius: 9999px;
        color: #6b7280;
        font-size: 0.875rem;
        font-weight: 500;
        transition: all 0.2s ease;
    }

    .toggle-option.active {
        background: white;
        color: #2563eb;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }

    .toggle-option i {
        font-size: 1rem;
    }

    .mode-toggle:hover .toggle-option:not(.active) {
        color: #4b5563;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-menu {
        display: block;
        position: absolute;
        background-color: white;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        z-index: 1500;
    }

    .dropdown-item {
        padding: 8px 16px;
        cursor: pointer;
    }

    .toggle-option i {
        font-size: 1rem;
    }

    .mode-toggle:hover .toggle-option:not(.active) {
        color: #4b5563;
    }

    .save-status {
        margin-right: 0.5rem;
        display: flex;
        align-items: center;
    }
    
    .status-indicator {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 4px 8px;
        border-radius: 16px;
        font-size: 12px;
        transition: all 0.2s ease;
    }
    
    .status-indicator.saving {
        color: #3b82f6;
        background: #eff6ff;
    }
    
    .status-indicator.unsaved {
        color: #f59e0b;
        background: #fef3c7;
        cursor: pointer;
    }
    
    .status-indicator.unsaved:hover {
        background: #fcd34d;
    }
    
    .status-indicator.saved {
        color: #10b981;
        background: #ecfdf5;
    }
    
    .status-indicator i {
        font-size: 10px;
    }
    
    .status-indicator.unsaved i {
        animation: pulse 1.5s infinite;
    }
    
    .status-indicator.saving i {
        animation: spin 1.5s linear infinite;
    }
    
    @keyframes pulse {
        0% { opacity: 0.6; }
        50% { opacity: 1; }
        100% { opacity: 0.6; }
    }
    
    .status-text {
        font-weight: 500;
    }

    /* Toast styles */
    .toast-container {
        position: fixed;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9999; /* Higher z-index to ensure visibility */
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }
    
    .toast {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 12px 20px;
        min-width: 280px;
        max-width: 400px;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        animation: slide-in 0.3s ease-out;
    }
    
    .toast-info {
        background-color: #e0f2fe;
        color: #0369a1;
        border-left: 4px solid #0284c7;
    }
    
    .toast-success {
        background-color: #d1fae5;
        color: #047857;
        border-left: 4px solid #059669;
    }
    
    .toast-error {
        background-color: #fee2e2;
        color: #b91c1c;
        border-left: 4px solid #dc2626;
    }
    
    .toast-icon {
        font-size: 18px;
    }
    
    .toast-message {
        font-size: 14px;
        font-weight: 500;
    }
    
    @keyframes slide-in {
        from { transform: translateY(-20px); opacity: 0; }
        to { transform: translateY(0); opacity: 1; }
    }

    .theme-toggle img {
        width: 22px;
        height: 22px;
        transition: transform 0.3s ease;
    }

    /* Toast notification styles */
    .toast-container {
        position: fixed;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        gap: 8px;
        z-index: 9999;
    }

    .toast-notification {
        display: flex;
        align-items: center;
        min-width: 280px;
        max-width: 400px;
        padding: 12px 20px;
        background: white;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        animation: slide-in 0.3s ease forwards;
    }

    .toast-notification.info {
        border-left: 4px solid #3b82f6;
    }

    .toast-notification.success {
        border-left: 4px solid #10b981;
    }

    .toast-notification.error {
        border-left: 4px solid #ef4444;
    }

    .toast-notification.warning {
        border-left: 4px solid #f59e0b;
    }

    .toast-content {
        flex: 1;
    }

    .toast-message {
        font-size: 14px;
        color: #1e293b;
        line-height: 1.4;
    }

    .toast-close {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        color: #64748b;
        cursor: pointer;
        font-size: 18px;
        margin-left: 8px;
        padding: 0;
        border-radius: 50%;
        transition: all 0.2s ease;
    }

    .toast-close:hover {
        background: #f1f5f9;
        color: #334155;
    }

    .toast-notification.toast-hiding {
        animation: slide-out 0.3s ease forwards;
    }

    @keyframes slide-in {
        from {
            transform: translateY(-20px);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    @keyframes slide-out {
        from {
            transform: translateY(0);
            opacity: 1;
        }
        to {
            transform: translateY(-20px);
            opacity: 0;
        }
    }

    @keyframes fadeIn {
        from { opacity: 0; transform: translateY(-10px); }
        to { opacity: 1; transform: translateY(0); }
    }

    .dropdown-item.disabled {
        opacity: 0.6;
        cursor: not-allowed;
        position: relative;
    }

    .coming-soon-badge {
        font-size: 10px;
        background-color: #5f59f7;
        color: white;
        padding: 2px 5px;
        border-radius: 10px;
        margin-left: 5px;
        position: relative;
        top: -1px;
    }
</style>