<script>
  import PromptSelection from './PromptSelection.svelte';
  import { navigate } from 'svelte-routing';
  import { authStore } from '../stores/authStore.js';
  import { storyStore } from '../stores/storyStore.js';

  function startNewStory() {
    if ($authStore.isAuthenticated) {
      navigate('/grid');
    } else {
      // Set the redirect path before login
      authStore.setRedirect('/grid');
      // Store a flag indicating we're creating a new story
      localStorage.setItem('pendingPromptId', 'new');
      // Trigger login
      authStore.loginWithGoogle();
    }
  }
</script>

<div class="create-story-container">
  <header class="header" style="display:none;">
    <h1>Create a New Data Story</h1>
    <p>Choose a prompt below to start exploring data and tell your story.</p>
  </header>
  <section class="prompt-selection-section">
    <PromptSelection />
  </section>
</div>

<style>

  .create-story-container {
    padding: 0px !important;
    height: 100vh; /* Use viewport height to ensure it takes up the full viewable space */
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    overflow-y: auto;
    flex-grow: 1; /* Allow the container to grow and fill available space */
  }

  .header {
    text-align: center;
  }

  h1 {
    font-size: 2rem;
    color: #1a1a1a;
    margin-bottom: 0.5rem;
  }

  p {
    color: #666;
    margin-bottom: 1rem;
    font-size: 1.1rem;
  }

  .prompt-selection-section {
    flex: 1;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    padding: 0px !important;
    height: 100%;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
  }
</style> 