<!-- src/components/admin/Admin-BackupManager.svelte -->
<script>
  import { onMount } from 'svelte';
  import Modal from '../Modal.svelte';

  let backupHistory = {
    evidence: [],
    articles: []
  };
  let isLoading = true;
  let isBackingUp = false;
  let isBackingUpArticles = false;
  let isRestoring = false;
  let error = null;
  let confirmModalOpen = false;
  let confirmArticlesModalOpen = false;
  let confirmDeleteModalOpen = false;
  let confirmRestoreModalOpen = false;
  let selectedBackup = null;

  onMount(async () => {
    await loadBackupHistory();
  });

  async function loadBackupHistory() {
    try {
      isLoading = true;
      error = null;
      const response = await fetch('/api/admin/backups/organized');
      const data = await response.json();
      
      if (data.success) {
        backupHistory = data.backups;
      } else {
        error = data.message || 'Failed to load backup history';
      }
    } catch (err) {
      error = 'Error loading backup history: ' + err.message;
    } finally {
      isLoading = false;
    }
  }

  async function createBackup() {
    try {
      isBackingUp = true;
      error = null;
      const response = await fetch('/api/admin/backup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      const data = await response.json();
      
      if (data.success) {
        await loadBackupHistory();
        confirmModalOpen = false;
      } else {
        error = data.message || 'Failed to create backup';
      }
    } catch (err) {
      error = 'Error creating backup: ' + err.message;
    } finally {
      isBackingUp = false;
    }
  }

  async function createArticlesBackup() {
    try {
      isBackingUpArticles = true;
      error = null;
      const response = await fetch('/api/admin/backup/articles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      const data = await response.json();
      
      if (data.success) {
        await loadBackupHistory();
        confirmArticlesModalOpen = false;
      } else {
        error = data.message || 'Failed to create articles backup';
      }
    } catch (err) {
      error = 'Error creating articles backup: ' + err.message;
    } finally {
      isBackingUpArticles = false;
    }
  }

  async function deleteBackup(backup) {
    try {
      const response = await fetch('/api/admin/backup', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filename: backup.filename,
          s3URL: backup.s3URL
        })
      });
      
      const data = await response.json();
      
      if (data.success) {
        await loadBackupHistory();
        confirmDeleteModalOpen = false;
        selectedBackup = null;
      } else {
        error = data.message || 'Failed to delete backup';
      }
    } catch (err) {
      error = 'Error deleting backup: ' + err.message;
    }
  }

  async function restoreBackup(backup) {
    try {
      isRestoring = true;
      error = null;
      const response = await fetch('/api/admin/backup/restore', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          s3URL: backup.s3URL,
          type: backup.action === 'backup' ? 'evidence' : 'articles'
        })
      });
      
      const data = await response.json();
      
      if (data.success) {
        await loadBackupHistory();
        confirmRestoreModalOpen = false;
        selectedBackup = null;
      } else {
        error = data.message || 'Failed to restore backup';
      }
    } catch (err) {
      error = 'Error restoring backup: ' + err.message;
    } finally {
      isRestoring = false;
    }
  }

  function formatDate(date) {
    return new Date(date).toLocaleString();
  }

  function openDeleteModal(backup) {
    selectedBackup = backup;
    confirmDeleteModalOpen = true;
  }

  function openRestoreModal(backup) {
    selectedBackup = backup;
    confirmRestoreModalOpen = true;
  }
</script>

<div class="backup-manager">
  <div class="header">
    <h2>Backup Manager</h2>
    <div class="button-group">
      <button 
        class="create-backup-btn" 
        on:click={() => confirmModalOpen = true}
        disabled={isBackingUp || isBackingUpArticles || isRestoring}
      >
        {#if isBackingUp}
          Creating Evidence Backup...
        {:else}
          Create Evidence Backup
        {/if}
      </button>
      <button 
        class="create-backup-btn articles" 
        on:click={() => confirmArticlesModalOpen = true}
        disabled={isBackingUp || isBackingUpArticles || isRestoring}
      >
        {#if isBackingUpArticles}
          Creating Articles Backup...
        {:else}
          Create Articles Backup
        {/if}
      </button>
    </div>
  </div>

  {#if error}
    <div class="error-message">
      {error}
    </div>
  {/if}

  <div class="backup-history">
    <div class="section">
      <h3>Evidence Backups</h3>
      {#if isLoading}
        <div class="loading">Loading backup history...</div>
      {:else if backupHistory.evidence.length === 0}
        <div class="no-backups">No evidence backups found</div>
      {:else}
        <div class="history-list">
          {#each backupHistory.evidence as backup}
            <div class="history-item">
              <div class="item-header">
                <span class="filename">{backup.filename}</span>
                <div class="actions">
                  <button 
                    class="action-btn restore" 
                    on:click={() => openRestoreModal(backup)}
                    disabled={isRestoring}
                  >
                    Restore
                  </button>
                  <button 
                    class="action-btn delete" 
                    on:click={() => openDeleteModal(backup)}
                  >
                    Delete
                  </button>
                </div>
              </div>
              <div class="item-details">
                <div class="detail">
                  <span class="label">Created:</span>
                  <span class="value">{backup.formattedDate} {backup.formattedTime}</span>
                </div>
                <div class="detail">
                  <span class="label">Size:</span>
                  <span class="value">{backup.sizeFormatted}</span>
                </div>
                {#if backup.stats}
                  <div class="detail">
                    <span class="label">Items:</span>
                    <span class="value">{backup.stats.totalItems || 'N/A'}</span>
                  </div>
                {/if}
              </div>
            </div>
          {/each}
        </div>
      {/if}
    </div>

    <div class="section">
      <h3>Articles Backups</h3>
      {#if isLoading}
        <div class="loading">Loading backup history...</div>
      {:else if backupHistory.articles.length === 0}
        <div class="no-backups">No articles backups found</div>
      {:else}
        <div class="history-list">
          {#each backupHistory.articles as backup}
            <div class="history-item">
              <div class="item-header">
                <span class="filename">{backup.filename}</span>
                <div class="actions">
                  <button 
                    class="action-btn restore" 
                    on:click={() => openRestoreModal(backup)}
                    disabled={isRestoring}
                  >
                    Restore
                  </button>
                  <button 
                    class="action-btn delete" 
                    on:click={() => openDeleteModal(backup)}
                  >
                    Delete
                  </button>
                </div>
              </div>
              <div class="item-details">
                <div class="detail">
                  <span class="label">Created:</span>
                  <span class="value">{backup.formattedDate} {backup.formattedTime}</span>
                </div>
                <div class="detail">
                  <span class="label">Size:</span>
                  <span class="value">{backup.sizeFormatted}</span>
                </div>
                {#if backup.stats}
                  <div class="detail">
                    <span class="label">Main Collection:</span>
                    <span class="value">{backup.stats.mainCollectionCount} items</span>
                  </div>
                  <div class="detail">
                    <span class="label">Temp Collection:</span>
                    <span class="value">{backup.stats.tempCollectionCount} items</span>
                  </div>
                {/if}
              </div>
            </div>
          {/each}
        </div>
      {/if}
    </div>
  </div>
</div>

<!-- Create Evidence Backup Modal -->
{#if confirmModalOpen}
  <Modal on:close={() => confirmModalOpen = false}>
    <div slot="header">
      <h3>Create Evidence Backup</h3>
    </div>
    
    <div slot="body">
      <p>Are you sure you want to create a new backup of the evidence collection?</p>
    </div>
    
    <div slot="footer">
      <div class="modal-actions">
        <button 
          class="cancel-btn" 
          on:click={() => confirmModalOpen = false}
          disabled={isBackingUp}
        >
          Cancel
        </button>
        <button 
          class="confirm-btn" 
          on:click={createBackup}
          disabled={isBackingUp}
        >
          {#if isBackingUp}
            Creating...
          {:else}
            Create Backup
          {/if}
        </button>
      </div>
    </div>
  </Modal>
{/if}

<!-- Create Articles Backup Modal -->
{#if confirmArticlesModalOpen}
  <Modal on:close={() => confirmArticlesModalOpen = false}>
    <div slot="header">
      <h3>Create Articles Backup</h3>
    </div>
    
    <div slot="body">
      <p>Are you sure you want to create a new backup of both articles collections (main and temporary)?</p>
    </div>
    
    <div slot="footer">
      <div class="modal-actions">
        <button 
          class="cancel-btn" 
          on:click={() => confirmArticlesModalOpen = false}
          disabled={isBackingUpArticles}
        >
          Cancel
        </button>
        <button 
          class="confirm-btn" 
          on:click={createArticlesBackup}
          disabled={isBackingUpArticles}
        >
          {#if isBackingUpArticles}
            Creating...
          {:else}
            Create Backup
          {/if}
        </button>
      </div>
    </div>
  </Modal>
{/if}

<!-- Delete Backup Modal -->
{#if confirmDeleteModalOpen && selectedBackup}
  <Modal on:close={() => {
    confirmDeleteModalOpen = false;
    selectedBackup = null;
  }}>
    <div slot="header">
      <h3>Delete Backup</h3>
    </div>
    
    <div slot="body">
      <p>Are you sure you want to delete this backup?</p>
      <div class="backup-details">
        <p><strong>Filename:</strong> {selectedBackup.filename}</p>
        <p><strong>Created:</strong> {selectedBackup.formattedDate} {selectedBackup.formattedTime}</p>
        <p><strong>Size:</strong> {selectedBackup.sizeFormatted}</p>
      </div>
      <p class="warning">This action cannot be undone!</p>
    </div>
    
    <div slot="footer">
      <div class="modal-actions">
        <button 
          class="cancel-btn" 
          on:click={() => {
            confirmDeleteModalOpen = false;
            selectedBackup = null;
          }}
        >
          Cancel
        </button>
        <button 
          class="confirm-btn delete" 
          on:click={() => deleteBackup(selectedBackup)}
        >
          Delete Backup
        </button>
      </div>
    </div>
  </Modal>
{/if}

<!-- Restore Backup Modal -->
{#if confirmRestoreModalOpen && selectedBackup}
  <Modal on:close={() => {
    confirmRestoreModalOpen = false;
    selectedBackup = null;
  }}>
    <div slot="header">
      <h3>Restore Backup</h3>
    </div>
    
    <div slot="body">
      <p>Are you sure you want to restore from this backup?</p>
      <div class="backup-details">
        <p><strong>Filename:</strong> {selectedBackup.filename}</p>
        <p><strong>Created:</strong> {selectedBackup.formattedDate} {selectedBackup.formattedTime}</p>
        <p><strong>Size:</strong> {selectedBackup.sizeFormatted}</p>
      </div>
      <p class="warning">This will replace all current data! A backup of the current data will be created before restoring.</p>
    </div>
    
    <div slot="footer">
      <div class="modal-actions">
        <button 
          class="cancel-btn" 
          on:click={() => {
            confirmRestoreModalOpen = false;
            selectedBackup = null;
          }}
          disabled={isRestoring}
        >
          Cancel
        </button>
        <button 
          class="confirm-btn restore" 
          on:click={() => restoreBackup(selectedBackup)}
          disabled={isRestoring}
        >
          {#if isRestoring}
            Restoring...
          {:else}
            Restore Backup
          {/if}
        </button>
      </div>
    </div>
  </Modal>
{/if}

<style>
  .backup-manager {
    padding: 1rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  .button-group {
    display: flex;
    gap: 1rem;
  }

  .create-backup-btn {
    background-color: #3b82f6;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    font-size: 0.875rem;
    cursor: pointer;
  }

  .create-backup-btn.articles {
    background-color: #10b981;
  }

  .create-backup-btn:hover:not(:disabled) {
    background-color: #2563eb;
  }

  .create-backup-btn.articles:hover:not(:disabled) {
    background-color: #059669;
  }

  .create-backup-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .error-message {
    background-color: #fee2e2;
    border: 1px solid #f87171;
    color: #b91c1c;
    padding: 0.75rem 1rem;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
  }

  .backup-history {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .section {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 1rem;
  }

  .section h3 {
    margin-bottom: 1rem;
    color: #1f2937;
  }

  .loading, .no-backups {
    color: #6b7280;
    text-align: center;
    padding: 1rem 0;
  }

  .history-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .history-item {
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    padding: 1rem;
  }

  .history-item:hover {
    background-color: #f9fafb;
  }

  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .filename {
    font-weight: 500;
  }

  .actions {
    display: flex;
    gap: 0.5rem;
  }

  .action-btn {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    border: none;
    cursor: pointer;
  }

  .action-btn.restore {
    background-color: #10b981;
    color: white;
  }

  .action-btn.restore:hover:not(:disabled) {
    background-color: #059669;
  }

  .action-btn.delete {
    background-color: #ef4444;
    color: white;
  }

  .action-btn.delete:hover:not(:disabled) {
    background-color: #dc2626;
  }

  .action-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .item-details {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    font-size: 0.875rem;
    color: #4b5563;
  }

  .detail {
    display: flex;
    gap: 0.5rem;
  }

  .label {
    font-weight: 500;
    min-width: 120px;
  }

  .backup-details {
    background-color: #f3f4f6;
    padding: 1rem;
    border-radius: 0.25rem;
    margin: 1rem 0;
  }

  .warning {
    color: #dc2626;
    font-weight: 500;
    margin-top: 1rem;
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
  }

  .cancel-btn {
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
    background: white;
    cursor: pointer;
  }

  .cancel-btn:hover:not(:disabled) {
    background-color: #f9fafb;
  }

  .confirm-btn {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    color: white;
  }

  .confirm-btn:not(.delete):not(.restore) {
    background-color: #3b82f6;
  }

  .confirm-btn:not(.delete):not(.restore):hover:not(:disabled) {
    background-color: #2563eb;
  }

  .confirm-btn.delete {
    background-color: #ef4444;
  }

  .confirm-btn.delete:hover:not(:disabled) {
    background-color: #dc2626;
  }

  .confirm-btn.restore {
    background-color: #10b981;
  }

  .confirm-btn.restore:hover:not(:disabled) {
    background-color: #059669;
  }

  .confirm-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
</style> 