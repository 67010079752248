<script>
  import { onMount, onDestroy, createEventDispatcher } from "svelte";
  import HeaderBar from "../components/HeaderBar.svelte";
  import ItemsPanel from "../components/ItemsPanel.svelte";
  import StoryCanvas from "../components/StoryCanvas.svelte";
  import EvidenceModal from "../components/EvidenceModal.svelte";
  import BreadcrumbNav from "../components/BreadcrumbNav.svelte";
  import CanvasSubmenu from "../components/CanvasSubmenu.svelte";
  import { fade } from "svelte/transition";
  import { getContext } from "svelte";
  import { get } from "svelte/store";
  import { generateResponse } from "../services/aiService";
  import { navigate, Link } from "svelte-routing";
  import { searchEvidence, evidenceStore } from '../stores/evidenceStore.js';
  //import AgentSlide from '../components/Agent-Slide.svelte';
  // import carrotIcon from "public/assets/carrot.svg";

  const selectedSlideIndex = getContext("selectedSlideIndex");
  const storyStore = getContext("storyStore");

  let canvasComponent;
  let selectedObject = null;
  let selectedObjectType = null;
  let showAnalysisModal = false;
  let selectedEvidence = null;
  let evidenceData = null;
  let showModal = false;
  let currentScript = "";
  let previousSlideIndex = null;
  let isGeneratingDescription = false;
  let slideTitle = '';
  let isEditingTitle = false;
  let isLoadingEvidence = false;
  let voices = [];
  let selectedVoice = null;
  let isModalOpen = false;
  let speechSynthesisUtterance;
  let isSpeaking = false;
  let isPlaying = false;
  let currentVoiceFilter = '';
  let isSlideMenuOpen = false;
  let isScriptPanelExpanded = true;

  // Subscribe to slide changes and update script
  $: {
    if ($selectedSlideIndex !== undefined && $selectedSlideIndex >= 0) {
      if ($selectedSlideIndex !== previousSlideIndex) {
        previousSlideIndex = $selectedSlideIndex;
        const slide = storyStore.getSlide($selectedSlideIndex);
        if (slide) {
          currentScript = slide.script || "";
          console.log("Slide changed, loading script:", {
            slideIndex: $selectedSlideIndex,
            script: currentScript,
          });
        }
      }
    }
  }

  // Subscribe to the current slide's title
  $: if ($selectedSlideIndex !== undefined && $selectedSlideIndex >= 0) {
    const slide = storyStore.getSlide($selectedSlideIndex);
    slideTitle = slide?.title || getDefaultTitle($selectedSlideIndex);
  }

  function getDefaultTitle(index) {
    return `Slide ${index + 1}`;
  }

  function handleCanvasInstance(instance) {
    // This function can be used if needed
  }

  function handleAddImageToCanvas(event) {
    console.log("handleAddImageToCanvas called", event.detail);
    if (canvasComponent) {
      const imageData = event.detail;

      // Handle both evidence and external images
      if (imageData.type === "evidence") {
        canvasComponent.addImageToCanvas({
          evidence: imageData.evidence,
          type: "evidence",
        });
      } else {
        canvasComponent.addImageToCanvas({
          url: imageData.url,
          type: "external",
        });
      }
    }
  }

  function handleUpdateSubMenu(event) {
    const {
      selectedObject: newSelectedObject,
      objectType,
      evidenceData: newEvidenceData,
    } = event.detail;

    selectedObject = newSelectedObject;
    selectedObjectType = objectType;
    evidenceData = newEvidenceData;
  }

  function handleObjectAction(event) {
    // Handle both direct object calls and event objects
    const { action, object } = event.detail || event;

    if (action === "delete" && canvasComponent) {
      canvasComponent.deleteSelectedObject();
    } else if (action === "analyze" && object && object.metadata?.evidence) {
      selectedEvidence = object.metadata.evidence;
      showAnalysisModal = true;
    }
  }

  function closeAnalysisModal() {
    showAnalysisModal = false;
    selectedEvidence = null;
  }

  async function generateSlideDescription() {
    if (isGeneratingDescription) return;
    
    isGeneratingDescription = true;
    try {
      const slide = storyStore.getSlide($selectedSlideIndex);
      if (!slide) return;
      
      // Get canvas objects
      const canvasObjects = canvasComponent?.getCanvasObjects() || [];
      
      // Extract text from canvas objects
      const textObjects = canvasObjects
        .filter(obj => obj.type === 'textbox' || obj.type === 'i-text')
        .map(obj => obj.text);
      
      // Extract evidence titles
      const evidenceObjects = canvasObjects
        .filter(obj => obj.metadata?.evidence)
        .map(obj => obj.metadata.evidence.title);
      
      // Combine all content
      const slideContent = [
        slide.title,
        slide.text,
        ...textObjects,
        ...evidenceObjects
      ].filter(Boolean).join('\n');
      
      if (!slideContent.trim()) {
        alert('Add some content to your slide first!');
        return;
      }
      
      const response = await generateResponse({
        messages: [
          {
            role: 'system',
            content: 'You are a helpful assistant that creates concise slide narration scripts based on slide content.'
          },
          {
            role: 'user',
            content: `Create a brief, conversational narration script (2-3 sentences) for a slide with the following content:\n\n${slideContent}`
          }
        ]
      });
      
      if (response && response.content) {
        currentScript = response.content;
        await storyStore.updateSlideScript($selectedSlideIndex, currentScript);
      }
    } catch (error) {
      console.error('Error generating slide description:', error);
      alert('Failed to generate description. Please try again.');
    } finally {
      isGeneratingDescription = false;
    }
  }

  function updateScript(event) {
    currentScript = event.target.value;
    storyStore.updateSlideScript($selectedSlideIndex, currentScript);
  }

  function startEditingTitle() {
    isEditingTitle = true;
  }

  function saveTitle() {
    storyStore.updateSlideTitle($selectedSlideIndex, slideTitle);
    isEditingTitle = false;
  }

  function handleTitleKeydown(event) {
    if (event.key === 'Enter') {
      saveTitle();
    } else if (event.key === 'Escape') {
      isEditingTitle = false;
      // Reset to original title
      const slide = storyStore.getSlide($selectedSlideIndex);
      slideTitle = slide?.title || getDefaultTitle($selectedSlideIndex);
    }
  }

  function handleShowAnalysis(event) {
    selectedEvidence = event.detail;
    showModal = true;
  }

  function closeModal() {
    showModal = false;
    selectedEvidence = null;
  }

  function handleScriptChange(event) {
    const newScript = event.target.value;
    currentScript = newScript; // Update local state
    
    const slide = storyStore.getSlide($selectedSlideIndex);
    if (slide) {
      storyStore.updateSlideScript($selectedSlideIndex, newScript);
      console.log("Script updated:", {
        slideIndex: $selectedSlideIndex,
        newScript: newScript,
      });
    } else {
      console.warn("Cannot update script - slide not found");
    }
  }

  async function generateChartDescription() {
    if (!evidenceData) {
      console.log("No evidence data available");
      return;
    }

    isGeneratingDescription = true;

    try {
      const context = {
        description: evidenceData.description,
        link: evidenceData.link || evidenceData.sourceURL || null,
        imageData: {
          src: evidenceData.mediaURL,
          naturalWidth: evidenceData.width,
          naturalHeight: evidenceData.height,
        },
      };

      const response = await generateResponse(
        "Write a brief video script (2-3 sentences) that describes this chart. Make it casual and engaging, like you're explaining it to a friend in a TikTok or Instagram video. Use the text description and the image to create a script. Don't make anything up. Be brief and concise. Only respond with the script, no other text.",
        context
      );

      // Prepend the generated description to the current script
      currentScript = `Video Script:\n${response}\n\n${currentScript}`;

      // Update the slide's script in the store
      storyStore.updateSlideScript($selectedSlideIndex, currentScript);
    } catch (error) {
      console.error("Error generating chart description:", error);
    } finally {
      isGeneratingDescription = false;
    }
  }

  function navigateToGrid(event) {
    console.log("Navigating to Grid");
    const storyId = event?.detail?.storyId || $storyStore?._id;
    
    // Save preview and navigate
    const savePreview = async () => {
      if (canvasComponent) {
        try {
          // Check if we need to update the preview
          if (storyStore.shouldUpdatePreview($selectedSlideIndex)) {
            // Generate and save the preview
            await canvasComponent.saveCanvasPreview();
            console.log('Canvas preview saved successfully');
          } else {
            console.log('Preview is up to date, skipping generation');
          }
          
          // Wait for the story to be saved
          const result = await storyStore.saveStory();
          if (!result) {
            throw new Error('Failed to save story');
          }
          console.log('Story saved successfully');
          
          // Navigate only after successful save
          if (storyId) {
            navigate(`/grid?story=${storyId}`);
          } else {
            navigate("/grid");
          }
        } catch (err) {
          console.error("Error saving:", err);
          alert('Failed to save the story. Please try again.');
        }
      } else {
        // If no canvas component, just navigate
        if (storyId) {
          navigate(`/grid?story=${storyId}`);
        } else {
          navigate("/grid");
        }
      }
    };

    // Execute the save and navigation
    savePreview();
  }

  // Clean up script when component is destroyed
  onDestroy(() => {
    currentScript = "";
    previousSlideIndex = null;
  });

  onMount(async () => {
    // Get URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const storyId = urlParams.get('story');
    const slideIndex = parseInt(urlParams.get('slide') || '0', 10);

    // Set the selected slide index
    selectedSlideIndex.set(slideIndex);

    try {
      // If we have a storyId in the URL, load it
      if (storyId) {
        console.log('Loading story from URL parameter:', storyId);
        const loadedStory = await storyStore.loadStory(storyId);
        if (!loadedStory) {
          console.error('Failed to load story:', storyId);
          // Handle error - maybe redirect to grid view
          navigate('/grid');
          return;
        }
      } else {
        // If no storyId in URL, check if we have one in the store
        const currentStory = get(storyStore);
        if (!currentStory._id) {
          console.error('No story ID found in URL or store');
          // Handle error - maybe redirect to grid view
          navigate('/grid');
          return;
        }
      }

      // Update URL to include story ID if not present
      const currentStory = get(storyStore);
      if (currentStory._id && !storyId) {
        const url = new URL(window.location);
        url.searchParams.set('story', currentStory._id);
        window.history.replaceState({}, '', url);
      }
    } catch (error) {
      console.error('Error initializing story:', error);
      // Handle error - maybe redirect to grid view
      navigate('/grid');
    }

    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";

    // Check if we need to load evidence
    const currentState = $storyStore;
    if (currentState.bigQuestion && $evidenceStore && $evidenceStore.evidenceCount === 0) {
      // If we have a big question but no evidence, search for it now
      isLoadingEvidence = true;
      try {
        await searchEvidence(currentState.bigQuestion);
      } finally {
        isLoadingEvidence = false;
      }
    }

    if (!isNaN(slideIndex)) {
      ensureSlideExists(slideIndex);
    } else {
      // If no slide index specified, go back to grid
      if (storyId) {
        navigate(`/grid?story=${storyId}`);
      } else {
        navigate("/grid");
      }
    }

    return () => {
      document.body.style.overflow = "";
      document.body.style.height = "";
    };
  });

  function startTitleEdit() {
    isEditingTitle = true;
  }

  function handleTitleChange(event) {
    const newTitle = event.target.value;
    slideTitle = newTitle; // Update local state immediately
    storyStore.updateSlideTitle($selectedSlideIndex, newTitle);
  }

  function handleTitleBlur() {
    isEditingTitle = false;
  }

  // Focus the input after render using Svelte's use directive
  function focusInput(node) {
    node.focus();
  }

  // Add a function to ensure slide exists
  function ensureSlideExists(index) {
    if (index === undefined || index < 0) return;
    
    const slide = storyStore.getSlide(index);
    if (!slide) {
      storyStore.addSlide({
        title: getDefaultTitle(index),
        text: '',
        evidence: [],
        images: [],
        canvasState: [],
        script: ''
      });
    }
  }

  function loadVoices() {
    let allVoices = window.speechSynthesis.getVoices();

    // Filter for only English voices
    voices = allVoices.filter(voice => voice.lang.startsWith("en"));

    // Manually prioritize Google voices if available (Chrome)
    const preferredVoices = voices.filter(voice => voice.name.includes("Google"));

    // Set default voice: Google voice > Default voice > First available
    selectedVoice = preferredVoices[0] || voices.find(v => v.default) || voices[0];
  }

  function handleGlobalClick(event) {
    const slideMenu = document.querySelector('.slide-menu-container');
    if (isSlideMenuOpen && slideMenu && !slideMenu.contains(event.target)) {
      isSlideMenuOpen = false;
    }
  }

  onMount(() => {
    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;
    document.addEventListener('click', handleGlobalClick);
  });

  onDestroy(() => {
    document.removeEventListener('click', handleGlobalClick);
  });

  function getVoiceCategories(voiceList) {
    return [...new Set(voiceList.map(voice => 
      voice.name.includes('Google') ? 'Google' : 
      voice.name.includes('Microsoft') ? 'Microsoft' : 'System'
    ))];
  }

  function filterVoices(voiceList, filter) {
    return voiceList.filter(voice => 
      voice.name.toLowerCase().includes(filter.toLowerCase()) ||
      voice.lang.toLowerCase().includes(filter.toLowerCase())
    );
  }

  function toggleSpeech() {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      isSpeaking = false;
      isPlaying = false;
      return;
    }

    if (currentScript.trim() === "" || !selectedVoice) {
      return;
    }

    let speech = new SpeechSynthesisUtterance(currentScript);
    speech.voice = selectedVoice;
    speech.onend = () => {
      isSpeaking = false;
      isPlaying = false;
    };
    speech.onstart = () => {
      isPlaying = true;
    };

    window.speechSynthesis.speak(speech);
    isSpeaking = true;
  }

  function previewVoice(voice) {
    const previewText = "Hello, this is a preview of my voice.";
    const speech = new SpeechSynthesisUtterance(previewText);
    speech.voice = voice;
    window.speechSynthesis.speak(speech);
  }

  function toggleModal() {
    isModalOpen = !isModalOpen;
  }

  function updateSelectedVoice(event) {
    // Fix: Don't try to set the name property directly
    const voiceName = event.target.value;
    selectedVoice = voices.find(v => v.name === voiceName);
  }

  // Cleanup when component is destroyed
  onDestroy(() => {
    window.speechSynthesis.cancel();
  });

  function handleSlideSelect({ detail }) {
    selectedSlideIndex.set(detail.index);
  }

  function handleTitleUpdate({ detail }) {
    storyStore.updateSlideTitle($selectedSlideIndex, detail.title);
  }

  function toggleScriptPanel() {
    isScriptPanelExpanded = !isScriptPanelExpanded;
  }
</script>

<div class="page-container">
  <HeaderBar />
  
  <BreadcrumbNav
    currentSlide={storyStore.getSlide($selectedSlideIndex)}
    slides={$storyStore.slides}
    currentSlideIndex={$selectedSlideIndex}
    on:selectSlide={handleSlideSelect}
    on:updateTitle={handleTitleUpdate}
    on:navigateToGrid={navigateToGrid}
  />

  <div class="content-container">
    <div class="agent-panel">
      <!--<AgentSlide />-->
    </div>
    <div class="tools-panel">
      <ItemsPanel
        bind:canvasComponent
        {selectedObject}
        {selectedObjectType}
        {evidenceData}
        on:addImageToCanvas={handleAddImageToCanvas}
        on:showAnalysis={handleShowAnalysis}
      />
    </div>
    <div class="canvas-panel" class:script-collapsed={!isScriptPanelExpanded}>
      <div id="canvas-item-submenu">
        <CanvasSubmenu
          {canvasComponent}
          {selectedObject}
          {selectedObjectType}
          {evidenceData}
          on:objectAction={handleObjectAction}
        />
      </div>
      <div class="canvas-container">
        <div class="canvas-scroll-area">
          <StoryCanvas
            bind:this={canvasComponent}
            on:updateSubMenu={handleUpdateSubMenu}
          />
        </div>
      </div>
      <div class="script-panel-container" class:collapsed={!isScriptPanelExpanded} role="complementary" aria-label="Script Editor">
        <button 
          class="panel-toggle"
          on:click={toggleScriptPanel}
          aria-expanded={isScriptPanelExpanded}
          aria-controls="scriptPanel"
          aria-label={isScriptPanelExpanded ? "Collapse script panel" : "Expand script panel"}
        >
          <div class="toggle-icon">
            <img 
              src={isScriptPanelExpanded ? "/assets/chevron-right.svg" : "/assets/chevron-left.svg"} 
              alt=""
              aria-hidden="true"
            />
          </div>
        </button>
        <div 
          id="scriptPanel"
          class="script-panel"
          role="region"
          aria-label="Script content"
        >
          <div class="panel-header">
            <div class="header-content">
              <img 
                src="/assets/script-icon.svg" 
                alt=""
                aria-hidden="true"
                class="panel-icon"
              />
              <span class="panel-title">Script</span>
            </div>
            {#if isScriptPanelExpanded}
              <div class="panel-actions">
                <button 
                  class="action-button"
                  on:click={toggleSpeech}
                  title={isSpeaking ? "Stop speaking" : "Start speaking"}
                  aria-label={isSpeaking ? "Stop speaking" : "Start speaking"}
                >
                  <img 
                    src={isPlaying ? "/assets/pause.svg" : "/assets/volume.svg"} 
                    alt=""
                    aria-hidden="true"
                  />
                </button>
                <button 
                  class="action-button"
                  on:click={toggleModal}
                  title="Voice settings"
                  aria-label="Open voice settings"
                >
                  <img 
                    src="/assets/carrot.svg" 
                    alt=""
                    aria-hidden="true"
                  />
                </button>
              </div>
            {/if}
          </div>
          {#if isScriptPanelExpanded}
            <div class="panel-content">
              {#if evidenceData}
                <button
                  class="quick-action-btn"
                  on:click={generateChartDescription}
                  disabled={isGeneratingDescription}
                >
                  {#if isGeneratingDescription}
                    <i class="fas fa-spinner fa-spin" aria-hidden="true"></i>
                    <span class="sr-only">Generating description...</span>
                  {:else}
                    <i class="fas fa-magic" aria-hidden="true"></i>
                    <span>Add chart description to script</span>
                  {/if}
                </button>
              {/if}
              <textarea
                class="script-content"
                placeholder="Add script for this slide..."
                bind:value={currentScript}
                on:input={handleScriptChange}
                aria-label="Slide script content"
              ></textarea>
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>

  {#if showModal && selectedEvidence}
    <div class="modal-backdrop" transition:fade on:click={closeModal}>
      <div class="modal-container" on:click|stopPropagation>
        <EvidenceModal
          evidence={selectedEvidence}
          isOpen={showModal}
          {closeModal}
          on:addToCanvas={(event) => handleAddImageToCanvas(event)}
        />
      </div>
    </div>
  {/if}

  {#if isLoadingEvidence}
    <div class="loading-overlay">
      <div class="spinner"></div>
      <p>Loading evidence...</p>
    </div>
  {/if}

  <!-- Floating modal for voice selection -->
  {#if isModalOpen}
    <div class="voice-modal" 
         role="dialog" 
         aria-labelledby="voice-selection-title"
         on:click|stopPropagation>
      <div class="voice-modal-header">
        <h3 id="voice-selection-title">Select Voice</h3>
        <button class="close-btn" on:click={toggleModal}>
          <i class="fas fa-times"></i>
        </button>
      </div>

      <div class="voice-search">
        <i class="fas fa-search search-icon"></i>
        <input
          type="text"
          placeholder="Search voices..."
          bind:value={currentVoiceFilter}
          class="voice-search-input"
        />
      </div>

      <div class="voice-list">
        {#each getVoiceCategories(voices) as category}
          <div class="voice-category">
            <h4>{category} Voices</h4>
            {#each filterVoices(voices.filter(v => 
              category === 'Google' ? v.name.includes('Google') :
              category === 'Microsoft' ? v.name.includes('Microsoft') :
              !v.name.includes('Google') && !v.name.includes('Microsoft')
            ), currentVoiceFilter) as voice}
              <div class="voice-option" 
                   class:selected={selectedVoice?.name === voice.name}>
                <div class="voice-info">
                  <label class="voice-name">
                    <input
                      type="radio"
                      name="voice-selection"
                      value={voice.name}
                      checked={selectedVoice?.name === voice.name}
                      on:change={() => {
                        selectedVoice = voice;
                        previewVoice(voice);
                      }}
                    />
                    {voice.name}
                  </label>
                  <span class="voice-lang">{voice.lang}</span>
                </div>
                <button 
                  class="preview-btn"
                  on:click={() => previewVoice(voice)}
                  title="Preview voice">
                  <i class="fas fa-play"></i>
                </button>
              </div>
            {/each}
          </div>
        {/each}
      </div>

      <div class="voice-modal-footer">
        <button class="apply-btn" on:click={toggleModal}>
          Apply Selection
        </button>
      </div>
    </div>
  {/if}
</div>

<style>
  :global(html),
  :global(body) {
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    width: 100%;
  }

  main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .page-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .content-container {
    display: flex;
    flex: 1;
    overflow: hidden;
    position: relative;
  }

  .agent-panel {
    flex: 0 0 auto;
    display: flex;
    transition: width 0.3s ease;
  }

  .tools-panel {
    width: 345px;
    min-width: 345px;
    flex: 0 0 auto;
    background: white;
    border-right: 1px solid #e5e7eb;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 30;
  }

  .canvas-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
    overflow: hidden;
    position: relative;
    padding-right: 300px; /* Make space for script panel */
    transition: padding-right 0.3s ease;
  }

  .canvas-panel.script-collapsed {
    padding-right: 48px; /* Collapsed width */
  }

  #canvas-item-submenu {
    flex: 0 0 48px;
    border-bottom: 1px solid #edf2f7;
    background: white;
    z-index: 20;
    position: relative;
    width: calc(100% + 300px); /* Extend over script panel */
    margin-right: -300px; /* Pull back to edge */
  }

  .canvas-container {
    flex: 1;
    overflow: auto;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
  }

  .canvas-scroll-area {
    width: 375px;
    flex-shrink: 0;
    position: relative;
    z-index: 10;
  }

  .script-panel-container {
    position: absolute;
    top: 48px;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    z-index: 10;
    transition: transform 0.2s ease;
  }

  .script-panel-container.collapsed {
    transform: translateX(calc(100% - 48px));
  }

  .panel-toggle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 32px;
    padding: 0;
    border: 0;
    background: #f8fafc;
    border: 1px solid #e2e8f0;
    border-right: none;
    border-radius: 6px 0 0 6px;
    cursor: pointer;
    z-index: 1;
  }

  .panel-toggle:hover {
    background: #f1f5f9;
  }

  .panel-toggle:focus {
    outline: none;
    box-shadow: -1px 0 0 2px #3b82f6;
  }

  .toggle-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    color: #64748b;
    transition: transform 0.2s ease;
  }

  .toggle-icon img {
    width: 100%;
    height: 100%;
  }

  .script-panel {
    width: 300px;
    background: white;
    border-left: 1px solid #e2e8f0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    background: #f8fafc;
    border-bottom: 1px solid #e2e8f0;
    height: 48px;
  }

  .header-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .panel-icon {
    width: 16px;
    height: 16px;
    color: #64748b;
  }

  .panel-title {
    font-size: 13px;
    font-weight: 500;
    color: #475569;
    user-select: none;
  }

  .panel-actions {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    padding: 0;
    border: none;
    background: transparent;
    border-radius: 4px;
    cursor: pointer;
    color: #64748b;
    transition: all 0.2s ease;
  }

  .action-button:hover {
    background: #e2e8f0;
    color: #475569;
  }

  .action-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px #bfdbfe;
  }

  .action-button img {
    width: 16px;
    height: 16px;
  }

  .panel-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: white;
  }

  .quick-action-btn {
    margin: 12px 16px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    border: none;
    background: #f0f7ff;
    color: #2563eb;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .quick-action-btn:hover {
    background: #e0f2fe;
  }

  .quick-action-btn:focus {
    outline: none;
    box-shadow: 0 0 0 2px #bfdbfe;
  }

  .quick-action-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .script-content {
    flex: 1;
    width: 100%;
    padding: 16px;
    border: none;
    resize: none;
    font-size: 14px;
    line-height: 1.5;
    color: #1e293b;
    background: #ffffff;
  }

  .script-content:focus {
    outline: none;
  }

  .script-content::placeholder {
    color: #94a3b8;
  }

  /* Accessibility */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  /* Animation keyframes for smoother transitions */
  @keyframes slideIn {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
  }

  @keyframes slideOut {
    from { transform: translateX(0); }
    to { transform: translateX(100%); }
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }

  .volume-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    border-radius: 6px;
    transition: all 0.2s ease;
  }

  .volume-button:hover {
    background: #f3f4f6;
  }

  .volume-button img {
    width: 20px;
    height: 20px;
    opacity: 0.8;
  }

  .volume-button:hover img {
    opacity: 1;
  }

  .voice-modal {
    position: absolute;
    top: 50px;
    right: 0;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    padding: 0;
    z-index: 1000;
    width: 320px;
    max-height: 480px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .voice-modal-header {
    padding: 16px;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .voice-modal-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #111827;
  }

  .close-btn {
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    color: #6b7280;
    border-radius: 4px;
  }

  .close-btn:hover {
    background: #f3f4f6;
    color: #111827;
  }

  .voice-search {
    padding: 12px 16px;
    border-bottom: 1px solid #e5e7eb;
    position: relative;
  }

  .voice-search-input {
    width: 100%;
    padding: 8px 32px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 14px;
  }

  .search-icon {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
  }

  .voice-list {
    flex: 1;
    overflow-y: auto;
    padding: 12px 0;
  }

  .voice-category {
    padding: 0 16px;
  }

  .voice-category h4 {
    margin: 8px 0;
    font-size: 12px;
    text-transform: uppercase;
    color: #6b7280;
    letter-spacing: 0.05em;
  }

  .voice-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    margin: 2px 0;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .voice-option:hover {
    background: #f3f4f6;
  }

  .voice-option.selected {
    background: #e0f2fe;
  }

  .voice-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .voice-name {
    font-size: 14px;
    color: #111827;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .voice-lang {
    font-size: 12px;
    color: #6b7280;
  }

  .preview-btn {
    background: none;
    border: none;
    padding: 6px;
    color: #2563eb;
    cursor: pointer;
    border-radius: 4px;
    opacity: 0;
    transition: all 0.2s ease;
  }

  .voice-option:hover .preview-btn {
    opacity: 1;
  }

  .preview-btn:hover {
    background: #e0f2fe;
  }

  .voice-modal-footer {
    padding: 12px 16px;
    border-top: 1px solid #e5e7eb;
    display: flex;
    justify-content: flex-end;
  }

  .apply-btn {
    padding: 8px 16px;
    background: #2563eb;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .apply-btn:hover {
    background: #1d4ed8;
  }

  .dropdown-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dropdown-button img {
    width: 16px;
    height: 16px;
  }

  .dropdown-button:hover img {
    opacity: 0.8;
  }

  .dropdown-container {
    position: relative;
    margin-left: -3px;
    z-index: 1001;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .slide-menu-container {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 200px;
    max-width: 400px;
  }

  .slide-controls {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    flex: 1;
  }

  .slide-title-button {
    flex: 1;
    padding: 0.5rem;
    background: none;
    border: none;
    border-radius: 4px;
    color: #1e293b;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    text-align: left;
    min-width: 0;
  }

  .slide-title-button span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .slide-select-button {
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    border-radius: 4px;
    color: #64748b;
    cursor: pointer;
  }

  .slide-select-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: #1e293b;
  }

  .title-input {
    flex: 1;
    font-size: 1rem;
    font-weight: 500;
    color: #1e293b;
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.05);
    min-width: 0;
    margin-right: 0.25rem;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 4px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    min-width: 240px;
    max-height: 400px;
    overflow-y: auto;
    z-index: 1001;
  }

  .dropdown-items {
    padding: 4px;
  }

  .dropdown-item {
    width: 100%;
    padding: 8px 12px;
    border: none;
    background: none;
    text-align: left;
    font-size: 0.875rem;
    color: #1e293b;
    cursor: pointer;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .dropdown-item.active {
    background-color: #e0f2fe;
    color: #0284c7;
  }

  /* Ensure proper stacking */
  .breadcrumb-navigation {
    position: relative;
    z-index: 10;
  }

  .submenu-controls {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px;
  }

  .control-group {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px;
    border-radius: 6px;
    background: #f8fafc;
  }

  .submenu-btn {
    height: 32px;
    width: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    color: #64748b;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .submenu-btn:hover {
    background: #e2e8f0;
    color: #334155;
  }

  .delete-btn:hover {
    background: #fee2e2;
    color: #dc2626;
  }

  .analyze-btn {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0 12px;
    height: 32px;
    background: #f0f7ff;
    color: #2563eb;
    border: none;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .analyze-btn:hover {
    background: #e0f2fe;
  }

  .btn-text {
    display: inline-block;
  }

  .color-picker {
    width: 32px;
    height: 32px;
    padding: 2px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .font-size-select {
    height: 32px;
    padding: 0 8px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background: white;
    color: #64748b;
    font-size: 12px;
    cursor: pointer;
  }

  .border-width {
    width: 100px;
    height: 32px;
    padding: 0 8px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    background: white;
    color: #64748b;
    font-size: 12px;
  }

  /* Hover states and interactions */
  .submenu-btn:active {
    transform: scale(0.95);
  }

  .font-size-select:hover,
  .color-picker:hover {
    border-color: #cbd5e1;
  }

  .font-size-select:focus,
  .color-picker:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
  }

  /* Tool separators */
  .control-group:not(:last-child)::after {
    content: '';
    height: 24px;
    width: 1px;
    background: #e2e8f0;
    margin: 0 4px;
  }

  /* Tooltip styles */
  .submenu-btn[title]::after {
    content: attr(title);
    position: absolute;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 11px;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    z-index: 1000;
  }

  .submenu-btn[title]:hover::after {
    opacity: 1;
    visibility: visible;
  }

  /* Icon styles */
  .submenu-btn i {
    font-size: 14px;
    transition: transform 0.2s ease;
  }

  .submenu-btn:hover i {
    transform: scale(1.1);
  }

  /* Disabled state */
  .submenu-btn:disabled,
  .analyze-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .submenu-btn:disabled:hover,
  .analyze-btn:disabled:hover {
    background: transparent;
    transform: none;
  }

  .toggle-panel-btn {
    position: absolute;
    left: 0;
    top: 12px;
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    z-index: 102;
  }

  .script-panel.collapsed .toggle-panel-btn {
    left: -28px;
    background: white;
    border: 1px solid #e5e7eb;
    border-right: none;
    border-radius: 4px 0 0 4px;
    padding: 4px;
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.05);
  }

  .toggle-panel-btn:hover {
    background: #f3f4f6;
  }

  .toggle-panel-btn img {
    width: 16px;
    height: 16px;
  }

  .script-panel.collapsed .toggle-panel-btn img {
    width: 14px;
    height: 14px;
  }

  .script-panel.collapsed .script-header {
    justify-content: center;
    padding: 12px 8px;
  }

  .script-icon {
    width: 20px;
    height: 20px;
    color: #64748b;
  }
</style>
