import { get } from 'svelte/store';
import { storyStore } from '../stores/storyStore.js';

const SHOTSTACK_API_URL = "https://api.shotstack.io/edit/stage/render";
// Get API key from environment or window.__env if it exists (browser environment)
const API_KEY = process.env.SHOTSTACK_API_KEY || 
                (typeof window !== 'undefined' && window.__env && window.__env.SHOTSTACK_API_KEY);

if (!API_KEY) {
    console.error("SHOTSTACK_API_KEY is not defined. Please set it in your .env file or make sure it's available in window.__env.");
    // Don't throw the error immediately as it would break the page loading
    // Instead we'll check for it when the function is called
}

function sanitizeFilename(title) {
    return title.replace(/[/\\?%*:|"<>]/g, '_').replace(/\s+/g, '_').trim() || 'story';
}

export async function generateVideo(renderedSlides, voice = 'Amy') {
    console.log('Generating video from story data...');
    console.log('Input data (renderedSlides):', renderedSlides);
    console.log('Using voice:', voice);

    // Check for API key before proceeding
    if (!API_KEY) {
        throw new Error("SHOTSTACK_API_KEY is not defined. Please set it in your .env file or make sure it's available in window.__env.");
    }

    // Map browser voices to Shotstack voices
    // Shotstack supported voices: Amy, Brian, Emma, Charlotte, Daniel, Liam, Matthew, Michael, Oliver, Thomas, William
    const voiceMap = {
        'Alex': 'Brian',
        'Daniel': 'Daniel',
        'Emma': 'Emma',
        'Charlotte': 'Charlotte',
        'Liam': 'Liam',
        'Matthew': 'Matthew',
        'Michael': 'Michael',
        'Oliver': 'Oliver',
        'Thomas': 'Thomas',
        'William': 'William',
        'Amy': 'Amy',
        'Brian': 'Brian'
    };
    
    // Use the mapped voice or default to Amy if not found
    const shotstackVoice = voiceMap[voice] || 'Amy';
    console.log('Mapped to Shotstack voice:', shotstackVoice);

    const timeline = { tracks: [{ clips: [] }, { clips: [] }] };

    let startTime = 0.0;
    let totalDuration = 0.0;

    renderedSlides.forEach((slide, index) => {
        const imageUrl = slide.previewImage;

        let ttsDuration = 5.0; // Default duration
        if (slide.script) {
            const words = slide.script.split(/\s+/).length; // Count words
            const wordsPerMinute = 150;
            ttsDuration = (words / wordsPerMinute) * 60;
        }

        const buffer = 1.0;
        const imageDuration = ttsDuration + buffer;
        totalDuration += imageDuration;

        if (imageUrl) {
            timeline.tracks[0].clips.push({
                asset: { type: 'image', src: imageUrl },
                length: imageDuration,
                start: startTime,
                fit: 'contain',
            });
        } else {
            console.error(`No preview image found for slide ${index}`);
        }

        if (slide.script) {
            timeline.tracks[1].clips.push({
                asset: { type: 'text-to-speech', text: slide.script, voice: shotstackVoice, volume: 1 },
                start: startTime,
                length: ttsDuration,
            });
        }

        startTime += imageDuration;
    });

    console.log('Generated timeline:', JSON.stringify(timeline, null, 2));

    try {
        console.log('Making request to Shotstack API with body:', JSON.stringify({
            timeline,
            output: { format: 'mp4', resolution: 'hd' }
        }, null, 2));

        const response = await fetch(SHOTSTACK_API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': API_KEY,
            },
            body: JSON.stringify({
                timeline,
                output: { format: 'mp4', resolution: 'hd' },
            }),
        });

        console.log('Shotstack API Response Status:', response.status);

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error generating video:', errorData);
            throw new Error('Failed to generate video', { cause: { response: errorData } });
        }

        const data = await response.json();
        console.log('Shotstack API Response:', JSON.stringify(data, null, 2));
        
        // Store the render ID and additional metadata for later access
        const renderId = data.response?.id;
        if (renderId) {
            // Add to state for tracking
            window.__videoData = {
                renderId,
                voice: shotstackVoice,
                duration: Math.round(totalDuration),
                status: 'processing',
                resolution: 'hd'
            };
        }
        
        return renderId;
    } catch (error) {
        console.error('Error generating video:', error);
        throw new Error('Failed to generate video');
    }
}

export async function waitForVideo(renderId) {
    let videoUrl = null;
    let attempts = 0;
    const maxAttempts = 60; // 5 minutes maximum wait time (5 seconds * 60)

    while (!videoUrl && attempts < maxAttempts) {
        try {
            console.log(`Checking render status for ID: ${renderId} (attempt ${attempts + 1}/${maxAttempts})`);
            const response = await fetch(`${SHOTSTACK_API_URL}/${renderId}`, {
                headers: {
                    'x-api-key': API_KEY
                }
            });

            console.log('Shotstack API Response Status:', response.status);

            if (!response.ok) {
                const errorData = await response.json();
                console.error("Error checking video status:", errorData);
                throw new Error("Failed to get video URL", { cause: { response: errorData } });
            }

            const data = await response.json();
            const status = data.response.status;
            console.log(`Render Status: ${status}.`);

            if (status === "done") {
                videoUrl = data.response.url;
                console.log('Video render complete. Video URL:', videoUrl);
                
                // Save the video information to our database
                try {
                    await saveVideoToDatabase({
                        renderId,
                        url: videoUrl,
                        voice: window.__videoData?.voice || 'Amy',
                        duration: window.__videoData?.duration || 0
                    });
                } catch (saveError) {
                    console.error("Error saving video to database:", saveError);
                    // Don't fail the whole process if saving to DB fails
                }
            } else if (status === "failed") {
                throw new Error("Video rendering failed", { cause: { response: data } });
            } else {
                console.log(`Render Status: ${status}. Retrying in 5 seconds...`);
                await new Promise(resolve => setTimeout(resolve, 5000));
                attempts++;
            }
        } catch (error) {
            console.error("Error checking video status:", error);
            throw error;
        }
    }

    if (!videoUrl) {
        throw new Error("Video rendering timed out after 5 minutes");
    }

    return videoUrl;
}

// Function to save video information to our database
async function saveVideoToDatabase(videoData) {
    try {
        const currentStory = get(storyStore);
        if (!currentStory || !currentStory._id) {
            console.error("Cannot save video: No active story found");
            return;
        }
        
        const payload = {
            storyId: currentStory._id,
            url: videoData.url,
            renderId: videoData.renderId,
            title: currentStory.title || "Untitled Video",
            voice: videoData.voice,
            description: `Video generated from ${currentStory.title || "story"} with ${videoData.voice} voice.`,
            duration: videoData.duration,
            status: 'completed'
        };
        
        const response = await fetch('/api/videos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to save video information: ${errorData.error || 'Unknown error'}`);
        }
        
        const data = await response.json();
        console.log('Video information saved successfully:', data);
        return data;
    } catch (error) {
        console.error('Error saving video information:', error);
        throw error;
    }
}

// Function to get all videos for a story
export async function getVideosForStory(storyId) {
    try {
        const response = await fetch(`/api/videos/story/${storyId}`);
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to fetch videos: ${errorData.error || 'Unknown error'}`);
        }
        
        const videos = await response.json();
        return videos;
    } catch (error) {
        console.error('Error fetching videos for story:', error);
        return [];
    }
}

// Function to delete a video
export async function deleteVideo(videoId) {
    try {
        const response = await fetch(`/api/videos/${videoId}`, {
            method: 'DELETE'
        });
        
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to delete video: ${errorData.error || 'Unknown error'}`);
        }
        
        return true;
    } catch (error) {
        console.error('Error deleting video:', error);
        return false;
    }
}
