<!-- src/pages/HomePage.svelte -->
<script>
  import { navigate } from "svelte-routing";
  import HomeSidebar from '../components/Home-Sidebar.svelte';
  import HomeCreateNewStory from '../components/Home-CreateNewStory.svelte';
  import HomeMyDataStories from '../components/Home-MyDataStories.svelte';
  import HomeClassroomLink from '../components/Home-ClassroomLink.svelte';
  import HomeResources from '../components/Home-Resources.svelte';
  import HomeAdminConsole from '../components/Home-AdminConsole.svelte';
  import { authStore } from '../stores/authStore.js';
  import { onMount } from 'svelte';
  import PromptSelection from '../components/PromptSelection.svelte';

  // Track which component to show
  let currentComponent = 'Home-CreateNewStory';

  // Debug logging
  $: {
    console.log('Current component:', currentComponent);
    console.log('Auth state:', $authStore);
    console.log('Is user admin?', $authStore?.user?.isAdmin);
  }

  // Handle menu selection
  function handleMenuSelect(event) {
    console.log('Menu selection event:', event.detail);
    currentComponent = event.detail.component;
  }
  
  function startDataStory() {
    // Check if user is authenticated before navigating
    if ($authStore.isAuthenticated) {
      navigate("/tool");
    } else {
      // Prompt login
      authStore.loginWithGoogle();
    }
  }
</script>

<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <a class="navbar-brand" href="/">
      <i class="bi bi-graph-up"></i> Data Remixer
    </a>
    
    <div class="ms-auto d-flex align-items-center">
      {#if $authStore.isLoading}
        <div class="spinner-border spinner-border-sm text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      {:else if $authStore.isAuthenticated}
        <div class="user-info">
          <div class="profile-container">
            {#if $authStore.user?.photoURL}
              <img 
                src={$authStore.user.photoURL} 
                alt="" 
                class="profile-image"
                referrerpolicy="no-referrer"
              />
            {:else if $authStore.user?.profileImage}
              <img 
                src={$authStore.user.profileImage} 
                alt="" 
                class="profile-image"
                referrerpolicy="no-referrer"
              />
            {:else}
              <div class="profile-placeholder">
                {$authStore.user?.displayName?.charAt(0).toUpperCase() || 'U'}
              </div>
            {/if}
          </div>
          <span class="user-name">{$authStore.user?.displayName || 'User'}</span>
          <!-- Debug: Show admin status -->
          {#if $authStore.user?.isAdmin}
            <span class="admin-badge">Admin</span>
          {/if}
        </div>
        <button 
          class="btn btn-outline-secondary btn-sm ms-3" 
          on:click={() => authStore.logout()}
        >
          Sign Out
        </button>
      {:else}
        <button class="btn btn-primary" on:click={() => authStore.loginWithGoogle()}>
          <i class="bi bi-google me-2"></i>
          Sign in with Google
        </button>
      {/if}
    </div>
  </div>
</nav>

<main>
  <div class="container-fluid">
    <div class="row">
      <div class="col-3" id="home-sidebar">
        <HomeSidebar on:menuSelect={handleMenuSelect} />
      </div>
      <div class="col">
        {#if currentComponent === 'Home-CreateNewStory'}
          <HomeCreateNewStory />
        {:else if currentComponent === 'Home-MyDataStories'}
          <HomeMyDataStories />
        {:else if currentComponent === 'Home-ClassroomLink'}
          <HomeClassroomLink />
        {:else if currentComponent === 'Home-Resources'}
          <HomeResources />
        {:else if currentComponent === 'Home-AdminConsole'}
          <HomeAdminConsole />
        {:else}
          <!-- Debug: Show unknown component -->
          <div class="alert alert-warning">
            Unknown component: {currentComponent}
          </div>
        {/if}
      </div>
    </div>
  </div>
</main>

<style>
  main {
    padding: 20px;
  }

  #home-sidebar {
    padding: 20px;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 4px;
    border-radius: 24px;
    background: #f8fafc;
  }

  .profile-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e2e8f0;
  }

  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .profile-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #94a3b8;
    color: white;
    font-weight: 500;
    font-size: 14px;
  }

  .user-name {
    font-size: 14px;
    color: #475569;
    font-weight: 500;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* Debug: Admin badge style */
  .admin-badge {
    font-size: 12px;
    padding: 2px 6px;
    background: #2563eb;
    color: white;
    border-radius: 4px;
    font-weight: 500;
  }
</style>