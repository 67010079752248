import { API_BASE_URL } from '../config/apiConfig';

// Get the base API URL from the centralized config
const getApiBaseUrl = () => {
  return API_BASE_URL;
};

const API_URL = getApiBaseUrl();

/**
 * Log agent activity
 * @param {Object} params - Activity parameters
 * @returns {Promise<Object>} - The created log entry
 */
export async function logAgentActivity(params) {
  try {
    const response = await fetch(`${API_URL}/api/agent-activity/log`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params)
    });

    if (!response.ok) {
      throw new Error(`Failed to log agent activity: ${response.statusText}`);
    }

    const data = await response.json();
    return data.log;
  } catch (error) {
    console.error('Error logging agent activity:', error);
    throw error;
  }
}

/**
 * Get agent activity logs for a chat thread
 * @param {string} chatThreadId - ID of the chat thread
 * @returns {Promise<Array>} - Array of log entries
 */
export async function getAgentActivityLogsByChatThread(chatThreadId) {
  try {
    const response = await fetch(`${API_URL}/api/agent-activity/chat/${chatThreadId}`, {
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`Failed to get agent activity logs: ${response.statusText}`);
    }

    const data = await response.json();
    return data.logs;
  } catch (error) {
    console.error('Error getting agent activity logs:', error);
    throw error;
  }
}

/**
 * Get agent activity logs for a user
 * @param {string} userId - ID of the user
 * @param {Object} options - Query options
 * @returns {Promise<Array>} - Array of log entries
 */
export async function getAgentActivityLogsByUser(userId, options = {}) {
  try {
    const queryParams = new URLSearchParams({
      userId,
      ...(options.limit && { limit: options.limit.toString() }),
      ...(options.startDate && { startDate: options.startDate.toISOString() }),
      ...(options.endDate && { endDate: options.endDate.toISOString() })
    });

    const response = await fetch(`${API_URL}/api/agent-activity/user?${queryParams}`, {
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`Failed to get agent activity logs: ${response.statusText}`);
    }

    const data = await response.json();
    return data.logs;
  } catch (error) {
    console.error('Error getting agent activity logs by user:', error);
    throw error;
  }
}

/**
 * Get agent activity logs for a story
 * @param {string} storyId - ID of the story
 * @returns {Promise<Array>} - Array of log entries
 */
export async function getAgentActivityLogsByStory(storyId) {
  try {
    const response = await fetch(`${API_URL}/api/agent-activity/story/${storyId}`, {
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`Failed to get agent activity logs: ${response.statusText}`);
    }

    const data = await response.json();
    return data.logs;
  } catch (error) {
    console.error('Error getting agent activity logs by story:', error);
    throw error;
  }
}

/**
 * Get agent activity logs for a specific evidence
 * @param {string} evidenceId - ID of the evidence
 * @returns {Promise<Array>} - Array of log entries
 */
export async function getAgentActivityLogsByEvidence(evidenceId) {
  try {
    const response = await fetch(`${API_URL}/api/agent-activity/evidence/${evidenceId}`, {
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`Failed to get agent activity logs: ${response.statusText}`);
    }

    const data = await response.json();
    return data.logs;
  } catch (error) {
    console.error('Error getting agent activity logs by evidence:', error);
    throw error;
  }
} 