<script>
  const resources = [
    {
      title: "Getting Started Guide",
      description: "Learn the basics of creating data stories",
      icon: "bi-book",
      link: "#"
    },
    {
      title: "Teacher Resources",
      description: "Lesson plans and classroom activities",
      icon: "bi-mortarboard",
      link: "#"
    },
    {
      title: "Tutorial Videos",
      description: "Step-by-step video guides",
      icon: "bi-play-circle",
      link: "#"
    }
  ];
</script>

<div class="resources-container">
  <div class="content">
    <h1>Classroom Resources</h1>
    <p class="intro">Access helpful resources to enhance your teaching experience.</p>
    
    <div class="resources-grid">
      {#each resources as resource}
        <a href={resource.link} class="resource-card">
          <i class="bi {resource.icon}"></i>
          <h2>{resource.title}</h2>
          <p>{resource.description}</p>
        </a>
      {/each}
    </div>
  </div>
</div>

<style>
  .resources-container {
    padding: 2rem;
    height: 100%;
  }

  .content {
    max-width: 800px;
    margin: 0 auto;
  }

  h1 {
    font-size: 2rem;
    color: #1a1a1a;
    margin-bottom: 1rem;
  }

  .intro {
    color: #666;
    margin-bottom: 2rem;
    font-size: 1.1rem;
  }

  .resources-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
  }

  .resource-card {
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    text-decoration: none;
    color: inherit;
    transition: all 0.2s ease;
  }

  .resource-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .resource-card i {
    font-size: 2rem;
    color: #2563eb;
    margin-bottom: 1rem;
  }

  .resource-card h2 {
    font-size: 1.25rem;
    color: #1a1a1a;
    margin-bottom: 0.5rem;
  }

  .resource-card p {
    color: #666;
    font-size: 0.9rem;
    margin: 0;
  }
</style> 