<script>
  export let canvasComponent; // Receive the canvasComponent as a prop

  // Define font sizes for the canvas text
  const headingFontSize = 24; // Font size for headings
  const paragraphFontSize = 14; // Font size for paragraphs
  const captionFontSize = 12; // Font size for captions

  // Function to add heading
  function addHeading() {
    console.log('Add Heading button clicked');
    if (canvasComponent) {
      console.log('Canvas component is available');
      canvasComponent.addTextToCanvas('Heading Text', {
        left: 100,
        top: 100,
        fontFamily: 'Arial',
        fontSize: headingFontSize,
        fontWeight: 'bold'
      });
    } else {
      console.error('Canvas component is not available');
    }
  }

  // Function to add paragraph
  function addParagraph() {
    console.log('Add Paragraph button clicked');
    if (canvasComponent) {
      console.log('Canvas component is available');
      canvasComponent.addTextToCanvas('Paragraph Text', {
        left: 100,
        top: 150,
        fontFamily: 'Arial',
        fontSize: paragraphFontSize,
        width: 300,
        lineHeight: 1.2
      });
    } else {
      console.error('Canvas component is not available');
    }
  }

  // Function to add caption
  function addCaption() {
    console.log('Add Caption button clicked');
    if (canvasComponent) {
      console.log('Canvas component is available');
      canvasComponent.addTextToCanvas('Caption Text', {
        left: 100,
        top: 200,
        fontFamily: 'Arial',
        fontSize: captionFontSize,
        fontStyle: 'italic'
      });
    } else {
      console.error('Canvas component is not available');
    }
  }
</script>

<div class="text-menu">
  <div class="text-options">
    <button class="text-button" on:click={addHeading}>
      <i class="fas fa-heading"></i>
      Add Heading
      <span class="text-preview">H1</span>
    </button>
    
    <button class="text-button" on:click={addParagraph}>
      <i class="fas fa-paragraph"></i>
      Add Paragraph
      <span class="text-preview">P</span>
    </button>
    
    <button class="text-button" on:click={addCaption}>
      <i class="fas fa-quote-right"></i>
      Add Caption
      <span class="text-preview">Caption</span>
    </button>
  </div>
</div>

<style>
  .text-menu {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    width: 253px;
  }

  .text-options {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .text-button {
    width: 237px; /* 253px - (8px * 2) padding */
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    gap: 8px;
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 13px;
    color: #1f2937;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .text-button:hover {
    background: #f8fafc;
    border-color: #2563eb;
  }

  .text-button i {
    width: 16px;
    color: #6b7280;
  }

  .text-preview {
    font-size: 13px;
    color: #6b7280;
    margin-left: auto;
    font-weight: 500;
  }

  .templates-section {
    padding: 12px;
    border-top: 1px solid #e5e7eb;
  }

  .section-title {
    font-size: 12px;
    font-weight: 500;
    color: #6b7280;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 8px;
  }

  .template-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  .template-item {
    aspect-ratio: 1;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    color: #4b5563;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .template-item:hover {
    border-color: #2563eb;
    background: #f0f7ff;
    color: #2563eb;
  }

  .styled-button {
    background-color: #f5f5f5; /* Light background color */
    color: #333; /* Dark text color */
    border: 1px solid #ccc; /* Light border */
    border-radius: 8px; /* Rounded corners */
    padding: 12px 24px; /* Increased padding for a larger button */
    font-weight: 500; /* Medium font weight */
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s; /* Smooth transitions */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }

  .heading-button {
    font-size: 24px; /* Match heading font size */
  }

  .paragraph-button {
    font-size: 14px; /* Match paragraph font size */
  }

  .caption-button {
    font-size: 12px; /* Match caption font size */
  }

  .styled-button:hover {
    background-color: #e0e0e0; /* Slightly darker on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
    transform: translateY(-1px); /* Slight lift effect */
  }

  .styled-button:active {
    background-color: #d0d0d0; /* Even darker shade for active state */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reset shadow */
    transform: translateY(0); /* Reset lift effect */
  }
</style>
