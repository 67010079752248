<script>
  import { createEventDispatcher, onMount } from 'svelte';
  
  export let fullscreen = false;
  
  const dispatch = createEventDispatcher();
  
  function closeModal() {
    dispatch('close');
  }
  
  function handleOverlayClick(event) {
    // Only close if clicking directly on the overlay (not the content)
    if (event.target === event.currentTarget) {
      closeModal();
    }
  }
  
  // Prevent scrolling when modal is open
  onMount(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  });
</script>

<div class="modal-overlay" on:click={handleOverlayClick}>
  <div class={`modal-container ${fullscreen ? 'fullscreen' : ''}`} on:click|stopPropagation>
    <div class="modal-header">
      <slot name="header">
        <h2>Modal Title</h2>
      </slot>
      
      <button class="close-button" on:click={closeModal} aria-label="Close modal">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
    
    <div class="modal-body">
      <slot name="body">
        <p>Modal content goes here</p>
      </slot>
    </div>
    
    <div class="modal-footer">
      <slot name="footer">
        <button class="button" on:click={closeModal}>Close</button>
      </slot>
    </div>
  </div>
</div>

<style>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    padding: 20px;
  }
  
  .modal-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }
  
  .modal-container.fullscreen {
    max-width: 1200px;
    height: 90vh;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .modal-body {
    flex: 1;
    padding: 24px;
    overflow-y: auto;
  }
  
  .modal-footer {
    padding: 16px 24px;
    border-top: 1px solid #e5e7eb;
    display: flex;
    justify-content: flex-end;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6b7280;
  }
  
  .close-button:hover {
    color: #111827;
  }
  
  .button {
    padding: 8px 16px;
    background-color: #4b5563;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #374151;
  }
</style>
