<script>
  import { onMount } from 'svelte';
  import { fade, scale } from 'svelte/transition';
  import { setSelectedPrompt, promptStore, savePrompt, removeSavedPrompt, loadSavedPrompts } from '../stores/promptStore';
  import { fetchSelectedEvidence, searchEvidence } from '../stores/evidenceStore';
  import { storyStore } from '../stores/storyStore.js';
  import { navigate } from "svelte-routing";
  import { apiService } from '../services/apiService';
  import { authStore } from '../stores/authStore.js';

  export let prompts = [];
  let loading = false;
  let selectedPrompt = null;
  let showDetails = false;
  let activeTab = 'browse';
  let browsedPrompts = [];
  let displayLimit = 20;
  let loadingMore = false;
  let browseLoading = false;
  let expandedPromptId = null;
  let modalPrompt = null;
  let browseSearchQuery = "";
  let originalBrowsedPrompts = [];
  let filteredPrompts = [];
  let displayedPrompts = [];
  let isSearching = false;
  let searchQuery = "";
  let hasMorePrompts = false;
  let totalPrompts = 0;
  let hasSubmittedSearch = false;
  let currentStep = 0;
  let showSearchTips = false;
  
  // Add search type toggle
  let searchType = 'text'; // 'text' or 'semantic'
  let searchErrorMessage = '';
  let showSearchError = false;

  $: savedPrompts = $promptStore?.savedPrompts || [];

  // Add this for tracking expanded states
  let expandedPrompts = {};

  const steps = [
    {
      title: "Choose Your Question",
      description: "Browse through our curated questions or search for topics that interest you. Each question is designed to help you explore data in meaningful ways.",
      image: "/images/guide/step1.png"
    },
    {
      title: "Select Your Template",
      description: "Pick a template that best fits your story. Each template is designed for different types of data visualization and storytelling approaches.",
      image: "/images/guide/step2.png"
    },
    {
      title: "Add Your Data",
      description: "Upload your data or use our sample datasets. Our tool will help you organize and prepare your data for visualization.",
      image: "/images/guide/step3.png"
    },
    {
      title: "Create Visualizations",
      description: "Use our intuitive tools to create beautiful and informative visualizations that help tell your story.",
      image: "/images/guide/step4.png"
    },
    {
      title: "Tell Your Story",
      description: "Add your narrative, insights, and conclusions to create a compelling data story that engages your audience.",
      image: "/images/guide/step5.png"
    }
  ];

  onMount(async () => {
    // Only load saved prompts if authenticated
    if ($authStore.isAuthenticated) {
      loadSavedPrompts();
    }
    
    await loadInitialPrompts();
    
    // Check if we have a pending prompt selection after authentication
    const pendingPromptId = localStorage.getItem('pendingPromptId');
    if (pendingPromptId && $authStore.isAuthenticated) {
      // Clear the pending prompt ID
      localStorage.removeItem('pendingPromptId');
      // Process the prompt selection
      await selectPrompt(pendingPromptId);
      // Navigate to tool page if selection was successful
      if (selectedPrompt) {
        startDataStory();
      }
    }
  });

  async function loadInitialPrompts() {
    browseLoading = true;
    try {
      const response = await fetch("/api/prompts?limit=20");
      if (!response.ok) throw new Error(response.statusText);
      const data = await response.json();
      prompts = data.prompts;
      displayedPrompts = prompts;
      totalPrompts = data.total;
      hasMorePrompts = prompts.length < totalPrompts;
    } catch (error) {
      console.error("Error loading prompts:", error);
    } finally {
      browseLoading = false;
    }
  }

  async function selectPrompt(promptId, fromBrowse = false) {
    if (!promptId) return;
    if (fromBrowse) {
      modalPrompt = prompts.find(p => p._id.toString() === promptId);
      return;
    }
    
    // Only require authentication when starting a data story
    loading = true;
    try {
      await setSelectedPrompt(promptId);
      selectedPrompt = prompts.find(p => p._id.toString() === promptId);
      if (selectedPrompt) {
        // Just update the big question, but don't search for evidence yet
        storyStore.updateBigQuestion(selectedPrompt.bigQuestion);
        storyStore.updateTemplate('none'); // Reset template, will be selected in grid page
        showDetails = true;
      }
    } catch (error) {
      console.error("Error selecting prompt:", error);
    } finally {
      loading = false;
    }
  }

  function startDataStory() {
    // Only check authentication when starting the data story
    if (!$authStore.isAuthenticated) {
      // Store the prompt ID for after authentication
      if (selectedPrompt) {
        localStorage.setItem('pendingPromptId', selectedPrompt._id);
      }
      // Set redirect path to the current page
      authStore.setRedirect(window.location.pathname);
      // Trigger login
      authStore.loginWithGoogle();
      return;
    }
    // Navigate to grid page to select template
    navigate("/grid");
  }

  function selectRandomQuestion() {
    if (prompts.length === 0) return;
    
    // Get random prompt
    const randomPrompt = prompts[Math.floor(Math.random() * prompts.length)];
    
    // Open it in the modal
    selectPrompt(randomPrompt._id.toString(), true);
  }

  function toggleSavePrompt(prompt, event) {
    event.preventDefault();
    event.stopPropagation(); // Ensure the event doesn't bubble up
    
    // If not authenticated, prompt for login
    if (!$authStore.isAuthenticated) {
      authStore.loginWithGoogle();
      return;
    }
    
    const isSaved = savedPrompts.some(p => p._id === prompt._id);
    if (isSaved) {
      removeSavedPrompt(prompt._id);
    } else {
      // Create a clean prompt object with only necessary fields
      const promptToSave = {
        _id: prompt._id,
        bigQuestion: prompt.bigQuestion,
        promptScript: prompt.promptScript,
        topics: prompt.topics
      };
      savePrompt(promptToSave);
    }
  }

  $: isCurrentPromptSaved = selectedPrompt && 
     savedPrompts.some(p => p._id === selectedPrompt._id);

  async function handleSearch(event) {
    if (event.type === 'click' || (event.type === 'keydown' && event.key === 'Enter')) {
      event.preventDefault();
      hasSubmittedSearch = true;
      searchErrorMessage = '';
      showSearchError = false;
      
      if (!browseSearchQuery.trim()) {
        await loadInitialPrompts();
        return;
      }

      isSearching = true;
      try {
        // First try with chosen search type
        try {
          // Pass the searchType to the API service
          const searchResults = await apiService.searchPrompts(
            browseSearchQuery.trim(), 
            { searchType }
          );
          displayedPrompts = searchResults;
          hasMorePrompts = false;
        } catch (error) {
          // If semantic search fails, fall back to text search
          if (searchType === 'semantic') {
            console.log('Semantic search failed, falling back to text search:', error.message);
            // Show a brief notification to the user
            searchErrorMessage = 'Smart search is temporarily unavailable. Using basic search instead.';
            showSearchError = true;
            const fallbackResults = await apiService.searchPrompts(
              browseSearchQuery.trim(), 
              { searchType: 'text' }
            );
            displayedPrompts = fallbackResults;
            hasMorePrompts = false;
          } else {
            // Re-throw if it's already a text search
            throw error;
          }
        }
      } catch (error) {
        console.error('Search failed:', error);
        displayedPrompts = [];
        searchErrorMessage = 'Search failed. Please try again later.';
        showSearchError = true;
      } finally {
        isSearching = false;
      }
    }
  }

  // Toggle between basic and smart search
  function toggleSearchType() {
    searchType = searchType === 'text' ? 'semantic' : 'text';
    
    // If there's an active search query, re-run the search
    if (hasSubmittedSearch && browseSearchQuery.trim()) {
      handleSearch({ type: 'click', preventDefault: () => {} });
    }
  }

  async function loadMore() {
    if (loadingMore || !hasMorePrompts) return;
    
    loadingMore = true;
    try {
      const offset = displayedPrompts.length;
      const response = await fetch(`/api/prompts?limit=20&offset=${offset}`);
      if (!response.ok) throw new Error(response.statusText);
      
      const data = await response.json();
      displayedPrompts = [...displayedPrompts, ...data.prompts];
      hasMorePrompts = displayedPrompts.length < data.total;
    } catch (error) {
      console.error("Error loading more prompts:", error);
    } finally {
      loadingMore = false;
    }
  }

  function resetBrowseFilters() {
    browseSearchQuery = "";
    hasSubmittedSearch = false;
    loadInitialPrompts();
  }

  function closeModal() {
    modalPrompt = null;
  }

  function toggleExpandPrompt(promptId) {
    expandedPrompts[promptId] = !expandedPrompts[promptId];
    expandedPrompts = expandedPrompts; // trigger reactivity
  }
</script>

<div class="prompt-selection-container">
  <div class="tabs-container">
    <div class="left-tabs">
      <button 
        class="tab-button" 
        class:active={activeTab === 'browse'}
        on:click={() => activeTab = 'browse'}
      >
        <i class="bi bi-grid"></i>
        Browse
      </button>
      {#if $authStore.isAuthenticated}
        <button 
          class="tab-button" 
          class:active={activeTab === 'saved'}
          on:click={() => activeTab = 'saved'}
        >
          <i class="bi bi-bookmark"></i>
          Saved
        </button>
      {/if}
    </div>
    <button 
      class="tab-button guide-tab" 
      class:active={activeTab === 'guide'}
      on:click={() => activeTab = 'guide'}
    >
      <i class="bi bi-lightbulb"></i>
      Guide Me
    </button>
  </div>

  {#if activeTab === 'saved'}
    {#if !$authStore.isAuthenticated}
      <div class="empty-state" transition:fade={{ duration: 300 }}>
        <div class="empty-state-decoration"></div>
        <div class="empty-state-dots"></div>
        <div class="empty-state-icon" in:scale={{ delay: 300, duration: 400, start: 0.8 }}>
          <i class="bi bi-person-lock"></i>
        </div>
        <h3 class="empty-state-title" in:scale={{ delay: 400, duration: 300, start: 0.9 }}>Sign in required</h3>
        <p class="empty-state-message" in:scale={{ delay: 500, duration: 300, start: 0.9 }}>Please sign in to save and view your prompts</p>
        <button 
          class="empty-state-button"
          on:click={() => authStore.loginWithGoogle()}
          in:scale={{ delay: 600, duration: 300, start: 0.9 }}
        >
          <i class="bi bi-google"></i>
          Sign in with Google
        </button>
      </div>
    {:else if !savedPrompts || savedPrompts.length === 0}
      <div class="empty-state" transition:fade={{ duration: 300 }}>
        <div class="empty-state-decoration"></div>
        <div class="empty-state-dots"></div>
        <div class="empty-state-icon" in:scale={{ delay: 300, duration: 400, start: 0.8 }}>
          <i class="bi bi-bookmark"></i>
        </div>
        <h3 class="empty-state-title" in:scale={{ delay: 400, duration: 300, start: 0.9 }}>No saved prompts yet</h3>
        <p class="empty-state-message" in:scale={{ delay: 500, duration: 300, start: 0.9 }}>Browse and save prompts that interest you</p>
        <button 
          class="empty-state-button"
          on:click={() => activeTab = 'browse'}
          in:scale={{ delay: 600, duration: 300, start: 0.9 }}
        >
          <i class="bi bi-grid"></i>
          Browse prompts
        </button>
      </div>
    {:else}
      {#each savedPrompts as prompt, i}
        {#if prompt && prompt.bigQuestion && prompt.promptScript}
          <div class="prompt-card">
            <div class="question-section" data-index={i % 10}>
              <h3 class="prompt-title">{prompt.bigQuestion}</h3>
            </div>
            
            <div class="prompt-content">
              <div class="prompt-preview-container">
                <p class="prompt-preview" class:expanded={expandedPrompts[prompt._id]}>
                  {prompt.promptScript}
                </p>
                {#if prompt.promptScript.length > 200 && !expandedPrompts[prompt._id]}
                  <button 
                    class="expand-button"
                    on:click={(e) => {
                      e.stopPropagation();
                      toggleExpandPrompt(prompt._id);
                    }}
                  >
                    more
                  </button>
                {:else if expandedPrompts[prompt._id]}
                  <button 
                    class="expand-button"
                    on:click={(e) => {
                      e.stopPropagation();
                      toggleExpandPrompt(prompt._id);
                    }}
                  >
                    less
                  </button>
                {/if}
              </div>

              <div class="prompt-card-footer">
                <span class="topic-label">
                  {#if prompt.topics && prompt.topics.length > 0}
                    {typeof prompt.topics[0] === 'string' 
                      ? prompt.topics[0].split('-')
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ')
                      : prompt.topics[0].topicLabel || 'General Topic'}
                  {:else}
                    General Topic
                  {/if}
                </span>
                
                {#if searchType === 'semantic' && prompt.similarityScore !== undefined}
                  <span class="similarity-score">
                    <i class="bi bi-lightning-charge"></i>
                    {prompt.similarityScore}% match
                  </span>
                {/if}
                
                <div class="card-footer-buttons">
                  <button 
                    class="save-button-small saved" 
                    on:click={(e) => {
                      e.stopPropagation();
                      toggleSavePrompt(prompt, e);
                    }}
                  >
                    <i class="bi bi-bookmark-fill"></i>
                    Saved
                  </button>
                  
                  <button 
                    class="create-story-button-small"
                    on:click={(e) => {
                      e.stopPropagation();
                      selectPrompt(prompt._id);
                      startDataStory();
                    }}
                  >
                    Start data story
                    <i class="bi bi-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        {/if}
      {/each}
    {/if}
  {:else if activeTab === 'guide'}
    <div class="guide-section">
      <div class="guide-content">
        <div class="guide-iframe-container">
          <iframe 
            src="https://docs.google.com/document/d/e/2PACX-1vRHKFdBAY_ZJ_0NyEQkLx_FheVngR8Ed4Goj2OAqNbLrX-7QuD3BzDNII_rm3NPJD3W-Eilt5M5F7Uj/pub?embedded=true&rm=minimal"
            class="guide-iframe"
            frameborder="0"
            allowfullscreen
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            on:load={() => {
              // Hide loading indicator when iframe loads
              const loadingEl = document.querySelector('.guide-loading');
              if (loadingEl) loadingEl.style.display = 'none';
            }}
            on:error={() => {
              // Show error message if iframe fails to load
              const loadingEl = document.querySelector('.guide-loading');
              if (loadingEl) {
                loadingEl.innerHTML = `
                  <i class="bi bi-exclamation-triangle"></i>
                  <span>Failed to load guide. Please try refreshing the page.</span>
                `;
              }
            }}
          ></iframe>
          <div class="guide-loading">
            <div class="loading-icon-container"><i class="bi bi-arrow-repeat spinner"></i></div>
            <span>Loading guide...</span>
          </div>
        </div>
      </div>
    </div>
  {:else}
    <div class="browse-search-container">
      <div class="filters-row">
        <div class="search-input-wrapper">
          <i class="bi bi-search search-icon"></i>
          <form on:submit|preventDefault={handleSearch}>
            <input
              type="text"
              placeholder="Search prompts using keywords, AND, OR, NOT, or 'exact phrases'..."
              bind:value={browseSearchQuery}
              on:keydown={handleSearch}
              class="search-input"
            />
            <button 
              type="button" 
              class="search-tips-button" 
              title="Search Tips"
              on:click={() => showSearchTips = !showSearchTips}
            >
              <i class="bi bi-question-circle"></i>
            </button>
            {#if browseSearchQuery}
              <button 
                type="button"
                class="clear-button" 
                on:click={resetBrowseFilters}
                aria-label="Clear search"
              >
                <i class="bi bi-x-lg"></i>
              </button>
            {/if}
          </form>
          {#if showSearchTips}
            <div class="search-tips-dropdown" transition:fade>
              <h4>Search Strategies</h4>
              <div class="search-tips-content">
                <section class="search-tip-section">
                  <h5>Boolean Operators</h5>
                  <ul>
                    <li><code>AND</code>: Match both terms (e.g. <span class="example">"education AND technology"</span>)</li>
                    <li><code>OR</code>: Match either term (e.g. <span class="example">"climate OR weather"</span>)</li>
                    <li><code>NOT</code>: Exclude term (e.g. <span class="example">"politics NOT economy"</span>)</li>
                  </ul>
                </section>

                <section class="search-tip-section">
                  <h5>Exact Phrase Matching</h5>
                  <ul>
                    <li><code>"..."</code>: Match exact phrase (e.g. <span class="example">"public health"</span>)</li>
                  </ul>
                </section>

                <section class="search-tip-section">
                  <h5>Grouping Terms</h5>
                  <ul>
                    <li><code>()</code>: Group terms (e.g. <span class="example">"(education OR learning) AND digital"</span>)</li>
                  </ul>
                </section>

                <section class="search-tip-section">
                  <h5>Wildcards</h5>
                  <ul>
                    <li><code>*</code>: Match any characters (e.g. <span class="example">"environ*"</span> matches environment, environmental, etc.)</li>
                  </ul>
                </section>
              </div>
              <button class="close-tips" on:click={() => showSearchTips = false}>
                <i class="bi bi-x"></i>
              </button>
            </div>
          {/if}
        </div>
        
        <div class="search-type-toggle">
          <button 
            class="search-type-button {searchType === 'text' ? 'active' : ''}"
            on:click={() => {
              if (searchType !== 'text') toggleSearchType();
            }}
            title="Text-based keyword search"
          >
            <i class="bi bi-text-paragraph"></i> Basic Search
          </button>
          <button 
            class="search-type-button {searchType === 'semantic' ? 'active' : ''}"
            on:click={() => {
              if (searchType !== 'semantic') toggleSearchType();
            }}
            title="AI-powered smart search that understands what you mean"
          >
            <i class="bi bi-lightning-charge"></i> Smart Search
          </button>
        </div>
      </div>
      
      {#if browseSearchQuery && hasSubmittedSearch}
        <div class="active-filters">
          <span class="filters-label">Search results for: {browseSearchQuery}</span>
          <span class="search-type-label">Search type: 
            {#if searchType === 'semantic' && !showSearchError}
              <i class="bi bi-lightning-charge"></i> Smart Search (AI-powered)
            {:else}
              <i class="bi bi-text-paragraph"></i> Basic Search
            {/if}
          </span>
          <button 
            class="clear-all-filters"
            on:click={resetBrowseFilters}
          >
            Clear search
          </button>
        </div>
        
        {#if showSearchError}
          <div class="search-error-message" transition:fade={{duration: 300}}>
            <i class="bi bi-exclamation-triangle"></i>
            {searchErrorMessage}
          </div>
        {/if}
      {/if}
    </div>

    <div class="browse-section">
      {#if isSearching}
        <div class="browse-loading">
          <div class="loading-icon-container"><i class="bi bi-arrow-repeat spinner"></i></div>
          <span>Searching prompts...</span>
        </div>
      {:else if browseLoading}
        <div class="browse-loading">
          <div class="loading-icon-container"><i class="bi bi-arrow-repeat spinner"></i></div>
          <span>Loading prompts...</span>
        </div>
      {:else if hasSubmittedSearch && displayedPrompts.length === 0}
        <div class="no-results">
          <i class="bi bi-emoji-frown"></i>
          <p>No prompts found matching "{browseSearchQuery}"</p>
          <button 
            class="clear-search-button"
            on:click={resetBrowseFilters}
          >
            Clear search
          </button>
        </div>
      {:else}
        {#each displayedPrompts as prompt, i}
          <div class="prompt-card">
            <div class="question-section" data-index={i % 10}>
              <h3 class="prompt-title">{prompt.bigQuestion}</h3>
            </div>
            
            <div class="prompt-content">
              <div class="prompt-preview-container">
                <p class="prompt-preview" class:expanded={expandedPrompts[prompt._id]}>
                  {prompt.promptScript}
                </p>
                {#if prompt.promptScript.length > 200 && !expandedPrompts[prompt._id]}
                  <button 
                    class="expand-button"
                    on:click={(e) => {
                      e.stopPropagation();
                      toggleExpandPrompt(prompt._id);
                    }}
                  >
                    more
                  </button>
                {:else if expandedPrompts[prompt._id]}
                  <button 
                    class="expand-button"
                    on:click={(e) => {
                      e.stopPropagation();
                      toggleExpandPrompt(prompt._id);
                    }}
                  >
                    less
                  </button>
                {/if}
              </div>

              <div class="prompt-card-footer">
                <span class="topic-label">
                  {#if prompt.topics && prompt.topics.length > 0}
                    {typeof prompt.topics[0] === 'string' 
                      ? prompt.topics[0].split('-')
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ')
                      : prompt.topics[0].topicLabel || 'General Topic'}
                  {:else}
                    General Topic
                  {/if}
                </span>
                
                {#if searchType === 'semantic' && prompt.similarityScore !== undefined}
                  <span class="similarity-score">
                    <i class="bi bi-lightning-charge"></i>
                    {prompt.similarityScore}% match
                  </span>
                {/if}
                
                <div class="card-footer-buttons">
                  <button 
                    class="save-button-small" 
                    class:saved={savedPrompts.some(p => p._id === prompt._id)}
                    on:click={(e) => {
                      e.stopPropagation();
                      toggleSavePrompt(prompt, e);
                    }}
                  >
                    <i class="bi bi-bookmark{savedPrompts.some(p => p._id === prompt._id) ? '-fill' : ''}"></i>
                    {savedPrompts.some(p => p._id === prompt._id) ? 'Saved' : 'Save'}
                  </button>
                  
                  <button 
                    class="create-story-button-small"
                    on:click={(e) => {
                      e.stopPropagation();
                      selectPrompt(prompt._id);
                      startDataStory();
                    }}
                  >
                    Start data story
                    <i class="bi bi-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        {/each}

        {#if hasMorePrompts}
          <button 
            class="load-more-button" 
            on:click={loadMore}
            disabled={loadingMore}
          >
            {#if loadingMore}
              <div class="loading-icon-container"><i class="bi bi-arrow-repeat spinner"></i></div>
            {:else}
              Load more prompts
            {/if}
          </button>
        {/if}
      {/if}
    </div>
  {/if}

  {#if loading}
    <div class="loading">
      <div class="loading-icon-container"><i class="bi bi-arrow-repeat spinner"></i></div> Loading...
    </div>
  {/if}

  {#if showDetails && selectedPrompt}
    <div class="details-container" transition:fade>
      <div class="details-card">
        <div class="details-header">
          <h2>{selectedPrompt.bigQuestion}</h2>
          <span class="label">
            {#if selectedPrompt.topics && selectedPrompt.topics.length > 0}
              {typeof selectedPrompt.topics[0] === 'string' 
                ? selectedPrompt.topics[0].split('-')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')
                : selectedPrompt.topics[0].topicLabel || 'General Topic'}
            {:else}
              General Topic
            {/if}
          </span>
        </div>
        
        <div class="prompt-text">
          <h3>About this question:</h3>
          <p>{selectedPrompt.promptScript}</p>
        </div>

        <div class="action-section">
          <div class="info-box">
            <div class="info-content">
              <i class="bi bi-filter-circle"></i>
              <p>This question will help focus your exploration on relevant data for your story</p>
            </div>
          </div>

          <div class="action-buttons">
            <button 
              class="save-button" 
              class:saved={isCurrentPromptSaved}
              on:click={(e) => toggleSavePrompt(selectedPrompt, e)}
            >
              <i class="bi bi-{isCurrentPromptSaved ? 'bookmark-fill' : 'bookmark'}"></i>
              {isCurrentPromptSaved ? 'Saved' : 'Save'}
            </button>
            <button class="create-story-button" on:click={startDataStory}>
              Start data story
              <i class="bi bi-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  {/if}

  {#if modalPrompt}
    <div class="modal-backdrop" on:click={closeModal} transition:fade>
      <div 
        class="modal-content"
        on:click|stopPropagation
        transition:scale={{duration: 200, start: 0.95}}
      >
        <div class="modal-header">
          <span class="topic-label">
            {#if modalPrompt.topics && modalPrompt.topics.length > 0}
              {typeof modalPrompt.topics[0] === 'string'
                ? modalPrompt.topics[0].split('-')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')
                : modalPrompt.topics[0].topicLabel || 'General Topic'}
            {:else}
              General Topic
            {/if}
          </span>
          <button class="close-button" on:click={closeModal}>
            <i class="bi bi-x-lg"></i>
          </button>
        </div>

        <h2 class="modal-title">{modalPrompt.bigQuestion}</h2>
        
        <div class="modal-body">
          <p class="modal-text">{modalPrompt.promptScript}</p>
        </div>

        <div class="modal-footer">
          <button 
            class="save-button" 
            class:saved={savedPrompts.some(p => p._id === modalPrompt._id)}
            on:click={(e) => toggleSavePrompt(modalPrompt, e)}
          >
            <i class="bi bi-bookmark{savedPrompts.some(p => p._id === modalPrompt._id) ? '-fill' : ''}"></i>
            {savedPrompts.some(p => p._id === modalPrompt._id) ? 'Saved' : 'Save'}
          </button>
          <button 
            class="create-story-button" 
            on:click={() => {
              selectPrompt(modalPrompt._id);
              startDataStory();
            }}
          >
            Start data story
            <i class="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  {/if}
</div>

<style>
  .prompt-selection-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .search-section {
    flex-shrink: 0;
    background: white;
    padding: 1rem;
    border-radius: 8px;
    position: relative;
    z-index: 10;
  }

  .search-container {
    position: relative;
    width: 100%;
  }

  .search-input-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .search-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: #94a3b8;
    font-size: 0.875rem;
    pointer-events: none;
  }

  .search-input {
    width: 100%;
    padding: 0.875rem 1rem 0.875rem 2.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.2s;
    line-height: 1.5;
  }

  .search-input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }

  .search-tips-button {
    position: absolute;
    right: 2.5rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #94a3b8;
    padding: 0.25rem;
    cursor: pointer;
    transition: color 0.2s;
    z-index: 1;
  }

  .search-tips-button:hover {
    color: #2563eb;
  }

  .clear-button {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #94a3b8;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s;
  }

  .clear-button i {
    font-size: 0.875rem;
  }

  .clear-button:hover {
    background-color: #f1f5f9;
    color: #475569;
  }

  .results-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 0.5rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 20;
    max-height: min(400px, 60vh);
    overflow-y: auto;
    margin: 0.5rem;
    width: calc(100% - 1rem);
  }

  .results-dropdown::-webkit-scrollbar {
    width: 6px;
  }

  .results-dropdown::-webkit-scrollbar-track {
    background: transparent;
  }

  .results-dropdown::-webkit-scrollbar-thumb {
    background-color: #cbd5e1;
    border-radius: 3px;
  }

  .results-dropdown::-webkit-scrollbar-thumb:hover {
    background-color: #94a3b8;
  }

  .result-item {
    width: 100%;
    padding: 0.75rem 1rem;
    border: none;
    background: none;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
    transition: background 0.2s;
  }

  .result-item:hover {
    background: #f8fafc;
  }

  .result-icon {
    color: #666;
    font-size: 0.875rem;
  }

  .details-container {
    flex: 1;
    min-height: 0;
    overflow-y: auto;
    padding: 0 1rem;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
    will-change: transform;
  }

  .details-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    border: 1px solid #e5e7eb;
    margin-bottom: 1rem;
    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: 1.5rem;
  }

  .details-header {
    flex-shrink: 0;
  }

  .details-header h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 0.5rem;
  }

  .label {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    background: #f0f9ff;
    color: #0369a1;
    border-radius: 1rem;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .action-section {
    margin-top: auto;
    padding-top: 1.5rem;
    border-top: 1px solid #e5e7eb;
  }

  .info-box {
    background: #f8fafc;
    border-radius: 12px;
    padding: 1rem 1.25rem;
  }

  .info-content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .info-icon {
    color: #64748b;
    font-size: 1.25rem;
  }

  .info-content p {
    color: #64748b;
    font-size: 0.875rem;
    margin: 0;
    line-height: 1.5;
  }

  .prompt-text {
    overflow-wrap: break-word;
  }

  .prompt-text h3 {
    font-size: 1rem;
    font-weight: 600;
    color: #374151;
    margin-bottom: 0.5rem;
  }

  .prompt-text p {
    color: #4b5563;
    line-height: 1.6;
  }

  .create-story-button {
    width: 100%;
    padding: 1rem;
    background: #2563eb;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    letter-spacing: 0.01em;
  }

  .create-story-button:hover {
    background: #1d4ed8;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(37, 99, 235, 0.1);
  }

  .create-story-button i {
    transition: transform 0.2s ease;
  }

  .create-story-button:hover i {
    transform: translateX(4px);
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 0.75rem 1.5rem;
    border-radius: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    z-index: 30;
  }

  .spinner {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  .search-hint {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    background: #f8fafc;
    border-radius: 8px;
    color: #64748b;
    font-size: 0.875rem;
  }

  .search-hint i {
    color: #2563eb;
    font-size: 1rem;
  }

  .random-link-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
  }

  .random-link {
    background: none;
    border: none;
    color: #6b7280;
    font-size: 0.875rem;
    padding: 0;
    cursor: pointer;
    transition: color 0.2s ease;
  }

  .random-link:hover {
    color: #2563eb;
    text-decoration: underline;
  }

  .tabs-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    background: white;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .left-tabs {
    display: flex;
    gap: 1rem;
  }

  .tab-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    background: none;
    color: #64748b;
    font-size: 0.875rem;
    font-weight: 500;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
  }

  .tab-button.active {
    color: #2563eb;
    border-bottom-color: #2563eb;
    background: #eff6ff;
  }

  .tab-button:hover {
    color: #2563eb;
    background: #f8fafc;
  }

  .guide-tab {
    font-size: 0.8125rem;
    padding: 0.5rem 0.75rem;
    background: #f8fafc;
    border-radius: 6px;
    border-bottom: none;
  }

  .guide-tab:hover {
    background: #f1f5f9;
    color: #2563eb;
  }

  .guide-tab.active {
    background: #eff6ff;
    color: #2563eb;
    font-weight: 600;
  }

  .browse-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;
    overflow-y: auto;
    height: calc(100vh - 200px);
    position: relative;
    align-items: center;
    width: 100%;
  }

  .prompt-card {
    background: white;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
    transition: all 0.2s ease;
    width: 100%;
    max-width: 470px;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .prompt-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }

  /* Question section that acts like an image */
  .question-section {
    width: 100%;
    min-height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
  }

  /* Gradient backgrounds */
  .question-section[data-index="0"] { background: linear-gradient(135deg, #FF6B6B, #FF8E8E); }
  .question-section[data-index="1"] { background: linear-gradient(135deg, #4ECDC4, #45B7AF); }
  .question-section[data-index="2"] { background: linear-gradient(135deg, #96CEB4, #FFEEAD); }
  .question-section[data-index="3"] { background: linear-gradient(135deg, #D4A5A5, #9B59B6); }
  .question-section[data-index="4"] { background: linear-gradient(135deg, #3498DB, #2980B9); }
  .question-section[data-index="5"] { background: linear-gradient(135deg, #E67E22, #D35400); }
  .question-section[data-index="6"] { background: linear-gradient(135deg, #1ABC9C, #16A085); }
  .question-section[data-index="7"] { background: linear-gradient(135deg, #F1C40F, #F39C12); }
  .question-section[data-index="8"] { background: linear-gradient(135deg, #9B59B6, #8E44AD); }
  .question-section[data-index="9"] { background: linear-gradient(135deg, #34495E, #2C3E50); }

  .prompt-title {
    font-size: 1.75rem;
    font-weight: 600;
    color: white;
    line-height: 1.3;
    margin: 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 1;
    max-width: 90%;
    margin: 0 auto;
  }

  /* Content section below the question */
  .prompt-content {
    padding: 1.25rem 1.5rem;
    background: white;
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 200px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .prompt-preview-container {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .prompt-preview {
    color: #262626;
    font-size: 0.9375rem;
    line-height: 1.6;
    margin: 0;
    transition: all 0.3s ease;
  }

  .prompt-preview.expanded {
    max-height: none;
  }

  .prompt-preview:not(.expanded) {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .expand-button {
    background: none;
    border: none;
    color: #8e8e8e;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.5rem 0;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: left;
    margin-top: 0.5rem;
  }

  .expand-button:hover {
    color: #262626;
  }

  .prompt-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.25rem;
    margin-top: 1.25rem;
    border-top: 1px solid #dbdbdb;
    background: white;
  }

  .topic-label {
    font-size: 0.75rem;
    color: #8e8e8e;
    background: #fafafa;
    padding: 0.375rem 0.875rem;
    border-radius: 1rem;
    font-weight: 500;
  }

  .card-footer-buttons {
    display: flex;
    gap: 0.75rem;
  }

  .save-button-small {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.625rem 1rem;
    background: white;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    color: #8e8e8e;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    height: 36px;
  }

  .save-button-small:hover {
    background: #fafafa;
    border-color: #c7c7c7;
  }

  .save-button-small.saved {
    color: #2563eb;
    border-color: #2563eb;
    background: #f0f7ff;
  }

  .create-story-button-small {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.625rem 1rem;
    background: #0095f6;
    border: none;
    border-radius: 6px;
    color: white;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    height: 36px;
    white-space: nowrap;
  }

  .create-story-button-small:hover {
    background: #0081d6;
  }

  .create-story-button-small i {
    transition: transform 0.2s ease;
  }

  .create-story-button-small:hover i {
    transform: translateX(2px);
  }

  /* Loading states */
  .browse-loading {
    width: 100%;
    max-width: 470px;
    padding: 2rem;
    text-align: center;
    background: white;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
    color: #8e8e8e;
  }

  .no-results {
    width: 100%;
    max-width: 470px;
    padding: 3rem 2rem;
    text-align: center;
    background: white;
    border-radius: 8px;
    border: 1px solid #dbdbdb;
    color: #8e8e8e;
  }

  .no-results i {
    font-size: 2rem;
  }

  .no-results p {
    margin: 0;
    font-size: 0.875rem;
  }

  .clear-search-button {
    padding: 0.5rem 1rem;
    background: #2563eb;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .clear-search-button:hover {
    background: #1d4ed8;
  }

  .load-more-button {
    margin: 1rem auto;
    padding: 0.75rem 2rem;
    background: white;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    color: #262626;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    max-width: 470px;
    justify-content: center;
  }

  .load-more-button:hover {
    background: #fafafa;
    border-color: #c7c7c7;
  }

  .load-more-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 1rem;
  }

  .modal-content {
    background: white;
    border-radius: 12px;
    width: 100%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
    line-height: 1.4;
    margin: 0;
  }

  .modal-body {
    flex: 1;
    min-height: 0;
    overflow: scroll;
  }

  .modal-text {
    color: #4b5563;
    line-height: 1.6;
    margin: 0;
  }

  .modal-footer {
    display: flex;
    gap: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
  }

  .close-button {
    background: none;
    border: none;
    color: #6b7280;
    padding: 0.5rem;
    cursor: pointer;
    transition: color 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close-button:hover {
    color: #111827;
  }

  .details-card:after {
    content: '';
    padding-bottom: 1rem;
  }

  .details-container::-webkit-scrollbar {
    width: 8px;
  }

  .details-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  .details-container::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
    border: 2px solid #f1f1f1;
  }

  .details-container::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1;
  }

  @media (max-width: 768px) {
    .details-container {
      padding: 0 0.5rem;
    }

    .details-card {
      padding: 1rem;
    }

    .search-input-wrapper {
      flex-direction: column;
      gap: 0.5rem;
    }
    
    .search-type-toggle {
      margin-left: 0;
      width: 100%;
      justify-content: space-between;
    }
    
    .search-type-button {
      flex: 1;
      justify-content: center;
    }
    
    .filters-row {
      flex-direction: column;
      gap: 0.5rem;
    }
    
    .search-type-label {
      margin-top: 0.25rem;
    }
  }

  .details-card {
    transition: transform 0.2s ease;
  }

  .details-container.loading {
    opacity: 0.7;
    pointer-events: none;
  }

  .browse-search-container {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }

  .browse-search-container .search-input-wrapper {
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 0.25rem;
  }

  .browse-search-container .search-input {
    border: none;
    padding: 0.75rem 1rem 0.75rem 2.5rem;
  }

  .browse-search-container .search-input:focus {
    box-shadow: none;
    border: none;
    outline: none;
  }

  .browse-section {
    padding-top: 0;
  }

  .filters-row {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .active-filters {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-top: 0.75rem;
    flex-wrap: wrap;
  }

  .filters-label {
    color: #6b7280;
    font-size: 0.875rem;
  }

  .filter-tag {
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem 0.5rem;
    background: #f3f4f6;
    border-radius: 1rem;
    font-size: 0.875rem;
    color: #374151;
  }

  .remove-filter {
    background: none;
    border: none;
    color: #6b7280;
    cursor: pointer;
    padding: 0 0.25rem;
    font-size: 1rem;
    line-height: 1;
  }

  .remove-filter:hover {
    color: #ef4444;
  }

  .clear-all-filters {
    background: none;
    border: none;
    color: #6b7280;
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    text-decoration: underline;
  }

  .clear-all-filters:hover {
    color: #ef4444;
  }

  .search-input-wrapper {
    flex: 1;
  }

  .search-input-wrapper form {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .spinner {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  .guide-section {
    padding: 1rem;
    background: #f8fafc;
    height: calc(100vh - 200px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
    will-change: transform;
  }

  .guide-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .guide-iframe-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 800px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .guide-iframe {
    width: 100%;
    height: 100%;
    border: none;
    background: white;
  }

  .guide-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    color: #64748b;
    font-size: 0.875rem;
    background: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .guide-loading .spinner {
    font-size: 1.5rem;
  }

  .guide-loading .bi-exclamation-triangle {
    font-size: 1.5rem;
    color: #ef4444;
  }

  @media (max-width: 768px) {
    .guide-section {
      padding: 0.5rem;
    }
  }

  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4rem 2rem;
    background: white;
    border-radius: 16px;
    max-width: 400px;
    margin: 2rem auto;
    transition: all 0.2s ease;
    height: 400px;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(0, 0, 0, 0.04);
    position: relative;
    overflow: hidden;
  }
  
  .empty-state-decoration {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      rgba(249, 250, 251, 0.8) 0%,
      rgba(249, 250, 251, 0.4) 100%
    );
    background-size: 200% 200%;
    animation: gradient-shift 15s ease infinite;
    pointer-events: none;
    z-index: 0;
  }
  
  .empty-state-decoration::before {
    content: '';
    position: absolute;
    top: -50%;
    right: -50%;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background: radial-gradient(
      circle,
      rgba(219, 234, 254, 0.8) 0%,
      rgba(219, 234, 254, 0) 70%
    );
  }
  
  .empty-state-decoration::after {
    content: '';
    position: absolute;
    bottom: -30%;
    left: -20%;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: radial-gradient(
      circle,
      rgba(224, 242, 254, 0.6) 0%,
      rgba(224, 242, 254, 0) 70%
    );
  }

  @keyframes gradient-shift {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .empty-state-icon {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #f8fafc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.75rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.02);
    position: relative;
    z-index: 1;
  }
  
  .empty-state-icon i {
    font-size: 2.25rem;
    color: #94a3b8;
  }
  
  .empty-state-title {
    font-size: 1.375rem;
    font-weight: 600;
    color: #1e293b;
    margin: 0 0 0.625rem 0;
    letter-spacing: -0.01em;
    position: relative;
    z-index: 1;
  }
  
  .empty-state-message {
    font-size: 0.9375rem;
    color: #64748b;
    margin: 0 0 2.25rem 0;
    line-height: 1.6;
    max-width: 280px;
    position: relative;
    z-index: 1;
  }
  
  .empty-state-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.875rem 1.75rem;
    background: #2563eb;
    color: white;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.9375rem;
    box-shadow: 0 2px 10px rgba(37, 99, 235, 0.1);
    position: relative;
    z-index: 1;
  }
  
  .empty-state-button:hover {
    background: #1d4ed8;
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(37, 99, 235, 0.15);
  }
  
  .empty-state-button:active {
    transform: translateY(0px);
  }
  
  .empty-state-button i {
    font-size: 1.125rem;
  }

  .empty-state-dots {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(#e2e8f0 1px, transparent 1px);
    background-size: 20px 20px;
    opacity: 0.3;
    z-index: 0;
    pointer-events: none;
  }

  .search-tips-dropdown {
    position: absolute;
    top: calc(100% + 0.5rem);
    left: 0;
    right: 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 1rem;
    z-index: 50;
    border: 1px solid #e2e8f0;
    max-height: 400px;
    overflow-y: auto;
  }

  .search-tips-dropdown h4 {
    margin: 0 0 0.75rem 0;
    color: #1e293b;
    font-size: 0.875rem;
    font-weight: 600;
    border-bottom: 1px solid #e2e8f0;
    padding-bottom: 0.5rem;
  }

  .search-tips-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .search-tip-section h5 {
    margin: 0 0 0.5rem 0;
    color: #475569;
    font-size: 0.8125rem;
    font-weight: 600;
  }

  .search-tips-dropdown ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .search-tips-dropdown li {
    margin-bottom: 0.5rem;
    color: #475569;
    font-size: 0.8125rem;
    line-height: 1.4;
    display: flex;
    align-items: flex-start;
    gap: 0.25rem;
  }

  .search-tips-dropdown code {
    background: #f1f5f9;
    padding: 0.125rem 0.25rem;
    border-radius: 4px;
    color: #2563eb;
    font-size: 0.75rem;
    font-family: monospace;
    white-space: nowrap;
  }

  .search-tips-dropdown .example {
    color: #0f766e;
    font-style: italic;
  }

  .close-tips {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    border: none;
    color: #64748b;
    padding: 0.25rem;
    cursor: pointer;
    transition: color 0.2s;
  }

  .close-tips:hover {
    color: #ef4444;
  }

  .search-type-toggle {
    display: flex;
    gap: 0.5rem;
    margin-left: 1rem;
  }

  .search-type-button {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    padding: 0.5rem 0.75rem;
    background: #f1f5f9;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    color: #64748b;
    font-size: 0.75rem;
    font-weight: 500;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .search-type-button.active {
    background: #e0f2fe;
    border-color: #7dd3fc;
    color: #0284c7;
  }

  .search-type-button:hover:not(.active) {
    background: #e2e8f0;
  }

  .search-type-label {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    font-size: 0.8125rem;
    color: #64748b;
    background: #f1f5f9;
    padding: 0.25rem 0.5rem;
    border-radius: 0.375rem;
  }

  .similarity-score {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    font-size: 0.6875rem;
    font-weight: 600;
    color: #0284c7;
    background: #e0f2fe;
    padding: 0.25rem 0.5rem;
    border-radius: 0.375rem;
    margin-left: 0.5rem;
  }

  .similarity-score i {
    font-size: 0.75rem;
    color: #0ea5e9;
  }
  .loading-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background: #f0f9ff;
    border-radius: 50%;
    margin-bottom: 0.5rem;
  }
  .browse-loading i {
    font-size: 2rem;
    color: #3b82f6;
  }

  .browse-loading span {
    font-size: 0.95rem;
    font-weight: 500;
    color: #6b7280;
    animation: pulse-text 2s ease-in-out infinite;
  }

  /* Add animation for the text */
  @keyframes pulse-text {
    0% { opacity: 0.6; }
    50% { opacity: 1; }
    100% { opacity: 0.6; }
  }

  .search-error-message {
    margin-top: 0.5rem;
    padding: 0.5rem 0.75rem;
    background: #fee2e2;
    border: 1px solid #fecaca;
    border-radius: 0.375rem;
    color: #b91c1c;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .search-error-message i {
    font-size: 0.875rem;
  }
</style>
