<script>
  import { onDestroy, onMount } from 'svelte';
  import { getContext } from 'svelte';
  import { storyStore } from '../stores/storyStore';
  import { generateResponse } from '../services/aiService';
  import { initializeChat as initializeChatSession, getChatHistory, addMessage as addChatMessage } from '../services/chatService';
  import { fade } from 'svelte/transition';
  import { createEventDispatcher } from 'svelte';
  import { AI_CONFIG } from '../../backend/config/aiConfig';
  import { writable } from 'svelte/store';
  import { slideAgentInstructions } from '../stores/agentInstructionsStore';
  import { logAgentActivity, getAgentActivityLogsByChatThread } from '../services/agentActivityService';
  import { chartAgentInstructions } from '../stores/agentInstructionsStore';
  
  const selectedSlideIndex = getContext('selectedSlideIndex');
  
  let messages = [];
  let userInput = '';
  let isLoading = false;
  let chatBox;
  let isMounted = false;

  const dispatch = createEventDispatcher();

  const AGENT_INSTRUCTIONS = AI_CONFIG.agentInstructions.slideAssistant;

  // Variables for instructions editing
  let currentInstructions;
  let editableInstructions;
  let showSettings = false;

  // Add currentChat store
  let currentChat = null;

  // Subscribe to instructions store
  slideAgentInstructions.subscribe(value => {
    currentInstructions = value;
    editableInstructions = value;
  });

  // Settings functions
  function toggleSettings() {
    showSettings = !showSettings;
  }

  function saveInstructions() {
    slideAgentInstructions.set(editableInstructions);
    messages = []; // Clear existing messages
    showSettings = false;
  }

  function resetInstructions() {
    slideAgentInstructions.reset();
    messages = []; // Clear existing messages
    showSettings = false;
  }

  onMount(async () => {
    isMounted = true;
    
    try {
      // Initialize chat session
      const storyId = storyStore.getStoryId();
      const userId = storyStore.getUserId();
      
      if (userId && storyId) {
        currentChat = await initializeChatSession({
          userId,
          storyId,
          chatType: 'slide-assistant',
          slideIndex: $selectedSlideIndex
        });

        // Log chat initiation
        await logAgentActivity({
          chatThreadId: currentChat._id,
          userId,
          storyId,
          activityType: 'chat_initiated',
          details: {
            slideIndex: $selectedSlideIndex,
            slideContent: storyStore.getSlide($selectedSlideIndex)
          }
        });

        // Check for existing activity logs
        const activityLogs = await getAgentActivityLogsByChatThread(currentChat._id);
        console.log('Existing activity logs:', activityLogs);

        // Load existing messages
        const history = await getChatHistory({
          userId,
          storyId,
          chatType: 'slide-assistant',
          slideIndex: $selectedSlideIndex
        });

        if (history.length > 0) {
          messages = history;
        } else {
          // Add welcome message if no history
          const welcomeMessage = {
            content: "Hi there! I'm here to help you with your slide. What would you like to know?",
            sender: 'ai',
            timestamp: new Date()
          };
          
          messages = [welcomeMessage];
          
          // Save to database
          if (currentChat) {
            await addChatMessage(currentChat._id, welcomeMessage);
            
            // Log AI message
            await logAgentActivity({
              chatThreadId: currentChat._id,
              userId,
              storyId,
              activityType: 'message_received',
              details: {
                messageContent: welcomeMessage.content,
                messageType: 'welcome'
              }
            });
          }
        }
      }
    } catch (error) {
      console.error('Error initializing chat:', error);
    }

    return () => {
      isMounted = false;
    };
  });

  const QUICK_ACTIONS = [
    { id: 'improve-script', text: 'Help me improve this script' },
    { id: 'suggest-visuals', text: 'Suggest visuals for this slide' },
    { id: 'clarify-message', text: 'Help clarify the main message' }
  ];

  async function handleSubmit() {
    if (!userInput.trim()) return;
    
    const userMessageObj = {
      content: userInput,
      sender: 'user',
      timestamp: new Date()
    };
    
    messages = [...messages, userMessageObj];
    const currentInput = userInput;
    userInput = '';
    isLoading = true;

    // Log user message
    if (currentChat) {
      const storyId = storyStore.getStoryId();
      const userId = storyStore.getUserId();
      
      await logAgentActivity({
        chatThreadId: currentChat._id,
        userId,
        storyId,
        activityType: 'message_sent',
        details: {
          messageContent: currentInput
        }
      });
    }

    try {
      const currentSlide = storyStore.getSlide($selectedSlideIndex);
      const bigQuestion = storyStore.getBigQuestion();
      const context = {
        slideIndex: $selectedSlideIndex,
        slideContent: currentSlide,
        bigQuestion: bigQuestion,
        script: currentInput,
        question: currentInput,
        instructions: currentInstructions
      };

      const prompt = `You are an assistant helping with slide ${$selectedSlideIndex + 1}. ${currentInput}`;

      console.log('Sending prompt:', prompt);
      console.log('Context:', context);

      const response = await generateResponse(prompt, context);
      console.log('AI response:', response);

      // Create AI message object
      const aiMessageObj = {
        content: response.text,
        sender: 'ai',
        timestamp: new Date()
      };

      messages = [...messages, aiMessageObj];

      // Save messages to database if we have a chat session
      if (currentChat) {
        await addChatMessage(currentChat._id, userMessageObj);
        await addChatMessage(currentChat._id, aiMessageObj);
        
        // Log AI message
        const storyId = storyStore.getStoryId();
        const userId = storyStore.getUserId();
        
        await logAgentActivity({
          chatThreadId: currentChat._id,
          userId,
          storyId,
          activityType: 'message_received',
          details: {
            messageContent: response.text,
            suggestions: response.suggestions || []
          }
        });
      }
    } catch (error) {
      console.error('Error getting AI response:', error);
      const errorMessage = {
        content: 'Sorry, I encountered an error. Please try again.',
        sender: 'ai',
        timestamp: new Date()
      };
      messages = [...messages, errorMessage];
      if (currentChat) {
        await addChatMessage(currentChat._id, errorMessage);
      }
    } finally {
      isLoading = false;
    }
  }

  function handleQuickAction(actionId) {
    const action = QUICK_ACTIONS.find(a => a.id === actionId);
    if (action) {
      userInput = action.text;
      handleSubmit();
    }
  }

  function scrollToBottom() {
    if (isMounted && chatBox) {
      setTimeout(() => {
        chatBox.scrollTop = chatBox.scrollHeight;
      }, 50);
    }
  }

  $: if (messages) {
    scrollToBottom();
  }

  // Add cleanup function to log chat closure
  onDestroy(() => {
    // Log chat closure if we have a chat session
    if (currentChat) {
      const storyId = storyStore.getStoryId();
      const userId = storyStore.getUserId();
      
      logAgentActivity({
        chatThreadId: currentChat._id,
        userId,
        storyId,
        activityType: 'chat_closed',
        details: {
          messageCount: messages.length
        }
      }).catch(error => {
        console.error('Error logging chat closure:', error);
      });
    }
  });
</script>

<div class="agent-slide-container">
  <div class="agent-header">
    <h3>Slide Assistant</h3>
    <button 
      class="settings-button" 
      on:click={toggleSettings}
      title="AI Settings"
    >
      <i class="fas fa-cog"></i>
    </button>
  </div>

  <div class="agent-content" transition:fade={{ duration: 200 }}>
    <div class="quick-actions">
      {#each QUICK_ACTIONS as action}
        <button 
          class="quick-action-btn"
          on:click={() => handleQuickAction(action.id)}
        >
          {action.text}
        </button>
      {/each}
    </div>

    <div class="chat-box" bind:this={chatBox}>
      {#each messages as message}
        <div class="message {message.sender}">
          <div class="message-content">
            {message.content}
          </div>
        </div>
      {/each}
      {#if isLoading}
        <div class="message ai loading">
          <div class="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      {/if}
    </div>

    <form class="input-form" on:submit|preventDefault={handleSubmit}>
      <input
        type="text"
        bind:value={userInput}
        placeholder="Ask about this slide..."
        disabled={isLoading}
      />
      <button type="submit" disabled={isLoading || !userInput.trim()}>
        <i class="fas fa-paper-plane"></i>
      </button>
    </form>
  </div>

  <!-- Add settings modal -->
  {#if showSettings}
    <div class="settings-modal" on:click|self={() => showSettings = false}>
      <div class="settings-content">
        <h3>AI Instructions</h3>
        <textarea
          bind:value={editableInstructions}
          rows="10"
          placeholder="Enter custom instructions for the AI..."
        ></textarea>
        <div class="settings-actions">
          <button class="reset-button" on:click={resetInstructions}>
            Reset to Default
          </button>
          <button class="save-button" on:click={saveInstructions}>
            Save Changes
          </button>
        </div>
      </div>
    </div>
  {/if}
</div>

<style>
  .agent-slide-container {
    height: 100%;
    background: white;
    /* border-right: 1px solid #e5e7eb; */
    width: 100%;
    transition: width 0.3s ease;
  }

  .agent-header {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    justify-content: space-between;
  }

  .agent-header h3 {
    margin: 0;
    font-size: 1rem;
    color: #1f2937;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .agent-content {
    display: flex;
    flex-direction: column;
    height: 90%;
    overflow: hidden;
  }

  .quick-actions {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-bottom: 1px solid #e5e7eb;
  }

  .quick-action-btn {
    padding: 0.5rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    background: white;
    color: #4b5563;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s;
    text-align: left;
  }

  .quick-action-btn:hover {
    background: #f3f4f6;
    border-color: #d1d5db;
  }

  .chat-box {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .message {
    display: flex;
    flex-direction: column;
    max-width: 85%;
  }

  .message.user {
    align-self: flex-end;
  }

  .message-content {
    padding: 0.75rem 1rem;
    border-radius: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.4;
  }

  .user .message-content {
    background: #2563eb;
    color: white;
    border-bottom-right-radius: 0.25rem;
  }

  .ai .message-content {
    background: #f3f4f6;
    color: #1f2937;
    border-bottom-left-radius: 0.25rem;
  }

  .input-form {
    padding: 1rem;
    border-top: 1px solid #e5e7eb;
    display: flex;
    gap: 0.5rem;
  }

  input {
    flex: 1;
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }

  input:focus {
    outline: none;
    border-color: #2563eb;
    ring: 2px solid rgba(37, 99, 235, 0.1);
  }

  button {
    padding: 0.5rem 1rem;
    background: #2563eb;
    color: white;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: all 0.2s;
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  button:not(:disabled):hover {
    background: #1d4ed8;
  }

  .typing-indicator {
    display: flex;
    gap: 0.25rem;
    padding: 0.75rem 1rem;
    background: #f3f4f6;
    border-radius: 0.75rem;
    border-bottom-left-radius: 0.25rem;
  }

  .typing-indicator span {
    width: 0.5rem;
    height: 0.5rem;
    background: #9ca3af;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out;
  }

  .typing-indicator span:nth-child(1) { animation-delay: -0.32s; }
  .typing-indicator span:nth-child(2) { animation-delay: -0.16s; }

  @keyframes bounce {
    0%, 80%, 100% { transform: scale(0); }
    40% { transform: scale(1); }
  }

  .settings-button {
    background-color: #f3f4f6;
    color: #64748b;
    border: none;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .settings-button:hover {
    background-color: #e5e7eb;
    color: #1f2937;
  }

  .settings-modal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .settings-content {
    background: white;
    padding: 24px;
    border-radius: 12px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  .settings-content h3 {
    margin: 0 0 16px 0;
    color: #1e293b;
    font-size: 1.25rem;
  }

  .settings-content textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-family: inherit;
    font-size: 0.95rem;
    line-height: 1.5;
    resize: vertical;
    margin-bottom: 16px;
  }

  .settings-actions {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
  }

  .settings-actions button {
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .reset-button {
    background: #f1f5f9;
    border: 1px solid #e2e8f0;
    color: #64748b;
  }

  .reset-button:hover {
    background: #e2e8f0;
    color: #1e293b;
  }

  .save-button {
    background: #2563eb;
    border: 1px solid #2563eb;
    color: white;
  }

  .save-button:hover {
    background: #1d4ed8;
  }
</style>