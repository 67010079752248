<script>
    import { onMount, onDestroy } from 'svelte';
    import { storyStore, testUploadSlidePreviews } from '../stores/storyStore.js';
    import { get } from 'svelte/store';
    import { generateVideo, waitForVideo, getVideosForStory, deleteVideo } from '../services/videoService.js';

    export let isOpen = false;
    export let onClose;

    let slides = [];
    let renderedSlides = [];
    let currentSlideIndex = 0;
    let isLoading = false;
    let videoUrl = '';
    let hasContent = false;
    let slideTitle = '';
    let isModalOpen = false;
    let voices = [];
    let selectedVoice = null;
    let generationComplete = false;
    let popupBlocked = false;
    let generationError = null;
    let uploadProgress = 0;
    let totalSlides = 0;
    let savedVideos = [];
    let showVideoHistory = false;
    let activeSavedVideo = null;

    let missingScriptMessage = ''; // Message to display if scripts are missing

    onMount(async () => {
        const unsubscribe = storyStore.subscribe((state) => {
        slides = state.slides || [];
    });

        // Check if any slide has content
        hasContent = slides.some(slide => slide && ((slide.canvasState && slide.canvasState.length > 0) || slide.script));

        // Render each slide and store the data URL if there is content
        if (hasContent) {
            renderedSlides = await Promise.all(slides.map(async (slide) => {
                if (slide) {
                    const dataUrl = await renderCanvas(slide);
                    return { ...slide, dataUrl };
                }
                return slide;
            }));
        }

        // Set the initial slide title
        updateSlideTitle();
        
        // Load saved videos
        loadSavedVideos();

        return () => {
        unsubscribe(); // Unsubscribe when the component is destroyed
    };

    });
    
    async function loadSavedVideos() {
        try {
            const currentStory = get(storyStore);
            if (currentStory && currentStory._id) {
                savedVideos = await getVideosForStory(currentStory._id);
                console.log('Loaded saved videos:', savedVideos);
            }
        } catch (error) {
            console.error('Error loading saved videos:', error);
            savedVideos = [];
        }
    }

    function closeModal() {
        if (onClose) {
            onClose();
        }
    }

    function nextSlide() {
        if (currentSlideIndex < renderedSlides.length - 1) {
            currentSlideIndex++;
            updateSlideTitle(); // Update the title when navigating to the next slide
        }
    }

    function prevSlide() {
        if (currentSlideIndex > 0) {
            currentSlideIndex--;
            updateSlideTitle(); // Update the title when navigating to the previous slide
        }
    }

    function updateSlideTitle() {
        if (slides[currentSlideIndex]) {
            slideTitle = slides[currentSlideIndex].title || `Slide ${currentSlideIndex + 1}`;
        }
    }

    async function renderCanvas(slide) {
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = 375;
    tempCanvas.height = 667;
    const fabricCanvas = new window.fabric.Canvas(tempCanvas);

    if (slide.canvasState && slide.canvasState.length > 0) {
        await new Promise((resolve) => {
            fabricCanvas.loadFromJSON(
                { version: '5.2.4', objects: slide.canvasState },
                () => {
                    // Add a white background
                    const backgroundRect = new fabric.Rect({
                        left: 0,
                        top: 0,
                        width: fabricCanvas.width,
                        height: fabricCanvas.height,
                        fill: 'white',
                        selectable: false,
                        evented: false,
                    });

                    // Add the background rectangle to the canvas
                    fabricCanvas.add(backgroundRect);
                    fabricCanvas.sendToBack(backgroundRect); // Ensure it is behind all other elements

                    // Render the canvas
                    fabricCanvas.renderAll();
                    resolve();
                }
            );
        });
    }

    // Export the canvas to a PNG
    return tempCanvas.toDataURL('image/png');
}

async function handleGenerateVideo() {
        isLoading = true;
        generationComplete = false;
        popupBlocked = false;
        generationError = null;
        videoUrl = '';
        uploadProgress = 0;

        try {
            // Check if any slide is missing a script
            const slidesWithoutScript = renderedSlides.filter(slide => !slide.script || slide.script.trim() === "");
            if (slidesWithoutScript.length > 0) {
                missingScriptMessage = "Please add a script to all slides before generating the video.";
                isLoading = false;
                return;
            }

            missingScriptMessage = ''; // Clear the message if all scripts are present
            totalSlides = renderedSlides.length;

            // Step 1: Upload slide previews
            console.log('Uploading slide previews...');
            for (let i = 0; i < renderedSlides.length; i++) {
                const slide = renderedSlides[i];
                if (slide && slide.dataUrl) {
                    try {
                        // Step 1: Request a presigned URL
                        const response = await fetch('/api/upload/presigned-url', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                filename: `slide-${i + 1}-${Date.now()}`,
                            }),
                        });

                        if (!response.ok) {
                            const errorData = await response.json().catch(() => ({}));
                            throw new Error(`Failed to upload slide ${i + 1}: ${errorData.message || response.statusText}`);
                        }

                        const { presignedUrl, fileUrl } = await response.json();

                        // Step 2: Upload the slide data to S3 using the presigned URL
                        const blob = dataURLToBlob(slide.dataUrl); // Convert Base64 to Blob
                        const uploadResponse = await fetch(presignedUrl, {
                            method: 'PUT',
                            body: blob,
                            headers: { 'Content-Type': 'image/png' },
                        });

                        if (!uploadResponse.ok) {
                            throw new Error(`Failed to upload slide ${i + 1} to storage: ${uploadResponse.statusText}`);
                        }

                        console.log(`Slide ${i + 1} uploaded successfully:`, fileUrl);

                        // Step 3: Update the slide's previewImage with the S3 URL
                        renderedSlides[i].previewImage = fileUrl;
                        uploadProgress = Math.floor(((i + 1) / totalSlides) * 100);
                    } catch (uploadError) {
                        console.error(`Error uploading slide ${i + 1}:`, uploadError);
                        throw new Error(`Failed to upload slide ${i + 1}: ${uploadError.message}`);
                    }
                } else {
                    console.warn(`Slide ${i + 1} has no dataUrl, skipping upload.`);
                }
            }

            // Step 2: Generate video
            console.log('Generating video...');
            const renderId = await generateVideo(renderedSlides, selectedVoice?.name); // Pass renderedSlides with updated previewImage URLs and voice
            if (renderId) {
                videoUrl = await waitForVideo(renderId);
                if (videoUrl) {
                    generationComplete = true;
                    // Try to open the video in a new tab
                    const newWindow = window.open(videoUrl, '_blank');
                    // Check if popup was blocked
                    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                        popupBlocked = true;
                        console.warn('Popup was blocked by the browser. Providing manual open link instead.');
                    }
                    
                    // Refresh the list of saved videos
                    await loadSavedVideos();
                }
            }
        } catch (error) {
            console.error('Error generating video:', error);
            generationError = error;
            
            // Display a user-friendly error message
            if (error.message && error.message.includes('SHOTSTACK_API_KEY')) {
                missingScriptMessage = "Missing API key. Please contact the administrator.";
            } else if (error.message && error.message.includes('Failed to upload')) {
                missingScriptMessage = error.message;
            } else if (error.message && error.message.includes('Failed to generate video')) {
                // Check if we have more details about the Shotstack error
                if (error.cause && error.cause.response) {
                    missingScriptMessage = `Video generation failed: ${error.cause.response.message || 'Unknown error'}`;
                } else {
                    missingScriptMessage = "Failed to generate video. Please try again later.";
                }
            } else if (error.message && error.message.includes('Video rendering failed')) {
                missingScriptMessage = "Video rendering failed. Please check your slides and try again.";
            } else if (error.message && error.message.includes('timed out')) {
                missingScriptMessage = "Video generation timed out. Please try again with fewer or simpler slides.";
            } else if (error.message && error.message.includes('network')) {
                missingScriptMessage = "Network error. Please check your internet connection and try again.";
            } else {
                missingScriptMessage = `Error: ${error.message || "An unexpected error occurred while generating the video."}`;
            }
        } finally {
            isLoading = false;
        }
    }

function dataURLToBlob(dataURL) {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const buffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(buffer);

    for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([buffer], { type: mimeString });
}

    function handleTextToSpeech() {
        if (renderedSlides[currentSlideIndex] && renderedSlides[currentSlideIndex].script) {
            const utterance = new SpeechSynthesisUtterance(renderedSlides[currentSlideIndex].script);
            if (selectedVoice) {
                utterance.voice = selectedVoice;
            }
            window.speechSynthesis.speak(utterance);
        }
    }

    function toggleModal() {
        isModalOpen = !isModalOpen;
    }
    
    function toggleVideoHistory() {
        showVideoHistory = !showVideoHistory;
        if (showVideoHistory) {
            loadSavedVideos();
        }
    }
    
    function handleViewSavedVideo(video) {
        activeSavedVideo = video;
        window.open(video.url, '_blank');
    }
    
    async function handleDeleteVideo(video) {
        if (confirm(`Are you sure you want to delete this video: "${video.title}"?`)) {
            try {
                await deleteVideo(video._id);
                await loadSavedVideos();
                if (activeSavedVideo && activeSavedVideo._id === video._id) {
                    activeSavedVideo = null;
                }
            } catch (error) {
                console.error('Error deleting video:', error);
                alert('Failed to delete the video. Please try again.');
            }
        }
    }

    function openVideoInNewTab() {
        if (videoUrl) {
            window.open(videoUrl, '_blank');
        }
    }

    function handleClickOutside(event) {
    const modal = document.querySelector('.voice-modal');
    const dropdownContainer = document.querySelector('.dropdown-container');

    if (
        isModalOpen &&
        (!modal || !modal.contains(event.target)) &&
        (!dropdownContainer || !dropdownContainer.contains(event.target))
    ) {
        isModalOpen = false;
    }
}

    function updateSelectedVoice(event) {
        const voiceName = event.target.value;
        selectedVoice = voices.find(v => v.name === voiceName);
    }

    function loadVoices() {
        let allVoices = window.speechSynthesis.getVoices();
        voices = allVoices.filter(voice => voice.lang.startsWith("en"));

        // Set default voice
        selectedVoice = voices.find(v => v.default) || voices[0];
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString();
    }
    
    function formatDuration(seconds) {
        if (!seconds) return "Unknown duration";
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    onMount(() => {
        loadVoices();
        window.speechSynthesis.onvoiceschanged = loadVoices;

        // Add click outside listener
        document.addEventListener('click', handleClickOutside);
    });

    onDestroy(() => {
        // Remove click outside listener
        document.removeEventListener('click', handleClickOutside);
    });

    function handleTestUpload(){
        testUploadSlidePreviews();
    }

</script>

{#if isOpen}
    <div class="modal-overlay" on:click={closeModal}>
        <div class="modal-content" on:click|stopPropagation>
            <button class="close-button" on:click={closeModal}>×</button>
            <h2>Video Preview</h2>
            
            <div class="tabs">
                <button class="tab-button {!showVideoHistory ? 'active' : ''}" 
                        on:click={() => showVideoHistory = false}>
                    Preview & Generate
                </button>
                <button class="tab-button {showVideoHistory ? 'active' : ''}" 
                        on:click={toggleVideoHistory}>
                    Video History ({savedVideos.length})
                </button>
            </div>
            
            {#if !showVideoHistory}
                {#if hasContent}
                    <!-- Slide Title and Navigation -->
                    <div class="slide-header">
                        <button class="nav-arrow" on:click={prevSlide} disabled={currentSlideIndex === 0}>&larr;</button>
                        <h3 class="title-display">{slideTitle}</h3>
                        <button class="nav-arrow" on:click={nextSlide} disabled={currentSlideIndex === renderedSlides.length - 1}>&rarr;</button>
                    </div>
                    {#if renderedSlides.length > 0}
                        <div class="slide-container">
                            <div class="slide-image">
                                {#if renderedSlides[currentSlideIndex]}
                                    <img src={renderedSlides[currentSlideIndex].dataUrl} alt="Slide Image" />
                                {/if}
                            </div>
                            <div class="slide-script">
                                {#if renderedSlides[currentSlideIndex] && renderedSlides[currentSlideIndex].script}
                                    <p>{renderedSlides[currentSlideIndex].script}</p>
                                {/if}
                            </div>
                        </div>
                    {/if}
                    
                    <div class="button-container">
                        {#if missingScriptMessage}
                            <p class="error-message">{missingScriptMessage}</p>
                        {/if}
                        
                        {#if isLoading && uploadProgress > 0}
                            <div class="progress-container">
                                <div class="progress-bar" style="width: {uploadProgress}%"></div>
                                <span class="progress-text">Uploading slides: {uploadProgress}%</span>
                            </div>
                        {/if}

                        {#if generationComplete && popupBlocked}
                            <p class="popup-blocked-message">Video ready! The popup was blocked. Click "Open Video" to view it.</p>
                        {/if}

                        {#if generationComplete && videoUrl}
                            <button class="open-video-button" on:click={openVideoInNewTab}>
                                Open Video
                            </button>
                        {/if}

                        <button class="volume-button" on:click={handleTextToSpeech}>
                            <img src="/assets/volume.svg" alt="Volume Icon" />
                        </button>
                            <!-- Dropdown Button with Carrot Icon -->
                            <div class="dropdown-container">
                                <!-- Dropdown Button -->
                                <button class="dropdown-button" on:click={toggleModal}>
                                    <img src="/assets/carrot.svg" alt="Dropdown Icon" />
                                </button>
                            
                                <!-- Voice Modal -->
                                {#if isModalOpen}
                                    <div class="voice-modal">
                                        <h3>Select Voice</h3>
                                        <select bind:value={selectedVoice.name} on:change={updateSelectedVoice}>
                                            {#each voices as voice}
                                                <option value="{voice.name}">{voice.name} ({voice.lang})</option>
                                            {/each}
                                        </select>
                                    </div>
                                {/if}
                            </div>
                        <button class="generate-button" on:click={handleGenerateVideo} disabled={isLoading}>
                            {isLoading ? "Generating..." : "Generate Video"}
                        </button>
                    </div>
                {:else}
                    <p>Please add content to your canvas or script before generating a video.</p>
                {/if}
            {:else}
                <!-- Video History Tab -->
                <div class="video-history-container">
                    {#if savedVideos.length === 0}
                        <p class="empty-state">No videos have been generated for this story yet.</p>
                    {:else}
                        <div class="video-list">
                            {#each savedVideos as video}
                                <div class="video-item">
                                    <div class="video-details">
                                        <h4>{video.title}</h4>
                                        <p class="video-meta">
                                            Voice: {video.voice} | 
                                            Duration: {formatDuration(video.duration)} | 
                                            Created: {formatDate(video.createdAt)}
                                        </p>
                                        <p class="video-description">{video.description}</p>
                                    </div>
                                    <div class="video-actions">
                                        <button class="view-video-button" on:click={() => handleViewSavedVideo(video)}>
                                            View
                                        </button>
                                        <button class="delete-video-button" on:click={() => handleDeleteVideo(video)}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            {/each}
                        </div>
                    {/if}
                </div>
            {/if}
        </div>
    </div>
{/if}

<style>
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2000;
    }

    .modal-content {
        background: white;
        padding: 20px;
        border-radius: 8px;
        width: 80%;
        max-width: 1000px;
        height: 90vh;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow-y: auto; /* Allows scrolling if content is too long */
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .slide-navigation {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .slide-navigation button {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .slide-container {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        overflow-y: auto;
    }

    .slide-image {
        flex: 1;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #ccc; /* Add vertical line */
        padding-right: 20px; /* Add spacing between the line and content */
    }

    .slide-image img {
        max-width: 56%;
        height: auto;
        display: block;
    }

    .slide-script {
        flex: 1;
        max-height: 300px;
        overflow-y: auto;
        display: flex;
        align-items: flex-start;
        padding-left: 20px; /* Add spacing between the line and script */
    }

    .slide-script p {
        margin: 0;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: auto; /* Pushes it to the bottom */
        padding-top: 10px;
        padding-bottom: 10px; /* Ensures spacing at bottom */
        background: white; /* Ensures visibility if modal scrolls */
    }

    .generate-button {
        background: #007bff;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 8px;
        cursor: pointer;
    }

    .volume-button {
        background: none;
        border: none;
        cursor: pointer;
        /* margin-right: 10px; */
    }

    .volume-button img {
        width: 24px;
        height: 24px;
    }

    .slide-title {
        margin-bottom: 20px;
        text-align: center;
    }

    .title-input {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 5px;
        width: 80%;
    }

    .edit-icon {
        font-size: 1rem;
        color: #888;
    }

    .edit-icon:hover {
        color: #555;
    }

    .slide-header {
        display: flex;
        align-items: center;
        justify-content: center; /* Center the title and arrows */
        gap: 10px; /* Add spacing between the arrows and the title */
        margin-bottom: 20px;
    }

    .nav-arrow {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        padding: 0 5px; /* Reduce padding to bring arrows closer to the title */
    }

    .nav-arrow:disabled {
        color: #ccc;
        cursor: not-allowed;
    }

    .title-display {
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
    }

    .button-container {
        display: flex;
        align-items: center;
        gap: 8px; /* Reduce spacing between buttons */
        justify-content: flex-end;
        margin-top: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        background: white;
    }

    .volume-button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .volume-button img {
        width: 20px;
        height: 20px;
    }

    .dropdown-container {
        position: relative;
        display: flex;
        align-items: center;
        z-index: 2101;
        min-width: 40px;
    }

    .dropdown-button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-left: -8px;
        margin-right: 10px; /* Move the carrot closer to the volume button */
    }

    .dropdown-button img {
        width: 16px;
        height: 16px;
    }

    .dropdown-button:hover img {
        opacity: 0.8;
    }

    .voice-modal {
        position: absolute;
        bottom: 45px;
        right: -125px;
        transform: none;
        background: white;
        border-radius: 8px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
        padding: 15px;
        z-index: 2100;
        width: 300px;
        text-align: left;
    }

    .voice-modal h3 {
        margin: 0 0 10px 0;
        font-size: 16px;
        font-weight: 600;
        color: #111827;
    }

    .voice-modal select {
        width: 100%;
        padding: 8px;
        margin: 10px 0;
        border: 1px solid #e5e7eb;
        border-radius: 6px;
        font-size: 14px;
        background-color: white;
    }

    .voice-modal select option:hover {
        background-color: #f3f4f6;
    }

    .error-message {
        color: red;
        font-size: 0.9rem;
        margin-right: auto;
        margin-bottom: 10px;
    }

    .progress-container {
        flex: 1;
        height: 20px;
        background-color: #f0f0f0;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 10px;
    }

    .progress-bar {
        height: 100%;
        background-color: #4CAF50;
        transition: width 0.3s ease;
    }

    .progress-text {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
        color: #333;
    }

    .popup-blocked-message {
        color: #ff8c00;
        font-size: 0.9rem;
        margin-right: auto;
    }

    .open-video-button {
        background: #28a745;
        color: white;
        border: none;
        padding: 10px 15px;
        border-radius: 8px;
        cursor: pointer;
        margin-right: 10px;
    }
    
    /* Tabs styling */
    .tabs {
        display: flex;
        margin-bottom: 20px;
        border-bottom: 1px solid #ddd;
    }
    
    .tab-button {
        padding: 10px 20px;
        border: none;
        background: none;
        cursor: pointer;
        font-size: 1rem;
        border-bottom: 3px solid transparent;
        transition: all 0.2s ease;
    }
    
    .tab-button.active {
        border-bottom: 3px solid #007bff;
        font-weight: bold;
    }
    
    .tab-button:hover {
        background-color: #f8f9fa;
    }
    
    /* Video history styling */
    .video-history-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }
    
    .video-list {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    
    .video-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid #eee;
        border-radius: 8px;
        background-color: #f9f9f9;
    }
    
    .video-details {
        flex: 1;
    }
    
    .video-details h4 {
        margin: 0 0 8px 0;
        font-size: 1.1rem;
    }
    
    .video-meta {
        color: #666;
        font-size: 0.9rem;
        margin: 0 0 5px 0;
    }
    
    .video-description {
        color: #444;
        font-size: 0.95rem;
        margin: 0;
    }
    
    .video-actions {
        display: flex;
        gap: 10px;
    }
    
    .view-video-button {
        background: #007bff;
        color: white;
        border: none;
        padding: 8px 15px;
        border-radius: 6px;
        cursor: pointer;
    }
    
    .delete-video-button {
        background: #dc3545;
        color: white;
        border: none;
        padding: 8px 15px;
        border-radius: 6px;
        cursor: pointer;
    }
    
    .empty-state {
        text-align: center;
        color: #666;
        padding: 40px 0;
        font-size: 1.1rem;
    }
</style>