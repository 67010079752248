import { writable } from 'svelte/store';
import { apiService } from '../services/apiService.js';
import lunr from 'lunr';

// Add loading progress to initial state
const initialState = {
  evidence: [],
  savedEvidence: [],
  sortedEvidenceIds: [],
  sortedEvidenceDetails: [],
  selectedEvidenceIds: [],
  currentEvidence: null,
  searchIndex: null,
  loadingProgress: {
    total: 0,
    loaded: 0,
    isLoading: false,
    stage: ''
  }
};

export const evidenceStore = writable(initialState);

// Function to build search index from evidence
function buildSearchIndex(evidence) {
  return lunr(function() {
    this.ref('_id');
    this.field('description');
    this.field('label');
    this.field('text');
    
    // Add documents to index
    evidence.forEach(doc => {
      this.add({
        _id: doc._id,
        description: doc.description || '',
        label: doc.label || '',
        text: doc.text || ''
      });
    });
  });
}

export const fetchSelectedEvidence = async () => {
    console.log('Fetching selected evidence');
};

export const searchEvidence = async (searchQuery) => {
  if (!searchQuery) {
    console.warn('No search query provided');
    return;
  }

  console.log('Evidence Store searching with query:', searchQuery);

  try {
    evidenceStore.update(store => ({
      ...store,
      loadingProgress: {
        total: 0,
        loaded: 0,
        isLoading: true,
        stage: `Searching evidence for: ${searchQuery}`
      }
    }));

    // Get all evidence from API
    const allResults = await apiService.searchEvidence(searchQuery);
    
    // Build search index with the results
    const searchIndex = buildSearchIndex(allResults);
    
    // Convert the search query to Lunr syntax
    const lunrQuery = searchQuery
      .replace(/\bNOT\b/g, '-')  // Replace NOT with Lunr's negation operator
      .replace(/\bAND\b/g, '+')  // Replace AND with Lunr's required operator
      .replace(/\bOR\b/g, ' ');  // Replace OR with space (default OR behavior)
    
    console.log('Lunr search query:', lunrQuery);
    
    try {
      // Perform boolean search using Lunr
      const lunrResults = searchIndex.search(lunrQuery);
      console.log('Lunr search results:', lunrResults.length);
      
      // Map results back to full evidence objects
      const searchResults = lunrResults
        .map(result => allResults.find(doc => doc._id === result.ref))
        .filter(Boolean);
      
      console.log('Final filtered results:', searchResults.length);
      
      evidenceStore.update(store => ({
        ...store,
        evidence: searchResults,
        loadingProgress: {
          total: searchResults.length,
          loaded: searchResults.length,
          isLoading: false,
          stage: 'Complete'
        }
      }));
      
    } catch (searchError) {
      console.warn('Boolean search failed, falling back to API results:', searchError);
      // Fall back to API results if Lunr search fails
      evidenceStore.update(store => ({
        ...store,
        evidence: allResults,
        loadingProgress: {
          total: allResults.length,
          loaded: allResults.length,
          isLoading: false,
          stage: 'Complete'
        }
      }));
    }

  } catch (error) {
    console.error('Evidence Store search error:', error);
    console.error('Error details:', {
      message: error.message,
      stack: error.stack
    });
    evidenceStore.update(store => ({
      ...store,
      loadingProgress: {
        total: 0,
        loaded: 0,
        isLoading: false,
        stage: `Error searching evidence: ${error.message}`
      }
    }));
    throw error;
  }
};

// Function to select evidence items
export const selectEvidence = (evidenceId) => {
  evidenceStore.update(store => {
    if (!store.selectedEvidenceIds.includes(evidenceId)) {
      store.selectedEvidenceIds.push(evidenceId); // Add the evidence ID if not already selected
    }
    return store;
  });
};

// Function to deselect evidence items
export const deselectEvidence = (evidenceId) => {
  evidenceStore.update(store => {
    store.selectedEvidenceIds = store.selectedEvidenceIds.filter(id => id !== evidenceId); // Remove the evidence ID
    return store;
  });
};

// Add new function to handle saving evidence
export const saveEvidence = (evidence) => {
  if (!evidence || !evidence._id) {
    console.error('Invalid evidence object:', evidence);
    return;
  }

  evidenceStore.update(store => {
    const currentSaved = store.savedEvidence || [];
    const isAlreadySaved = currentSaved.some(e => e._id === evidence._id);
    if (!isAlreadySaved) {
      return {
        ...store,
        savedEvidence: [...currentSaved, evidence]
      };
    }
    return store;
  });
};

// Add function to remove from saved
export const removeSavedEvidence = (evidenceId) => {
  evidenceStore.update(store => ({
    ...store,
    savedEvidence: store.savedEvidence.filter(e => e._id !== evidenceId)
  }));
};

// Add function to set current evidence
export const setCurrentEvidence = (evidence) => {
  evidenceStore.update(store => ({
    ...store,
    currentEvidence: evidence
  }));
};

// Add function to clear current evidence
export const clearCurrentEvidence = () => {
  evidenceStore.update(store => ({
    ...store,
    currentEvidence: null
  }));
};
