<script>
  import { onMount } from "svelte";
  import HeaderBar from "../components/HeaderBar.svelte";
  import EvidenceModal from "../components/EvidenceModal.svelte";
  import { getContext } from "svelte";
  import { navigate } from "svelte-routing";
  import EvidenceSelection from "../components/EvidenceSelection.svelte";
  import ResearchEvidence from "../components/Research-Evidence.svelte";
  import { get } from "svelte/store";

  // Get the storyStore from context
  const storyStore = getContext("storyStore");

  // add state for modal
  let showModal = false;
  let selectedEvidence = null;
  let isLoadingStory = true;

  // handler for opening modal
  function handleEvidenceClick(evidence) {
    selectedEvidence = evidence;
    showModal = true;
  }

  onMount(async () => {
    // Get URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const storyId = urlParams.get('story');

    try {
      // If we have a storyId in the URL, load it
      if (storyId) {
        console.log('Loading story from URL parameter:', storyId);
        const loadedStory = await storyStore.loadStory(storyId);
        if (!loadedStory) {
          console.error('Failed to load story:', storyId);
          // Handle error - maybe redirect to grid view
          navigate('/grid');
          return;
        }
      } else {
        // If no storyId in URL, check if we have one in the store
        const currentStory = get(storyStore);
        if (!currentStory._id) {
          console.error('No story ID found in URL or store');
          // Handle error - maybe redirect to grid view
          navigate('/grid');
          return;
        }
      }

      // Update URL to include story ID if not present
      const currentStory = get(storyStore);
      if (currentStory._id && !storyId) {
        const url = new URL(window.location);
        url.searchParams.set('story', currentStory._id);
        window.history.replaceState({}, '', url);
      }
    } catch (error) {
      console.error('Error initializing story:', error);
      // Handle error - maybe redirect to grid view
      navigate('/grid');
    } finally {
      isLoadingStory = false;
    }
  });
</script>

<div class="page-container">
  <HeaderBar />
  <ResearchEvidence {handleEvidenceClick} />
  
  <!-- Add modal component -->
  {#if showModal}
    <EvidenceModal 
      evidence={selectedEvidence}
      on:close={() => showModal = false}
    />
  {/if}

  {#if isLoadingStory}
    <div class="loading-overlay">
      <div class="spinner"></div>
      <p>Loading story...</p>
    </div>
  {/if}
</div>

<style>
  .page-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>
