import { authStore } from '../stores/authStore.js';
import { get } from 'svelte/store';

export const storyService = {
  // Create a new story
  createStory: async (storyData) => {
    try {
      // Check if user is authenticated
      const auth = get(authStore);
      if (!auth.isAuthenticated) {
        throw new Error('Authentication required');
      }
      
      const response = await fetch('/api/stories', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(storyData)
      });
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to create story');
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error creating story:', error);
      throw error;
    }
  },
  
  // Get all stories for the current user
  getUserStories: async () => {
    try {
      const response = await fetch('/api/stories');
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to fetch stories');
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error fetching stories:', error);
      throw error;
    }
  },
  
  // Get a specific story
  getStory: async (id) => {
    try {
      const response = await fetch(`/api/stories/${id}`);
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to fetch story');
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error fetching story:', error);
      throw error;
    }
  },
  
  // Update a story
  updateStory: async (id, storyData) => {
    try {
      const response = await fetch(`/api/stories/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(storyData)
      });
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to update story');
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error updating story:', error);
      throw error;
    }
  },
  
  // Delete a story
  deleteStory: async (id) => {
    try {
      const response = await fetch(`/api/stories/${id}`, {
        method: 'DELETE'
      });
      
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to delete story');
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error deleting story:', error);
      throw error;
    }
  }
}; 