import { API_BASE_URL } from '../config/apiConfig';

export const apiService = {
  // Add a simple cache for search results
  _searchCache: {},

  async saveStory(storyData) {
    console.log('Attempting to save story with data:', storyData);
    try {
      const response = await fetch(`${API_BASE_URL}/api/story`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(storyData),
      });
      if (!response.ok) {
        throw new Error('Failed to save the story. Response status: ' + response.status);
      }
      console.log('Story saving request sent');
      return response;
    } catch (error) {
      console.error('Error saving the story:', error);
      throw error;
    }
  },

  async getPrompts() {
    try {
      const response = await fetch(`${API_BASE_URL}/api/prompts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch prompts. Response status: ' + response.status);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching prompts:', error);
      throw error;
    }
  },

  // New method to fetch prompt details by ID
  async getPromptDetails(promptId) {
    try {
      const response = await fetch(`${API_BASE_URL}/api/prompts/${promptId}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch prompt details');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching prompt details:', error);
      throw error;
    }
  },

  async getEvidence(topic) {
    try {
      const response = await fetch(`${API_BASE_URL}/api/evidence?topic=${encodeURIComponent(topic)}`, { 
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch evidence. Response status: ' + response.status);
      }
  
      const data = await response.json();
      return data; // Only filtered evidence is returned
    } catch (error) {
      console.error('Error fetching evidence:', error);
      throw error;
    }
  },
  

  async getevidenceDetails(evidenceId) {
    // This function fetches the details of a specific piece of evidence by its ID.
    try {
      // Make a GET request to the API endpoint for evidence details, using the provided evidenceId.
      const response = await fetch(`${API_BASE_URL}/api/evidence/${evidenceId}`, {
        method: 'GET', // Specify the HTTP method as GET.
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON.
        },
      });

      // Check if the response is not OK (status code is not in the range 200-299).
      if (!response.ok) {
        // If the response is not OK, throw an error with the response status.
        throw new Error('Failed to fetch evidence details. Response status: ' + response.status);
      }

      // Parse the JSON response to extract the evidence details.
      const data = await response.json();
      
      // Return the parsed data, which contains the details of the evidence.
      return data; 
    } catch (error) {
      // If an error occurs during the fetch or parsing, log the error message.
      console.error('Error fetching evidence details:', error);
      
      // Rethrow the error for further handling by the calling function.
      throw error;
    }
  },

  async searchImages(query) {
    const apiKey = process.env.GOOGLE_API_KEY; // Access the environment variable
    const searchEngineId = 'c5e5e0d86c2304c3a'; // Replace with your Custom Search Engine ID

    try {
      const response = await fetch(`https://www.googleapis.com/customsearch/v1?q=${encodeURIComponent(query)}&cx=${searchEngineId}&searchType=image&key=${apiKey}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch images. Response status: ' + response.status);
      }
      const data = await response.json();
      return data.items; // Adjust based on the API response structure
    } catch (error) {
      console.error('Error fetching images:', error);
      throw error;
    }
  },

  async searchGifs(query) {
    const apiKey = process.env.GIPHY_API_KEY; // Access the Giphy API key from the environment variable

    try {
      const response = await fetch(`https://api.giphy.com/v1/gifs/search?q=${encodeURIComponent(query)}&api_key=${apiKey}&limit=25`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch GIFs. Response status: ' + response.status);
      }
      const data = await response.json();
      return data.data; // Return the array of GIF objects
    } catch (error) {
      console.error('Error fetching GIFs:', error);
      throw error;
    }
  },

  async searchEvidence(query) {
    try {
      console.log('API Service searching with query:', query);
      const response = await fetch(`${API_BASE_URL}/api/evidence/search?query=${encodeURIComponent(query)}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Search API error details:', errorData);
        throw new Error(`Failed to search evidence. Response status: ${response.status}. Details: ${JSON.stringify(errorData)}`);
      }

      const data = await response.json();
      console.log('API Service received results:', data?.length || 0);
      return data;
    } catch (error) {
      console.error('API Service error:', error);
      throw error;
    }
  },

  async searchPrompts(query, options = {}) {
    try {
      // Default to text search if searchType not specified
      const { searchType = 'text' } = options;
      
      // Generate cache key
      const cacheKey = `${query}-${searchType}`;
      
      // Check cache first (but only for text searches, as they're more reliable)
      if (searchType === 'text' && this._searchCache[cacheKey]) {
        console.log('Using cached search results for:', query);
        return this._searchCache[cacheKey];
      }
      
      // Construct the URL with search type parameter
      const url = `${API_BASE_URL}/api/prompts/search?query=${encodeURIComponent(query)}&type=${searchType}`;
      
      console.log(`Searching prompts with ${searchType} search: ${query}`);
      
      const response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });

      if (!response.ok) {
        throw new Error('Failed to search prompts. Response status: ' + response.status);
      }

      const data = await response.json();
      
      let results;
      // If smart search, ensure similarity score is formatted nicely
      if (searchType === 'semantic' && Array.isArray(data)) {
        results = data.map(item => ({
          ...item,
          // Format similarity score for display if it exists
          similarityScore: item.similarity !== undefined ? 
            Math.round(item.similarity * 100) : undefined
        }));
      } else {
        results = data;
      }
      
      // Cache results (especially important for text searches)
      this._searchCache[cacheKey] = results;
      
      // Limit cache size to avoid memory issues
      const cacheKeys = Object.keys(this._searchCache);
      if (cacheKeys.length > 20) { // Keep only 20 most recent searches
        delete this._searchCache[cacheKeys[0]];
      }
      
      return results;
    } catch (error) {
      console.error('Error searching prompts:', error);
      throw error;
    }
  },

};
