// Remove the direct fabric import since it's a global variable
// import { fabric } from 'fabric';

export function generatePreviewForSlide(canvasState) {
  return new Promise((resolve, reject) => {
    try {
      // Get fabric from window since it's loaded globally
      const fabric = window.fabric;
      if (!fabric) {
        throw new Error('Fabric.js not loaded');
      }

      // Create a temporary canvas
      const tempCanvas = new fabric.Canvas(null, {
        width: 375,
        height: 667
      });
      
      // Load the canvas state
      if (canvasState && canvasState.length > 0) {
        tempCanvas.loadFromJSON({ objects: canvasState }, () => {
          const scaleFactor = 0.5;
          
          // Create another temp canvas for the scaled version
          const previewCanvas = new fabric.Canvas(null, {
            width: 375 * scaleFactor,
            height: 667 * scaleFactor
          });
          
          // Clone and scale all objects
          tempCanvas.getObjects().forEach(obj => {
            const clonedObj = fabric.util.object.clone(obj);
            clonedObj.scaleX = obj.scaleX * scaleFactor;
            clonedObj.scaleY = obj.scaleY * scaleFactor;
            clonedObj.left = obj.left * scaleFactor;
            clonedObj.top = obj.top * scaleFactor;
            previewCanvas.add(clonedObj);
          });
          
          // Generate optimized SVG
          const svgData = previewCanvas.toSVG({
            viewBox: {
              x: 0,
              y: 0,
              width: 375 * scaleFactor,
              height: 667 * scaleFactor
            },
            width: 375 * scaleFactor,
            height: 667 * scaleFactor,
            suppressPreamble: false,
            preserveAspectRatio: 'xMidYMid meet'
          });
          
          // Clean up
          tempCanvas.dispose();
          previewCanvas.dispose();
          
          // Optimize SVG
          const optimizedSvg = svgData
            .replace(/\s+/g, ' ')
            .replace(/>\s+</g, '><')
            .replace(/\s+>/g, '>')
            .replace(/\s+\/>/g, '/>')
            .replace(/<svg/, `<svg width="${375 * scaleFactor}" height="${667 * scaleFactor}"`);
          
          resolve(optimizedSvg);
        });
      } else {
        // If no canvas state, return empty string
        resolve('');
      }
    } catch (err) {
      console.error('Error generating preview:', err);
      reject(err);
    }
  });
} 