<script>
  import { createEventDispatcher } from 'svelte';
  import { authStore } from '../stores/authStore.js';
  
  const dispatch = createEventDispatcher();
  
  // Menu items configuration
  const menuItems = [
    {
      id: 'create-new',
      label: 'Create New Data Story',
      icon: 'bi-plus-circle',
      component: 'Home-CreateNewStory'
    },
    {
      id: 'my-stories',
      label: 'My Data Stories',
      icon: 'bi-collection',
      component: 'Home-MyDataStories'
    },
    {
      id: 'divider'
    },
    {
      id: 'classroom-link',
      label: 'Generate Classroom Link',
      icon: 'bi-link-45deg',
      component: 'Home-ClassroomLink'
    },
    {
      id: 'divider'
    },
    {
      id: 'admin-console',
      label: 'Admin Console',
      icon: 'bi-shield-lock',
      component: 'Home-AdminConsole',
      adminOnly: true // Flag to show only for admin users
    }
  ];

  let activeItem = menuItems[0].id; // Default to first item

  function handleMenuClick(item) {
    if (item.id === 'divider') return;
    activeItem = item.id;
    dispatch('menuSelect', { component: item.component });
  }

  // Check if user is admin
  $: isAdmin = $authStore?.user?.isAdmin || false;
  
  // Debug logging
  $: {
    console.log('Auth store state:', $authStore);
    console.log('User object:', $authStore?.user);
    console.log('Is admin?', isAdmin);
  }
</script>

<nav class="sidebar-menu">
  {#each menuItems as item}
    {#if item.id === 'divider'}
      <div class="menu-divider"></div>
    {:else if !item.adminOnly || (item.adminOnly && isAdmin)}
      <button
        class="menu-item {activeItem === item.id ? 'active' : ''}"
        on:click={() => handleMenuClick(item)}
      >
        <i class="bi {item.icon}"></i>
        <span>{item.label}</span>
      </button>
    {/if}
  {/each}
</nav>

<style>
  .sidebar-menu {
    padding: 0px !important;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }

  .menu-item {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 12px 16px;
    border: none;
    background: none;
    border-radius: 8px;
    color: #4b5563;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .menu-item:hover {
    background: #f3f4f6;
    color: #2563eb;
  }

  .menu-item.active {
    background: #eff6ff;
    color: #2563eb;
  }

  .menu-item i {
    font-size: 18px;
  }

  .menu-divider {
    height: 1px;
    background: #e5e7eb;
    margin: 12px 0;
  }
</style> 