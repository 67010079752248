<script>
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-routing';
  import { authStore } from '../stores/authStore.js';
  
  export let redirectTo = '/';
  export let path = ''; // Add this to track the current path
  export let adminRequired = false; // Add admin requirement option
  
  let isChecking = true;
  let accessDenied = false;
  
  // Function to check if user is allowed to access this route
  function checkAccess(auth) {
    isChecking = auth.isLoading;
    
    // If not loading and not authenticated, redirect
    if (!auth.isLoading && !auth.isAuthenticated) {
      // Store the current path for redirect after login
      authStore.setRedirect(path || window.location.pathname);
      navigate(redirectTo);
      return;
    }
    
    // Check admin requirement
    if (adminRequired && !auth.isLoading && auth.isAuthenticated) {
      if (!auth.user?.isAdmin) {
        accessDenied = true;
      } else {
        accessDenied = false;
      }
    }
  }
  
  onMount(async () => {
    // Check if user is authenticated
    await authStore.checkAuth();
    
    // Subscribe to auth store changes
    const unsubscribe = authStore.subscribe(checkAccess);
    
    return unsubscribe;
  });
</script>

{#if isChecking}
  <div class="auth-loading">
    <div class="spinner"></div>
    <p>Checking authentication...</p>
  </div>
{:else if accessDenied}
  <div class="access-denied">
    <i class="bi bi-shield-lock"></i>
    <h3>Access Denied</h3>
    <p>You don't have permission to access this page.</p>
    <button class="back-btn" on:click={() => navigate('/')}>Back to Home</button>
  </div>
{:else}
  <slot></slot>
{/if}

<style>
  .auth-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #2563eb;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .access-denied {
    padding: 4rem 2rem;
    text-align: center;
    color: #64748b;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .access-denied i {
    font-size: 3rem;
    color: #94a3b8;
    margin-bottom: 1rem;
  }

  .access-denied h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1e293b;
    margin: 0 0 0.5rem 0;
  }

  .access-denied p {
    margin: 0 0 1.5rem 0;
  }
  
  .back-btn {
    padding: 0.5rem 1rem;
    background: #2563eb;
    color: white;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 0.875rem;
    transition: background-color 0.2s;
  }
  
  .back-btn:hover {
    background: #1d4ed8;
  }
</style> 