<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import { apiService } from '../services/apiService'; // Import the apiService
  const dispatch = createEventDispatcher();

  export let canvasComponent; // Receive the canvasComponent as a prop
  export let selectedObject;
  export let evidenceData;

  let imageSearchQuery = ''; // Search query for image search
  let gifSearchQuery = ''; // Search query for gif search
  let images = []; // Array to hold fetched images
  let gifs = []; // Array to hold fetched animated gifs
  let selectedTab = 'images'; // Track the selected tab
  let isLoading = false;
  let hasSearched = false;  // Track if a search has been performed

  // Function to fetch images based on the search query
  async function fetchImages() {
    if (!imageSearchQuery) return; // Do not fetch if the search query is empty

    try {
      isLoading = true;
      hasSearched = true;  // Mark that a search has been performed
      const response = await apiService.searchImages(imageSearchQuery); // Call the searchImages method
      console.log('API Response:', response); // Log the response
      images = response; // Set the fetched images
    } catch (error) {
      console.error('Error fetching images:', error);
    } finally {
      isLoading = false;
    }
  }

  // Function to fetch animated gifs based on the search query
  async function fetchGifs() {
    if (!gifSearchQuery) return; // Do not fetch if the search query is empty

    try {
      const response = await apiService.searchGifs(gifSearchQuery); // Call the searchGifs method
      console.log('API Response for Gifs:', response); // Log the response
      gifs = response; // Set the fetched gifs
    } catch (error) {
      console.error('Error fetching gifs:', error);
    }
  }

  // Function to get proxied image URL
  function getProxiedImageUrl(originalUrl) {
    // Use current origin instead of hardcoded URL
    const baseUrl = window.location.origin;
    return `${baseUrl}/api/proxy/image?url=${encodeURIComponent(originalUrl)}`;
  }

  // Function to handle image click and add it to the canvas
  async function addImageToCanvas(imageUrl) {
    if (canvasComponent) {
      try {
        const proxiedUrl = getProxiedImageUrl(imageUrl);
        console.log('Loading image from:', proxiedUrl);
        
        // Pass image data in a structured way
        await canvasComponent.addImageToCanvas({
          url: proxiedUrl,
          type: 'external'
        });
        
      } catch (error) {
        console.error('Error loading image:', error);
      }
    }
  }

  // Function to clear the search input and reset state
  function clearSearch() {
    imageSearchQuery = '';
    hasSearched = false;
    images = [];
  }

  function handleAnalyzeClick() {
    if (evidenceData) {
      console.log('Analyzing evidence:', evidenceData);
      dispatch('showAnalysis', evidenceData);
    }
  }

  // Handle key press for search input
  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      fetchImages();
    }
  }
</script>

<div class="images-menu">
  <div class="search-section">
    <div class="search-bar">
      <input 
        type="text" 
        placeholder="Search images and press Enter..." 
        bind:value={imageSearchQuery}
        on:keydown={handleKeyPress}
      />
      {#if imageSearchQuery}
        <button class="clear-button" on:click={clearSearch}>
          <i class="fas fa-times"></i>
        </button>
      {/if}
      <button class="search-button" on:click={fetchImages}>
        <i class="fas fa-search"></i>
      </button>
    </div>
  </div>

  <div class="images-grid">
    {#if isLoading}
      <div class="message">Loading...</div>
    {:else if !hasSearched}
      <div class="message">Enter a search term and press Enter or click the search button.</div>
    {:else if images.length === 0}
      <div class="message">No images found. Try a different search.</div>
    {:else}
      {#each images as image}
        <div class="image-item" on:click={() => addImageToCanvas(image.link)}>
          <img 
            class="image-preview" 
            src={image.image.thumbnailLink} 
            alt={image.title}
          />
          <div class="image-overlay">
            <button class="image-action">
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
      {/each}
    {/if}
  </div>
</div>

<style>
  .images-menu {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    width: 253px;
  }

  .search-section {
    padding: 8px;
    border-bottom: 1px solid #e5e7eb;
  }

  .search-bar {
    display: flex;
    gap: 6px;
    position: relative;
  }

  .search-bar input {
    width: 195px;
    height: 36px;
    padding: 0 32px 0 10px; /* Added right padding for clear button */
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 13px;
    color: #1f2937;
  }

  .clear-button {
    position: absolute;
    right: 48px; /* Position it before the search button */
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: none;
    background: none;
    color: #9ca3af;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 50%;
  }

  .clear-button:hover {
    color: #6b7280;
    background: #f3f4f6;
  }

  .search-button {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8fafc;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    color: #6b7280;
    cursor: pointer;
  }

  .images-grid {
    flex: 1;
    padding: 8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
    overflow-y: auto;
  }

  .image-item {
    width: 115px; /* (253px - 16px padding - 6px gap) / 2 */
    position: relative;
    aspect-ratio: 1;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #e5e7eb;
  }

  .image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  .image-item:hover .image-overlay {
    opacity: 1;
  }

  .image-action {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border: none;
    border-radius: 4px;
    color: #1f2937;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .image-action:hover {
    background: #f3f4f6;
    transform: scale(1.05);
  }

  .message {
    grid-column: 1 / -1;
    padding: 20px;
    text-align: center;
    color: #6b7280;
    font-size: 14px;
    background: #f9fafb;
    border-radius: 6px;
    border: 1px dashed #e5e7eb;
  }
</style>
