import { writable, get } from 'svelte/store';
import { navigate } from '$app/navigation';

// Create a writable store with initial state
const createAuthStore = () => {
  const { subscribe, set, update } = writable({
    isAuthenticated: false,
    user: null,
    isLoading: true,
    redirectAfterLogin: null
  });

  // Add a function to normalize user data
  function normalizeUserData(user) {
    if (!user) return null;
    
    // Debug logging
    console.log('Raw user data before normalization:', {
      ...user,
      isAdmin: user.isAdmin,
      isAdminType: typeof user.isAdmin,
      isAdminValue: Boolean(user.isAdmin)
    });
    
    const normalized = {
      uid: user.uid || user._id || user.id,
      email: user.email,
      displayName: user.displayName || user.name || user.email?.split('@')[0] || 'User',
      photoURL: user.photoURL || user.profileImage || user.picture || user.avatar,
      profileImage: user.photoURL || user.profileImage || user.picture || user.avatar,
      isAdmin: user.isAdmin === true // Ensure boolean conversion
    };
    
    // Debug logging
    console.log('Normalized user data:', {
      ...normalized,
      isAdminType: typeof normalized.isAdmin,
      isAdminValue: normalized.isAdmin
    });
    
    return normalized;
  }

  return {
    subscribe,
    
    // Check authentication status from server
    checkAuth: async () => {
      try {
        update(state => ({ ...state, isLoading: true }));
        const response = await fetch('/auth/current-user');
        const data = await response.json();
        
        if (data.isAuthenticated) {
          // Log raw response data
          console.log('Raw server response:', {
            user: data.user,
            isAdmin: data.user?.isAdmin,
            isAdminType: typeof data.user?.isAdmin
          });
          
          // Normalize the user data
          const normalizedUser = normalizeUserData(data.user);
          console.log('Final normalized user:', {
            ...normalizedUser,
            isAdmin: normalizedUser.isAdmin,
            isAdminType: typeof normalizedUser.isAdmin
          });
          
          update(state => ({ 
            isAuthenticated: true, 
            user: normalizedUser, 
            isLoading: false,
            redirectAfterLogin: state.redirectAfterLogin
          }));
        } else {
          update(state => ({ 
            isAuthenticated: false, 
            user: null, 
            isLoading: false,
            redirectAfterLogin: state.redirectAfterLogin
          }));
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
        update(state => ({ 
          isAuthenticated: false, 
          user: null, 
          isLoading: false,
          redirectAfterLogin: state.redirectAfterLogin
        }));
      }
    },
    
    // Set redirect destination
    setRedirect: (path) => {
      update(state => ({ ...state, redirectAfterLogin: path }));
    },
    
    // Clear redirect
    clearRedirect: () => {
      update(state => ({ ...state, redirectAfterLogin: null }));
    },
    
    // Login with Google
    loginWithGoogle: (redirectPath = null) => {
      // If a redirect path is provided, set it
      if (redirectPath) {
        update(state => ({ ...state, redirectAfterLogin: redirectPath }));
      }
      
      // Open a popup window for Google auth
      const width = 600;
      const height = 600;
      const left = window.innerWidth / 2 - width / 2;
      const top = window.innerHeight / 2 - height / 2;
      
      const popup = window.open(
        '/auth/google',
        'googleAuth',
        `width=${width},height=${height},left=${left},top=${top}`
      );
      
      // Listen for message from popup
      window.addEventListener('message', async (event) => {
        if (event.data === 'authentication-successful') {
          // Refresh auth status
          await authStore.checkAuth();
          
          // Get the current state to check for redirect
          const currentState = get(authStore);
          
          // If there's a redirect path, navigate there
          if (currentState.redirectAfterLogin) {
            const redirectTo = currentState.redirectAfterLogin;
            // Clear the redirect first to prevent loops
            authStore.clearRedirect();
            
            // Check if we have a pending prompt ID
            const pendingPromptId = localStorage.getItem('pendingPromptId');
            if (pendingPromptId) {
              // If we have a pending prompt, navigate directly to grid
              navigate('/grid', { replace: true });
              // Remove the pending prompt ID
              localStorage.removeItem('pendingPromptId');
            } else {
              // Otherwise navigate to the intended destination
              navigate(redirectTo, { replace: true });
            }
          } else {
            // If no redirect path, stay on current page
            window.location.reload();
          }
        }
      }, { once: true });
    },
    
    // Logout
    logout: async () => {
      try {
        // Set loading state
        update(state => ({ ...state, isLoading: true }));
        
        // Call the logout endpoint
        await fetch('/auth/logout');
        
        // Update the store state
        set({ 
          isAuthenticated: false, 
          user: null, 
          isLoading: false, 
          redirectAfterLogin: null 
        });
        
        // Force a full page reload to the home page
        window.location.href = '/?t=' + Date.now();
        
        return true;
      } catch (error) {
        console.error('Error during logout:', error);
        update(state => ({ ...state, isLoading: false }));
        return false;
      }
    },

    get: () => get({ subscribe }),
  };
};

export const authStore = createAuthStore();

// Check auth status when the app loads
if (typeof window !== 'undefined') {
  authStore.checkAuth();
} 