<script>
  import { getContext } from 'svelte';
  import StoryMenuEvidence from './StoryMenu-Evidence.svelte';
  import StoryMenuText from './StoryMenu-Text.svelte';
  import StoryMenuImages from './StoryMenu-Images.svelte';
  import StoryMenuDraw from './StoryMenu-Draw.svelte';
  import StoryMenuElements from './StoryMenu-Elements.svelte';
  import StoryMenuHeadlines from './StoryMenu-Headlines.svelte';
  import AgentSlide from './Agent-Slide.svelte';
  import StoryMenuShapes from './StoryMenu-Shapes.svelte';
  import { createEventDispatcher } from 'svelte';
  import { settingsStore } from '../stores/settingsStore';

  export let canvasComponent; // Receive the canvasComponent as a prop
  export let selectedObject = null;
  export let selectedObjectType = null;
  export let evidenceData = null;

  let showHeadlines = false;

  // Subscribe to settings store
  settingsStore.subscribe(settings => {
    showHeadlines = settings.developerFeatures.showHeadlines;
  });

  // Access the context for the selected item
  const selectedItem = getContext('selectedItem');

  // When initializing, set charts ('evidence') as default
  selectedItem.set('evidence'); // Set default selection to evidence/charts

  const dispatch = createEventDispatcher();

  // Function to change the selected menu option
  function selectMenu(option) {
    // If switching away from draw menu, reset canvas to select mode
    if ($selectedItem === 'draw' && option !== 'draw' && canvasComponent) {
      const canvas = canvasComponent.getCanvas();
      canvas.isDrawingMode = false; // Disable drawing mode
    }
    
    selectedItem.set(option);
    console.log(`Menu option selected is: ${option}`);
  }

  function handleAddImageToCanvas(event) {
    dispatch('addImageToCanvas', event.detail);
  }
</script>

<style>
  .container {
    display: flex;
    height: 100%;
    overflow: hidden;
    width: 100%; /* Fill parent container */
  }

  .left-menu {
    width: 85px;
    min-width: 85px; /* Prevent shrinking */
    background-color: #ffffff;
    height: 100%;
    border-right: 1px solid #f0f0f0;
    flex: 0 0 auto; /* Don't grow or shrink */
  }

  .menu-item {
    padding: 16px 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    color: #666;
    transition: all 0.2s ease;
    gap: 6px;
  }

  

  .menu-item:hover {
    background-color: #f8f9fa;
    color: #333;
  }

  .menu-icon {
    font-size: 16px;
    opacity: 0.8;
  }

  .details-panel {
    width: 260px;
    min-width: 260px; /* Prevent shrinking */
    background-color: #ffffff;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 0 0 auto; /* Don't grow or shrink */
  }

  /* Modern selected state */
  .selected {
    background-color: #f8f9fa;
    color: #007AFF; /* Modern blue */
    position: relative;
  }

  .selected::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #007AFF;
  }

  .selected .menu-icon {
    opacity: 1;
  }
</style>

<div class="container">
  <div class="left-menu">
    <div class="menu-item { $selectedItem === 'evidence' ? 'selected' : '' }" on:click={() => selectMenu('evidence')}>
      <i class="fas fa-chart-bar menu-icon"></i> Charts
    </div>
    {#if showHeadlines}
      <div class="menu-item { $selectedItem === 'headlines' ? 'selected' : '' }" on:click={() => selectMenu('headlines')}>
        <i class="bi bi-newspaper menu-icon"></i> Headlines
      </div>
    {/if}
    <div class="menu-item { $selectedItem === 'text' ? 'selected' : '' }" on:click={() => selectMenu('text')}>
      <i class="fas fa-font menu-icon"></i> Text
    </div>
    <div class="menu-item { $selectedItem === 'images' ? 'selected' : '' }" on:click={() => selectMenu('images')}>
      <i class="fas fa-image menu-icon"></i> Images
    </div>
    <div class="menu-item { $selectedItem === 'shapes' ? 'selected' : '' }" on:click={() => selectMenu('shapes')}>
      <i class="fas fa-shapes menu-icon"></i> Shapes
    </div>
    <div class="menu-item { $selectedItem === 'draw' ? 'selected' : '' }" on:click={() => selectMenu('draw')}>
      <i class="bi bi-pencil menu-icon"></i> Draw
    </div>
    <div class="menu-item { $selectedItem === 'agent-slide' ? 'selected' : '' }" on:click={() => selectMenu('agent-slide')}>
      <i class="fas fa-robot menu-icon"></i> Agent
    </div>
  </div>
  <div class="details-panel">
    {#if $selectedItem === 'evidence'}
      <StoryMenuEvidence on:addImageToCanvas={handleAddImageToCanvas} />
    {:else if $selectedItem === 'headlines'}
      <StoryMenuHeadlines {canvasComponent} />
    {:else if $selectedItem === 'text'}
      <StoryMenuText {canvasComponent} />
    {:else if $selectedItem === 'images'}
      <StoryMenuImages 
        {canvasComponent}
        {selectedObject}
        {evidenceData}
        on:showAnalysis
        on:addImageToCanvas
      />
    {:else if $selectedItem === 'shapes'}
      <StoryMenuShapes {canvasComponent} {selectedObject} />
    {:else if $selectedItem === 'draw'}
      <StoryMenuDraw {canvasComponent} />
    {:else if $selectedItem === 'agent-slide'}
      <AgentSlide />
    {/if}
  </div>
</div>