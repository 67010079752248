<script>
  import { navigate } from "svelte-routing";
  
  // Breadcrumb properties
  export let current = ""; // Current page name
  export let parent = ""; // Optional parent page
  export let parentPath = ""; // Path to parent page
  
  function goHome() {
    navigate("/");
  }
  
  function goToParent() {
    if (parentPath) {
      navigate(parentPath);
    }
  }
</script>

<div class="admin-breadcrumb">
  <div class="breadcrumb-container">
    <div class="breadcrumb-item home" on:click={goHome}>
      <i class="bi bi-house-door"></i>
      <span>Home</span>
    </div>
    
    <div class="separator">
      <i class="bi bi-chevron-right"></i>
    </div>
    
    <div class="breadcrumb-item">
      <i class="bi bi-shield-lock"></i>
      <span>Admin Console</span>
    </div>
    
    {#if parent}
      <div class="separator">
        <i class="bi bi-chevron-right"></i>
      </div>
      
      <div class="breadcrumb-item parent" on:click={goToParent}>
        <span>{parent}</span>
      </div>
    {/if}
    
    {#if current}
      <div class="separator">
        <i class="bi bi-chevron-right"></i>
      </div>
      
      <div class="breadcrumb-item current">
        <span>{current}</span>
      </div>
    {/if}
  </div>
</div>

<style>
  .admin-breadcrumb {
    background-color: #f8fafc;
    padding: 0.75rem 1.5rem;
    border-bottom: 1px solid #e2e8f0;
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }
  
  .breadcrumb-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .breadcrumb-item {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    color: #64748b;
  }
  
  .breadcrumb-item i {
    font-size: 0.75rem;
  }
  
  .breadcrumb-item.home,
  .breadcrumb-item.parent {
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .breadcrumb-item.home:hover,
  .breadcrumb-item.parent:hover {
    color: #2563eb;
  }
  
  .breadcrumb-item.current {
    color: #0f172a;
    font-weight: 500;
  }
  
  .separator {
    margin: 0 0.5rem;
    color: #cbd5e1;
    display: flex;
    align-items: center;
  }
  
  /* Responsive adjustments */
  @media (max-width: 640px) {
    .admin-breadcrumb {
      padding: 0.5rem 1rem;
    }
    
    .breadcrumb-item span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
    }
  }
</style> 