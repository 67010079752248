<script>
  import { onMount } from 'svelte';
  import { fade, slide } from 'svelte/transition';
  import { apiService } from '../../services/apiService';

  // Tab management
  let activeTab = 'embeddings'; // 'embeddings' or 'generate'
  
  // Embedding generation state
  let embeddingsLoading = false;
  let onlyListNoCreate = false;
  let promptsWithoutEmbeddings = [];
  let totalPrompts = 0;
  let processedPrompts = 0;
  let processingError = null;
  let successMessage = null;
  
  // Prompt generation state
  let articleUrl = '';
  let promptGenerationLoading = false;
  let promptsToGenerate = 1;
  let generatedPrompts = [];
  let showGeneratedPrompts = false;
  let generationError = null;
  
  // Current prompt for review/edit
  let currentPrompt = null;
  let editingPrompt = false;
  
  // Add toggle for prompts with keywords too
  let includePromptsWithKeywords = false;
  let promptsWithKeywords = [];
  let allPrompts = [];
  
  onMount(async () => {
    await checkPromptsWithoutEmbeddings();
  });
  
  // Function to check for prompts without embeddings
  async function checkPromptsWithoutEmbeddings() {
    try {
      embeddingsLoading = true;
      processingError = null;
      successMessage = null;
      
      const response = await fetch('/api/admin/prompts/check-embeddings');
      const data = await response.json();
      
      if (data.success) {
        promptsWithoutEmbeddings = data.promptsWithoutEmbeddings || [];
        totalPrompts = data.totalPrompts || 0;
        
        // Get a list of all prompts by ID to use for regeneration
        const allPromptIds = data.allPromptIds || [];
        allPrompts = allPromptIds.map(id => ({ _id: id }));
        
        // Calculate prompts with keywords as the difference
        promptsWithKeywords = allPrompts.length - promptsWithoutEmbeddings.length;
      } else {
        throw new Error(data.message || 'Failed to check prompts without embeddings');
      }
    } catch (error) {
      console.error('Error checking prompts without embeddings:', error);
      processingError = error.message;
    } finally {
      embeddingsLoading = false;
    }
  }
  
  // Function to generate embeddings for prompts
  async function generateEmbeddings() {
    try {
      embeddingsLoading = true;
      processingError = null;
      successMessage = null;
      processedPrompts = 0;
      
      // If only listing, don't make the API call
      if (onlyListNoCreate) {
        return;
      }
      
      const response = await fetch('/api/admin/prompts/generate-embeddings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          promptIds: promptsWithoutEmbeddings.map(p => p._id)
        })
      });
      
      const data = await response.json();
      
      if (data.success) {
        // If successful, update the processed count and refresh the list
        processedPrompts = data.processedCount || 0;
        successMessage = `Successfully generated keywords for ${processedPrompts} prompts`;
        await checkPromptsWithoutEmbeddings();
      } else {
        throw new Error(data.message || 'Failed to generate keywords');
      }
    } catch (error) {
      console.error('Error generating keywords:', error);
      processingError = error.message;
    } finally {
      embeddingsLoading = false;
    }
  }
  
  // Function to generate prompts from an article URL
  async function generatePromptsFromArticle() {
    if (!articleUrl || !articleUrl.trim().startsWith('http')) {
      generationError = 'Please enter a valid URL';
      return;
    }
    
    try {
      promptGenerationLoading = true;
      generationError = null;
      generatedPrompts = [];
      
      const response = await fetch('/api/admin/prompts/generate-from-article', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          articleUrl,
          count: promptsToGenerate
        })
      });
      
      const data = await response.json();
      
      if (data.success) {
        generatedPrompts = data.prompts || [];
        showGeneratedPrompts = true;
      } else {
        throw new Error(data.message || 'Failed to generate prompts');
      }
    } catch (error) {
      console.error('Error generating prompts:', error);
      generationError = error.message;
    } finally {
      promptGenerationLoading = false;
    }
  }
  
  // Function to edit a generated prompt
  function editPrompt(prompt) {
    currentPrompt = { ...prompt };
    editingPrompt = true;
  }
  
  // Function to save a prompt to the database
  async function savePrompt(prompt) {
    try {
      // Create a deep copy to avoid reference issues
      const promptToSave = JSON.parse(JSON.stringify(prompt));
      
      // Ensure topics are properly formatted with both required fields
      if (!promptToSave.topics) {
        promptToSave.topics = [{ topicTag: 'general', topicLabel: 'General' }];
      } else {
        // Clean up the topics array to ensure all entries have required fields
        promptToSave.topics = promptToSave.topics
          .filter(topic => topic) // Remove null/undefined entries
          .map(topic => {
            // If it's a string, convert to proper object
            if (typeof topic === 'string') {
              const topicText = topic.trim();
              return {
                topicTag: topicText.toLowerCase().replace(/\s+/g, '-'),
                topicLabel: topicText.charAt(0).toUpperCase() + topicText.slice(1)
              };
            }
            
            // If it's an object, ensure it has both required fields
            if (topic && typeof topic === 'object') {
              const result = {
                topicTag: 'general',
                topicLabel: 'General'
              };
              
              if (topic.topicTag && typeof topic.topicTag === 'string' && topic.topicTag.trim()) {
                result.topicTag = topic.topicTag.toLowerCase().replace(/\s+/g, '-').trim();
              } else if (topic.topicLabel && typeof topic.topicLabel === 'string' && topic.topicLabel.trim()) {
                result.topicTag = topic.topicLabel.toLowerCase().replace(/\s+/g, '-').trim();
              }
              
              if (topic.topicLabel && typeof topic.topicLabel === 'string' && topic.topicLabel.trim()) {
                result.topicLabel = topic.topicLabel.trim().charAt(0).toUpperCase() + 
                  topic.topicLabel.trim().slice(1);
              } else if (topic.topicTag && typeof topic.topicTag === 'string' && topic.topicTag.trim()) {
                result.topicLabel = topic.topicTag.trim().charAt(0).toUpperCase() + 
                  topic.topicTag.trim().slice(1).replace(/-/g, ' ');
              }
              
              return result;
            }
            
            // Default fallback for invalid entries
            return {
              topicTag: 'general',
              topicLabel: 'General'
            };
          });
      }
      
      // If no valid topics were found after processing, add a default
      if (!promptToSave.topics.length) {
        promptToSave.topics = [{
          topicTag: 'general',
          topicLabel: 'General'
        }];
      }
      
      const response = await fetch('/api/admin/prompts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(promptToSave)
      });
      
      const data = await response.json();
      
      if (data.success) {
        // Remove the saved prompt from the generated list
        generatedPrompts = generatedPrompts.filter(p => p !== prompt);
        
        // If no more prompts, hide the section
        if (generatedPrompts.length === 0) {
          showGeneratedPrompts = false;
        }
        
        // Reset current prompt
        currentPrompt = null;
        editingPrompt = false;
        
        return true;
      } else {
        throw new Error(data.message || 'Failed to save prompt');
      }
    } catch (error) {
      console.error('Error saving prompt:', error);
      generationError = error.message;
      return false;
    }
  }
  
  // Function to cancel editing
  function cancelEdit() {
    currentPrompt = null;
    editingPrompt = false;
  }
  
  // Function to change tab
  function setActiveTab(tab) {
    activeTab = tab;
    
    // Reset states when changing tabs
    if (tab === 'embeddings') {
      checkPromptsWithoutEmbeddings();
    } else {
      // Reset prompt generation state
      articleUrl = '';
      generatedPrompts = [];
      showGeneratedPrompts = false;
      generationError = null;
    }
  }
  
  // Function to regenerate only keywords for prompts (faster than full embeddings)
  async function regenerateKeywords() {
    try {
      embeddingsLoading = true;
      processingError = null;
      successMessage = null;
      processedPrompts = 0;
      
      // Determine which prompts to process
      const promptsToProcess = includePromptsWithKeywords 
        ? allPrompts 
        : promptsWithoutEmbeddings;
      
      if (promptsToProcess.length === 0) {
        successMessage = "No prompts to process";
        embeddingsLoading = false;
        return;
      }
      
      const response = await fetch('/api/admin/prompts/regenerate-keywords', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          promptIds: promptsToProcess.map(p => p._id)
        })
      });
      
      const data = await response.json();
      
      if (data.success) {
        // If successful, update the processed count and refresh the list
        processedPrompts = data.processedCount || 0;
        successMessage = `Successfully regenerated keywords for ${processedPrompts} prompts`;
        await checkPromptsWithoutEmbeddings();
      } else {
        throw new Error(data.message || 'Failed to regenerate keywords');
      }
    } catch (error) {
      console.error('Error regenerating keywords:', error);
      processingError = error.message;
    } finally {
      embeddingsLoading = false;
    }
  }
</script>

<div class="admin-prompt-manager">
  <div class="section-header">
    <h2><i class="bi bi-chat-text"></i> Prompt Manager</h2>
    <p>Create embeddings for existing prompts and generate new prompts from articles</p>
  </div>
  
  <div class="tabs">
    <button 
      class="tab-button {activeTab === 'embeddings' ? 'active' : ''}" 
      on:click={() => setActiveTab('embeddings')}
    >
      <i class="bi bi-diagram-3"></i> Prompt Embeddings
    </button>
    <button 
      class="tab-button {activeTab === 'generate' ? 'active' : ''}" 
      on:click={() => setActiveTab('generate')}
    >
      <i class="bi bi-magic"></i> Generate Prompts
    </button>
  </div>
  
  <div class="content-container">
    {#if activeTab === 'embeddings'}
      <div class="content-section" in:fade={{ duration: 200 }}>
        <div class="section-intro">
          <h3>Create Missing Prompt Embeddings</h3>
          <p>
            Keywords help our Smart Search understand what your prompts are about.
            This tool helps you find and process prompts that don't have Smart Search data yet.
          </p>
        </div>
        
        <div class="options-bar">
          <label class="checkbox-container">
            <input type="checkbox" bind:checked={onlyListNoCreate} />
            <span class="checkbox-label">Only list prompts without keywords/embeddings (don't create them)</span>
          </label>
          
          <label class="checkbox-container">
            <input type="checkbox" bind:checked={includePromptsWithKeywords} />
            <span class="checkbox-label">Include prompts that already have keywords when regenerating</span>
          </label>
          
          <button 
            class="action-button primary" 
            on:click={checkPromptsWithoutEmbeddings}
            disabled={embeddingsLoading}
          >
            <i class="bi bi-search"></i> Check Prompts
          </button>
          
          <button 
            class="action-button" 
            on:click={generateEmbeddings}
            disabled={embeddingsLoading || onlyListNoCreate || promptsWithoutEmbeddings.length === 0}
          >
            <i class="bi bi-gear{embeddingsLoading ? '-fill spin' : ''}"></i>
            {embeddingsLoading ? 'Processing...' : 'Generate Keywords & Embeddings'}
          </button>
          
          <button 
            class="action-button" 
            on:click={regenerateKeywords}
            disabled={embeddingsLoading || onlyListNoCreate || (promptsWithoutEmbeddings.length === 0 && !includePromptsWithKeywords)}
          >
            <i class="bi bi-lightning{embeddingsLoading ? '-fill spin' : ''}"></i>
            {embeddingsLoading ? 'Processing...' : 'Regenerate Keywords Only (Fast)'}
          </button>
        </div>
        
        {#if processingError}
          <div class="error-message">
            <i class="bi bi-exclamation-triangle"></i> {processingError}
          </div>
        {/if}
        
        {#if successMessage}
          <div class="success-message">
            <i class="bi bi-check-circle"></i> {successMessage}
          </div>
        {/if}
        
        {#if promptsWithoutEmbeddings.length > 0}
          <div class="results-summary">
            <span class="results-count">
              Found {promptsWithoutEmbeddings.length} prompts without keywords
              (out of {totalPrompts} total prompts)
            </span>
          </div>
          
          <div class="prompts-list">
            {#each promptsWithoutEmbeddings as prompt, index}
              <div class="prompt-item" in:slide={{ delay: index * 50, duration: 300 }}>
                <div class="prompt-details">
                  <h4>{prompt.bigQuestion || 'Untitled Prompt'}</h4>
                  <div class="prompt-meta">
                    <span class="prompt-id">ID: {prompt._id}</span>
                    {#if prompt.topics && prompt.topics.length > 0}
                      <span class="prompt-topic">
                        {prompt.topics.map(t => typeof t === 'string' ? t : t.topicLabel).join(', ')}
                      </span>
                    {/if}
                  </div>
                  <p class="prompt-script">{prompt.promptScript?.substring(0, 150)}...</p>
                  
                  {#if prompt.keywords && prompt.keywords.length > 0}
                    <div class="keywords-container">
                      <span class="keywords-label">Keywords:</span>
                      <div class="keyword-tags">
                        {#each prompt.keywords as keyword}
                          <span class="keyword-tag">{keyword}</span>
                        {/each}
                      </div>
                    </div>
                  {/if}
                </div>
              </div>
            {/each}
          </div>
          
          {#if !onlyListNoCreate}
            <div class="action-row">
              <button 
                class="action-button primary" 
                on:click={generateEmbeddings}
                disabled={embeddingsLoading}
              >
                <i class="bi bi-gear{embeddingsLoading ? '-fill spin' : ''}"></i>
                {embeddingsLoading ? 'Processing...' : 'Generate Keywords'}
              </button>
            </div>
          {/if}
        {:else if !embeddingsLoading}
          <div class="empty-state">
            <i class="bi bi-check-circle"></i>
            <p>All prompts have embeddings!</p>
          </div>
        {/if}
        
        {#if embeddingsLoading}
          <div class="loading-state">
            <i class="bi bi-arrow-repeat spin"></i>
            {#if processedPrompts > 0}
              <p>Processing embeddings: {processedPrompts} / {promptsWithoutEmbeddings.length}</p>
            {:else}
              <p>Checking prompts...</p>
            {/if}
          </div>
        {/if}
      </div>
    {:else if activeTab === 'generate'}
      <div class="content-section" in:fade={{ duration: 200 }}>
        <div class="section-intro">
          <h3>Generate Prompts from Articles</h3>
          <p>
            Create new prompts automatically from articles with data visualizations.
            Enter a URL to analyze and our AI will generate prompt suggestions.
          </p>
        </div>
        
        <div class="url-input-container">
          <div class="input-group">
            <label for="articleUrl">Article URL</label>
            <input 
              type="url" 
              id="articleUrl" 
              bind:value={articleUrl} 
              placeholder="https://example.com/article-with-data-viz"
              disabled={promptGenerationLoading}
            />
          </div>
          
          <div class="input-group">
            <label for="promptCount">Number of Prompts to Generate</label>
            <select 
              id="promptCount" 
              bind:value={promptsToGenerate} 
              disabled={promptGenerationLoading}
            >
              <option value={1}>1 Prompt</option>
              <option value={2}>2 Prompts</option>
              <option value={3}>3 Prompts</option>
              <option value={4}>4 Prompts</option>
              <option value={5}>5 Prompts</option>
            </select>
          </div>
          
          <button 
            class="action-button primary" 
            on:click={generatePromptsFromArticle}
            disabled={promptGenerationLoading || !articleUrl}
          >
            <i class="bi bi-magic{promptGenerationLoading ? ' spin' : ''}"></i>
            {promptGenerationLoading ? 'Generating...' : 'Generate Prompts'}
          </button>
        </div>
        
        {#if generationError}
          <div class="error-message">
            <i class="bi bi-exclamation-triangle"></i> {generationError}
          </div>
        {/if}
        
        {#if showGeneratedPrompts && generatedPrompts.length > 0}
          <div class="results-summary">
            <span class="results-count">
              Generated {generatedPrompts.length} prompt{generatedPrompts.length === 1 ? '' : 's'}
            </span>
          </div>
          
          <div class="generated-prompts">
            {#each generatedPrompts as prompt, index}
              <div class="generated-prompt-item" in:slide={{ delay: index * 100, duration: 300 }}>
                <div class="prompt-details">
                  <h4>{prompt.bigQuestion || 'Untitled Prompt'}</h4>
                  
                  {#if prompt.topics && prompt.topics.length > 0}
                    <div class="prompt-topics">
                      {#each prompt.topics as topic}
                        <span class="topic-tag">
                          {typeof topic === 'string' ? topic : topic.topicLabel}
                        </span>
                      {/each}
                    </div>
                  {/if}
                  
                  <div class="prompt-content">
                    <p>{prompt.promptScript}</p>
                  </div>
                  
                  <div class="prompt-actions">
                    <button 
                      class="action-button edit" 
                      on:click={() => editPrompt(prompt)}
                    >
                      <i class="bi bi-pencil"></i> Edit
                    </button>
                    
                    <button 
                      class="action-button primary" 
                      on:click={() => savePrompt(prompt)}
                    >
                      <i class="bi bi-plus-circle"></i> Add to Collection
                    </button>
                  </div>
                </div>
              </div>
            {/each}
          </div>
        {:else if promptGenerationLoading}
          <div class="loading-state">
            <i class="bi bi-arrow-repeat spin"></i>
            <p>Analyzing article and generating prompts...</p>
          </div>
        {/if}
      </div>
    {/if}
  </div>
</div>

{#if editingPrompt && currentPrompt}
  <div class="modal-backdrop" on:click={cancelEdit} in:fade={{ duration: 150 }}>
    <div class="edit-modal" on:click|stopPropagation>
      <div class="modal-header">
        <h3>Edit Prompt</h3>
        <button class="close-button" on:click={cancelEdit}>
          <i class="bi bi-x-lg"></i>
        </button>
      </div>
      
      <div class="modal-body">
        <div class="form-group">
          <label for="bigQuestion">Big Question</label>
          <input 
            type="text" 
            id="bigQuestion" 
            bind:value={currentPrompt.bigQuestion}
            placeholder="The main question users will explore"
          />
        </div>
        
        <div class="form-group">
          <label for="promptScript">Prompt Script</label>
          <textarea 
            id="promptScript" 
            bind:value={currentPrompt.promptScript}
            placeholder="Detailed script for this prompt"
            rows="5"
          ></textarea>
        </div>
        
        <div class="form-group">
          <label>Topics</label>
          {#if currentPrompt.topics && currentPrompt.topics.length > 0}
            <div class="topic-tags">
              {#each currentPrompt.topics as topic, i}
                <div class="topic-tag-input">
                  <input 
                    type="text" 
                    value={typeof topic === 'string' ? topic : (topic?.topicLabel || 'General')}
                    on:input={(e) => {
                      const value = e.target.value.trim();
                      if (value) {
                        // Always ensure we're working with a proper topic object
                        if (typeof currentPrompt.topics[i] === 'string' || !currentPrompt.topics[i]) {
                          currentPrompt.topics[i] = {
                            topicTag: value.toLowerCase().replace(/\s+/g, '-'),
                            topicLabel: value.charAt(0).toUpperCase() + value.slice(1)
                          };
                        } else {
                          // Update existing object
                          currentPrompt.topics[i].topicLabel = value.charAt(0).toUpperCase() + value.slice(1);
                          currentPrompt.topics[i].topicTag = value.toLowerCase().replace(/\s+/g, '-');
                        }
                      } else {
                        // If empty, use default value
                        currentPrompt.topics[i] = {
                          topicTag: 'general',
                          topicLabel: 'General'
                        };
                      }
                    }}
                    placeholder="Topic"
                  />
                  <button 
                    class="remove-topic" 
                    on:click={() => currentPrompt.topics = currentPrompt.topics.filter((_, index) => index !== i)}
                  >
                    <i class="bi bi-x"></i>
                  </button>
                </div>
              {/each}
            </div>
          {/if}
          
          <button 
            class="add-topic-button" 
            on:click={() => {
              if (!currentPrompt.topics) currentPrompt.topics = [];
              currentPrompt.topics = [...currentPrompt.topics, { topicTag: 'general', topicLabel: 'General' }];
            }}
          >
            <i class="bi bi-plus"></i> Add Topic
          </button>
        </div>
      </div>
      
      <div class="modal-footer">
        <button class="action-button secondary" on:click={cancelEdit}>
          Cancel
        </button>
        <button class="action-button primary" on:click={() => savePrompt(currentPrompt)}>
          <i class="bi bi-check2"></i> Save Prompt
        </button>
      </div>
    </div>
  </div>
{/if}

<style>
  .admin-prompt-manager {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }

  .section-header {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    margin-bottom: 1rem;
  }

  .section-header h2 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.5rem;
    color: #1a1a1a;
    margin: 0;
  }

  .section-header p {
    margin: 0.5rem 0 0;
    color: #64748b;
    font-size: 0.95rem;
  }

  .tabs {
    display: flex;
    gap: 1rem;
    background: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .tab-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.25rem;
    border: 1px solid #e5e7eb;
    background: white;
    color: #64748b;
    border-radius: 6px;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .tab-button:hover {
    background: #f8fafc;
    color: #2563eb;
    border-color: #bfdbfe;
  }

  .tab-button.active {
    background: #eff6ff;
    color: #2563eb;
    border-color: #93c5fd;
  }

  .content-container {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .content-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .section-intro {
    margin-bottom: 1rem;
  }

  .section-intro h3 {
    font-size: 1.25rem;
    color: #1e293b;
    margin: 0 0 0.5rem;
  }

  .section-intro p {
    color: #64748b;
    margin: 0;
    font-size: 0.95rem;
    line-height: 1.5;
  }

  .options-bar {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: #f8fafc;
    padding: 1rem;
    border-radius: 6px;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }

  .checkbox-label {
    font-size: 0.95rem;
    color: #64748b;
  }

  .action-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.65rem 1.25rem;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    background: white;
    color: #64748b;
  }

  .action-button:hover:not(:disabled) {
    background: #f8fafc;
    color: #2563eb;
    border-color: #bfdbfe;
  }

  .action-button.primary {
    background: #2563eb;
    color: white;
    border-color: #2563eb;
  }

  .action-button.primary:hover:not(:disabled) {
    background: #1d4ed8;
  }

  .action-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .action-button.edit {
    color: #0f766e;
    border-color: #99f6e4;
    background: #ccfbf1;
  }

  .action-button.edit:hover {
    background: #99f6e4;
  }

  .spin {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .error-message {
    background: #fef2f2;
    color: #b91c1c;
    padding: 1rem;
    border-radius: 6px;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid #fee2e2;
  }

  .success-message {
    background: #f0fdf4;
    color: #166534;
    padding: 1rem;
    border-radius: 6px;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 1px solid #bbf7d0;
  }

  .results-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    background: #f0f9ff;
    border-radius: 6px;
    font-size: 0.95rem;
    color: #0369a1;
    border: 1px solid #e0f2fe;
  }

  .prompts-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .prompt-item {
    background: #f8fafc;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 1.25rem;
    transition: all 0.2s ease;
  }

  .prompt-item:hover {
    border-color: #93c5fd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }

  .prompt-details h4 {
    font-size: 1.1rem;
    margin: 0 0 0.5rem;
    color: #1e293b;
  }

  .prompt-meta {
    display: flex;
    gap: 1rem;
    font-size: 0.85rem;
    color: #64748b;
    margin-bottom: 0.75rem;
  }

  .prompt-script {
    color: #475569;
    font-size: 0.95rem;
    line-height: 1.5;
    margin: 0;
  }

  .keywords-container {
    margin-top: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .keywords-label {
    font-size: 0.85rem;
    color: #64748b;
    font-weight: 500;
  }

  .keyword-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;
  }

  .keyword-tag {
    background: #dbeafe;
    color: #2563eb;
    font-size: 0.75rem;
    padding: 0.2rem 0.5rem;
    border-radius: 3px;
    white-space: nowrap;
  }

  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    gap: 1rem;
    text-align: center;
    color: #0c4a6e;
  }

  .empty-state i {
    font-size: 2.5rem;
    color: #22c55e;
  }

  .empty-state p {
    font-size: 1.1rem;
    margin: 0;
  }

  .loading-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    gap: 1rem;
    text-align: center;
    color: #2563eb;
  }

  .loading-state i {
    font-size: 2rem;
  }

  .loading-state p {
    font-size: 1rem;
    margin: 0;
  }

  .action-row {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }

  .url-input-container {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
    min-width: 250px;
  }

  .input-group label {
    font-size: 0.95rem;
    color: #475569;
    font-weight: 500;
  }

  .input-group input,
  .input-group select {
    padding: 0.75rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 0.95rem;
    transition: all 0.2s ease;
  }

  .input-group input:focus,
  .input-group select:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }

  .generated-prompts {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .generated-prompt-item {
    background: #f8fafc;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 1.5rem;
    transition: all 0.2s ease;
  }

  .generated-prompt-item:hover {
    border-color: #93c5fd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }

  .prompt-topics {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .topic-tag {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    background: #e0f2fe;
    color: #0369a1;
    border-radius: 99px;
    font-size: 0.85rem;
    font-weight: 500;
  }

  .prompt-content {
    background: white;
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid #e5e7eb;
    margin-bottom: 1.25rem;
  }

  .prompt-content p {
    color: #475569;
    font-size: 0.95rem;
    line-height: 1.6;
    margin: 0;
  }

  .prompt-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  /* Modal styles */
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .edit-modal {
    background: white;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
  }

  .modal-header h3 {
    margin: 0;
    font-size: 1.25rem;
    color: #1e293b;
  }

  .close-button {
    background: none;
    border: none;
    color: #64748b;
    font-size: 1.1rem;
    cursor: pointer;
    transition: color 0.2s ease;
  }

  .close-button:hover {
    color: #ef4444;
  }

  .modal-body {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .modal-footer {
    padding: 1.5rem;
    border-top: 1px solid #e5e7eb;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .form-group label {
    font-size: 0.95rem;
    color: #475569;
    font-weight: 500;
  }

  .form-group input,
  .form-group textarea {
    padding: 0.75rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 0.95rem;
    transition: all 0.2s ease;
    font-family: inherit;
  }

  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }

  .topic-tags {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .topic-tag-input {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .remove-topic {
    background: #fef2f2;
    border: 1px solid #fee2e2;
    color: #b91c1c;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .remove-topic:hover {
    background: #fee2e2;
  }

  .add-topic-button {
    background: #f0fdf4;
    border: 1px solid #bbf7d0;
    color: #166534;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .add-topic-button:hover {
    background: #dcfce7;
  }

  .action-button.secondary {
    background: #f1f5f9;
    color: #475569;
    border-color: #e2e8f0;
  }

  .action-button.secondary:hover {
    background: #e2e8f0;
  }

  @media (max-width: 768px) {
    .options-bar {
      flex-direction: column;
      align-items: flex-start;
    }

    .url-input-container {
      flex-direction: column;
      align-items: stretch;
    }

    .url-input-container .action-button {
      align-self: flex-end;
    }

    .prompt-actions {
      flex-direction: column;
    }
  }
</style> 