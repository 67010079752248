<script>
  import { onMount } from 'svelte';
  import { slide } from 'svelte/transition';

  export let canvasComponent;
  export let selectedObject;

  // Shape definitions
  const shapes = [
    { name: 'Rectangle', icon: 'fa-square', type: 'rect' },
    { name: 'Circle', icon: 'fa-circle', type: 'circle' },
    { name: 'Triangle', icon: 'fa-play', type: 'triangle' },
    { name: 'Line', icon: 'fa-minus', type: 'line' }
  ];

  // Shape properties
  let shapeFillColor = '#007AFF';
  let shapeBorderColor = '#007AFF';
  let shapeBorderWidth = 2;
  let shapeOpacity = 0.6;

  // Function to create a line with proper controls
  function createLine() {
    const canvas = canvasComponent.getCanvas();
    
    const line = new fabric.Line([50, 50, 200, 50], {
      stroke: shapeBorderColor,
      strokeWidth: shapeBorderWidth,
      opacity: shapeOpacity,
      fill: shapeBorderColor,
      originX: 'center',
      originY: 'center',
      centeredRotation: true,
      selectable: true,
      hasBorders: false,
      hasControls: true,
      objectCaching: false
    });

    // Create custom controls
    line.controls = {
      ml: new fabric.Control({
        x: -0.5,
        y: 0,
        cursorStyle: 'pointer',
        positionHandler: function(dim, finalMatrix, fabricObject) {
          const x = fabricObject.x1 - fabricObject.left;
          const y = fabricObject.y1 - fabricObject.top;
          return fabric.util.transformPoint(
            new fabric.Point(x, y),
            fabric.util.multiplyTransformMatrices(
              fabricObject.canvas.viewportTransform,
              fabricObject.calcTransformMatrix()
            )
          );
        },
        actionHandler: function(eventData, transform, x, y) {
          const target = transform.target;
          const mouseLocalPosition = target.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
          const localPoint = new fabric.Point(mouseLocalPosition.x, mouseLocalPosition.y);
          
          target.set({
            x1: localPoint.x + target.left,
            y1: localPoint.y + target.top
          });
          
          return true;
        },
        render: function(ctx, left, top, styleOverride, fabricObject) {
          ctx.save();
          ctx.translate(left, top);
          ctx.beginPath();
          ctx.arc(0, 0, 6, 0, 2 * Math.PI);
          ctx.fillStyle = '#ffffff';
          ctx.strokeStyle = fabricObject.stroke;
          ctx.lineWidth = 1;
          ctx.fill();
          ctx.stroke();
          ctx.restore();
        }
      }),
      mr: new fabric.Control({
        x: 0.5,
        y: 0,
        cursorStyle: 'pointer',
        positionHandler: function(dim, finalMatrix, fabricObject) {
          const x = fabricObject.x2 - fabricObject.left;
          const y = fabricObject.y2 - fabricObject.top;
          return fabric.util.transformPoint(
            new fabric.Point(x, y),
            fabric.util.multiplyTransformMatrices(
              fabricObject.canvas.viewportTransform,
              fabricObject.calcTransformMatrix()
            )
          );
        },
        actionHandler: function(eventData, transform, x, y) {
          const target = transform.target;
          const mouseLocalPosition = target.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
          const localPoint = new fabric.Point(mouseLocalPosition.x, mouseLocalPosition.y);
          
          target.set({
            x2: localPoint.x + target.left,
            y2: localPoint.y + target.top
          });
          
          return true;
        },
        render: function(ctx, left, top, styleOverride, fabricObject) {
          ctx.save();
          ctx.translate(left, top);
          ctx.beginPath();
          ctx.arc(0, 0, 6, 0, 2 * Math.PI);
          ctx.fillStyle = '#ffffff';
          ctx.strokeStyle = fabricObject.stroke;
          ctx.lineWidth = 1;
          ctx.fill();
          ctx.stroke();
          ctx.restore();
        }
      })
    };

    // Hide all other controls
    line.setControlsVisibility({
      tl: false,
      tr: false,
      bl: false,
      br: false,
      mtr: false,
      mt: false,
      mb: false,
      ml: true,
      mr: true
    });

    return line;
  }

  // Function to add a new shape to the canvas
  function addShape(shapeType) {
    const canvas = canvasComponent.getCanvas();
    let shape;

    // Common properties for all shapes
    const shapeProps = {
      fill: shapeFillColor,
      stroke: shapeBorderColor,
      strokeWidth: shapeBorderWidth,
      opacity: shapeOpacity,
      left: 100,
      top: 100
    };

    if (shapeType === 'line') {
      shape = createLine();
    } else {
      switch (shapeType) {
        case 'rect':
          shape = new fabric.Rect({
            ...shapeProps,
            width: 100,
            height: 100
          });
          break;
        case 'circle':
          shape = new fabric.Circle({
            ...shapeProps,
            radius: 50
          });
          break;
        case 'triangle':
          shape = new fabric.Triangle({
            ...shapeProps,
            width: 100,
            height: 100
          });
          break;
      }
    }

    if (shape) {
      canvas.add(shape);
      canvas.setActiveObject(shape);
      canvas.requestRenderAll();
    }
  }

  // Handle shape selection
  function handleShapeSelection(obj) {
    if (!obj) return;

    if (obj.type === 'line') {
      shapeBorderColor = obj.stroke || '#007AFF';
      shapeBorderWidth = obj.strokeWidth || 2;
      shapeOpacity = obj.opacity || 0.6;
    } else if (obj.type === 'rect' || obj.type === 'circle' || obj.type === 'triangle') {
      shapeFillColor = obj.fill || '#007AFF';
      shapeBorderColor = obj.stroke || '#007AFF';
      shapeBorderWidth = obj.strokeWidth || 2;
      shapeOpacity = obj.opacity || 0.6;
    }
  }

  // Initialize canvas events
  onMount(() => {
    const canvas = canvasComponent.getCanvas();
    if (canvas) {
      canvas.on('selection:created', (e) => {
        if (e.selected && e.selected.length > 0) {
          handleShapeSelection(e.selected[0]);
        }
      });
      canvas.on('selection:updated', (e) => {
        if (e.selected && e.selected.length > 0) {
          handleShapeSelection(e.selected[0]);
        }
      });
      canvas.on('selection:cleared', () => {
        handleShapeSelection(null);
      });
    }
  });
</script>

<div class="shapes-menu">
  <div class="shapes-grid">
    {#each shapes as shape}
      <button 
        class="shape-button" 
        on:click={() => addShape(shape.type)}
        title={shape.name}
      >
        <i class="fas {shape.icon}"></i>
        <span class="shape-name">{shape.name}</span>
      </button>
    {/each}
  </div>
</div>

<style>
  .shapes-menu {
    height: 100%;
    padding: 20px;
    background: #f8f9fa;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .shapes-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  .shape-button {
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .shape-button:hover {
    background: #f0f7ff;
    border-color: #007AFF;
  }

  .shape-button i {
    font-size: 24px;
    color: #333;
  }

  .shape-name {
    font-size: 13px;
    color: #64748b;
  }
</style> 