import { writable } from 'svelte/store';
import { AI_CONFIG } from '../../backend/config/aiConfig';

// Create separate stores for each agent type
const createAgentInstructionsStore = (agentType, storageKey) => {
  const getDefaultInstructions = () => {
    return agentType === 'chart' 
      ? AI_CONFIG.agentInstructions.chartAnalysis
      : AI_CONFIG.agentInstructions.slideAssistant;
  };

  const loadSavedInstructions = () => {
    try {
      const saved = localStorage.getItem(storageKey);
      return saved || getDefaultInstructions();
    } catch (error) {
      return getDefaultInstructions();
    }
  };

  const { subscribe, set, update } = writable(loadSavedInstructions());

  return {
    subscribe,
    set: (value) => {
      try {
        localStorage.setItem(storageKey, value);
        set(value);
      } catch (error) {
        set(getDefaultInstructions());
      }
    },
    reset: () => {
      try {
        const defaultInstructions = getDefaultInstructions();
        localStorage.setItem(storageKey, defaultInstructions);
        set(defaultInstructions);
      } catch (error) {
        set(getDefaultInstructions());
      }
    }
  };
};

// Create separate stores for each agent
export const chartAgentInstructions = createAgentInstructionsStore('chart', 'chartAgentInstructions');
export const slideAgentInstructions = createAgentInstructionsStore('slide', 'slideAgentInstructions');
export const storyAgentInstructions = createAgentInstructionsStore('story', 'storyAgentInstructions'); 