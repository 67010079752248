import { writable } from 'svelte/store';

// Define agent guidance levels
export const AGENT_GUIDANCE_LEVELS = {
    MINIMAL: 'minimal',
    DEFAULT: 'default',
    MAXIMUM: 'maximum'
};

// Define default settings
const defaultSettings = {
    agentGuidance: AGENT_GUIDANCE_LEVELS.DEFAULT,
    developerFeatures: {
        showHeadlines: false
    }
};

// Create the settings store
export const settingsStore = writable(defaultSettings);

// Helper functions to update settings
export function updateAgentGuidance(level) {
    settingsStore.update(settings => ({
        ...settings,
        agentGuidance: level
    }));
}

export function toggleHeadlinesFeature(show) {
    settingsStore.update(settings => ({
        ...settings,
        developerFeatures: {
            ...settings.developerFeatures,
            showHeadlines: show
        }
    }));
} 