<script>
    import { getContext, onMount } from 'svelte';
    import { createEventDispatcher, onDestroy } from 'svelte';
    import { fade } from 'svelte/transition';
    import { 
        headlinesStore, 
        initializeHeadlines, 
        filterHeadlines,
        saveHeadline, 
        removeSavedHeadline 
    } from '../stores/headlinesStore';
    import { promptStore } from '../stores/promptStore';
    import HeadlineModal from './HeadlineModal.svelte';
    
    export let canvasComponent;
    const dispatch = createEventDispatcher();
    
    const selectedSlideIndex = getContext('selectedSlideIndex');
    let searchQuery = "";
    let isLoading = false;
    let headlines = [];
    let filteredHeadlines = [];
    let savedHeadlines = [];
    let selectedTab = 'all';
    let error = null;
    let message = null;
    let suggestedTerms = null;
    let currentTopic = null;
    let isInitialized = false;
    
    let showModal = false;
    let selectedHeadline = null;

    // Subscribe to promptStore to watch for topic changes
    const unsubscribePrompt = promptStore.subscribe(store => {
        const newTopic = store.selectedPromptDetails?.topic;
        if (newTopic && newTopic !== currentTopic && isInitialized) {
            currentTopic = newTopic;
            initializeHeadlines();
            searchQuery = '';
        }
        if (newTopic && !isInitialized) {
            currentTopic = newTopic;
        }
    });

    // Subscribe to the headlines store
    const unsubscribe = headlinesStore.subscribe(store => {
        headlines = store.headlines || [];
        filteredHeadlines = store.filteredHeadlines || [];
        savedHeadlines = store.savedHeadlines || [];
        isLoading = store.isLoading;
        error = store.error;
        message = store.message;
        suggestedTerms = store.suggestedTerms;
    });

    // Initialize headlines when component mounts
    onMount(async () => {
        if (currentTopic && !isInitialized) {
            await initializeHeadlines();
            isInitialized = true;
        }
    });

    // Cleanup subscriptions
    onDestroy(() => {
        unsubscribe();
        unsubscribePrompt();
    });

    // Handle search input for filtering
    function handleSearch() {
        filterHeadlines(searchQuery);
    }

    function handleAddHeadline(headline) {
        if (!canvasComponent) return;
        
        const canvas = canvasComponent.getCanvas();
        if (canvas) {
            const { fabric } = window;
            if (!fabric) {
                console.error('Fabric.js not loaded');
                return;
            }
            
            // Calculate max width (80% of canvas width)
            const maxWidth = canvas.width * 0.8;
            
            // Create a group with title and description
            const title = new fabric.Text(headline.title, {
                fontSize: 14,
                fontWeight: 'bold',
                width: maxWidth,
                lineHeight: 1.2,
                top: 0,
                left: 0,
                textWrapping: 'word',
                splitByGrapheme: false
            });

            const description = new fabric.Text(headline.description || '', {
                fontSize: 12,
                width: maxWidth,
                lineHeight: 1.3,
                top: title.height + 10,
                left: 0,
                textWrapping: 'word',
                splitByGrapheme: false
            });

            // Center the group horizontally
            const group = new fabric.Group([title, description], {
                left: (canvas.width - maxWidth) / 2,
                top: 50
            });

            // Make the group selectable and movable
            group.setControlsVisibility({
                mt: true,
                mb: true,
                ml: true,
                mr: true,
                bl: true,
                br: true,
                tl: true,
                tr: true,
                mtr: true,
            });

            canvas.add(group);
            canvas.renderAll();
        }
    }

    function handleAnalyzeClick(headline, event) {
        event.stopPropagation();
        selectedHeadline = headline;
        showModal = true;
    }

    function handleSaveHeadline(headline, event) {
        event.stopPropagation();
        const isSaved = savedHeadlines.some(h => h.url === headline.url);
        
        if (isSaved) {
            removeSavedHeadline(headline.url);
        } else {
            saveHeadline(headline);
        }
    }

    function closeModal() {
        showModal = false;
        selectedHeadline = null;
    }
</script>

<div class="headlines-container">
    <div class="fixed-header">
        {#if currentTopic}
            <div class="current-topic">
                Current Topic: {currentTopic}
            </div>
        {/if}
        <div class="tabs">
            <button 
                class="tab-button {selectedTab === 'all' ? 'active' : ''}" 
                on:click={() => selectedTab = 'all'}
            >
                All
            </button>
            <button 
                class="tab-button {selectedTab === 'saved' ? 'active' : ''}" 
                on:click={() => selectedTab = 'saved'}
            >
                Saved
            </button>
        </div>

        {#if selectedTab === 'all'}
            <div class="search-bar">
                <div class="search-input-group">
                    <input
                        type="text"
                        placeholder="Filter current results..."
                        bind:value={searchQuery}
                        on:input={handleSearch}
                        disabled={isLoading}
                    />
                </div>
                {#if filteredHeadlines.length !== headlines.length}
                    <div class="filter-info">
                        Showing {filteredHeadlines.length} of {headlines.length} headlines
                    </div>
                {/if}
            </div>
        {/if}
    </div>

    <div class="headlines-list">
        {#if isLoading}
            <div class="loading-container">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        {:else}
            {#if selectedTab === 'all'}
                {#if headlines.length === 0}
                    <div class="empty-state">
                        {#if error}
                            <p class="error-message">{error}</p>
                        {:else if message}
                            <div class="no-results">
                                <p>{message}</p>
                                {#if suggestedTerms}
                                    <p class="suggested-terms">
                                        Try searching for: 
                                        {#each suggestedTerms as term}
                                            <span class="term" 
                                                on:click={() => handleSearch(term)}>
                                                {term}
                                            </span>
                                        {/each}
                                    </p>
                                {/if}
                            </div>
                        {:else}
                            <p>Loading headlines related to the big question...</p>
                        {/if}
                    </div>
                {:else}
                    {#each filteredHeadlines as headline}
                        <div class="headline-item" on:click={() => handleAddHeadline(headline)}>
                            {#if headline.urlToImage}
                                <div class="image-container">
                                    <img src={headline.urlToImage} alt={headline.title} />
                                </div>
                            {/if}
                            <div class="content-wrapper">
                                <h3>{headline.title}</h3>
                                <p class="description">{headline.description}</p>
                                <p class="source">{headline.source}</p>
                                <p class="date">{new Date(headline.date).toLocaleDateString()}</p>
                                <div class="action-buttons">
                                    <button 
                                        class="action-button save-button {savedHeadlines.some(h => h.url === headline.url) ? 'saved' : ''}" 
                                        on:click={(e) => handleSaveHeadline(headline, e)}
                                    >
                                        <i class="bi bi-bookmark{savedHeadlines.some(h => h.url === headline.url) ? '-fill' : ''}"></i>
                                    </button>
                                    <button 
                                        class="action-button analyze-button" 
                                        on:click={(e) => handleAnalyzeClick(headline, e)}
                                    >
                                        <i class="bi bi-eye"></i>
                                        <span>View</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    {/each}
                {/if}
            {:else}
                {#if savedHeadlines.length === 0}
                    <div class="empty-state">
                        <p>No saved headlines. Click the bookmark icon <i class="bi bi-bookmark"></i> to save headlines.</p>
                    </div>
                {:else}
                    {#each savedHeadlines as headline}
                        <div class="headline-item" on:click={() => handleAddHeadline(headline)}>
                            {#if headline.urlToImage}
                                <div class="image-container">
                                    <img src={headline.urlToImage} alt={headline.title} />
                                </div>
                            {/if}
                            <div class="content-wrapper">
                                <h3>{headline.title}</h3>
                                <p class="description">{headline.description}</p>
                                <p class="source">{headline.source}</p>
                                <p class="date">{new Date(headline.date).toLocaleDateString()}</p>
                                <div class="action-buttons">
                                    <button 
                                        class="action-button save-button saved" 
                                        on:click={(e) => handleSaveHeadline(headline, e)}
                                    >
                                        <i class="bi bi-bookmark-fill"></i>
                                    </button>
                                    <button 
                                        class="action-button analyze-button" 
                                        on:click={(e) => handleAnalyzeClick(headline, e)}
                                    >
                                        <i class="bi bi-eye"></i>
                                        <span>View</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    {/each}
                {/if}
            {/if}
        {/if}
    </div>
</div>

{#if showModal && selectedHeadline}
    <div class="modal-backdrop" transition:fade on:click={closeModal}>
        <div class="modal-container" on:click|stopPropagation>
            <HeadlineModal 
                headline={selectedHeadline}
                isOpen={showModal}
                closeModal={closeModal}
            />
        </div>
    </div>
{/if}

<style>
    .headlines-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    }

    .search-bar {
        padding: 16px;
        border-bottom: 1px solid #f0f0f0;
    }

    .search-bar input {
        width: 100%;
        padding: 8px 12px;
        border: 1px solid #ddd;
        border-radius: 6px;
        font-size: 14px;
        flex: 1;
    }

    .headlines-list {
        flex: 1;
        overflow-y: auto;
        padding: 16px;
    }

    .headline-item {
        background: white;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 12px;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .headline-item:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    }

    .headline-content h3 {
        font-size: 14px;
        margin: 0 0 8px 0;
        color: #333;
        line-height: 1.4;
    }

    .source {
        font-size: 12px;
        color: #007bff;
        margin: 0;
        font-weight: 500;
    }

    .date {
        font-size: 12px;
        color: #999;
        margin: 4px 0 0 0;
    }

    .action-buttons {
        margin-top: 12px;
        display: flex;
        justify-content: flex-end;
    }

    .action-button {
        display: inline-flex;
        align-items: center;
        gap: 6px;
        padding: 6px 12px;
        border: 1px solid #e6e6e6;
        border-radius: 6px;
        background: white;
        color: #666;
        font-size: 13px;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .action-button:hover {
        background: #f8f9fa;
        color: #333;
    }

    .add-button {
        color: #007bff;
        border-color: #007bff;
    }

    .add-button:hover {
        background: #007bff;
        color: white;
    }

    .loading-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
    }

    .empty-state {
        text-align: center;
        padding: 40px;
        color: #666;
    }

    .image-container {
        width: 100%;
        height: 160px;
        overflow: hidden;
        border-radius: 8px 8px 0 0;
    }

    .image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .description {
        font-size: 13px;
        color: #4a5568;
        margin: 8px 0;
        line-height: 1.4;
        max-height: 3.6em;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .fixed-header {
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
        padding: 12px 12px 0 12px;
    }

    .tabs {
        display: flex;
        gap: 2px;
        background-color: #f5f5f5;
        padding: 3px;
        border-radius: 6px;
        margin-bottom: 12px;
    }

    .tab-button {
        flex: 1;
        padding: 8px 16px;
        border: none;
        background: transparent;
        color: #666;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;
        border-radius: 4px;
    }

    .tab-button:hover:not(.active) {
        background-color: rgba(0, 0, 0, 0.05);
        color: #333;
    }

    .tab-button.active {
        background-color: white;
        color: #007bff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1050;
    }

    .modal-container {
        background: white;
        border-radius: 8px;
        width: 95%;
        height: 95%;
        max-width: 1400px;
        position: relative;
        overflow: hidden;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    }

    .search-input-group {
        display: flex;
        gap: 8px;
        width: 100%;
    }

    .search-button {
        display: inline-flex;
        align-items: center;
        gap: 6px;
        padding: 8px 16px;
        background: #007bff;
        color: white;
        border: none;
        border-radius: 6px;
        font-size: 14px;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .search-button:hover:not(:disabled) {
        background: #0056b3;
    }

    .search-button:disabled {
        background: #ccc;
        cursor: not-allowed;
    }

    .search-hint {
        font-size: 12px;
        color: #666;
        margin-top: 4px;
        padding-left: 4px;
    }

    .no-results {
        text-align: center;
        padding: 20px;
        background: #f8f9fa;
        border-radius: 8px;
        margin: 20px;
    }

    .suggested-terms {
        margin-top: 12px;
        font-size: 14px;
    }

    .term {
        display: inline-block;
        padding: 4px 8px;
        margin: 4px;
        background: #e9ecef;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .term:hover {
        background: #dee2e6;
    }

    .error-message {
        color: #dc3545;
    }

    .current-topic {
        padding: 8px 12px;
        background: #f8f9fa;
        border-radius: 4px;
        font-size: 14px;
        color: #666;
        margin-bottom: 8px;
    }

    .filter-info {
        font-size: 12px;
        color: #666;
        margin-top: 4px;
        text-align: right;
        padding-right: 8px;
    }
</style> 