<script>
    import { fade } from 'svelte/transition';
    import { settingsStore, AGENT_GUIDANCE_LEVELS, updateAgentGuidance, toggleHeadlinesFeature } from '../stores/settingsStore';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let isOpen = false;
    export let onClose = () => {};

    let selectedTab = 'tool';
    let currentSettings;

    // Subscribe to settings store
    settingsStore.subscribe(settings => {
        currentSettings = settings;
    });

    function handleAgentGuidanceChange(level) {
        updateAgentGuidance(level);
    }

    function handleHeadlinesToggle(event) {
        toggleHeadlinesFeature(event.target.checked);
    }

    function closeModal() {
        dispatch('close');
    }
</script>

<div class="modal-backdrop" transition:fade on:click={closeModal}>
    <div class="modal-content settings-modal" on:click|stopPropagation>
        <div class="modal-header">
            <h2>Settings</h2>
            <button class="close-button" on:click={closeModal}>
                <i class="bi bi-x"></i>
            </button>
        </div>

        <div class="tabs">
            <button 
                class="tab-button {selectedTab === 'tool' ? 'active' : ''}"
                on:click={() => selectedTab = 'tool'}
            >
                Tool Settings
            </button>
            <button 
                class="tab-button {selectedTab === 'dev' ? 'active' : ''}"
                on:click={() => selectedTab = 'dev'}
            >
                Developer Only
            </button>
        </div>

        <div class="modal-body">
            {#if selectedTab === 'tool'}
                <div class="settings-section">
                    <h3>Agent Guidance Level</h3>
                    <div class="radio-group">
                        {#each Object.entries(AGENT_GUIDANCE_LEVELS) as [key, value]}
                            <label class="radio-label">
                                <input 
                                    type="radio" 
                                    name="guidance" 
                                    value={value}
                                    checked={currentSettings.agentGuidance === value}
                                    on:change={() => handleAgentGuidanceChange(value)}
                                />
                                <span>{key.charAt(0) + key.slice(1).toLowerCase()}</span>
                            </label>
                        {/each}
                    </div>
                </div>
            {:else}
                <div class="settings-section">
                    <h3>Feature Toggles</h3>
                    <label class="toggle-label">
                        <input 
                            type="checkbox"
                            checked={currentSettings.developerFeatures.showHeadlines}
                            on:change={handleHeadlinesToggle}
                        />
                        <span>Enable Headlines Feature</span>
                    </label>
                </div>
            {/if}
        </div>
    </div>
</div>

<style>
    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .modal-content {
        background: white;
        border-radius: 8px;
        width: 90%;
        max-width: 500px;
        max-height: 90vh;
        overflow-y: auto;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .modal-header {
        padding: 16px;
        border-bottom: 1px solid #e5e7eb;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modal-header h2 {
        margin: 0;
        font-size: 1.25rem;
        color: #1f2937;
    }

    .close-button {
        background: none;
        border: none;
        color: #6b7280;
        cursor: pointer;
        padding: 4px;
    }

    .tabs {
        display: flex;
        padding: 8px 16px;
        gap: 8px;
        background: #f9fafb;
        border-bottom: 1px solid #e5e7eb;
    }

    .tab-button {
        padding: 8px 16px;
        border: none;
        background: none;
        border-radius: 6px;
        cursor: pointer;
        color: #6b7280;
        font-size: 14px;
    }

    .tab-button.active {
        background: #fff;
        color: #2563eb;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }

    .modal-body {
        padding: 16px;
    }

    .settings-section {
        margin-bottom: 24px;
    }

    .settings-section h3 {
        font-size: 1rem;
        color: #374151;
        margin-bottom: 12px;
    }

    .radio-group {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .radio-label {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
    }

    .toggle-label {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
    }
</style> 