import { API_BASE_URL } from '../config/apiConfig';

// Get the base API URL from the centralized config
const getApiBaseUrl = () => {
    return API_BASE_URL;
};

const API_URL = getApiBaseUrl();

// Get or create a chat session
export async function initializeChat({ userId, storyId, chatType, slideIndex = null, evidenceId = null }) {
  try {
    // Validate required parameters
    if (!userId) throw new Error('userId is required');
    if (!storyId) throw new Error('storyId is required');
    if (!chatType) throw new Error('chatType is required');

    console.log('Initializing chat with params:', {
      userId,
      storyId,
      chatType,
      slideIndex,
      evidenceId
    });

    const response = await fetch(`${API_URL}/api/chats/session`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        storyId,
        chatType,
        slideIndex,
        evidenceId
      })
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      console.error('Chat initialization failed:', {
        status: response.status,
        statusText: response.statusText,
        errorData
      });
      throw new Error(`Failed to initialize chat: ${response.status} ${response.statusText} ${errorData.error || ''}`);
    }

    const data = await response.json();
    
    if (!data?.chat?._id) {
      console.error('Invalid chat response:', data);
      throw new Error('Invalid chat response: missing chat ID');
    }

    console.log('Chat initialized successfully:', data.chat);
    return data.chat;
  } catch (error) {
    console.error('Error initializing chat:', error);
    throw error;
  }
}

// Get chat history
export async function getChatHistory({ userId, storyId, chatType, slideIndex = null, evidenceId = null }) {
  try {
    // Validate required parameters
    if (!userId || !storyId || !chatType) {
      throw new Error('Missing required parameters for chat history');
    }

    // Build query parameters
    const params = new URLSearchParams({
      userId,
      storyId,
      chatType,
      ...(slideIndex !== null && { slideIndex }),
      ...(evidenceId && { evidenceId })
    });

    const response = await fetch(`${API_URL}/api/chats/history?${params}`);
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(`Failed to fetch chat history: ${response.statusText} ${errorData.error || ''}`);
    }

    const data = await response.json();

    // Ensure we have an array of messages
    const messages = Array.isArray(data) ? data : [];

    // Log received messages for debugging
    console.log('Received chat history:', messages.map(msg => ({
      id: msg._id,
      chatId: msg.chatId,
      role: msg.role,
      content: msg.content?.substring(0, 50) + '...'
    })));

    return messages;
  } catch (error) {
    console.error('Error in getChatHistory:', error);
    // Return empty array instead of throwing to prevent UI breaks
    return [];
  }
}

// Add a message to the chat
export async function addMessage(chatId, message) {
  try {
    const response = await fetch(`${API_URL}/api/chats/message`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chatId,
        message
      })
    });

    if (!response.ok) {
      throw new Error(`Failed to add message: ${response.statusText}`);
    }

    const data = await response.json();
    return data.chat;
  } catch (error) {
    console.error('Error adding message:', error);
    throw error;
  }
}

// Delete a chat
export async function deleteChat(chatId) {
  try {
    const response = await fetch(`${API_URL}/api/chats/${chatId}`, {
      method: 'DELETE',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`Failed to delete chat: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error deleting chat:', error);
    throw error;
  }
}

// Add welcome message function
export async function addWelcomeMessage(chatId, chatType) {
    try {
        // Get welcome message based on chat type
        let welcomeContent = "Hi there! I'm here to help you analyze this chart. What would you like to know about it?";
        
        if (chatType === 'slide-assistant') {
            welcomeContent = "Hi! I'm here to help you understand this slide. What would you like to know?";
        } else if (chatType === 'evidence-analysis') {
            welcomeContent = "Hello! I'm here to help you analyze this evidence. What would you like to know?";
        }

        // Create welcome message
        const welcomeMessage = {
            content: welcomeContent,
            sender: 'ai',
            timestamp: new Date(),
            suggestions: [
                "What does this chart show?",
                "What are the main trends?",
                "How can I interpret this data?"
            ]
        };

        // Save to database using the addMessage endpoint
        const response = await fetch(`${API_URL}/api/chats/message`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                chatId,
                message: welcomeMessage
            })
        });

        if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(`Failed to save welcome message: ${response.status} ${response.statusText} ${errorData.error || ''}`);
        }

        return welcomeMessage;
    } catch (error) {
        console.error('Error adding welcome message:', error);
        throw error;
    }
} 