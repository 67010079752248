import { writable } from 'svelte/store';

// Create a toast notifications store
const createToastStore = () => {
  // Default toast state
  const DEFAULT_DURATION = 3000;
  
  // Create the writable store with initial state
  const { subscribe, update } = writable({
    toasts: []
  });
  
  // Generate unique IDs for toasts
  const generateId = () => `toast-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  
  return {
    subscribe,
    
    // Add a new toast
    showToast: ({ message, type = 'info', duration = DEFAULT_DURATION }) => {
      const id = generateId();
      
      // Add the toast to the store
      update(state => {
        const newToasts = [...state.toasts, { id, message, type, duration }];
        return { toasts: newToasts };
      });
      
      // Automatically remove the toast after the specified duration
      setTimeout(() => {
        update(state => {
          const newToasts = state.toasts.filter(toast => toast.id !== id);
          return { toasts: newToasts };
        });
      }, duration);
      
      return id;
    },
    
    // Remove a specific toast
    removeToast: (id) => {
      update(state => {
        const newToasts = state.toasts.filter(toast => toast.id !== id);
        return { toasts: newToasts };
      });
    },
    
    // Clear all toasts
    clearToasts: () => {
      update(() => ({ toasts: [] }));
    }
  };
};

export const toast = createToastStore(); 