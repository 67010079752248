<script>
  import { onMount, afterUpdate, onDestroy } from "svelte";
  import Agent_ChartAnalysis from "./Agent_ChartAnalysis.svelte";
  import { 
    evidenceStore, 
    saveEvidence, 
    removeSavedEvidence,
    setCurrentEvidence,
    clearCurrentEvidence 
  } from "../stores/evidenceStore";
  import { chartAgentInstructions } from "../stores/agentInstructionsStore";
  import { chatStore, resetChat } from "../stores/chatStore";
  import { createEventDispatcher } from 'svelte';
  import { selectionStore } from '../stores/selectionStore';
  import ChartSelection from './ChartSelection.svelte';
  import { get } from 'svelte/store';
  import { promptStore } from '../stores/promptStore';
  import { storyStore } from '../stores/storyStore';
  import Zoomist from 'zoomist';

  export let evidence;
  export let isOpen = false;
  export let closeModal;
  
  const dispatch = createEventDispatcher();
  let activeTab = 'discuss';
  let savedEvidence = [];
  let canvasContainer;
  let canvas;
  let selectionActive = false;
  let selectedRegion = null;
  let imageElement;
  let bigQuestion;
  let promptDetails;
  let chatKey;

  let zoomInstance;
  let zoomContainer;
  let isZoomInitialized = false;

  evidenceStore.subscribe(store => {
    // Only update if no evidence is provided as prop
    if (!evidence) {
      evidence = store.currentEvidence;
    }
    savedEvidence = store.savedEvidence || [];
  });

  storyStore.subscribe(store => {
    bigQuestion = store.bigQuestion;
  });

  promptStore.subscribe(store => {
    promptDetails = store.selectedPromptDetails;
  });

  // Set current evidence when modal opens, clear when it closes
  $: if (isOpen && evidence) {
    setCurrentEvidence(evidence);
    // Initialize story store if needed
    const currentStory = get(storyStore);
    if (!currentStory?._id) {
      // Create a temporary story for research mode
      storyStore.update(state => ({
        ...state,
        _id: 'research-mode',
        bigQuestion: evidence.bigQuestion || 'Research Mode',
        title: 'Research Mode',
        slides: [],
        template: 'none',
        isDirty: false,
        isSaving: false
      }));
    }
  } else {
    clearCurrentEvidence();
  }

  $: isEvidenceSaved = savedEvidence.some(e => e._id === evidence._id);

  // Subscribe to agent instructions changes
  $: {
    $chartAgentInstructions; // This creates a dependency
    if (activeTab === 'discuss') {
      resetChat(); // Reset chat when instructions change
    }
  }

  // Subscribe to chat store to get the key
  chatStore.subscribe(state => {
    chatKey = state.key;
  });

  function handleAddEvidence(event) {
    event.stopPropagation();
    dispatch('addToCanvas', { evidence });
  }

  function handleSaveEvidence(event) {
    event.stopPropagation();
    if (!evidence) return;
    
    const isAlreadySaved = savedEvidence.some(e => e._id === evidence._id);
    if (isAlreadySaved) {
      removeSavedEvidence(evidence._id);
    } else {
      saveEvidence(evidence);
    }
  }

  function initializeCanvas() {
    if (!canvasContainer || !window.fabric) return;

    // Get the image element
    const imageElement = canvasContainer.querySelector('.evidence-image');
    if (!imageElement) return;

    // Get the actual displayed dimensions of the image
    const imageBounds = imageElement.getBoundingClientRect();
    const containerRect = canvasContainer.getBoundingClientRect();

    // Calculate the offset to center the canvas over the image
    const offsetX = (containerRect.width - imageBounds.width) / 2;
    const offsetY = (containerRect.height - imageBounds.height) / 2;

    // Destroy existing canvas if it exists
    if (canvas) {
      canvas.dispose();
    }

    // Create new canvas with image dimensions
    canvas = new window.fabric.Canvas('selection-canvas', {
      width: imageBounds.width,
      height: imageBounds.height,
      selection: false,
      renderOnAddRemove: true,
      backgroundColor: 'transparent'
    });

    // Position the canvas absolutely over the image
    const canvasElement = document.getElementById('selection-canvas');
    if (canvasElement) {
      canvasElement.style.position = 'absolute';
      canvasElement.style.left = `${offsetX}px`;
      canvasElement.style.top = `${offsetY}px`;
      canvasElement.style.zIndex = '100';
    }

    // Add selection rectangle capability
    let isDown = false;
    let startX = 0;
    let startY = 0;
    let selectionRect = null;

    canvas.on('mouse:down', (o) => {
      if (!selectionActive) return;
      isDown = true;
      const pointer = canvas.getPointer(o.e);
      startX = pointer.x;
      startY = pointer.y;

      selectionRect = new window.fabric.Rect({
        left: startX,
        top: startY,
        width: 0,
        height: 0,
        fill: 'rgba(37, 99, 235, 0.1)',
        stroke: '#2563eb',
        strokeWidth: 2,
        selectable: false,
        evented: false
      });

      canvas.add(selectionRect);
      canvas.renderAll();
    });

    canvas.on('mouse:move', (o) => {
      if (!isDown || !selectionActive) return;
      const pointer = canvas.getPointer(o.e);

      if (selectionRect) {
        const width = Math.abs(pointer.x - startX);
        const height = Math.abs(pointer.y - startY);
        selectionRect.set({
          width: width,
          height: height,
          left: Math.min(startX, pointer.x),
          top: Math.min(startY, pointer.y)
        });
        canvas.renderAll();
      }
    });

    canvas.on('mouse:up', () => {
      if (!selectionActive) return;
      isDown = false;
      if (selectionRect && selectionRect.width > 10 && selectionRect.height > 10) {
        // Add debug logging
        console.log('Canvas Selection:', {
            left: selectionRect.left,
            top: selectionRect.top,
            width: selectionRect.width,
            height: selectionRect.height
        });
        
        // Get canvas dimensions
        console.log('Canvas Dimensions:', {
            width: canvas.width,
            height: canvas.height,
            scale: canvas.getZoom()
        });

        selectedRegion = {
            x: selectionRect.left,
            y: selectionRect.top,
            width: selectionRect.width,
            height: selectionRect.height
        };
      } else {
        // Remove too small selections
        canvas.remove(selectionRect);
        selectedRegion = null;
      }
      canvas.renderAll();
    });
  }

  function toggleSelection() {
    if ($selectionStore.isSelecting) {
      selectionStore.clearSelection();
    } else {
      selectionStore.startSelection(evidence);
    }
  }

  // Initialize canvas when modal opens
  $: if (isOpen) {
    setTimeout(() => {
      initializeCanvas();
    }, 100); // Small delay to ensure container is ready
  }

  // Cleanup on modal close
  $: if (!isOpen && canvas) {
    canvas.dispose();
    canvas = null;
    selectionActive = false;
    selectedRegion = null;
  }

  // Handle window resize
  function handleResize() {
    if (isOpen) {
      initializeCanvas();
    }
  }

  onMount(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (canvas) {
        canvas.dispose();
      }
    };
  });

  function validateSelection(selection, imageDimensions) {
    const { naturalWidth, naturalHeight } = imageDimensions;
    
    // Ensure coordinates are within image bounds
    const validatedSelection = {
        x: Math.max(0, Math.min(selection.x, naturalWidth)),
        y: Math.max(0, Math.min(selection.y, naturalHeight)),
        width: Math.min(selection.width, naturalWidth - selection.x),
        height: Math.min(selection.height, naturalHeight - selection.y)
    };
    
    console.log('Validated Selection:', {
        original: selection,
        validated: validatedSelection,
        imageDimensions: { naturalWidth, naturalHeight }
    });
    
    return validatedSelection;
  }

  function handleSelectionComplete(event) {
    const { selection } = event.detail;
    console.log('Raw Selection:', selection);
    
    // Ensure we have valid image dimensions
    const naturalWidth = imageElement?.naturalWidth || 0;
    const naturalHeight = imageElement?.naturalHeight || 0;
    
    console.log('Image Dimensions:', {
        naturalWidth,
        naturalHeight,
        clientWidth: imageElement?.clientWidth,
        clientHeight: imageElement?.clientHeight
    });

    if (!naturalWidth || !naturalHeight) {
        console.error('Invalid image dimensions');
        return;
    }

    // Convert pixel coordinates to percentages
    const regionPercentages = {
        x: (selection.x / naturalWidth) * 100,
        y: (selection.y / naturalHeight) * 100,
        width: (selection.width / naturalWidth) * 100,
        height: (selection.height / naturalHeight) * 100
    };
    
    console.log('Calculated Region Percentages:', regionPercentages);

    activeTab = 'discuss';
    
    if (typeof window !== 'undefined' && evidence.mediaURL) {
        window.dispatchEvent(new CustomEvent('askAgent', {
            detail: {
                question: "Can you help me understand what's happening in this selected region of the graph?",
                context: {
                    region: regionPercentages,  // Now in percentages
                    bigQuestion: evidence.bigQuestion || 'Default Question',
                    description: evidence.description || 'No description available',
                    imageData: {
                        src: evidence.mediaURL,  // No fallback needed
                        naturalWidth,
                        naturalHeight,
                        displayWidth: imageElement?.clientWidth || 0,
                        displayHeight: imageElement?.clientHeight || 0
                    }
                }
            }
        }));
    }

    // Reset selection state
    selectionStore.clearSelection();
  }

  // Make sure to clear current evidence when component is destroyed
  onDestroy(() => {
    clearCurrentEvidence();
    selectionStore.reset();
  });

  // Add a resize observer to handle image size changes
  onMount(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (isOpen) {
        // Small delay to ensure image has finished resizing
        setTimeout(initializeCanvas, 100);
      }
    });

    if (canvasContainer) {
      resizeObserver.observe(canvasContainer);
    }

    return () => {
      resizeObserver.disconnect();
    };
  });

  // Add debug logging
  $: console.log('EvidenceModal props:', { evidence, isOpen });

  function drawDebugOverlay(selection) {
    // Add a temporary debug rectangle
    const debugRect = new window.fabric.Rect({
        left: selection.x,
        top: selection.y,
        width: selection.width,
        height: selection.height,
        fill: 'transparent',
        stroke: 'red',
        strokeWidth: 1,
        selectable: false,
        evented: false
    });
    
    canvas.add(debugRect);
    setTimeout(() => {
        canvas.remove(debugRect);
        canvas.renderAll();
    }, 2000); // Remove after 2 seconds
  }

  onMount(() => {
    // Delay initialization to ensure the DOM is ready
    setTimeout(() => {
      if (zoomContainer && !isZoomInitialized) {
        initializeZoomist();
      }
    }, 100); // Small delay to ensure the DOM is rendered

    return () => {
      // Clean up Zoomist instance when the component is destroyed
      destroyZoomist();
    };
  });

  function initializeZoomist() {
    if (!zoomContainer || isZoomInitialized) return;
    
    try {
      zoomInstance = new Zoomist(zoomContainer, {
        maxScale: 4,
        bounds: true,
        slider: false,
        zoomer: false, // Disable built-in zoomer
        minScale: 1,
        draggable: true,
      });
      isZoomInitialized = true;
      console.log("Zoomist initialized");
    } catch (error) {
      console.error("Error initializing Zoomist:", error);
      isZoomInitialized = false;
    }
  }

  function destroyZoomist() {
    if (zoomInstance) {
      try {
        zoomInstance.destroy();
      } catch (error) {
        console.error("Error destroying Zoomist:", error);
      }
      zoomInstance = null;
      isZoomInitialized = false;
    }
  }

  // Handle zoom operations manually
  function zoomIn() {
    if (zoomInstance && isZoomInitialized) {
      try {
        // Check if transform exists before zooming
        if (zoomInstance.transform) {
          zoomInstance.zoom(0.2, { animate: true });
        } else {
          console.warn("Zoomist transform not initialized yet");
          // Reinitialize if necessary
          destroyZoomist();
          initializeZoomist();
        }
      } catch (error) {
        console.error("Error zooming in:", error);
      }
    }
  }

  function zoomOut() {
    if (zoomInstance && isZoomInitialized) {
      try {
        // Check if transform exists before zooming
        if (zoomInstance.transform) {
          zoomInstance.zoom(-0.2, { animate: true });
        } else {
          console.warn("Zoomist transform not initialized yet");
          // Reinitialize if necessary
          destroyZoomist();
          initializeZoomist();
        }
      } catch (error) {
        console.error("Error zooming out:", error);
      }
    }
  }

  function resetZoom() {
    if (zoomInstance && isZoomInitialized) {
      try {
        zoomInstance.reset({ animate: true });
      } catch (error) {
        console.error("Error resetting zoom:", error);
      }
    }
  }

  $: if (isOpen && !isZoomInitialized) {
    setTimeout(() => {
      if (zoomContainer && !isZoomInitialized) {
        initializeZoomist();
      }
    }, 200); // Slightly longer delay to ensure DOM is fully rendered
  } else if (!isOpen) {
    destroyZoomist();
  }

</script>

{#if evidence && Object.keys(evidence).length > 0}
  <!-- Debug output -->
  <pre style="display: none;">{JSON.stringify(evidence, null, 2)}</pre>
  <div class="modal-content">
    <button class="close-button" on:click={closeModal}>
      <i class="fas fa-times"></i>
    </button>
    
    <div class="modal-grid">
      <!-- Left Column - Image -->
      <div class="image-column">
        <div class="action-bar">
          <div class="content-header">
            <div class="header-text">
              <h2>{evidence.label}</h2>
              <p class="description">{evidence.description}</p> 
            </div>
            <div class="save-button-container">
              <button 
                class="action-button save-button {isEvidenceSaved ? 'saved' : ''}" 
                on:click={handleSaveEvidence}
                title={isEvidenceSaved ? "Remove from saved" : "Save evidence"}
              >
                <i class="{isEvidenceSaved ? 'fas fa-bookmark' : 'far fa-bookmark'}"></i>
                
              </button>
            </div>
          </div>
        </div>
        <div bind:this={zoomContainer} class="image-container zoomist-container">
            <div class="zoomist-wrapper">
              <img 
                bind:this={imageElement}
                src={evidence.s3URL} 
                alt={evidence.label}
                class="evidence-image zoomist-image"
                draggable="false"
                on:error={() => console.error('Image failed to load:', evidence.s3URL)}
              />
              <ChartSelection 
                {imageElement}
                on:selectionComplete={handleSelectionComplete}
              />
              <!-- Custom zoom controls -->
              <div class="custom-zoom-controls">
                <button class="zoom-button zoom-in" on:click={zoomIn} title="Zoom In">+</button>
                <button class="zoom-button zoom-out" on:click={zoomOut} title="Zoom Out">−</button>
                <button class="zoom-button zoom-reset" on:click={resetZoom} title="Reset Zoom">○</button>
              </div>
            </div>
          </div>
     
      
        </div>
      <!-- Right Column - Content -->
      <div class="content-column">

        <div class="tabs-container">
          <div class="tabs">
            <button 
              class="tab-button {activeTab === 'discuss' ? 'active' : ''}"
              on:click={() => activeTab = 'discuss'}
            >
              <i class="fas fa-comments"></i> Discuss
            </button>
            <button 
              class="tab-button {activeTab === 'research' ? 'active' : ''}"
              on:click={() => activeTab = 'research'}
            >
              <i class="fas fa-search"></i> Research
            </button>
          </div>

          <div class="tab-content">
            {#if activeTab === 'discuss'}
              <div class="discuss-tab">
                <Agent_ChartAnalysis key={chatKey} />
              </div>
            {:else}
              <div class="research-tab">
                <div class="metadata">
                  {#if evidence.sourceURL}
                  <div class="metadata-item">
                    <span class="label">Source:</span>
                    <span><a href={evidence.sourceURL} target="_blank" rel="noopener noreferrer">{evidence.sourceLabel}</a></span>
                  </div>
                  {/if}
                </div>
              </div>
            {/if}
          </div>
        </div>
      </div>
    </div>
    </div>
  
{:else}
  <div class="modal-content" style="padding: 20px;">
    <button class="close-button" on:click={closeModal}>
      <i class="fas fa-times"></i>
    </button>
    <p>Loading evidence...</p>
    <!-- Debug info -->
    <pre style="font-size: 12px; color: #666;">
      Evidence: {JSON.stringify(evidence, null, 2)}
      IsOpen: {isOpen}
    </pre>
  </div>
{/if}

<style>
  .modal-content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .modal-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    height: 100%;
    overflow: hidden;
    flex: 1;
  }

  .image-column {
    height: 100%;
    border-right: 1px solid #eee;
    background: #f8f9fa;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .image-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .evidence-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    display: block;
    pointer-events: none;
    user-select: none;

  }

  .content-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  .action-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    background-color: #f8f9fa; /* Optional: Add a background color */
    border-bottom: 1px solid #eee; /* Optional: Add a separator */
  }

  .content-header {
    display: flex;
    justify-content: space-between; /* Space between text and button */
    align-items: flex-start; /* Align items at the top */
    padding: 8px 16px;
    border-bottom: 1px solid #eee; /* Optional: Add a separator */
    background-color: #f8f9fa; /* Optional: Add a background color */
    z-index: 1; /* Ensure it stays above other elements */
  }

  .header-text {
    flex: 1; /* Allow the text to take up available space */
    margin-right: 16px; /* Add spacing between text and button */
  }

  .header-text h2 {
    font-size: 1.1rem;
    margin: 0 0 4px 0;
  }

  .header-text .description {
    font-size: 0.9rem;
    margin: 0;
    color: #64748b;
    word-wrap: break-word; /* Ensure long text wraps */
  }

  .save-button-container {
    flex-shrink: 0; /* Prevent the button from shrinking */
  }

  .save-button {
    background: white;
    color: #64748b;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .save-button:hover {
    background: #f8fafc;
    color: #1e293b;
  }

  .save-button.saved {
    background: #2563eb;
    color: white;
    border-color: #2563eb;
  }

  .tabs-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0; /* Important for Firefox */
    overflow: hidden;
  }

  .tabs {
    display: flex;
    gap: 4px;
    padding: 4px 12px;
    background: #f8fafc;
    border-bottom: 1px solid #eee;
    flex-shrink: 0;
  }

  .tab-button {
    flex: 1;
    text-align: center;
    padding: 6px 0;
    border: none;
    border-bottom: 2px solid transparent;
    background: transparent;
    color: #64748b;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .tab-button:hover:not(.active) {
    color: #1e293b;
  }

  .tab-button.active {
    border-color: #2563eb;
    color: #2563eb;
  }

  .tab-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .discuss-tab,
  .research-tab {
    flex: 1;
    overflow: auto;
    padding: 16px;
  }

  /* Style improvements for the Agent chat component */
  :global(.discuss-tab .chat-container) {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  :global(.discuss-tab .chat-box) {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  :global(.discuss-tab .chat-controls) {
    padding: 16px;
    border-top: 1px solid #eee;
    background: white;
  }

  :global(.discuss-tab .message) {
    max-width: 85%;
    margin-bottom: 8px;
  }

  :global(.discuss-tab .message.user) {
    align-self: flex-end;
  }

  :global(.discuss-tab .message.ai) {
    align-self: flex-start;
  }

  /* Research tab styles */
  .research-tab {
    padding: 24px;
  }

  .metadata {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
  }

  .metadata-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .metadata-item .label {
    font-weight: 500;
    color: #64748b;
    font-size: 14px;
  }

  .metadata-item a {
    color: #2563eb;
    text-decoration: none;
    word-break: break-all;
  }

  .metadata-item a:hover {
    text-decoration: underline;
  }

  /* Action buttons */
  .action-bar {
    display: flex;
    gap: 8px;
    margin-top: 16px;
  }

  .action-button {
    display: inline-flex
;
    align-items: center;
    justify-content: center;
    height: 36px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 8px;
    transition: all 0.2sease;
    cursor: pointer;
    background: transparent;
    border: 1px solid #e2e8f0;
  }

  .action-button:hover {
    background: #f1f5f9;
    color: #1e293b;
  }

  .save-button {
    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    border-radius: 8px;
    transition: all 0.2sease;
    cursor: pointer;
    background: transparent;
    border: 1px solid #e2e8f0;
  }

  .save-button:hover {
    background: #f8fafc;
    color: #1e293b;
  }

  .save-button.saved {
    color: rgb(15, 23, 42);
    background: rgb(241, 245, 249);
    border-color: rgb(226, 232, 240);
  }



  .add-button:hover {
    background: #1d4ed8;
  }

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10001;
    background: white;
    border: 1px solid #eee;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  .floating-controls {
    position: absolute;
    bottom: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 101;
    pointer-events: all;
  }

  .button-background {
    background: white;
    border-radius: 8px;
    padding: 8px 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .selection-toggle {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    background: #f8fafc;
    border: 1px solid #e2e8f0;
    color: #64748b;
  }

  .selection-toggle:hover:not(.active) {
    background: #f1f5f9;
    color: #1e293b;
  }

  .selection-toggle.active {
    background: #2563eb;
    border-color: #2563eb;
    color: white;
  }

  .beta-tag {
    background-color: #f3f4f6;
    color: #4b5563;
    font-size: 0.75rem;
    margin-left: 8px;
    padding: 2px 6px;
    border-radius: 4px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .zoomist-container{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .zoomist-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    user-select: none;
    -webkit-user-drag: none;
  }
  
  /* Custom zoom controls */
  .custom-zoom-controls {
    position: absolute;
    bottom: 12px;
    right: 12px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 1px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    padding: 2px;
  }
  
  .zoom-button {
    width: 30px;
    height: 30px;
    border: none;
    background: white;
    color: #333;
    font-size: 18px;
    line-height: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: all 0.2s ease;
  }
  
  .zoom-button:hover {
    background: #f0f0f0;
    color: #000;
  }
  
  .zoom-in {
    border-bottom: 1px solid #eee;
  }
  
  .zoom-out {
    border-bottom: 1px solid #eee;
  }
  
  .zoom-reset {
    font-size: 14px;
  }

</style>
