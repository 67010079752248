<script>
  import {
    onMount,
    onDestroy,
    createEventDispatcher,
    getContext,
  } from "svelte";
  import { Router, Route } from "svelte-routing";
  import HomePage from "./pages/HomePage.svelte";
  import StorySlidePage from "./pages/StorySlidePage.svelte";
  import StoryGridPage from "./pages/StoryGridPage.svelte";
  import { setContext } from "svelte";
  import { writable, get } from "svelte/store";
  import { storyStore } from "./stores/storyStore.js";
  import HeaderBar from "./components/HeaderBar.svelte";
  import { evidenceStore, searchEvidence } from "./stores/evidenceStore";
  import { promptStore } from "./stores/promptStore";
  import { navigate } from "svelte-routing";
  import StoryCanvas from "./components/StoryCanvas.svelte";
  import StoryResearcherPage from "./pages/StoryResearcherPage.svelte";
  import AuthGuard from "./components/AuthGuard.svelte";
  import { authStore } from "./stores/authStore.js";
  import ToastContainer from "./components/ToastContainer.svelte";
  import ManageEvidencePage from "./pages/ManageEvidencePage.svelte";

  const selectedTool = writable("select");
  const selectedSlideIndex = writable(0);
  const selectedItem = writable("elements");

  setContext("selectedTool", selectedTool);
  setContext("selectedSlideIndex", selectedSlideIndex);
  setContext("selectedItem", selectedItem);
  setContext("storyStore", storyStore);
  setContext("authStore", authStore);

  // Create a store for classroom info
  const classroomInfo = writable({
    nickname: "",
    id: "",
  });

  setContext("classroomInfo", classroomInfo);

  // Auto-save functionality
  let autoSaveTimer;
  let lastSaveTime = Date.now();
  const AUTO_SAVE_DELAY = 3000; // 3 seconds delay for auto-save
  
  const storyUnsubscribe = storyStore.subscribe(state => {
    if (state.isDirty && !state.isSaving && state._id) {
      // Only schedule auto-save if enough time has passed since last save
      const now = Date.now();
      if (now - lastSaveTime > AUTO_SAVE_DELAY) {
        // Clear any pending timer
        if (autoSaveTimer) {
          clearTimeout(autoSaveTimer);
        }
        
        // Set a new timer
        autoSaveTimer = setTimeout(async () => {
          console.log("Auto-saving story changes...");
          try {
            await storyStore.saveStory();
            lastSaveTime = Date.now();
            console.log("Auto-save completed successfully");
          } catch (error) {
            console.error("Auto-save failed:", error);
          }
        }, AUTO_SAVE_DELAY);
      }
    }
  });

  onMount(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const room = urlParams.get("room");
    const id = urlParams.get("id");

    if (room && id) {
      classroomInfo.set({
        nickname: room,
        id: id,
      });
    }
    
    // Check authentication status when app loads
    authStore.checkAuth();
  });

  onDestroy(() => {
    // Clean up auto-save timer and subscription
    if (autoSaveTimer) {
      clearTimeout(autoSaveTimer);
    }
    storyUnsubscribe();
  });

  export let url = "";
</script>

<!-- Global Toast Container - Outside of Router to ensure it's always visible -->
<ToastContainer />

<Router {url}>
  <Route path="/" component={HomePage} />
  
  <!-- Protected routes -->
  <Route path="/grid" let:params>
    <AuthGuard path="/grid">
      <StoryGridPage />
    </AuthGuard>
  </Route>
  
  <Route path="/tool" let:params>
    <AuthGuard path="/tool">
      <StorySlidePage />
    </AuthGuard>
  </Route>
  
  <Route path="/researcher" let:params>
    <AuthGuard path="/researcher">
      <StoryResearcherPage />
    </AuthGuard>
  </Route>

  <!-- Admin routes -->
  <Route path="/admin/evidence" let:params>
    <AuthGuard path="/admin/evidence" adminRequired={true}>
      <ManageEvidencePage />
    </AuthGuard>
  </Route>
</Router>
