import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App.svelte';
import { navigate } from 'svelte-routing';

const app = new App({
  target: document.body,
  props: {
    url: window.location.pathname
  }
});

// Optional: Add this to help with direct URL access
window.addEventListener('popstate', () => {
  navigate(window.location.pathname);
});

export default app;