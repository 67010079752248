<!-- src/components/admin/Admin-DataCollector.svelte -->
<script>
  import { onMount, onDestroy } from 'svelte';
  import Modal from '../Modal.svelte';
  
  // Chart scraper state
  export let urls = '';
  export let processing = false;
  export let sessionId = null;
  export let progress = 0;
  export let results = [];
  export let progressInterval = null;
  export let totalUrls = 0;
  export let processedUrls = 0;
  export let overwriteCharts = false;
  export let overwriteArticles = false;
  export let autoSync = false;
  export let autoAddToTemp = false;
  
  // Search URL modal state
  export let searchUrlModalOpen = false;
  export let searchUrl = '';
  export let isLoadingSearchUrls = false;
  export let searchUrlError = null;
  
  // Duplicate chart confirmation state
  let duplicateChartModalOpen = false;
  let currentDuplicateChart = null;
  let existingChart = null;
  
  // Event dispatcher for parent component
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  
  // Clean up intervals when component is destroyed
  onDestroy(() => {
    if (progressInterval) {
      clearInterval(progressInterval);
      progressInterval = null;
    }
  });
  
  async function processUrls() {
    // Split and clean URLs
    const urlList = urls
      .split('\n')
      .map(url => url.trim())
      .filter(url => url.length > 0 && url.startsWith('http'));
    
    if (urlList.length === 0) {
      alert('Please enter at least one valid URL');
      return;
    }
    
    processing = true;
    progress = 0;
    sessionId = null;
    results = [];
    
    try {
      console.log('Starting URL processing with options:', {
        overwriteCharts,
        overwriteArticles,
        autoSync,
        autoAddToTemp
      });
      
      // Start the processing
      const response = await fetch('/api/admin/process-chart-urls', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          urls: urlList,
          options: {
            overwriteCharts: Boolean(overwriteCharts),
            overwriteArticles: Boolean(overwriteArticles),
            autoSync: Boolean(autoSync),
            autoAddToTemp: Boolean(autoAddToTemp)
          }
        }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Server error: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Process URLs response:', data);
      
      if (data.success) {
        sessionId = data.sessionId;
        totalUrls = data.totalUrls;
        
        // Verify options were received correctly
        console.log('Server received options:', data.options);
        
        // Set up progress polling
        if (progressInterval) {
          clearInterval(progressInterval);
        }
        progressInterval = setInterval(checkProgress, 2000);
      } else {
        throw new Error(data.error || 'Failed to start processing');
      }
    } catch (error) {
      console.error('Error starting URL processing:', error);
      alert('Failed to start processing: ' + error.message);
    } finally {
      if (!sessionId) {
        processing = false;
      }
    }
  }
  
  async function checkProgress() {
    try {
      if (!sessionId) return;
      
      const response = await fetch(`/api/admin/scraping-progress/${sessionId}`);
      const data = await response.json();
      
      progress = data.progress;
      processedUrls = data.processedUrls;
      totalUrls = data.totalUrls;
      results = data.results;
      
      if (!data.isRunning) {
        clearInterval(progressInterval);
        progressInterval = null;
        processing = false;
        
        // If auto-sync is enabled and processing completed successfully
        if (autoSync && !data.error) {
          dispatch('autoSync');
        }
      }
    } catch (error) {
      console.error('Error checking progress:', error);
      clearInterval(progressInterval);
      progressInterval = null;
      processing = false;
    }
  }
  
  async function cancelProcessing() {
    if (!sessionId) return;
    
    try {
      const response = await fetch(`/api/admin/cancel-scraping/${sessionId}`, {
        method: 'POST'
      });
      
      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        clearInterval(progressInterval);
        progressInterval = null;
        processing = false;
      } else {
        throw new Error(data.error || 'Failed to cancel processing');
      }
    } catch (error) {
      console.error('Error cancelling processing:', error);
      alert('Failed to cancel processing: ' + error.message);
    }
  }

  function formatResultMessage(result) {
    if (!result) return 'No result data';
    
    if (result.success) {
      let message = `Found ${result.chartsFound} charts`;
      
      if (result.chartsSaved !== undefined) {
        message += `, saved ${result.chartsSaved}`;
        
        if (result.skippedCharts > 0) {
          message += `, skipped ${result.skippedCharts} existing`;
        }
      }
      
      if (result.articleFound) {
        message += `, article content extracted`;
      }
      
      if (result.errors?.length > 0) {
        message += ` (with ${result.errors.length} errors)`;
      }
      
      return message;
    } else {
      return result.message || 'Failed to process';
    }
  }

  function getStatusClass(result) {
    if (!result) return '';
    return result.success ? 'success' : 'error';
  }

  // Add this function to handle loading URLs from search
  async function loadUrlsFromSearch() {
    if (!searchUrl) {
      searchUrlError = 'Please enter a search URL';
      return;
    }

    isLoadingSearchUrls = true;
    searchUrlError = '';

    try {
      const response = await fetch('/api/admin/load-search-urls', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ searchUrl })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to load URLs');
      }

      if (!data.success || !data.urls || !Array.isArray(data.urls)) {
        throw new Error('Invalid response format from server');
      }

      // Append new URLs to existing ones, avoiding duplicates
      const existingUrls = urls.split('\n').filter(url => url.trim());
      const newUrls = data.urls.filter(url => !existingUrls.includes(url));
      
      if (newUrls.length > 0) {
        // Add a newline if there are existing URLs
        const separator = existingUrls.length > 0 ? '\n' : '';
        urls = existingUrls.join('\n') + separator + newUrls.join('\n');
      }
      
      // Close modal and reset state
      searchUrlModalOpen = false;
      searchUrl = '';
      
    } catch (error) {
      console.error('Error loading URLs:', error);
      searchUrlError = error.message;
    } finally {
      isLoadingSearchUrls = false;
    }
  }

  // Function to handle manual addition of a chart
  async function addChartToTemp(chart, force = false) {
    try {
      console.log('Adding chart to temp:', { chart, force });
      
      const response = await fetch('/api/admin/add-chart-to-temp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ ...chart, force })
      });
      
      const data = await response.json();
      console.log('Server response:', { status: response.status, data });
      
      if (!response.ok) {
        if (response.status === 409 && !force) {  // Duplicate found
          console.log('Duplicate chart found, showing modal');
          // Show confirmation dialog
          currentDuplicateChart = chart;
          existingChart = data.existingChart;
          duplicateChartModalOpen = true;
          return;
        }
        throw new Error(data.error || 'Failed to add chart to temp collection');
      }
      
      // Update the chart's status in the UI
      chart.addedToTemp = true;
      results = [...results]; // Trigger reactivity
      
    } catch (error) {
      console.error('Error adding chart to temp:', error);
      alert('Failed to add chart: ' + error.message);
    }
  }

  function handleDuplicateConfirm() {
    if (currentDuplicateChart) {
      addChartToTemp(currentDuplicateChart, true);  // Add with force=true
    }
    closeDuplicateModal();
  }

  function closeDuplicateModal() {
    duplicateChartModalOpen = false;
    currentDuplicateChart = null;
    existingChart = null;
  }
</script>

<div class="content-section">
  <div class="description">
    Enter URLs of webpages to scrape, one per line. The system will process each URL and extract:
    <ul>
      <li><strong>Data charts and visualizations</strong> - Saved to the evidence_temp collection</li>
      <li><strong>Article content</strong> - Saved to the articles_temp collection if detected</li>
    </ul>
  </div>
  
  <div class="url-input-container">
    <textarea 
      bind:value={urls}
      placeholder="Enter URLs here, one per line&#10;Example:&#10;https://pewresearch.org/article-with-charts&#10;https://statistics-site.com/data-visualization"
      rows="12"
      disabled={processing}
    ></textarea>
    
    <div class="options-container">
      <label class="option-label">
        <input type="checkbox" bind:checked={overwriteCharts} disabled={processing} />
        Overwrite existing charts
      </label>
      <label class="option-label">
        <input type="checkbox" bind:checked={overwriteArticles} disabled={processing} />
        Overwrite existing articles
      </label>
      <label class="option-label">
        <input type="checkbox" bind:checked={autoSync} disabled={processing} />
        Automatically sync evidence after saving
      </label>
      <label class="option-label">
        <input type="checkbox" bind:checked={autoAddToTemp} disabled={processing} />
        Automatically add to temp collection
      </label>
    </div>
    
    <div class="action-buttons">
      {#if processing}
        <button 
          class="cancel-btn" 
          on:click={cancelProcessing}
        >
          <i class="bi bi-x-circle"></i> Cancel
        </button>
        <div class="progress-container">
          <div class="progress-header">
            <span class="progress-text">
              Processing {processedUrls} of {totalUrls} URLs ({progress}%)
            </span>
          </div>
          <div class="progress-bar">
            <div class="progress-fill" style="width: {progress}%"></div>
          </div>
        </div>
      {:else}
        <button 
          class="process-btn" 
          on:click={processUrls}
        >
          <i class="bi bi-play-fill"></i> Process URLs
        </button>
        <button 
          class="load-btn" 
          on:click={() => searchUrlModalOpen = true}
          disabled={processing}
        >
          <i class="bi bi-search"></i> Load URLs from Search
        </button>
      {/if}
    </div>
  </div>
  
  {#if results.length > 0}
    <div class="results-section">
      <h3>Processing Results</h3>
      
      <!-- Summary Section -->
      <div class="results-summary">
        <div class="summary-item success">
          <i class="bi bi-check-circle-fill"></i>
          <span>Successfully Processed: {results.filter(r => r.success).length}</span>
        </div>
        <div class="summary-item warning">
          <i class="bi bi-exclamation-triangle-fill"></i>
          <span>Pending Addition: {results.filter(r => r.success && r.processingDetails?.chartDetection?.chartDetails?.some(c => !c.addedToTemp)).length}</span>
        </div>
        <div class="summary-item error">
          <i class="bi bi-x-circle-fill"></i>
          <span>Failed: {results.filter(r => !r.success).length}</span>
        </div>
      </div>

      <!-- Detailed Results -->
      <div class="results-list">
        {#each results as result}
          <div class="result-item {result.success ? 'success' : 'error'}">
            <div class="result-header">
              <i class="bi {result.success ? 'bi-check-circle-fill' : 'bi-x-circle-fill'}"></i>
              <span class="url">{result.url}</span>
            </div>
            
            {#if result.success}
              <div class="result-details">
                <!-- Charts Summary -->
                <div class="detail-section">
                  <h4>Charts Found ({result.chartsFound})</h4>
                  
                  <!-- Add Debug Logs Section -->
                  <div class="debug-logs">
                    <h5>Processing Logs</h5>
                    {#if result.processingDetails.chartDetection.debugLog?.length > 0}
                      <div class="log-section">
                        <h6>Chart Detection:</h6>
                        <ul>
                          {#each result.processingDetails.chartDetection.debugLog as log}
                            <li>{log}</li>
                          {/each}
                        </ul>
                      </div>
                    {/if}
                    
                    {#if result.processingDetails.databaseOperations.debugLog?.length > 0}
                      <div class="log-section">
                        <h6>Database Operations:</h6>
                        <ul>
                          {#each result.processingDetails.databaseOperations.debugLog as log}
                            <li>{log}</li>
                          {/each}
                        </ul>
                      </div>
                    {/if}
                  </div>

                  <div class="chart-list">
                    {#each result.processingDetails.chartDetection.chartDetails as chart}
                      <div class="chart-item">
                        <div class="chart-content">
                          <div class="chart-info">
                            <span class="chart-label">{chart.label || 'Untitled Chart'}</span>
                            <span class="chart-dimensions">
                              {chart.dimensions.width}x{chart.dimensions.height}px
                            </span>
                            
                            {#if chart.isDirectDownload}
                              <span class="direct-download-badge">
                                <i class="bi bi-cloud-download"></i> Direct Download
                              </span>
                            {/if}
                          </div>
                          
                          {#if chart.mediaURL}
                            <div class="chart-image-container">
                              <img 
                                src={chart.mediaURL} 
                                alt={chart.label || 'Chart preview'} 
                                class="chart-preview"
                              />
                            </div>
                          {/if}
                        </div>
                        
                        <div class="chart-status">
                          {#if !autoAddToTemp && !chart.addedToTemp}
                            <button 
                              class="add-btn"
                              on:click={() => addChartToTemp(chart)}
                              disabled={chart.addedToTemp}
                            >
                              <i class="bi bi-plus-circle"></i>
                              Add to Temp
                            </button>
                          {:else if chart.addedToTemp}
                            <span class="status success">
                              <i class="bi bi-check-circle"></i>
                              Added to Temp
                            </span>
                          {:else if chart.status === 'skipped'}
                            <span class="status warning">
                              <i class="bi bi-exclamation-triangle"></i>
                              Skipped: {chart.reason}
                            </span>
                          {:else}
                            <span class="status success">
                              <i class="bi bi-check-circle"></i>
                              Processed
                            </span>
                          {/if}
                        </div>
                      </div>
                    {/each}
                  </div>

                  <div class="save-summary">
                    <h5>Save Results</h5>
                    <div class="save-stats">
                      <div class="stat-item">
                        <span class="label">Successfully Saved:</span>
                        <span class="value success">{result.chartsSaved}</span>
                      </div>
                      {#if result.skippedCharts > 0}
                        <div class="stat-item">
                          <span class="label">Skipped (Already Exist):</span>
                          <span class="value warning">{result.skippedCharts}</span>
                        </div>
                      {/if}
                      {#if result.processingDetails.databaseOperations.failed > 0}
                        <div class="stat-item">
                          <span class="label">Failed to Save:</span>
                          <span class="value error">{result.processingDetails.databaseOperations.failed}</span>
                          <div class="error-details">
                            {#each result.processingDetails.databaseOperations.operationDetails as error}
                              <div class="error-item">
                                <i class="bi bi-x-circle"></i>
                                {error.message}
                              </div>
                            {/each}
                          </div>
                        </div>
                      {/if}
                    </div>
                  </div>
                </div>

                <!-- Sample Preview -->
                {#if result.sample}
                  <div class="sample-preview">
                    <h4>Sample Chart</h4>
                    <a href={result.sample.mediaURL} target="_blank" class="sample-link">
                      <img 
                        src={result.sample.mediaURL} 
                        alt={result.sample.description || 'Sample chart'} 
                        class="sample-image"
                      />
                      <div class="sample-description">{result.sample.description}</div>
                    </a>
                  </div>
                {/if}
              </div>
            {:else}
              <div class="error-message">
                <i class="bi bi-exclamation-circle"></i>
                {result.message}
                {#if result.errors?.length > 0}
                  <div class="error-details">
                    {#each result.errors as error}
                      <div class="error-item">
                        <i class="bi bi-x-circle"></i>
                        {error.message}
                      </div>
                    {/each}
                  </div>
                {/if}
              </div>
            {/if}
          </div>
        {/each}
      </div>
    </div>
  {:else if processing}
    <div class="status-container">
      <p class="status">Waiting for results...</p>
    </div>
  {/if}
</div>

<!-- Search URL Modal -->
{#if searchUrlModalOpen}
<Modal 
  on:close={() => {
    searchUrlModalOpen = false;
    searchUrl = '';
    searchUrlError = null;
  }}
>
  <div slot="header">
    <h2>Load URLs from Search</h2>
  </div>
  
  <div slot="body">
    <div class="search-url-form">
      <div class="form-group">
        <label for="searchUrl">Enter Search URL</label>
        <input 
          type="text" 
          id="searchUrl"
          bind:value={searchUrl}
          placeholder="https://www.pewresearch.org/search/your-search-term"
          disabled={isLoadingSearchUrls}
        />
      </div>
      
      {#if searchUrlError}
        <div class="error-message">
          <i class="bi bi-exclamation-circle"></i>
          {searchUrlError}
        </div>
      {/if}
      
      <div class="form-help">
        <p>Enter a Pew Research search URL to automatically load article URLs from the search results.</p>
        <p>Example: https://www.pewresearch.org/search/executive+orders</p>
      </div>
    </div>
  </div>
  
  <div slot="footer">
    <div class="modal-actions">
      <button 
        class="cancel-btn" 
        on:click={() => {
          searchUrlModalOpen = false;
          searchUrl = '';
          searchUrlError = null;
        }}
        disabled={isLoadingSearchUrls}
      >
        Cancel
      </button>
      <button 
        class="process-btn" 
        on:click={loadUrlsFromSearch}
        disabled={isLoadingSearchUrls}
      >
        {#if isLoadingSearchUrls}
          <i class="bi bi-arrow-repeat spin"></i> Loading...
        {:else}
          <i class="bi bi-search"></i> Load URLs
        {/if}
      </button>
    </div>
  </div>
</Modal>
{/if}

<!-- Move the duplicate chart modal here, outside the content-section -->
{#if duplicateChartModalOpen}
<Modal on:close={closeDuplicateModal}>
  <div slot="header">
    <h2>Duplicate Chart Found</h2>
  </div>
  
  <div slot="body">
    <div class="duplicate-comparison">
      <div class="chart-comparison">
        <div class="chart-column">
          <h3>New Chart</h3>
          {#if currentDuplicateChart}
            <div class="chart-preview-container">
              <img 
                src={currentDuplicateChart.mediaURL} 
                alt="New chart preview"
                class="chart-preview"
              />
              <div class="chart-details">
                <p><strong>Label:</strong> {currentDuplicateChart.label || 'Untitled'}</p>
                <p><strong>Source:</strong> {currentDuplicateChart.sourceLabel || 'Unknown'}</p>
              </div>
            </div>
          {/if}
        </div>
        
        <div class="chart-column">
          <h3>Existing Chart</h3>
          {#if existingChart}
            <div class="chart-preview-container">
              <img 
                src={existingChart.mediaURL} 
                alt="Existing chart preview"
                class="chart-preview"
              />
              <div class="chart-details">
                <p><strong>Label:</strong> {existingChart.label || 'Untitled'}</p>
                <p><strong>Source:</strong> {existingChart.sourceLabel || 'Unknown'}</p>
              </div>
            </div>
          {/if}
        </div>
      </div>
      
      <p class="warning-text">
        <i class="bi bi-exclamation-triangle"></i>
        This chart appears to be a duplicate. Do you want to add it anyway?
      </p>
    </div>
  </div>
  
  <div slot="footer">
    <div class="modal-actions">
      <button class="cancel-btn" on:click={closeDuplicateModal}>
        Cancel
      </button>
      <button class="process-btn" on:click={handleDuplicateConfirm}>
        Add Anyway
      </button>
    </div>
  </div>
</Modal>
{/if}

<style>
  /* Content section styles */
  .content-section {
    background: #ffffff;
    padding: 2rem;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
  }

  .description {
    margin-bottom: 1.5rem;
    color: #4b5563;
    font-size: 0.95rem;
    line-height: 1.6;
  }

  .description ul {
    margin: 0.5rem 0 0 1.5rem;
  }

  .description li {
    margin-bottom: 0.25rem;
  }

  /* URL input container styles */
  .url-input-container {
    margin-bottom: 2rem;
  }

  .url-input-container textarea {
    width: 100%;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    font-size: 0.95rem;
    line-height: 1.5;
    resize: vertical;
    min-height: 200px;
    transition: all 0.2s ease;
  }

  .url-input-container textarea:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }

  .options-container {
    display: flex;
    gap: 2rem;
    margin: 1.5rem 0;
  }

  .option-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #4b5563;
    font-size: 0.95rem;
    cursor: pointer;
  }

  .action-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
  }

  .process-btn {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    background: #2563eb;
    color: #ffffff;
  }

  .process-btn:hover {
    background: #1d4ed8;
  }

  .cancel-btn {
    padding: 0.75rem 1.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #4b5563;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .cancel-btn:hover {
    background: #f1f5f9;
    border-color: #2563eb;
    color: #2563eb;
  }

  /* Progress container styles */
  .progress-container {
    margin-top: 1.5rem;
    background: #f8fafc;
    padding: 1rem;
    border-radius: 0.5rem;
    flex: 1;
  }

  .progress-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
  }

  .progress-text {
    font-size: 0.95rem;
    color: #4b5563;
    font-weight: 500;
  }

  .progress-bar {
    height: 4px;
    background: #e2e8f0;
    border-radius: 2px;
    overflow: hidden;
  }

  .progress-fill {
    height: 100%;
    background: #2563eb;
    transition: width 0.3s ease;
  }

  /* Results section styles */
  .results-section {
    margin-top: 2rem;
    padding: 1.5rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .results-summary {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 2rem;
    padding: 1rem;
    background: #f8fafc;
    border-radius: 6px;
  }

  .summary-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-weight: 500;
  }

  .summary-item.success {
    color: #059669;
    background: #ecfdf5;
  }

  .summary-item.warning {
    color: #d97706;
    background: #fef3c7;
  }

  .summary-item.error {
    color: #dc2626;
    background: #fef2f2;
  }

  .result-item {
    padding: 1rem;
    border-radius: 6px;
    margin-bottom: 1rem;
    background: #f8fafc;
  }

  .result-item.success {
    border-left: 4px solid #059669;
  }

  .result-item.error {
    border-left: 4px solid #dc2626;
  }

  .result-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .url {
    font-family: monospace;
    color: #475569;
    font-size: 0.9rem;
    word-break: break-all;
  }

  .result-details {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .detail-section {
    background: white;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid #e2e8f0;
  }

  .detail-section h4 {
    margin: 0 0 0.75rem 0;
    color: #1e293b;
    font-size: 0.9rem;
  }

  .chart-list {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .chart-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    background: #f8fafc;
    border-radius: 8px;
    font-size: 0.875rem;
    border: 1px solid #e2e8f0;
    gap: 1rem;
  }
  
  .chart-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .chart-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .chart-label {
    font-weight: 500;
    color: #1e293b;
    font-size: 0.9rem;
  }
  
  .chart-dimensions {
    color: #64748b;
    font-size: 0.75rem;
  }
  
  .chart-image-container {
    width: 100%;
    max-width: 600px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #e2e8f0;
    background: white;
  }
  
  .chart-preview {
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
  }
  
  .chart-status {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
    min-width: 120px;
  }
  
  .status {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.75rem;
    white-space: nowrap;
  }
  
  .status.success {
    background: #ecfdf5;
    color: #059669;
  }
  
  .status.warning {
    background: #fef3c7;
    color: #d97706;
  }
  
  .add-btn {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.375rem 0.75rem;
    border: 1px solid #2563eb;
    border-radius: 4px;
    background: #ffffff;
    color: #2563eb;
    font-size: 0.75rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    white-space: nowrap;
  }
  
  .add-btn:hover:not(:disabled) {
    background: #eff6ff;
  }
  
  .add-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: #94a3b8;
    color: #94a3b8;
  }
  
  @media (max-width: 768px) {
    .chart-item {
      flex-direction: column;
    }
    
    .chart-status {
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
    }
  }

  .save-summary {
    margin-top: 1.5rem;
    padding: 1rem;
    background: #f8fafc;
    border-radius: 6px;
  }

  .save-summary h5 {
    margin: 0 0 0.75rem 0;
    font-size: 0.875rem;
    color: #1e293b;
  }

  .save-stats {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .stat-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
  }

  .stat-item .label {
    color: #64748b;
  }

  .stat-item .value {
    font-weight: 500;
    padding: 0.125rem 0.375rem;
    border-radius: 4px;
  }

  .value.success {
    background: #ecfdf5;
    color: #059669;
  }

  .value.warning {
    background: #fef3c7;
    color: #d97706;
  }

  .value.error {
    background: #fef2f2;
    color: #dc2626;
  }

  .error-details {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    color: #dc2626;
  }

  .error-item {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-top: 0.25rem;
  }

  .sample-preview {
    margin-top: 1rem;
    padding: 1rem;
    background: white;
    border-radius: 4px;
    border: 1px solid #e2e8f0;
  }

  .sample-link {
    text-decoration: none;
    color: inherit;
    display: block;
  }

  .sample-link:hover .sample-image {
    transform: scale(1.02);
  }

  .sample-image {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    margin: 0.5rem 0;
    transition: transform 0.2s ease;
  }

  .sample-description {
    font-size: 0.9rem;
    color: #64748b;
    margin: 0.5rem 0 0 0;
  }

  .error-message {
    color: #dc2626;
    font-size: 0.9rem;
    padding: 0.5rem;
    background: #fef2f2;
    border-radius: 4px;
  }

  /* Load button styles */
  .load-btn {
    padding: 0.75rem 1.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #2563eb;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
  }

  .load-btn:hover:not(:disabled) {
    background: #eff6ff;
    border-color: #2563eb;
  }

  .load-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  /* Search URL form styles */
  .search-url-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .form-group label {
    font-size: 0.95rem;
    color: #4b5563;
    font-weight: 500;
  }

  .form-group input {
    padding: 0.75rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    font-size: 0.95rem;
    transition: all 0.2s ease;
  }

  .form-group input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }

  .form-help {
    background: #f8fafc;
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    color: #4b5563;
  }

  .form-help p {
    margin: 0.5rem 0;
  }

  .modal-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }

  .spin {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  /* Status container styles */
  .status-container {
    margin-top: 2rem;
    padding: 2rem;
    background: #f8fafc;
    border-radius: 0.5rem;
    text-align: center;
  }

  .status {
    color: #4b5563;
    font-size: 1rem;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .options-container {
      flex-direction: column;
      gap: 1rem;
    }
    
    .action-buttons {
      flex-direction: column;
    }
    
    .results-summary {
      flex-direction: column;
      gap: 0.75rem;
    }
  }

  .duplicate-comparison {
    padding: 1rem;
  }

  .chart-comparison {
    display: flex;
    gap: 2rem;
    margin-bottom: 1.5rem;
  }

  .chart-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .chart-column h3 {
    margin: 0;
    font-size: 1rem;
    color: #1e293b;
  }

  .chart-preview-container {
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    padding: 1rem;
    background: #f8fafc;
  }

  .chart-preview {
    width: 100%;
    height: auto;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
  }

  .chart-details {
    font-size: 0.9rem;
  }

  .chart-details p {
    margin: 0.5rem 0;
    color: #4b5563;
  }

  .warning-text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #d97706;
    background: #fef3c7;
    padding: 1rem;
    border-radius: 0.5rem;
    margin: 1rem 0;
  }

  .warning-text i {
    font-size: 1.2rem;
  }

  .debug-logs {
    margin-top: 1rem;
    padding: 1rem;
    background: #f8fafc;
    border-radius: 0.5rem;
    border: 1px solid #e2e8f0;
  }

  .debug-logs h5 {
    margin: 0 0 1rem 0;
    color: #1e293b;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .log-section {
    margin-bottom: 1rem;
  }

  .log-section h6 {
    margin: 0 0 0.5rem 0;
    color: #4b5563;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .log-section ul {
    margin: 0;
    padding-left: 1.5rem;
    list-style-type: none;
  }

  .log-section li {
    color: #64748b;
    font-size: 0.8rem;
    font-family: monospace;
    margin-bottom: 0.25rem;
    line-height: 1.4;
  }

  /* Add styles for direct download badge */
  .direct-download-badge {
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.75rem;
    background: #dbeafe;
    color: #1d4ed8;
    font-weight: 500;
    margin-top: 0.25rem;
  }
</style> 