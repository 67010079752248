<!-- src/components/admin/Admin-Stats.svelte -->
<script>
  import { onMount } from 'svelte';
  
  // Props
  export let loading = false;
  export let error = null;
  export let stats = {
    evidence: {
      total: 0,
      byTopic: {},
      bySource: {},
      recentAdditions: []
    },
    articles: {
      total: 0,
      byTopic: {},
      bySource: {},
      recentAdditions: []
    },
    users: {
      total: 0,
      active: 0,
      inactive: 0,
      byRole: {}
    },
    system: {
      lastSync: null,
      lastBackup: null,
      storageUsed: 0,
      storageAvailable: 0
    }
  };
  
  // Event dispatcher
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  
  // Methods
  function handleRefresh() {
    dispatch('refresh');
  }
  
  function formatDate(dateString) {
    if (!dateString) return 'Never';
    return new Date(dateString).toLocaleString();
  }
  
  function formatBytes(bytes) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }
  
  function formatPercentage(value, total) {
    if (total === 0) return '0%';
    return Math.round((value / total) * 100) + '%';
  }
  
  // Sort objects by value (descending)
  function sortByValue(obj) {
    return Object.entries(obj)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5); // Top 5
  }
</script>

<div class="content-section">
  <!-- Header -->
  <div class="header">
    <h2>System Statistics</h2>
    <button 
      class="refresh-btn"
      on:click={handleRefresh}
      disabled={loading}
    >
      <i class="bi {loading ? 'bi-arrow-repeat spin' : 'bi-arrow-clockwise'}"></i>
      {loading ? 'Refreshing...' : 'Refresh Stats'}
    </button>
  </div>
  
  {#if error}
    <div class="error-message">
      <i class="bi bi-exclamation-circle"></i>
      {error}
    </div>
  {/if}
  
  <!-- Stats Grid -->
  <div class="stats-grid">
    <!-- Evidence Stats -->
    <div class="stats-card">
      <div class="card-header">
        <i class="bi bi-graph-up"></i>
        <h3>Evidence</h3>
      </div>
      
      <div class="card-content">
        <div class="stat-item">
          <span class="stat-label">Total Evidence</span>
          <span class="stat-value">{stats.evidence.total}</span>
        </div>
        
        <div class="stat-section">
          <h4>Top Topics</h4>
          {#if Object.keys(stats.evidence.byTopic).length === 0}
            <p class="empty-message">No topic data available</p>
          {:else}
            <ul class="stat-list">
              {#each sortByValue(stats.evidence.byTopic) as [topic, count]}
                <li>
                  <span class="item-label">{topic}</span>
                  <span class="item-value">{count}</span>
                </li>
              {/each}
            </ul>
          {/if}
        </div>
        
        <div class="stat-section">
          <h4>Top Sources</h4>
          {#if Object.keys(stats.evidence.bySource).length === 0}
            <p class="empty-message">No source data available</p>
          {:else}
            <ul class="stat-list">
              {#each sortByValue(stats.evidence.bySource) as [source, count]}
                <li>
                  <span class="item-label">{source}</span>
                  <span class="item-value">{count}</span>
                </li>
              {/each}
            </ul>
          {/if}
        </div>
        
        <div class="stat-section">
          <h4>Recent Additions</h4>
          {#if stats.evidence.recentAdditions.length === 0}
            <p class="empty-message">No recent additions</p>
          {:else}
            <ul class="stat-list">
              {#each stats.evidence.recentAdditions.slice(0, 3) as item}
                <li>
                  <span class="item-label">{item.title || 'Untitled'}</span>
                  <span class="item-date">{formatDate(item.createdAt)}</span>
                </li>
              {/each}
            </ul>
          {/if}
        </div>
      </div>
    </div>
    
    <!-- Articles Stats -->
    <div class="stats-card">
      <div class="card-header">
        <i class="bi bi-file-text"></i>
        <h3>Articles</h3>
      </div>
      
      <div class="card-content">
        <div class="stat-item">
          <span class="stat-label">Total Articles</span>
          <span class="stat-value">{stats.articles.total}</span>
        </div>
        
        <div class="stat-section">
          <h4>Top Topics</h4>
          {#if Object.keys(stats.articles.byTopic).length === 0}
            <p class="empty-message">No topic data available</p>
          {:else}
            <ul class="stat-list">
              {#each sortByValue(stats.articles.byTopic) as [topic, count]}
                <li>
                  <span class="item-label">{topic}</span>
                  <span class="item-value">{count}</span>
                </li>
              {/each}
            </ul>
          {/if}
        </div>
        
        <div class="stat-section">
          <h4>Top Sources</h4>
          {#if Object.keys(stats.articles.bySource).length === 0}
            <p class="empty-message">No source data available</p>
          {:else}
            <ul class="stat-list">
              {#each sortByValue(stats.articles.bySource) as [source, count]}
                <li>
                  <span class="item-label">{source}</span>
                  <span class="item-value">{count}</span>
                </li>
              {/each}
            </ul>
          {/if}
        </div>
        
        <div class="stat-section">
          <h4>Recent Additions</h4>
          {#if stats.articles.recentAdditions.length === 0}
            <p class="empty-message">No recent additions</p>
          {:else}
            <ul class="stat-list">
              {#each stats.articles.recentAdditions.slice(0, 3) as item}
                <li>
                  <span class="item-label">{item.headline || 'Untitled'}</span>
                  <span class="item-date">{formatDate(item.createdAt)}</span>
                </li>
              {/each}
            </ul>
          {/if}
        </div>
      </div>
    </div>
    
    <!-- Users Stats -->
    <div class="stats-card">
      <div class="card-header">
        <i class="bi bi-people"></i>
        <h3>Users</h3>
      </div>
      
      <div class="card-content">
        <div class="stat-item">
          <span class="stat-label">Total Users</span>
          <span class="stat-value">{stats.users.total}</span>
        </div>
        
        <div class="stat-item">
          <span class="stat-label">Active Users</span>
          <span class="stat-value">{stats.users.active}</span>
          <span class="stat-percent">{formatPercentage(stats.users.active, stats.users.total)}</span>
        </div>
        
        <div class="stat-item">
          <span class="stat-label">Inactive Users</span>
          <span class="stat-value">{stats.users.inactive}</span>
          <span class="stat-percent">{formatPercentage(stats.users.inactive, stats.users.total)}</span>
        </div>
        
        <div class="stat-section">
          <h4>By Role</h4>
          {#if Object.keys(stats.users.byRole).length === 0}
            <p class="empty-message">No role data available</p>
          {:else}
            <ul class="stat-list">
              {#each Object.entries(stats.users.byRole) as [role, count]}
                <li>
                  <span class="item-label">{role}</span>
                  <span class="item-value">{count}</span>
                  <span class="item-percent">{formatPercentage(count, stats.users.total)}</span>
                </li>
              {/each}
            </ul>
          {/if}
        </div>
      </div>
    </div>
    
    <!-- System Stats -->
    <div class="stats-card">
      <div class="card-header">
        <i class="bi bi-gear"></i>
        <h3>System</h3>
      </div>
      
      <div class="card-content">
        <div class="stat-item">
          <span class="stat-label">Last Sync</span>
          <span class="stat-value">{formatDate(stats.system.lastSync)}</span>
        </div>
        
        <div class="stat-item">
          <span class="stat-label">Last Backup</span>
          <span class="stat-value">{formatDate(stats.system.lastBackup)}</span>
        </div>
        
        <div class="stat-section">
          <h4>Storage</h4>
          <div class="storage-info">
            <div class="storage-bar">
              <div 
                class="storage-used" 
                style="width: {formatPercentage(stats.system.storageUsed, stats.system.storageUsed + stats.system.storageAvailable)}"
              ></div>
            </div>
            <div class="storage-details">
              <span class="storage-label">Used:</span>
              <span class="storage-value">{formatBytes(stats.system.storageUsed)}</span>
              <span class="storage-label">Available:</span>
              <span class="storage-value">{formatBytes(stats.system.storageAvailable)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  /* Content section styles */
  .content-section {
    background: #ffffff;
    padding: 2rem;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  /* Header styles */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .header h2 {
    margin: 0;
    color: #1e293b;
    font-size: 1.5rem;
  }
  
  .refresh-btn {
    padding: 0.75rem 1.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #2563eb;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .refresh-btn:hover:not(:disabled) {
    background: #eff6ff;
    border-color: #2563eb;
  }
  
  .refresh-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Error message styles */
  .error-message {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    background: #fef2f2;
    border-radius: 0.5rem;
    color: #dc2626;
    margin-bottom: 2rem;
  }
  
  /* Stats grid styles */
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .stats-card {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: all 0.2s ease;
  }
  
  .stats-card:hover {
    border-color: #2563eb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .card-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 1.5rem;
    background: #f8fafc;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .card-header i {
    font-size: 1.25rem;
    color: #2563eb;
  }
  
  .card-header h3 {
    margin: 0;
    color: #1e293b;
    font-size: 1.1rem;
  }
  
  .card-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .stat-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #f1f5f9;
  }
  
  .stat-label {
    color: #64748b;
    font-size: 0.95rem;
  }
  
  .stat-value {
    color: #1e293b;
    font-weight: 600;
    font-size: 1.1rem;
  }
  
  .stat-percent {
    color: #2563eb;
    font-size: 0.85rem;
    font-weight: 500;
    margin-left: 0.5rem;
  }
  
  .stat-section {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .stat-section h4 {
    margin: 0;
    color: #1e293b;
    font-size: 0.95rem;
    font-weight: 600;
  }
  
  .empty-message {
    color: #94a3b8;
    font-size: 0.9rem;
    font-style: italic;
    margin: 0;
  }
  
  .stat-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .stat-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
  }
  
  .item-label {
    color: #475569;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.5rem;
  }
  
  .item-value {
    color: #1e293b;
    font-weight: 500;
  }
  
  .item-date {
    color: #64748b;
    font-size: 0.85rem;
  }
  
  .item-percent {
    color: #2563eb;
    font-size: 0.85rem;
    margin-left: 0.5rem;
  }
  
  /* Storage styles */
  .storage-info {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .storage-bar {
    height: 8px;
    background: #f1f5f9;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .storage-used {
    height: 100%;
    background: #2563eb;
    border-radius: 4px;
  }
  
  .storage-details {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 0.85rem;
  }
  
  .storage-label {
    color: #64748b;
  }
  
  .storage-value {
    color: #1e293b;
    font-weight: 500;
  }
  
  /* Animation */
  .spin {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .stats-grid {
      grid-template-columns: 1fr;
    }
    
    .header {
      flex-direction: column;
      gap: 1rem;
      align-items: stretch;
    }
    
    .refresh-btn {
      justify-content: center;
    }
  }
</style> 