<script>
    import { fade } from 'svelte/transition';
    
    export let headline = null;
    export let isOpen = false;
    export let closeModal = () => {};

    $: formattedDate = headline?.date ? new Date(headline.date).toLocaleDateString() : '';
</script>

<div class="modal-content">
    <div class="modal-header">
        <h2>{headline.title}</h2>
        <button class="close-button" on:click={closeModal}>
            <i class="bi bi-x-lg"></i>
        </button>
    </div>

    <div class="modal-body">
        <div class="article-details">
            {#if headline.urlToImage}
                <div class="image-container">
                    <img src={headline.urlToImage} alt={headline.title} />
                </div>
            {/if}
            
            <div class="metadata">
                <div class="source">{headline.source}</div>
                <div class="date">{formattedDate}</div>
                {#if headline.author}
                    <div class="author">By {headline.author}</div>
                {/if}
            </div>

            <div class="content">
                <p class="description">{headline.description}</p>
                {#if headline.content}
                    <p class="full-content">{headline.content}</p>
                {/if}
            </div>

            <div class="actions">
                <a href={headline.url} target="_blank" rel="noopener noreferrer" class="read-more">
                    Read Full Article <i class="bi bi-box-arrow-up-right"></i>
                </a>
            </div>
        </div>
    </div>
</div>

<style>
    .modal-content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .modal-header {
        padding: 16px;
        border-bottom: 1px solid #e5e7eb;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 16px;
    }

    .modal-header h2 {
        margin: 0;
        font-size: 1.25rem;
        color: #1f2937;
        line-height: 1.4;
        flex: 1;
    }

    .close-button {
        background: none;
        border: none;
        color: #6b7280;
        cursor: pointer;
        padding: 4px;
    }

    .modal-body {
        flex: 1;
        overflow-y: auto;
        padding: 16px;
    }

    .article-details {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .image-container {
        width: 100%;
        max-height: 400px;
        overflow: hidden;
        border-radius: 8px;
    }

    .image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .metadata {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        color: #6b7280;
        font-size: 0.875rem;
    }

    .source {
        color: #2563eb;
        font-weight: 500;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .description {
        font-size: 1.125rem;
        color: #374151;
        line-height: 1.6;
        margin: 0;
    }

    .full-content {
        font-size: 1rem;
        color: #4b5563;
        line-height: 1.6;
        margin: 0;
    }

    .actions {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }

    .read-more {
        display: inline-flex;
        align-items: center;
        gap: 6px;
        padding: 8px 16px;
        background: #2563eb;
        color: white;
        text-decoration: none;
        border-radius: 6px;
        font-size: 0.875rem;
        transition: all 0.2s ease;
    }

    .read-more:hover {
        background: #1d4ed8;
    }
</style> 