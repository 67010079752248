<!-- src/components/Home-AdminConsole.svelte -->
<script>
  import { authStore } from '../stores/authStore.js';
  import { navigate } from "svelte-routing";
  import { onMount, onDestroy } from 'svelte';
  import Modal from './Modal.svelte';
  import AdminBreadcrumb from './AdminBreadcrumb.svelte';
  import AdminDataCollector from './admin/Admin-DataCollector.svelte';
  import AdminDataManager from './admin/Admin-DataManager.svelte';
  import AdminUserManager from './admin/Admin-UserManager.svelte';
  import AdminStats from './admin/Admin-Stats.svelte';
  import AdminBackupManager from './admin/Admin-BackupManager.svelte';
  import AdminPromptManager from './admin/Admin-PromptManager.svelte';

  // Admin tools configuration
  const adminTools = [
    {
      id: 'data-charts',
      label: 'Data Collector',
      icon: 'bi-bar-chart',
      description: 'Extract data visualizations and article content from websites to add to the database'
    },
    {
      id: 'manage-data',
      label: 'Manage Evidence & Articles',
      icon: 'bi-database-gear',
      description: 'View, filter, and delete evidence and articles in the database'
    },
    {
      id: 'prompt-manager',
      label: 'Prompt Manager',
      icon: 'bi-chat-text',
      description: 'Create prompt embeddings and generate new prompts from articles'
    },
    {
      id: 'backup-manager',
      label: 'Backup Manager',
      icon: 'bi-cloud-arrow-down',
      description: 'Create and manage backups of the evidence collection'
    },
    {
      id: 'users',
      label: 'View All Users',
      icon: 'bi-people',
      description: 'Manage user accounts and permissions',
      route: '/admin/users'
    },
    {
      id: 'stats',
      label: 'Platform Statistics',
      icon: 'bi-graph-up',
      description: 'View usage metrics and analytics',
      route: '/admin/stats'
    }
  ];

  // Check if user is admin
  $: isAdmin = $authStore?.user?.isAdmin || false;
  
  // Debug info
  $: console.log('Auth store state:', $authStore);
  $: console.log('Is admin?', isAdmin);
  
  // Current view state
  let currentView = 'home'; // 'home', 'data-charts', 'manage-data'
  
  // Chart scraper state
  let urls = '';
  let processing = false;
  let sessionId = null;
  let progress = 0;
  let results = [];
  let progressInterval = null;
  let totalUrls = 0;
  let processedUrls = 0;
  let overwriteCharts = false;
  let overwriteArticles = false;
  let autoSync = false;
  
  // Management state
  let managementTab = 'evidence'; // 'evidence' or 'articles'
  let evidenceItems = [];
  let articleItems = [];
  let evidencePagination = { page: 1, limit: 20, total: 0, totalPages: 0 };
  let articlesPagination = { page: 1, limit: 20, total: 0, totalPages: 0 };
  let filterSourceURL = '';
  let filterTopic = '';
  let showCustomOnly = false;
  let isLoading = false;
  let deleteConfirmOpen = false;
  let deleteItemType = '';
  let deleteItemId = '';
  let deleteItemSourceURL = '';
  let deleteAllConfirm = false;
  
  // Add these variables to the script section
  let syncingEvidence = false;
  let syncingArticles = false;
  let syncResults = {
    evidence: null,
    articles: null
  };
  let syncError = null;
  
  // Add these variables to the script section
  let searchUrlModalOpen = false;
  let searchUrl = '';
  let isLoadingSearchUrls = false;
  let searchUrlError = null;
  
  onMount(() => {
    console.log('Admin Console component mounted');
  });
  
  // Clean up intervals when component is destroyed
  onDestroy(() => {
    if (progressInterval) {
      clearInterval(progressInterval);
      progressInterval = null;
    }
  });
  
  function handleToolClick(tool) {
    console.log('Tool clicked:', tool);
    if (tool.id === 'data-charts') {
      currentView = 'data-charts';
    } else if (tool.id === 'manage-data') {
      currentView = 'manage-data';
      managementTab = 'evidence';
      loadEvidenceItems();
    } else if (tool.id === 'prompt-manager') {
      currentView = 'prompt-manager';
    } else if (tool.id === 'users') {
      currentView = 'users';
    } else if (tool.id === 'stats') {
      currentView = 'stats';
    } else if (tool.id === 'backup-manager') {
      currentView = 'backup-manager';
    } else if (tool.route) {
      console.log('Navigating to route:', tool.route);
      navigate(tool.route);
    }
  }

  function goBack() {
    currentView = 'home';
    // Reset states
    urls = '';
    processing = false;
    results = [];
    filterSourceURL = '';
    filterTopic = '';
    managementTab = 'evidence';
  }
  
  // Handle keyboard shortcuts
  function handleKeydown(event) {
    // Don't handle shortcuts if user is typing in an input/textarea
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
      return;
    }

    // Check for modifier keys
    const isCtrlOrCmd = event.ctrlKey || event.metaKey;
    const isShift = event.shiftKey;
    const isAlt = event.altKey;

    // Handle different keyboard shortcuts
    switch (event.key.toLowerCase()) {
      case 'escape':
        // Go back to home view
        if (currentView !== 'home') {
          goBack();
        }
        break;

      case 'h':
        if (isCtrlOrCmd) {
          event.preventDefault();
          goBack();
        }
        break;

      case 'd':
        if (isCtrlOrCmd && currentView === 'manage-data') {
          event.preventDefault();
          if (deleteConfirmOpen) {
            closeDeleteConfirm();
          } else {
            openDeleteAllConfirm(managementTab);
          }
        }
        break;

      case 'f':
        if (isCtrlOrCmd && currentView === 'manage-data') {
          event.preventDefault();
          // Focus the source URL filter
          const sourceUrlInput = document.querySelector('input[placeholder="Source URL"]');
          if (sourceUrlInput) {
            sourceUrlInput.focus();
          }
        }
        break;
    }
  }
  
  async function processUrls() {
    // Split and clean URLs
    const urlList = urls
      .split('\n')
      .map(url => url.trim())
      .filter(url => url.length > 0 && url.startsWith('http'));
    
    if (urlList.length === 0) {
      alert('Please enter at least one valid URL');
      return;
    }
    
    processing = true;
    progress = 0;
    sessionId = null;
    results = [];
    
    try {
      console.log('Starting URL processing with options:', {
        overwriteCharts,
        overwriteArticles,
        autoSync
      });
      
      // Start the processing
      const response = await fetch('/api/admin/process-chart-urls', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          urls: urlList,
          options: {
            overwriteCharts: Boolean(overwriteCharts),
            overwriteArticles: Boolean(overwriteArticles),
            autoSync: Boolean(autoSync)
          }
        }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Server error: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Process URLs response:', data);
      
      if (data.success) {
        sessionId = data.sessionId;
        totalUrls = data.totalUrls;
        
        // Verify options were received correctly
        console.log('Server received options:', data.options);
        
        // Set up progress polling
        if (progressInterval) {
          clearInterval(progressInterval);
        }
        progressInterval = setInterval(checkProgress, 2000);
      } else {
        throw new Error(data.error || 'Failed to start processing');
      }
    } catch (error) {
      console.error('Error starting URL processing:', error);
      alert('Failed to start processing: ' + error.message);
    } finally {
      if (!sessionId) {
        processing = false;
      }
    }
  }
  
  async function checkProgress() {
    try {
      if (!sessionId) return;
      
      const response = await fetch(`/api/admin/scraping-progress/${sessionId}`);
      const data = await response.json();
      
      progress = data.progress;
      processedUrls = data.processedUrls;
      totalUrls = data.totalUrls;
      results = data.results;
      
      if (!data.isRunning) {
        clearInterval(progressInterval);
        progressInterval = null;
        processing = false;
        
        // If auto-sync is enabled and processing completed successfully
        if (autoSync && !data.error) {
          await handleAutoSync();
        }
      }
    } catch (error) {
      console.error('Error checking progress:', error);
      clearInterval(progressInterval);
      progressInterval = null;
      processing = false;
    }
  }
  
  async function cancelProcessing() {
    if (!sessionId) return;
    
    try {
      const response = await fetch(`/api/admin/cancel-scraping/${sessionId}`, {
        method: 'POST'
      });
      
      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (data.success) {
        clearInterval(progressInterval);
        progressInterval = null;
        processing = false;
      } else {
        throw new Error(data.error || 'Failed to cancel processing');
      }
    } catch (error) {
      console.error('Error cancelling processing:', error);
      alert('Failed to cancel processing: ' + error.message);
    }
  }

  function formatResultMessage(result) {
    if (!result) return 'No result data';
    
    if (result.success) {
      let message = `Found ${result.chartsFound} charts`;
      
      if (result.chartsSaved !== undefined) {
        message += `, saved ${result.chartsSaved}`;
        
        if (result.skippedCharts > 0) {
          message += `, skipped ${result.skippedCharts} existing`;
        }
      }
      
      if (result.articleFound) {
        message += `, article content extracted`;
      }
      
      if (result.errors?.length > 0) {
        message += ` (with ${result.errors.length} errors)`;
      }
      
      return message;
    } else {
      return result.message || 'Failed to process';
    }
  }

  function getStatusClass(result) {
    if (!result) return '';
    return result.success ? 'success' : 'error';
  }

  // Handle viewing the charts collection
  function openEvidenceCollection() {
    window.location.href = '/evidence/manage';
  }

  async function loadEvidenceItems() {
    isLoading = true;
    try {
      const { page, limit } = evidencePagination;
      let url = `/api/admin/evidence?page=${page}&limit=${limit}`;
      
      if (filterSourceURL) {
        url += `&sourceURL=${encodeURIComponent(filterSourceURL)}`;
      }
      
      if (filterTopic) {
        url += `&topic=${encodeURIComponent(filterTopic)}`;
      }
      
      if (showCustomOnly) {
        url += `&showCustomOnly=true`;
      }
      
      console.log('Loading evidence items with URL:', url);
      const response = await fetch(url);
      const data = await response.json();
      
      if (data.success) {
        evidenceItems = data.data.items;
        // Check if the items have the _id property
        if (evidenceItems.length > 0 && !evidenceItems[0]._id) {
          console.error('Evidence items do not have _id property:', evidenceItems[0]);
        }
        evidencePagination = {
          ...evidencePagination,
          total: data.data.pagination.total,
          totalPages: data.data.pagination.totalPages
        };
        console.log('Loaded evidence items:', {
          items: evidenceItems.length,
          pagination: evidencePagination,
          firstItem: evidenceItems.length > 0 ? evidenceItems[0] : null
        });
      } else {
        console.error('Failed to load evidence items:', data);
      }
    } catch (error) {
      console.error('Error loading evidence:', error);
    } finally {
      isLoading = false;
    }
  }
  
  async function loadArticleItems() {
    isLoading = true;
    try {
      const { page, limit } = articlesPagination;
      let url = `/api/admin/articles?page=${page}&limit=${limit}`;
      
      if (filterSourceURL) {
        url += `&sourceURL=${encodeURIComponent(filterSourceURL)}`;
      }
      
      if (filterTopic) {
        url += `&topic=${encodeURIComponent(filterTopic)}`;
      }
      
      const response = await fetch(url);
      const data = await response.json();
      
      if (data.success) {
        articleItems = data.data.items;
        articlesPagination = {
          ...articlesPagination,
          total: data.data.pagination.total,
          totalPages: data.data.pagination.totalPages
        };
      } else {
        console.error('Failed to load article items');
      }
    } catch (error) {
      console.error('Error loading articles:', error);
    } finally {
      isLoading = false;
    }
  }
  
  function openManagementModal() {
    managementTab = 'evidence';
    loadEvidenceItems();
  }
  
  function changeTab(tab) {
    managementTab = tab;
    if (tab === 'evidence') {
      loadEvidenceItems();
    } else {
      loadArticleItems();
    }
  }
  
  function applyFilters() {
    if (managementTab === 'evidence') {
      evidencePagination.page = 1;
      loadEvidenceItems();
    } else {
      articlesPagination.page = 1;
      loadArticleItems();
    }
  }
  
  function clearFilters() {
    filterSourceURL = '';
    filterTopic = '';
    showCustomOnly = false;
    applyFilters();
  }
  
  function changePage(tab, page) {
    if (tab === 'evidence') {
      evidencePagination.page = page;
      loadEvidenceItems();
    } else {
      articlesPagination.page = page;
      loadArticleItems();
    }
  }
  
  function openDeleteConfirm(type, id, sourceURL) {
    // Don't open a new confirmation if one is already open
    if (deleteConfirmOpen) {
      console.log('Delete confirmation already open, ignoring request');
      return;
    }
    console.log('Opening delete confirm with:', { type, id, sourceURL });
    deleteItemType = type;
    deleteItemId = id;
    deleteItemSourceURL = sourceURL;
    deleteAllConfirm = false;
    deleteConfirmOpen = true;
  }
  
  function openDeleteAllConfirm(type) {
    // Don't open a new confirmation if one is already open
    if (deleteConfirmOpen) {
      console.log('Delete confirmation already open, ignoring request');
      return;
    }
    deleteItemType = type;
    deleteItemId = '';
    deleteItemSourceURL = '';
    deleteAllConfirm = true;
    deleteConfirmOpen = true;
  }

  function closeDeleteConfirm() {
    deleteConfirmOpen = false;
    deleteItemType = '';
    deleteItemId = '';
    deleteItemSourceURL = '';
    deleteAllConfirm = false;
  }
  
  async function confirmDelete() {
    try {
      const endpoint = deleteItemType === 'evidence' ? 
        '/api/admin/evidence' : 
        '/api/admin/articles';
      
      const body = {};
      
      if (deleteAllConfirm) {
        // For delete all operations, set the deleteAll flag
        body.deleteAll = true;
        console.log('Performing delete all operation for:', deleteItemType);
      } else if (deleteItemId) {
        // For single item deletion by ID
        body.id = deleteItemId;
        console.log('Performing single item deletion by ID:', deleteItemId);
      } else if (deleteItemSourceURL) {
        // For deletion by source URL
        body.sourceURL = deleteItemSourceURL;
        console.log('Performing deletion by source URL:', deleteItemSourceURL);
      }
      
      console.log('Deleting item with data:', {
        endpoint,
        body,
        deleteItemType,
        deleteItemId,
        deleteItemSourceURL,
        deleteAllConfirm
      });
      
      const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      
      console.log('Delete response status:', response.status);
      const responseText = await response.text();
      console.log('Delete response text:', responseText);
      
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        console.error('Error parsing response:', e);
        throw new Error('Invalid response from server');
      }
      
      if (data.success) {
        // Reload the data
        if (deleteItemType === 'evidence') {
          loadEvidenceItems();
        } else {
          loadArticleItems();
        }
        
        // Close the confirm dialog and reset all state
        closeDeleteConfirm();
      } else {
        throw new Error(data.message || 'Failed to delete item(s)');
      }
    } catch (error) {
      console.error('Error deleting item(s):', error);
      alert(`Error: ${error.message || 'Failed to delete item(s)'}`);
      // Make sure to close the dialog even if there's an error
      closeDeleteConfirm();
    }
  }

  // Add these functions to the script section
  async function syncEvidence() {
    syncingEvidence = true;
    try {
      const response = await fetch('/api/admin/sync-evidence', {
        method: 'POST'
      });
      const data = await response.json();
      syncResults.evidence = data;
      if (data.success) {
        loadEvidenceItems(); // Refresh the list
      }
    } catch (error) {
      console.error('Error syncing evidence:', error);
    } finally {
      syncingEvidence = false;
    }
  }

  async function syncArticles() {
    syncingArticles = true;
    try {
      const response = await fetch('/api/admin/sync-articles', {
        method: 'POST'
      });
      const data = await response.json();
      syncResults.articles = data;
      if (data.success) {
        loadArticleItems(); // Refresh the list
      }
    } catch (error) {
      console.error('Error syncing articles:', error);
    } finally {
      syncingArticles = false;
    }
  }

  // Add function to handle auto-sync after processing
  async function handleAutoSync() {
    try {
      console.log('Starting auto-sync of evidence...');
      syncingEvidence = true;
      
      const response = await fetch('/api/admin/sync-evidence', {
        method: 'POST'
      });
      
      if (!response.ok) {
        throw new Error(`Failed to sync evidence: ${response.status}`);
      }
      
      const result = await response.json();
      console.log('Sync result:', result);
      
      if (result.success) {
        alert(`Successfully synced ${result.stats.inserted} new and updated ${result.stats.updated} existing evidence items.`);
      } else {
        throw new Error(result.message || 'Failed to sync evidence');
      }
    } catch (error) {
      console.error('Error during auto-sync:', error);
      alert('Failed to auto-sync evidence: ' + error.message);
    } finally {
      syncingEvidence = false;
    }
  }

  // Add this function to handle loading URLs from search
  async function loadUrlsFromSearch() {
    if (!searchUrl) {
      searchUrlError = 'Please enter a search URL';
      return;
    }

    isLoadingSearchUrls = true;
    searchUrlError = '';

    try {
      const response = await fetch('/api/admin/load-search-urls', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ searchUrl })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to load URLs');
      }

      if (!data.success || !data.urls || !Array.isArray(data.urls)) {
        throw new Error('Invalid response format from server');
      }

      // Append new URLs to existing ones, avoiding duplicates
      const existingUrls = urls.split('\n').filter(url => url.trim());
      const newUrls = data.urls.filter(url => !existingUrls.includes(url));
      
      if (newUrls.length > 0) {
        // Add a newline if there are existing URLs
        const separator = existingUrls.length > 0 ? '\n' : '';
        urls = existingUrls.join('\n') + separator + newUrls.join('\n');
      }
      
      // Close modal and reset state
      searchUrlModalOpen = false;
      searchUrl = '';
      
    } catch (error) {
      console.error('Error loading URLs:', error);
      searchUrlError = error.message;
    } finally {
      isLoadingSearchUrls = false;
    }
  }
</script>

<svelte:window on:keydown={handleKeydown}/>

{#if isAdmin}
  <div class="admin-console">
    <div class="admin-header">
      <h2>Admin Console</h2>
      <p class="text-muted">Manage content and system settings</p>
    </div>

    <div class="admin-content">
      {#if currentView === 'home'}
        <div class="tools-grid">
          {#each adminTools as tool}
            <div class="tool-card" on:click={() => handleToolClick(tool)}>
              <div class="tool-icon">
                <i class={tool.icon}></i>
              </div>
              <div class="tool-info">
                <h3>{tool.label}</h3>
                <p>{tool.description}</p>
              </div>
            </div>
          {/each}
        </div>
      {:else}
        <!-- Add breadcrumb navigation -->
        <nav aria-label="breadcrumb" class="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="#" on:click|preventDefault={() => goBack()}>
                <i class="bi bi-house-door"></i> Admin Console
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {#if currentView === 'data-charts'}
                Data Collector
              {:else if currentView === 'manage-data'}
                Manage Evidence & Articles
              {:else if currentView === 'prompt-manager'}
                Prompt Manager
              {:else if currentView === 'users'}
                User Manager
              {:else if currentView === 'stats'}
                Platform Statistics
              {:else if currentView === 'backup-manager'}
                Backup Manager
              {:else}
                {currentView}
              {/if}
            </li>
          </ol>
        </nav>

        {#if currentView === 'data-charts'}
          <AdminDataCollector />
        {:else if currentView === 'manage-data'}
          <AdminDataManager 
            evidenceItems={evidenceItems}
            articleItems={articleItems}
            currentTab={managementTab}
            loading={isLoading}
            syncing={syncingEvidence || syncingArticles}
            syncResults={syncResults}
            syncError={syncError}
            sourceUrlFilter={filterSourceURL}
            topicFilter={filterTopic}
            showCustomOnly={showCustomOnly}
            appliedFilters={filterSourceURL !== '' || filterTopic !== '' || showCustomOnly}
            currentPage={managementTab === 'evidence' ? evidencePagination.page : articlesPagination.page}
            itemsPerPage={managementTab === 'evidence' ? evidencePagination.limit : articlesPagination.limit}
            totalItems={managementTab === 'evidence' ? evidencePagination.total : articlesPagination.total}
            on:tabChange={({ detail }) => {
              managementTab = detail.tab;
              if (detail.tab === 'evidence') {
                loadEvidenceItems();
              } else {
                loadArticleItems();
              }
            }}
            on:filter={({ detail }) => {
              filterSourceURL = detail.sourceUrl;
              filterTopic = detail.topic;
              showCustomOnly = detail.showCustomOnly;
              if (managementTab === 'evidence') {
                evidencePagination.page = 1;
                loadEvidenceItems();
              } else {
                articlesPagination.page = 1;
                loadArticleItems();
              }
            }}
            on:clearFilters={() => {
              filterSourceURL = '';
              filterTopic = '';
              showCustomOnly = false;
              if (managementTab === 'evidence') {
                evidencePagination.page = 1;
                loadEvidenceItems();
              } else {
                articlesPagination.page = 1;
                loadArticleItems();
              }
            }}
            on:pageChange={({ detail }) => {
              if (managementTab === 'evidence') {
                evidencePagination.page = detail.page;
                loadEvidenceItems();
              } else {
                articlesPagination.page = detail.page;
                loadArticleItems();
              }
            }}
            on:sync={({ detail }) => {
              if (detail.type === 'evidence') {
                syncEvidence();
              } else {
                syncArticles();
              }
            }}
            on:deleteItem={({ detail }) => {
              console.log('Delete item event received:', detail);
              if (!detail.item || !detail.item.id) {
                console.error('Missing required id in delete item event:', detail);
                alert('Error: Missing required id for deletion');
                return;
              } else {
                // Check if a confirmation dialog is already open
                if (deleteConfirmOpen) {
                  console.log('Delete confirmation already open, ignoring request');
                  return;
                }
                openDeleteConfirm(detail.type, detail.item.id, detail.item.sourceURL);
              }
            }}
            on:deleteAll={({ detail }) => {
              console.log('Delete all event received:', detail);
              // Check if a confirmation dialog is already open
              if (deleteConfirmOpen) {
                console.log('Delete confirmation already open, ignoring request');
                return;
              }
              if (detail.action === 'deleteAll') {
                openDeleteAllConfirm(detail.type);
              } else {
                console.warn('Received deleteAll event without proper action type:', detail);
              }
            }}
          />
        {:else if currentView === 'prompt-manager'}
          <AdminPromptManager />
        {:else if currentView === 'backup-manager'}
          <AdminBackupManager />
        {:else if currentView === 'users'}
          <AdminUserManager />
        {:else if currentView === 'stats'}
          <AdminStats />
        {/if}
      {/if}
    </div>
  </div>
{:else}
  <div class="access-denied">
    <i class="bi bi-shield-lock"></i>
    <h3>Access Denied</h3>
    <p>You don't have permission to access the admin console.</p>
    <div class="debug-info">
      <p>Debug Info:</p>
      <pre>{JSON.stringify($authStore?.user || {}, null, 2)}</pre>
    </div>
  </div>
{/if}

<!-- Delete Confirmation Modal -->
{#if deleteConfirmOpen}
<Modal 
  on:close={closeDeleteConfirm}
>
  <div slot="header">
    <h2>Confirm Deletion</h2>
  </div>
  
  <div slot="body">
    <div class="delete-warning">
      <i class="bi bi-exclamation-triangle"></i>
      <p>This action cannot be undone!</p>
    </div>
    
    {#if deleteAllConfirm}
      <p>Are you sure you want to delete <strong>ALL {deleteItemType === 'evidence' ? 'evidence items' : 'articles'}</strong> from the database?</p>
      <p class="delete-detail">This will permanently remove all {deleteItemType === 'evidence' ? 'evidence items' : 'articles'} from the system.</p>
    {:else if deleteItemSourceURL}
      <p>Are you sure you want to delete all {deleteItemType === 'evidence' ? 'evidence items' : 'articles'} from this source URL?</p>
      <p class="delete-detail"><strong>Source URL:</strong> {deleteItemSourceURL}</p>
    {:else if deleteItemId}
      <p>Are you sure you want to delete this {deleteItemType === 'evidence' ? 'evidence item' : 'article'}?</p>
      <p class="delete-detail"><strong>ID:</strong> {deleteItemId}</p>
    {/if}
  </div>
  
  <div slot="footer">
    <div class="confirm-actions">
      <button 
        class="cancel-btn" 
        on:click={closeDeleteConfirm}
      >
        Cancel
      </button>
      <button 
        class="danger-btn" 
        on:click={confirmDelete}
      >
        <i class="bi bi-trash"></i> {deleteAllConfirm ? 'Delete All' : 'Delete'}
      </button>
    </div>
  </div>
</Modal>
{/if}

<!-- Add this at the end of the file, before the closing </svelte> tag -->
{#if searchUrlModalOpen}
<Modal 
  on:close={() => {
    searchUrlModalOpen = false;
    searchUrl = '';
    searchUrlError = null;
  }}
>
  <div slot="header">
    <h2>Load URLs from Search</h2>
  </div>
  
  <div slot="body">
    <div class="search-url-form">
      <div class="form-group">
        <label for="searchUrl">Enter Search URL</label>
        <input 
          type="text" 
          id="searchUrl"
          bind:value={searchUrl}
          placeholder="https://www.pewresearch.org/search/your-search-term"
          disabled={isLoadingSearchUrls}
        />
      </div>
      
      {#if searchUrlError}
        <div class="error-message">
          <i class="bi bi-exclamation-circle"></i>
          {searchUrlError}
        </div>
      {/if}
      
      <div class="form-help">
        <p>Enter a Pew Research search URL to automatically load article URLs from the search results.</p>
        <p>Example: https://www.pewresearch.org/search/executive+orders</p>
      </div>
    </div>
  </div>
  
  <div slot="footer">
    <div class="modal-actions">
      <button 
        class="cancel-btn" 
        on:click={() => {
          searchUrlModalOpen = false;
          searchUrl = '';
          searchUrlError = null;
        }}
        disabled={isLoadingSearchUrls}
      >
        Cancel
      </button>
      <button 
        class="process-btn" 
        on:click={loadUrlsFromSearch}
        disabled={isLoadingSearchUrls}
      >
        {#if isLoadingSearchUrls}
          <i class="bi bi-arrow-repeat spin"></i> Loading...
        {:else}
          <i class="bi bi-search"></i> Load URLs
        {/if}
      </button>
    </div>
  </div>
</Modal>
{/if}

<style lang="postcss">
  /* Base container styles */
  .admin-console {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    background: #ffffff;
    overflow: hidden;
    position: relative;
    padding: 1.5rem;
    gap: 1.5rem;
  }

  /* Header styles */
  .admin-header {
    padding: 1.5rem;
    background: #ffffff;
    border-bottom: 1px solid #eaeaea;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .admin-header h2 {
    margin: 0;
    font-size: 1.75rem;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 1.2;
  }

  .admin-header p {
    margin: 0.5rem 0 0 0;
    color: #666;
    font-size: 0.95rem;
    line-height: 1.5;
  }

  /* Content area styles */
  .admin-content {
    flex: 1;
    overflow-y: auto;
    padding: 0.5rem;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
    will-change: transform;
  }

  /* Tool card styles */
  .tools-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    padding: 1rem;
  }

  .tool-card {
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 0.75rem;
    padding: 1.5rem;
    transition: all 0.2s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .tool-card:hover {
    border-color: #2563eb;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }

  .tool-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 0.75rem;
    background: #f8fafc;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
  }

  .tool-card:hover .tool-icon {
    background: #eff6ff;
  }

  .tool-icon i {
    font-size: 1.5rem;
    color: #2563eb;
  }

  .tool-info {
    flex: 1;
  }

  .tool-info h3 {
    color: #1a1a1a;
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    line-height: 1.3;
  }

  .tool-info p {
    color: #666;
    font-size: 0.95rem;
    line-height: 1.5;
    margin: 0;
  }

  /* Content section styles */
  .content-section {
    background: #ffffff;
    padding: 2rem;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    margin-bottom: 2rem;
  }

  /* Tabs styles */
  .tabs {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 1rem;
  }

  .tabs button {
    background: none;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 0.95rem;
    color: #666;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 0.5rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .tabs button:hover {
    background: #f8fafc;
    color: #2563eb;
  }

  .tabs button.active {
    color: #2563eb;
    background: #eff6ff;
  }

  .tabs button i {
    font-size: 1.1rem;
  }

  /* Sync buttons styles */
  .sync-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }

  .sync-btn {
    padding: 0.75rem 1.5rem;
    border: 1px solid #eaeaea;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #666;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
  }

  .sync-btn:hover {
    background: #f8fafc;
    border-color: #2563eb;
    color: #2563eb;
  }

  .sync-btn.syncing {
    background: #f8fafc;
    border-color: #2563eb;
    color: #2563eb;
  }

  .sync-btn.syncing i {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /* Sync results styles */
  .sync-results {
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .sync-results.success {
    background: #f0fdf4;
    border: 1px solid #bbf7d0;
    color: #166534;
  }

  .sync-results.error {
    background: #fef2f2;
    border: 1px solid #fecaca;
    color: #991b1b;
  }

  .sync-results i {
    font-size: 1.25rem;
  }

  .sync-stats {
    display: flex;
    gap: 1.5rem;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }

  /* Filters styles */
  .filters-section {
    background: #f8fafc;
    border-radius: 0.75rem;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .filters {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    align-items: flex-end;
  }

  .filter-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
    min-width: 250px;
  }

  .filter-group label {
    font-size: 0.9rem;
    font-weight: 500;
    color: #4b5563;
  }

  .filters input {
    padding: 0.75rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    font-size: 0.95rem;
    transition: all 0.2s ease;
    background: #ffffff;
  }

  .filters input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }

  .filter-actions {
    display: flex;
    gap: 0.75rem;
    margin-left: auto;
  }

  .filter-btn {
    padding: 0.75rem 1.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #4b5563;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
  }

  .filter-btn:hover {
    background: #f1f5f9;
    border-color: #2563eb;
    color: #2563eb;
  }

  .filter-btn.secondary {
    color: #6b7280;
  }

  /* Bulk actions styles */
  .bulk-actions {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
  }

  .danger-btn {
    padding: 0.75rem 1.5rem;
    border: 1px solid #fee2e2;
    border-radius: 0.5rem;
    background: #fef2f2;
    color: #dc2626;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
  }

  .danger-btn:hover {
    background: #fee2e2;
    border-color: #fecaca;
  }

  /* Items container styles */
  .items-container {
    margin-bottom: 2rem;
  }

  .items-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 1.5rem;
  }

  .item {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 0.75rem;
    padding: 1.5rem;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .item:hover {
    border-color: #2563eb;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transform: translateY(-2px);
  }

  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    gap: 1rem;
  }

  .item-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #1e293b;
    line-height: 1.4;
    flex: 1;
  }

  .item-actions {
    display: flex;
    gap: 0.5rem;
  }

  .delete-btn {
    background: none;
    border: none;
    color: #dc2626;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .delete-btn:hover {
    background: #fee2e2;
  }

  .item-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
  }

  .detail {
    display: flex;
    gap: 0.75rem;
    font-size: 0.95rem;
    color: #64748b;
    line-height: 1.5;
  }

  .detail .label {
    font-weight: 500;
    color: #475569;
    min-width: 80px;
  }

  .detail a {
    color: #2563eb;
    text-decoration: none;
    word-break: break-all;
  }

  .detail a:hover {
    text-decoration: underline;
  }

  .item-preview {
    margin-top: 1rem;
    border-radius: 0.5rem;
    overflow: hidden;
    border: 1px solid #e2e8f0;
  }

  .item-preview img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .article-preview {
    margin-top: 1rem;
    padding: 1rem;
    background: #f8fafc;
    border-radius: 0.5rem;
    border: 1px solid #e2e8f0;
    flex: 1;
  }

  .preview-heading {
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: #334155;
  }

  .preview-content {
    font-size: 0.95rem;
    color: #64748b;
    line-height: 1.6;
    max-height: 150px;
    overflow-y: auto;
  }

  /* Loading and no items styles */
  .loading, .no-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    background: #f8fafc;
    border-radius: 0.75rem;
    border: 1px dashed #e2e8f0;
  }

  .spinner {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(37, 99, 235, 0.1);
    border-radius: 50%;
    border-top-color: #2563eb;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }

  .no-items p {
    color: #64748b;
    font-size: 1rem;
  }

  /* Pagination styles */
  .pagination {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #e2e8f0;
  }

  .pagination-info {
    color: #64748b;
    font-size: 0.95rem;
  }

  .pagination-controls {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  .page-btn {
    padding: 0.5rem 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    background: #ffffff;
    color: #4b5563;
    font-size: 0.9rem;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-btn:hover:not(:disabled) {
    background: #f1f5f9;
    border-color: #2563eb;
    color: #2563eb;
  }

  .page-btn.active {
    background: #2563eb;
    border-color: #2563eb;
    color: #ffffff;
  }

  .page-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .items-list {
      grid-template-columns: 1fr;
    }
    
    .filters {
      flex-direction: column;
      gap: 1rem;
    }
    
    .filter-actions {
      margin-left: 0;
      width: 100%;
      justify-content: space-between;
    }
    
    .filter-btn {
      flex: 1;
      justify-content: center;
    }
    
    .bulk-actions {
      flex-direction: column;
    }
    
    .danger-btn {
      width: 100%;
      justify-content: center;
    }
    
    .sync-buttons {
      flex-direction: column;
    }
    
    .sync-btn {
      width: 100%;
      justify-content: center;
    }
  }

  /* Management header styles */
  .management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 1rem;
  }

  .sync-buttons {
    display: flex;
    gap: 1rem;
  }

  .sync-btn {
    padding: 0.75rem 1.5rem;
    border: 1px solid #eaeaea;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #2563eb;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
  }

  .sync-btn:hover:not(:disabled) {
    background: #eff6ff;
    border-color: #2563eb;
  }

  .sync-btn.syncing {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .sync-btn.syncing i {
    animation: spin 1s linear infinite;
  }

  .sync-results {
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 0.95rem;
  }

  .sync-results.success {
    background: #ecfdf5;
    color: #059669;
    border: 1px solid #a7f3d0;
  }

  .sync-results.error {
    background: #fef2f2;
    color: #dc2626;
    border: 1px solid #fecaca;
  }

  .sync-stats {
    margin-left: auto;
    display: flex;
    gap: 1rem;
    font-size: 0.9rem;
    color: #666;
  }

  .filters-section {
    background: #f8fafc;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
  }

  .filter-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .filter-group label {
    font-size: 0.9rem;
    color: #666;
    font-weight: 500;
  }

  .filter-actions {
    display: flex;
    gap: 0.5rem;
    align-items: flex-end;
  }

  .chart-list {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .chart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    background: #f8fafc;
    border-radius: 4px;
    font-size: 0.875rem;
  }

  .chart-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .chart-label {
    font-weight: 500;
    color: #1e293b;
  }

  .chart-dimensions {
    color: #64748b;
    font-size: 0.75rem;
  }

  .chart-status {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .status {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-weight: 500;
  }

  .status.success {
    background: #ecfdf5;
    color: #059669;
  }

  .status.warning {
    background: #fef3c7;
    color: #d97706;
  }

  .save-summary {
    margin-top: 1.5rem;
    padding: 1rem;
    background: #f8fafc;
    border-radius: 6px;
  }

  .save-summary h5 {
    margin: 0 0 0.75rem 0;
    font-size: 0.875rem;
    color: #1e293b;
  }

  .save-stats {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .stat-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
  }

  .stat-item .label {
    color: #64748b;
  }

  .stat-item .value {
    font-weight: 500;
    padding: 0.125rem 0.375rem;
    border-radius: 4px;
  }

  .value.success {
    background: #ecfdf5;
    color: #059669;
  }

  .value.warning {
    background: #fef3c7;
    color: #d97706;
  }

  .value.error {
    background: #fef2f2;
    color: #dc2626;
  }

  .error-details {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    color: #dc2626;
  }

  .error-item {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-top: 0.25rem;
  }

  .sample-link {
    text-decoration: none;
    color: inherit;
    display: block;
  }

  .sample-link:hover .sample-image {
    transform: scale(1.02);
  }

  .sample-image {
    transition: transform 0.2s ease;
  }

  /* Load button styles */
  .load-btn {
    padding: 0.75rem 1.5rem;
    border: 1px solid #eaeaea;
    border-radius: 0.5rem;
    background: #ffffff;
    color: #2563eb;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
  }

  .load-btn:hover:not(:disabled) {
    background: #eff6ff;
    border-color: #2563eb;
  }

  .load-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  /* Search URL form styles */
  .search-url-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .form-group label {
    font-size: 0.95rem;
    color: #666;
    font-weight: 500;
  }

  .form-group input {
    padding: 0.75rem 1rem;
    border: 1px solid #eaeaea;
    border-radius: 0.5rem;
    font-size: 0.95rem;
    transition: all 0.2s ease;
  }

  .form-group input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }

  .form-help {
    background: #f8fafc;
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    color: #666;
  }

  .form-help p {
    margin: 0.5rem 0;
  }

  .modal-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }

  .spin {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
</style> 