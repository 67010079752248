<!-- BreadcrumbNav.svelte -->
<script>
  import { createEventDispatcher } from 'svelte';
  import { getContext } from 'svelte';
  
  export let currentSlide;
  export let slides = [];
  export let currentSlideIndex;
  
  const dispatch = createEventDispatcher();
  const storyStore = getContext("storyStore");
  
  let isEditingTitle = false;
  let isDropdownOpen = false;
  let dropdownTrigger;
  let dropdownMenu;
  let editInput;
  
  // Add default title handling
  $: slideTitle = currentSlide?.title || `Slide ${currentSlideIndex + 1}`;
  
  $: if (isEditingTitle && editInput) {
    editInput.focus();
    editInput.select();
  }

  function handleTitleEdit() {
    isEditingTitle = true;
  }

  function handleTitleSave(event) {
    if (event.type === 'blur' || (event.type === 'keydown' && event.key === 'Enter')) {
      isEditingTitle = false;
      dispatch('updateTitle', { title: slideTitle });
    } else if (event.type === 'keydown' && event.key === 'Escape') {
      isEditingTitle = false;
      slideTitle = currentSlide?.title || `Slide ${currentSlideIndex + 1}`; // Reset to original
    }
  }

  function handleSlideSelect(index) {
    isDropdownOpen = false;
    dispatch('selectSlide', { index });
  }

  function handleClickOutside(event) {
    if (isDropdownOpen && dropdownMenu && !dropdownMenu.contains(event.target) && 
        !dropdownTrigger.contains(event.target)) {
      isDropdownOpen = false;
    }
  }

  function handleNavigateToGrid() {
    // Include story ID in navigation if available
    const storyId = $storyStore?._id;
    dispatch('navigateToGrid', { storyId });
  }

  function toggleDropdown(event) {
    event.stopPropagation();
    isDropdownOpen = !isDropdownOpen;
  }
</script>

<svelte:window on:click={handleClickOutside} />

<nav class="breadcrumb-nav" role="navigation" aria-label="Breadcrumb navigation">
  <div class="breadcrumb-list">
    <!-- All Slides Link -->
    <button 
      class="breadcrumb-item home-item" 
      on:click={handleNavigateToGrid}
      aria-label="Return to all slides"
    >
      <i class="fas fa-th-large" aria-hidden="true"></i>
      <span>All Slides</span>
    </button>

    <span class="separator" aria-hidden="true">/</span>

    <!-- Current Slide -->
    <div class="breadcrumb-item current-item">
      <div class="slide-title-container">
        {#if isEditingTitle}
          <!-- Edit Mode -->
          <input
            bind:this={editInput}
            bind:value={slideTitle}
            class="title-input"
            on:blur={handleTitleSave}
            on:keydown={handleTitleSave}
            aria-label="Edit slide title"
          />
        {:else}
          <!-- View Mode -->
          <button 
            class="title-button"
            on:click={handleTitleEdit}
            aria-label="Click to edit slide title"
          >
            <span class="title-text">{slideTitle}</span>
            <i class="fas fa-pencil edit-icon" aria-hidden="true"></i>
          </button>
        {/if}

        <!-- Slide Selector -->
        <button 
          bind:this={dropdownTrigger}
          class="slide-select-button" 
          on:click={toggleDropdown}
          aria-haspopup="listbox"
          aria-expanded={isDropdownOpen}
          aria-label="Select slide"
        >
          <i class="fas fa-chevron-down" class:rotated={isDropdownOpen} aria-hidden="true"></i>
        </button>

        <!-- Dropdown Menu -->
        <div
          bind:this={dropdownMenu}
          class="slide-dropdown"
          class:open={isDropdownOpen}
          role="listbox"
          tabindex="-1"
          aria-label="Slide list"
        >
          {#each slides as slide, index}
            <button
              class="dropdown-item"
              class:active={index === currentSlideIndex}
              role="option"
              aria-selected={index === currentSlideIndex}
              on:click|stopPropagation={() => handleSlideSelect(index)}
            >
              <span class="item-number">#{index + 1}</span>
              <span class="item-title">{slide?.title || `Slide ${index + 1}`}</span>
              {#if index === currentSlideIndex}
                <i class="fas fa-check" aria-hidden="true"></i>
              {/if}
            </button>
          {/each}
        </div>
      </div>
    </div>
  </div>
</nav>

<style>
  .breadcrumb-nav {
    padding: 0.75rem 1.5rem;
    background: white;
    border-bottom: 1px solid #e2e8f0;
    height: 56px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1000;
  }

  .breadcrumb-list {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    height: 100%;
  }

  .breadcrumb-item {
    display: flex;
    align-items: center;
    position: relative;
  }

  .home-item {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    color: #64748b;
    font-size: 0.875rem;
    font-weight: 500;
    border: none;
    background: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .home-item:hover {
    background-color: #f1f5f9;
    color: #334155;
  }

  .home-item i {
    font-size: 1rem;
  }

  .separator {
    color: #94a3b8;
    font-size: 1.25rem;
    font-weight: 300;
    user-select: none;
  }

  .current-item {
    flex: 1;
    min-width: 0;
  }

  .slide-title-container {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    max-width: 400px;
    position: relative;
  }

  .title-button {
    flex: 1;
    min-width: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    background: none;
    border: none;
    border-radius: 6px;
    color: #0f172a;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .title-button:hover {
    background-color: #f1f5f9;
  }

  .title-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .edit-icon {
    font-size: 0.75rem;
    color: #94a3b8;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  .title-button:hover .edit-icon {
    opacity: 1;
  }

  .title-input {
    flex: 1;
    min-width: 0;
    padding: 0.5rem 0.75rem;
    background: #f8fafc;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.875rem;
    font-weight: 500;
    color: #0f172a;
    transition: all 0.2s ease;
  }

  .title-input:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  }

  .slide-select-button {
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: none;
    border: none;
    border-radius: 4px;
    color: #64748b;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .slide-select-button:hover {
    background-color: #f1f5f9;
    color: #334155;
  }

  .slide-dropdown {
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    width: 280px;
    max-height: 400px;
    overflow-y: auto;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 1001;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.2s ease;
  }

  .slide-dropdown.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .fa-chevron-down {
    transition: transform 0.2s ease;
  }

  .fa-chevron-down.rotated {
    transform: rotate(180deg);
  }

  .dropdown-item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.625rem 1rem;
    background: none;
    border: none;
    text-align: left;
    font-size: 0.875rem;
    color: #334155;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .dropdown-item:hover {
    background-color: #f8fafc;
  }

  .dropdown-item.active {
    background-color: #f0f9ff;
    color: #0284c7;
  }

  .item-number {
    flex-shrink: 0;
    font-size: 0.75rem;
    font-weight: 500;
    color: #64748b;
  }

  .item-title {
    flex: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dropdown-item i {
    flex-shrink: 0;
    font-size: 0.875rem;
  }

  /* Scrollbar styling */
  .slide-dropdown {
    scrollbar-width: thin;
    scrollbar-color: #cbd5e1 #f1f5f9;
  }

  .slide-dropdown::-webkit-scrollbar {
    width: 6px;
  }

  .slide-dropdown::-webkit-scrollbar-track {
    background: #f1f5f9;
    border-radius: 3px;
  }

  .slide-dropdown::-webkit-scrollbar-thumb {
    background-color: #cbd5e1;
    border-radius: 3px;
  }
</style> 