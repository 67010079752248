import { writable } from 'svelte/store';
import { promptStore } from './promptStore';

// Constants
const API_ENDPOINTS = {
    SEARCH: '/api/news/search'
};

// Rate limiting constants
let lastFetchTimestamp = 0;
const FETCH_COOLDOWN = 2000; // 2 seconds cooldown between fetches

// Source name mapping based on NewsAPI source IDs
const SOURCE_NAMES = {
    'pew': 'Pew Research',
    'pewresearch': 'Pew Research'
};

// Types
/**
 * @typedef {Object} Article
 * @property {string} title
 * @property {string} description
 * @property {string} source
 * @property {string} url
 * @property {string} date
 * @property {string} urlToImage
 * @property {string} content
 * @property {string} author
 */

// Create stores for headlines and saved headlines
export const headlinesStore = writable({
    headlines: [],
    savedHeadlines: [],
    isLoading: false,
    error: null,
    lastSearchQuery: '',
    filteredHeadlines: [],
    searchTimestamp: null,
    message: null,
    suggestedTerms: null
});

// Utility function to check if we should allow a new search
const canMakeNewSearch = (lastTimestamp) => {
    if (!lastTimestamp) return true;
    // Allow new search after 1 second (adjust as needed)
    return Date.now() - lastTimestamp > 1000;
};

/**
 * Transforms raw article data into consistent format
 * @param {Object} article - Raw article data from API
 * @returns {Article} Formatted article
 */
const formatArticle = (article) => ({
    title: article.title || '',
    description: article.description || '',
    source: SOURCE_NAMES[article.source?.id] || 
           article.source?.name || 
           'Unknown Source',
    url: article.url || '',
    date: article.publishedAt || new Date().toISOString(),
    urlToImage: article.urlToImage || '',
    content: article.content || '',
    author: article.author || ''
});

// Function to filter existing headlines
export function filterHeadlines(searchQuery = '') {
    headlinesStore.update(store => {
        if (!searchQuery.trim()) {
            return {
                ...store,
                filteredHeadlines: store.headlines
            };
        }

        const searchTerms = searchQuery.toLowerCase().split(' ');
        const filtered = store.headlines.filter(headline => {
            const searchableText = [
                headline.title,
                headline.description,
                headline.source,
                headline.content
            ].join(' ').toLowerCase();

            return searchTerms.every(term => searchableText.includes(term));
        });

        return {
            ...store,
            filteredHeadlines: filtered
        };
    });
}

// Function to initialize headlines with topic
export async function initializeHeadlines() {
    // Get the current topic from promptStore
    let currentTopic;
    promptStore.subscribe(store => {
        currentTopic = store.selectedPromptDetails?.topic;
    })();
    
    if (!currentTopic) {
        console.log('❌ No topic selected');
        headlinesStore.update(store => ({
            ...store,
            error: 'Please select a topic first'
        }));
        return;
    }

    // Check if we're within the cooldown period
    const now = Date.now();
    if (now - lastFetchTimestamp < FETCH_COOLDOWN) {
        console.log('🕒 Skipping fetch - within cooldown period');
        return;
    }

    console.log('🔍 Initializing headlines with topic:', currentTopic);
    lastFetchTimestamp = now;
    await fetchHeadlines(currentTopic, true);
}

// Add utility function to clean query
const cleanSearchQuery = (query) => {
    return query
        ?.replace(/[?]/g, '') // Remove question marks
        .split(' ')
        .slice(0, 6) // Take first 6 words to get main concept
        .join(' ')
        .trim() || '';
};

// Function to fetch headlines from News API
export async function fetchHeadlines(searchQuery, isInitialSearch = false) {
    try {
        // Check if we're within the cooldown period
        const now = Date.now();
        if (!isInitialSearch && now - lastFetchTimestamp < FETCH_COOLDOWN) {
            console.log('🕒 Skipping fetch - within cooldown period');
            return;
        }
        lastFetchTimestamp = now;

        console.log('🔍 Initiating headline search:', searchQuery);

        if (!searchQuery || searchQuery.trim().length < 3) {
            console.log('❌ Search query too short');
            headlinesStore.update(store => ({
                ...store,
                headlines: [],
                error: 'Search query must be at least 3 characters'
            }));
            return;
        }

        // Clean the search query
        const cleanQuery = isInitialSearch ? searchQuery : cleanSearchQuery(searchQuery);

        headlinesStore.update(store => ({
            ...store,
            isLoading: true,
            error: null,
            lastSearchQuery: cleanQuery,
            searchTimestamp: Date.now()
        }));

        const searchUrl = `${API_ENDPOINTS.SEARCH}?q=${encodeURIComponent(cleanQuery)}`;
        console.log('🌐 Fetching from:', {
            url: searchUrl,
            fullUrl: window.location.origin + searchUrl
        });

        const response = await fetch(searchUrl, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });

        console.log('📊 Response details:', {
            status: response.status,
            statusText: response.statusText,
            headers: Object.fromEntries(response.headers),
            url: response.url
        });

        console.log('📊 Response status:', response.status);
        const contentType = response.headers.get('content-type');
        console.log('📄 Content-Type:', contentType);

        if (!contentType || !contentType.includes('application/json')) {
            console.error('❌ Invalid content type received');
            throw new Error(`Expected JSON response but got ${contentType}`);
        }

        if (!response.ok) {
            const errorData = await response.json();
            console.error('❌ API error:', errorData);
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('📦 Received data:', { 
            status: data.status, 
            articleCount: data.articles?.length || 0 
        });
        
        if (data.status === 'ok' && Array.isArray(data.articles)) {
            const headlines = data.articles.map(formatArticle);
            console.log(`✅ Successfully processed ${headlines.length} articles`);
            
            headlinesStore.update(store => ({
                ...store,
                headlines,
                filteredHeadlines: headlines,
                isLoading: false,
                error: null
            }));
        } else {
            console.error('❌ News API returned error:', data);
            throw new Error(data.message || 'Failed to fetch headlines');
        }
    } catch (error) {
        console.error('❌ Error fetching headlines:', error);
        // Get suggested terms from the current search query
        const suggestedTerms = searchQuery
            ? cleanSearchQuery(searchQuery).split(' ').filter(term => term.length > 2)
            : [];

        headlinesStore.update(store => ({
            ...store,
            headlines: [],
            isLoading: false,
            error: error.message,
            searchTimestamp: null,
            message: 'No articles found. Try simplifying your search or using different keywords.',
            suggestedTerms
        }));
    }
}

// Function to save a headline
export function saveHeadline(headline) {
    headlinesStore.update(store => {
        const savedHeadlines = [...store.savedHeadlines, headline];
        return {
            ...store,
            savedHeadlines
        };
    });
}

// Function to remove a saved headline
export function removeSavedHeadline(headlineUrl) {
    headlinesStore.update(store => {
        const savedHeadlines = store.savedHeadlines.filter(h => h.url !== headlineUrl);
        return {
            ...store,
            savedHeadlines
        };
    });
} 