import { writable } from 'svelte/store';

export const chatStore = writable({
  key: 0, // This will be used to force a refresh of the chat component
});

export function resetChat() {
  chatStore.update(state => ({
    ...state,
    key: state.key + 1
  }));
} 