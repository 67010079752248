import { writable, get, derived } from 'svelte/store';
import { apiService } from '../services/apiService.js'; 
import { searchEvidence, selectEvidence, deselectEvidence } from './evidenceStore.js'; // Replace fetchEvidenceByTopic with searchEvidence

export const promptStore = writable({
  selectedPromptId: null,
  selectedPromptDetails: null,
  sortedEvidenceDetails: [],
  sortedEvidenceIds: [],
  selectedEvidenceIds: [],
  evidence: [],
  prompts: [],
  savedPrompts: [],
  pagination: {
    currentPage: 1,
    itemsPerPage: 9, // Shows 3x3 grid
    totalPages: 1
  }
});

// Add derived store for bigQuestion
export const bigQuestion = derived(promptStore, $store => 
  $store.selectedPromptDetails?.bigQuestion || ''
);

setupTestingEnv();
function setupTestingEnv(){
  promptStore.update(store => ({
    ...store,
    selectedPromptDetails: {
      "_id": "default_climate_prompt", // Added an ID since the store expects one
      "sourceURL": "https://www.pewresearch.org/science/2019/11/25/u-s-public-views-on-climate-and-energy/",
      "statistical_mentions": [
        "About two-thirds of U.S. adults (67%) say the federal government is doing too little to reduce the effects of climate change, and similar shares say the same about government efforts to protect air (67%) and water quality (68%) – findings that are consistent with results from a2018 Center survey. While there is strong consensus among Democrats (90%, including independents who lean to the Democratic Party) on the need for more government efforts to reduce the effects of climate change, Republican views are divided along ideological, generational and gender lines. A majority of moderate or liberal Republicans (65%, including GOP-leaning independents) say the federal government is doing too little to reduce the effects of climate change. In contrast, only about one-quarter of conservative Republicans (24%) say the same, while about half (48%) think the government is doing about the right amount and another 26% say it is doing too much.",
        "About two-thirds of U.S. adults (67%) say the federal government is doing too little to reduce the effects of climate change, and similar shares say the same about government efforts to protect air (67%) and water quality (68%) – findings that are consistent with results from a2018 Center survey. While there is strong consensus among Democrats (90%, including independents who lean to the Democratic Party) on the need for more government efforts to reduce the effects of climate change, Republican views are divided along ideological, generational and gender lines. A majority of moderate or liberal Republicans (65%, including GOP-leaning independents) say the federal government is doing too little to reduce the effects of climate change. In contrast, only about one-quarter of conservative Republicans (24%) say the same, while about half (48%) think the government is doing about the right amount and another 26% say it is doing too much. There are also divides by age. Among younger Republicans – adults in theMillennial generation and Generation Z, ages 18 to 38 in 2019 – 52% think the government is doing too little on climate. By comparison, 41% among Generation X and 31% of Baby Boomer and older Americans say this. Republican women (46%) also are more inclined than GOP men (34%) to think the government's efforts on climate are insufficient.",
        "While there is strong consensus among Democrats (90%, including independents who lean to the Democratic Party) on the need for more government efforts to reduce the effects of climate change, Republican views are divided along ideological, generational and gender lines. A majority of moderate or liberal Republicans (65%, including GOP-leaning independents) say the federal government is doing too little to reduce the effects of climate change. In contrast, only about one-quarter of conservative Republicans (24%) say the same, while about half (48%) think the government is doing about the right amount and another 26% say it is doing too much. There are also divides by age. Among younger Republicans – adults in theMillennial generation and Generation Z, ages 18 to 38 in 2019 – 52% think the government is doing too little on climate. By comparison, 41% among Generation X and 31% of Baby Boomer and older Americans say this. Republican women (46%) also are more inclined than GOP men (34%) to think the government's efforts on climate are insufficient."
      ],
      "article_context": "Majorities of Americans say the federal government is doing too little for key aspects of the environment, from protecting water or air quality to reducing the effects of climate change. And most believe the United States should focus on developing alternative sources of energy over expansion of fossil fuel sources, according to a new Pew Research Center survey.",
      "article_date": "2019",
      "bigQuestion": "How do people's political views affect what they think the government should do about climate change?",
      "promptScript": "Hey, did you know that a recent study from 2019 found that most Americans think the government needs to do more to fight climate change? But when you look at people's political views, things get really interesting. Most Democrats want the government to take stronger action, but Republicans are split. Younger and more moderate Republicans are more likely to say the government should do more, while older and more conservative Republicans often think the government is already doing enough or even too much. What do you think about that? Do you think people's political views really should play a big role in how the government addresses climate change?",
      "topics": [
        {
          "topicTag": "climate-energy-environment",
          "topicLabel": "Climate & Energy"
        }
      ],
      "variation_index": 0
    }
  }));
}

export const fetchPrompts = async () => {
  try {
    const prompts = await apiService.getPrompts();
    promptStore.update(store => {
      store.prompts = prompts;
      store.pagination.totalPages = Math.ceil(prompts.length / store.pagination.itemsPerPage);
      return store;
    });
  } catch (error) {
    console.error('Error fetching prompts:', error.message, error.stack);
  }
};

export const setSelectedPrompt = async (promptId) => {
  try {
    promptStore.update((state) => ({
      ...state,
      selectedPromptId: promptId
    }));

    const promptDetails = await apiService.getPromptDetails(promptId);
    
    promptStore.update((state) => ({
      ...state,
      selectedPromptDetails: promptDetails
    }));

    // Extract the topic tag correctly from the topics array
    const topicTag = promptDetails.topics?.[0]?.topicTagStatic || 
                    promptDetails.topics?.[0]?.topicTag || 
                    (typeof promptDetails.topics?.[0] === 'string' ? promptDetails.topics[0] : null) ||
                    'general';

    // Search using the topic tag
    if (topicTag) {
      console.log('Searching with topic tag:', topicTag); // Debug log
      await searchEvidence(topicTag);
    }

  } catch (error) {
    console.error('Failed to fetch prompt details:', error);
    promptStore.update((state) => ({
      ...state,
      selectedPromptId: null,
      selectedPromptDetails: null
    }));
    throw error;
  }
};

export const updatePagination = (totalItems, itemsPerPage) => {
  promptStore.update(store => ({
    ...store,
    pagination: {
      ...store.pagination,
      totalPages: Math.ceil(totalItems / itemsPerPage)
    }
  }));
};

// Add this validation function at the top
function isValidPrompt(prompt) {
  return prompt && 
    typeof prompt === 'object' &&
    prompt._id &&
    prompt.bigQuestion &&
    prompt.promptScript &&
    Array.isArray(prompt.topics);
}

export const savePrompt = (prompt) => {
  // Validate prompt before saving
  if (!isValidPrompt(prompt)) {
    console.error('Invalid prompt format:', prompt);
    return;
  }

  promptStore.update(store => {
    const exists = store.savedPrompts.some(p => p._id === prompt._id);
    if (!exists) {
      // Ensure we only save the required fields
      const cleanPrompt = {
        _id: prompt._id,
        bigQuestion: prompt.bigQuestion,
        promptScript: prompt.promptScript,
        topics: prompt.topics
      };
      const updatedPrompts = [...store.savedPrompts, cleanPrompt];
      localStorage.setItem('savedPrompts', JSON.stringify(updatedPrompts));
      return {
        ...store,
        savedPrompts: updatedPrompts
      };
    }
    return store;
  });
};

export const removeSavedPrompt = (promptId) => {
  promptStore.update(store => {
    const updatedPrompts = store.savedPrompts.filter(p => p._id !== promptId);
    // Save to localStorage
    localStorage.setItem('savedPrompts', JSON.stringify(updatedPrompts));
    return {
      ...store,
      savedPrompts: updatedPrompts
    };
  });
};

export const loadSavedPrompts = () => {
  const saved = localStorage.getItem('savedPrompts');
  if (saved) {
    try {
      const parsedPrompts = JSON.parse(saved);
      // Validate each prompt
      const validPrompts = Array.isArray(parsedPrompts) 
        ? parsedPrompts.filter(isValidPrompt)
        : [];
      
      // If we found invalid prompts, update storage with only valid ones
      if (validPrompts.length !== parsedPrompts.length) {
        localStorage.setItem('savedPrompts', JSON.stringify(validPrompts));
      }

      promptStore.update(store => ({
        ...store,
        savedPrompts: validPrompts
      }));
    } catch (error) {
      console.error('Error loading saved prompts:', error);
      localStorage.removeItem('savedPrompts');
      promptStore.update(store => ({
        ...store,
        savedPrompts: []
      }));
    }
  }
};

