<script>
  import { onMount } from 'svelte';

  let linkGenerated = false;
  let classroomLink = "";
  let nickname = "";
  let error = "";
  let selectedTopics = [];
  let topics = [];

  async function fetchPrompts() {
    try {
      const response = await fetch('/api/prompts');
      if (!response.ok) throw new Error(response.statusText);
      const prompts = await response.json();

      // Create a Map to deduplicate topics
      const topicsMap = new Map();

      // Flatten and collect unique topics
      prompts.forEach(prompt => {
        prompt.topics.forEach(topic => {
          if (!topicsMap.has(topic.topicTag)) {
            topicsMap.set(topic.topicTag, {
              tag: topic.topicTag,
              label: topic.topicLabel
            });
          }
        });
      });

      // Convert Map values to array and sort by label
      topics = Array.from(topicsMap.values())
        .sort((a, b) => a.label.localeCompare(b.label));
      console.log('Topics populated:', topics);
    } catch (error) {
      console.error('Error fetching prompts:', error);
    }
  }

  onMount(() => {
    fetchPrompts();
  });

  function generateRandomId(length = 6) {
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({ length }, () => chars[Math.floor(Math.random() * chars.length)]).join('');
  }

  function validateNickname(value) {
    // Only allow alphanumeric characters and hyphens
    return /^[a-zA-Z0-9-]*$/.test(value);
  }

  function handleNicknameInput(event) {
    const value = event.target.value;
    if (validateNickname(value)) {
      nickname = value.toLowerCase();
      error = "";
    } else {
      error = "Only letters, numbers, and hyphens allowed";
    }
  }

  function handleTopicChange(event) {
    const { value, checked } = event.target;
    if (checked) {
      selectedTopics = [...selectedTopics, value];
    } else {
      selectedTopics = selectedTopics.filter(topic => topic !== value);
    }
  }

  function generateLink() {
    if (!nickname) {
      error = "Please enter a classroom nickname";
      return;
    }
    const randomId = generateRandomId();
    classroomLink = `https://dataremixer.edu/tool?room=${nickname}&id=${randomId}`;
    linkGenerated = true;
  }

  function copyLink() {
    navigator.clipboard.writeText(classroomLink);
  }

  console.log('Initial topics:', topics);
</script>

<div class="classroom-link-container">
  <div class="content">
    <h1>Generate Classroom Link</h1>
    <p>Create a shareable link for your students to join your classroom.</p>
    
    <div class="nickname-input-container">
      <label for="nickname">Classroom Nickname</label>
      <input 
        type="text" 
        id="nickname"
        maxlength="10"
        placeholder="e.g., room-101"
        value={nickname}
        on:input={handleNicknameInput}
        class:error={error}
      />
      {#if error}
        <span class="error-message">{error}</span>
      {/if}
      <span class="char-count">{nickname.length}/10</span>
    </div>

    <div class="topics-input-container">
      <label for="topics">Select Topics</label>
      <div class="topics-list">
        {#each topics as topic}
          <div class="topic-checkbox">
            <input 
              type="checkbox" 
              id={topic.tag} 
              value={topic.tag} 
              on:change={handleTopicChange}
            />
            <label for={topic.tag}>{topic.label}</label>
          </div>
        {/each}
      </div>
    </div>
    
    {#if !linkGenerated}
      <button 
        class="generate-button" 
        on:click={generateLink}
        disabled={!nickname || error}
      >
        <i class="bi bi-link-45deg"></i>
        Generate Link
      </button>
    {:else}
      <div class="link-display">
        <input type="text" readonly value={classroomLink} />
        <button class="copy-button" on:click={copyLink}>
          <i class="bi bi-clipboard"></i>
          Copy
        </button>
      </div>
    {/if}
  </div>
</div>

<style>
  .classroom-link-container {
    padding: 2rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    text-align: center;
    max-width: 600px;
  }

  h1 {
    font-size: 2rem;
    color: #1a1a1a;
    margin-bottom: 1rem;
  }

  p {
    color: #666;
    margin-bottom: 2rem;
    font-size: 1.1rem;
  }

  .generate-button, .copy-button {
    background: #2563eb;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .generate-button:hover, .copy-button:hover {
    background: #1d4ed8;
    transform: translateY(-1px);
  }

  .link-display {
    display: flex;
    gap: 8px;
    margin-top: 1rem;
  }

  input {
    flex: 1;
    padding: 12px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 1rem;
    color: #4b5563;
  }

  .copy-button {
    background: #059669;
  }

  .copy-button:hover {
    background: #047857;
  }

  .nickname-input-container {
    margin-bottom: 2rem;
    position: relative;
  }

  .topics-input-container {
    margin-bottom: 2rem;
  }

  .topics-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    height: 200px;
    overflow-y: scroll;
  }

  .topic-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    background: #f9fafb;
    padding: 0.5rem;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
  }

  label {
    margin-left: 0.5rem;
    color: #4b5563;
    font-size: 0.875rem;
    font-weight: 500;
  }

  input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
  }

  input, select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.2s ease;
  }

  input:focus, select:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }

  input.error {
    border-color: #ef4444;
  }

  .error-message {
    color: #ef4444;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    display: block;
  }

  .char-count {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    font-size: 0.75rem;
    color: #6b7280;
  }

  .generate-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
</style>