<script>
  import { onMount } from 'svelte';
  import { authStore } from '../stores/authStore.js';
  import { storyService } from '../services/storyService.js';
  import { navigate } from 'svelte-routing';
  
  let stories = [];
  let isLoading = true;
  let error = null;
  let editingStoryId = null;
  let editTitle = '';
  let selectedStories = new Set();
  let selectAll = false;
  let viewMode = 'grid'; // 'grid' or 'list'
  let searchQuery = '';
  let filteredStories = [];

  // Load stories when component mounts and user is authenticated
  $: if ($authStore.isAuthenticated && isLoading) {
    loadStories();
  }

  // Filter stories based on search query
  $: {
    if (stories.length > 0) {
      filteredStories = stories.filter(story => 
        story.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        story.slides?.some(slide => slide.content?.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    } else {
      filteredStories = [];
    }
  }

  // Handle select all toggle
  $: if (stories.length > 0) {
    if (selectAll) {
      // Select either all stories or all filtered stories if search is being used
      const storiesToSelect = searchQuery ? filteredStories : stories;
      selectedStories = new Set(storiesToSelect.map(story => story._id));
    } else if (selectedStories.size === (searchQuery ? filteredStories.length : stories.length)) {
      selectedStories = new Set();
    }
  }

  // Also add at component initialization
  $: if (editingStoryId) {
    console.log('editingStoryId changed to:', editingStoryId);
  }

  async function loadStories() {
    try {
      stories = await storyService.getUserStories();
      stories = stories.filter(story => story._id);
      error = null;
      editingStoryId = null;
    } catch (err) {
      console.error('Error loading stories:', err);
      error = 'Failed to load stories. Please try again.';
    } finally {
      isLoading = false;
    }
  }

  function formatDate(dateString) {
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  }

  function handleStoryClick(storyId) {
    if (selectedStories.size > 0) {
      toggleStorySelection(null, storyId);
    } else {
      editingStoryId = null;
      navigate(`/grid?story=${storyId}`);
    }
  }

  function toggleStorySelection(event, storyId) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (selectedStories.has(storyId)) {
      selectedStories.delete(storyId);
    } else {
      selectedStories.add(storyId);
    }
    selectedStories = selectedStories;
    
    // Update selectAll based on whether all stories or all filtered stories are selected
    const relevantStories = searchQuery ? filteredStories : stories;
    selectAll = selectedStories.size === relevantStories.length && relevantStories.length > 0;
  }

  async function deleteSelectedStories() {
    const validStories = Array.from(selectedStories).filter(id => id !== null);
    
    if (validStories.length === 0) return;
    
    if (confirm(`Are you sure you want to delete ${validStories.length} ${validStories.length === 1 ? 'story' : 'stories'}?`)) {
      try {
        for (const storyId of validStories) {
          await storyService.deleteStory(storyId);
        }
        // Filter out deleted stories
        stories = stories.filter(story => !selectedStories.has(story._id));
        // Update filtered stories based on the new stories array
        filteredStories = stories.filter(story => 
          story.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          story.slides?.some(slide => slide.content?.toLowerCase().includes(searchQuery.toLowerCase()))
        );
        selectedStories = new Set();
        selectAll = false;
      } catch (error) {
        console.error('Error deleting stories:', error);
      }
    }
  }

  function startEditingTitle(event, story) {
    if (!story._id) return;
    event.stopPropagation();
    editingStoryId = story._id;
    editTitle = story.title;
  }

  async function saveTitle(story) {
    if (editTitle.trim() === '' || !story._id) return;
    try {
      await storyService.updateStory(story._id, {
        ...story,
        title: editTitle.trim()
      });
      stories = stories.map(s => 
        s._id === story._id ? { ...s, title: editTitle.trim() } : s
      );
    } catch (error) {
      console.error('Error updating title:', error);
    } finally {
      editingStoryId = null;
    }
  }

  function handleTitleKeydown(event, story) {
    if (event.key === 'Enter') {
      saveTitle(story);
    } else if (event.key === 'Escape') {
      editingStoryId = null;
    }
  }

  function handleClickOutside(event) {
    if (!event.target.closest('.story-item')) {
      editingStoryId = null;
    }
  }

  function clearSelection() {
    selectedStories = new Set();
    selectAll = false;
  }
</script>

<div class="stories-container" on:click={handleClickOutside}>
  {#if !$authStore.isAuthenticated}
    <div class="auth-prompt">
      <div class="auth-content">
        <i class="bi bi-lock"></i>
        <h2>Sign in to view your stories</h2>
        <button class="btn-primary" on:click={() => authStore.loginWithGoogle()}>
          <i class="bi bi-google me-2"></i>
          Sign in with Google
        </button>
      </div>
    </div>
  {:else if isLoading}
    <div class="loading-state">
      <div class="spinner"></div>
      <span>Loading your stories...</span>
    </div>
  {:else if error}
    <div class="error-state">
      <i class="bi bi-exclamation-circle"></i>
      <p>{error}</p>
      <button class="btn-secondary" on:click={() => { isLoading = true; }}>
        Try Again
      </button>
    </div>
  {:else}
    <div class="stories-header">
      <div class="header-left">
        {#if selectedStories.size > 0}
          <div class="selection-controls">
            <button 
              class="btn-icon" 
              on:click={clearSelection}
              title="Clear selection"
            >
              <i class="bi bi-x-lg"></i>
            </button>
            <span class="selected-count">
              {selectedStories.size} selected
            </span>
            <button class="btn-danger" on:click={deleteSelectedStories}>
              <i class="bi bi-trash"></i>
              Delete
            </button>
          </div>
        {:else}
          <div class="search-bar">
            <i class="bi bi-search"></i>
            <input 
              type="text" 
              placeholder="Search stories..." 
              bind:value={searchQuery}
            >
            {#if searchQuery}
              <button class="btn-icon" on:click={() => searchQuery = ''}>
                <i class="bi bi-x"></i>
              </button>
            {/if}
          </div>
        {/if}
      </div>
      <div class="header-right">
        {#if (searchQuery ? filteredStories.length > 0 : stories.length > 0)}
          <div class="select-all-control">
            <label class="select-all-label">
              <input 
                type="checkbox" 
                bind:checked={selectAll} 
                title="Select all stories"
              />
              <span>Select All{searchQuery ? ' Results' : ''}</span>
            </label>
          </div>
        {/if}
        <div class="view-toggle">
          <button 
            class="btn-icon" 
            class:active={viewMode === 'grid'} 
            on:click={() => viewMode = 'grid'}
            title="Grid view"
          >
            <i class="bi bi-grid"></i>
          </button>
          <button 
            class="btn-icon" 
            class:active={viewMode === 'list'} 
            on:click={() => viewMode = 'list'}
            title="List view"
          >
            <i class="bi bi-list"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="stories-content">
      {#if stories.length === 0}
        <div class="empty-state">
          <i class="bi bi-journal-text"></i>
          <h3>No stories yet</h3>
          <p>Create your first data story to get started</p>
          <button class="btn-primary" on:click={() => navigate('/grid')}>
            <i class="bi bi-plus-lg"></i>
            Create Story
          </button>
        </div>
      {:else if filteredStories.length === 0}
        <div class="empty-state">
          <i class="bi bi-search"></i>
          <h3>No results found</h3>
          <p>Try adjusting your search terms</p>
          <button class="btn-secondary" on:click={() => searchQuery = ''}>
            Clear Search
          </button>
        </div>
      {:else}
        <div class="stories-grid" class:list-view={viewMode === 'list'}>
          {#each filteredStories as story (story._id)}
            <div 
              class="story-item"
              class:selected={selectedStories.has(story._id)}
              on:click={() => handleStoryClick(story._id)}
            >
              <div class="story-checkbox" on:click|stopPropagation>
                <input 
                  type="checkbox"
                  checked={selectedStories.has(story._id)}
                  on:change={() => toggleStorySelection(null, story._id)}
                />
              </div>
              
              <div class="story-content">
                <div class="story-icon">
                  <i class="bi bi-file-earmark-text"></i>
                </div>
                
                <div class="story-info">
                  {#if editingStoryId === story._id}
                    <textarea
                      class="title-input"
                      bind:value={editTitle}
                      on:blur={() => saveTitle(story)}
                      on:keydown={(e) => handleTitleKeydown(e, story)}
                      rows="1"
                      on:input={(e) => {
                        e.target.style.height = 'auto';
                        e.target.style.height = e.target.scrollHeight + 'px';
                      }}
                      autofocus
                    ></textarea>
                  {:else}
                    <h3 class="story-title" on:dblclick={(e) => startEditingTitle(e, story)}>
                      {story.title || 'Untitled Story'}
                    </h3>
                  {/if}
                  
                  <div class="story-meta">
                    <span class="slides-count">
                      <i class="bi bi-collection"></i>
                      {story.slides?.length || 0} slides
                    </span>
                    <span class="date">
                      <i class="bi bi-clock"></i>
                      {formatDate(story.updatedAt)}
                    </span>
                  </div>
                </div>

                <div class="story-actions">
                  <button 
                    class="btn-icon" 
                    title="Edit title"
                    on:click={(e) => startEditingTitle(e, story)}
                  >
                    <i class="bi bi-pencil"></i>
                  </button>
                </div>
              </div>
            </div>
          {/each}
        </div>
      {/if}
    </div>
  {/if}
</div>

<style>
  .stories-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: #f8fafc;  /* Light gray background like Google Drive */
    overflow: hidden;
  }

  .stories-header {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1.5rem;
    background: white;
    border-bottom: 1px solid #e2e8f0;
    position: sticky;
    top: 0;
    z-index: 10;
    height: 64px; /* Fixed header height like Google Drive */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .header-left {
    flex: 1;
    min-width: 0;
  }

  .header-right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .select-all-control {
    display: flex;
    align-items: center;
  }

  .select-all-label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: #64748b;
    cursor: pointer;
    user-select: none;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    transition: all 0.15s ease;
  }

  .select-all-label:hover {
    background: #f1f5f9;
    color: #0f172a;
  }

  .select-all-label input[type="checkbox"] {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 2px solid #cbd5e1;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    position: relative;
    background: white;
    transition: all 0.15s ease;
  }

  .select-all-label input[type="checkbox"]:checked {
    background: #2563eb;
    border-color: #2563eb;
  }

  .select-all-label input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .search-bar {
    display: flex;
    align-items: center;
    background: #f8fafc;
    border-radius: 8px;
    padding: 0.625rem 1rem;
    max-width: 720px;
    width: 100%;
    border: 1px solid #e2e8f0;
    transition: all 0.2s ease;
  }

  .search-bar:focus-within {
    background: white;
    border-color: #cbd5e1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  .search-bar i {
    color: #64748b;
    font-size: 0.875rem;
  }

  .search-bar input {
    border: none;
    background: none;
    outline: none;
    width: 100%;
    font-size: 0.9375rem;
    color: #0f172a;
    margin: 0 0.75rem;
  }

  .search-bar input::placeholder {
    color: #94a3b8;
  }

  .view-toggle {
    display: flex;
    gap: 0.25rem;
    padding: 0.25rem;
    border-radius: 6px;
    background: #f8fafc;
  }

  .view-toggle .btn-icon {
    width: 32px;
    height: 32px;
  }

  .view-toggle .btn-icon.active {
    background: white;
    color: #2563eb;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .stories-content {
    flex: 1;
    overflow-y: auto;
    padding: 1rem 1.5rem;
    height: calc(100vh - 64px); /* Subtract header height */
  }

  .stories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Smaller cards like Drive */
    gap: 1rem;
    height: auto;
    align-content: start;
  }

  .stories-grid.list-view {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .story-item {
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    transition: all 0.15s ease;
    cursor: pointer;
    position: relative;
    display: flex;
    padding: 1rem;
    min-height: 48px;
  }

  .story-item:hover {
    background: #f8fafc;
    border-color: #cbd5e1;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .story-item.selected {
    background: #eff6ff;
    border-color: #3b82f6;
  }

  /* Google Drive-like list view styles */
  .list-view .story-item {
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    margin: 0;
    padding: 0.5rem 1rem;
  }

  .list-view .story-item:last-child {
    border-bottom: none;
  }

  .list-view .story-item:hover {
    background: #f8fafc;
  }

  .list-view .story-content {
    flex-direction: row;
    align-items: center;
  }

  .list-view .story-icon {
    width: 36px;
    height: 36px;
    font-size: 1.25rem;
  }

  .list-view .story-info {
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }

  .list-view .story-title {
    flex: 1;
    margin: 0;
    -webkit-line-clamp: 1;
  }

  .list-view .story-meta {
    margin: 0;
    flex-shrink: 0;
    min-width: 200px;
  }

  .story-checkbox {
    position: absolute;
    top: 0.75rem;
    left: 0.75rem;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  .story-checkbox input[type="checkbox"] {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid #cbd5e1;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    position: relative;
    background: white;
    transition: all 0.15s ease;
  }

  .story-checkbox input[type="checkbox"]:checked {
    background: #2563eb;
    border-color: #2563eb;
  }

  .story-checkbox input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .story-item:hover .story-checkbox,
  .story-item.selected .story-checkbox {
    opacity: 1;
  }

  .story-content {
    display: flex;
    align-items: flex-start;
    gap: 1.25rem;  /* Increased gap */
    flex: 1;
  }

  .story-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #3b82f6;
    font-size: 1.25rem;
    flex-shrink: 0;
    background: #eff6ff;
    transition: all 0.2s ease;
  }

  .story-item:hover .story-icon {
    color: #2563eb;
    background: #dbeafe;
  }

  .story-info {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .story-title {
    font-size: 1.125rem;  /* Increased size */
    font-weight: 500;
    color: #333;
    margin: 0;
    line-height: 1.4;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .story-meta {
    display: flex;
    gap: 1.25rem;  /* Increased gap */
    color: #999;  /* Lighter text */
    font-size: 0.875rem;
    margin-top: 0.75rem;  /* Added margin */
  }

  .story-meta i {
    font-size: 0.75rem;
    margin-right: 0.25rem;
  }

  .story-actions {
    display: flex;
    gap: 0.25rem;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  .story-item:hover .story-actions {
    opacity: 1;
  }

  .story-actions .btn-icon {
    width: 32px;
    height: 32px;
    font-size: 0.875rem;
  }

  .btn-icon {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: #64748b;
    transition: all 0.15s ease;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .btn-icon:hover {
    background: #f1f5f9;
    color: #0f172a;
  }

  .btn-icon.active {
    color: #2563eb;
    background: #eff6ff;
  }

  .btn-icon i {
    font-size: 1.125rem;
  }

  .btn-primary {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 2rem;  /* Increased padding */
    background: #2563eb;
    color: white;
    border: none;
    border-radius: 12px;  /* Increased radius */
    font-weight: 500;
    font-size: 1.125rem;  /* Increased size */
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .btn-primary:hover {
    background: #1d4ed8;
    transform: translateY(-1px);
  }

  .btn-secondary {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background: #f1f5f9;
    color: #1e293b;
    border: none;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .btn-secondary:hover {
    background: #e2e8f0;
  }

  .btn-danger {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background: #fef2f2;
    color: #ef4444;
    border: none;
    border-radius: 6px;
    font-size: 0.875rem;
    font-weight: 500;
    transition: all 0.15s ease;
  }

  .btn-danger:hover {
    background: #fee2e2;
  }

  .btn-danger i {
    font-size: 0.875rem;
  }

  .selection-controls {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 48px;
    padding: 0 0.5rem;
  }

  .selection-controls .btn-icon {
    color: #64748b;
  }

  .selection-controls .btn-icon:hover {
    color: #0f172a;
    background: #f1f5f9;
  }

  .selected-count {
    color: #1e293b;
    font-weight: 500;
    font-size: 0.9375rem;
  }

  .empty-state,
  .auth-prompt,
  .loading-state,
  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 2rem;
    text-align: center;
    color: #64748b;
  }

  .empty-state i {
    font-size: 3rem;
    color: #cbd5e1;
    margin-bottom: 1.5rem;
    opacity: 0.9;
  }

  .empty-state h3 {
    font-size: 1.5rem;
    color: #1e293b;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
  }

  .empty-state p {
    color: #64748b;
    margin: 0 0 2rem 0;
    font-size: 1rem;
  }

  .auth-prompt {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-height: 0;
    padding: 2rem;
    overflow-y: auto;
  }

  .auth-content {
    text-align: center;
    max-width: 400px;
  }

  .auth-content i {
    font-size: 3rem;
    color: #94a3b8;
    margin-bottom: 1rem;
  }

  .auth-content h2 {
    font-size: 1.5rem;
    color: #1e293b;
    margin: 0 0 1.5rem 0;
  }

  .loading-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-height: 0;
    gap: 1rem;
    color: #64748b;
    overflow-y: auto;
  }

  .spinner {
    width: 2rem;
    height: 2rem;
    border: 2px solid #e2e8f0;
    border-top-color: #3b82f6;
    border-radius: 50%;
    animation: spin 0.6s linear infinite;
  }

  @keyframes spin {
    to { transform: rotate(360deg); }
  }

  .error-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-height: 0;
    gap: 1rem;
    text-align: center;
    padding: 2rem;
    overflow-y: auto;
  }

  .error-state i {
    font-size: 2rem;
    color: #ef4444;
  }

  .error-state p {
    color: #1e293b;
    margin: 0;
  }

  .title-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #3b82f6;
    border-radius: 4px;
    font-size: 0.9375rem;
    outline: none;
    background: white;
    color: #1e293b;
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(59, 130, 246, 0.1);
    transition: all 0.2s ease;
    min-height: 36px;
    resize: none;
    overflow: hidden;
    line-height: 1.4;
    font-family: inherit;
  }

  .title-input:focus {
    border-color: #2563eb;
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }

  /* Improved scrollbar styling */
  .stories-content::-webkit-scrollbar {
    width: 12px;
  }

  .stories-content::-webkit-scrollbar-track {
    background: transparent;
  }

  .stories-content::-webkit-scrollbar-thumb {
    background-color: #cbd5e1;
    border: 3px solid #f8fafc;
    border-radius: 6px;
  }

  .stories-content::-webkit-scrollbar-thumb:hover {
    background-color: #94a3b8;
  }

  @media (max-width: 768px) {
    .stories-grid {
      grid-template-columns: 1fr;
    }

    .stories-header {
      padding: 0.75rem;
    }

    .stories-content {
      padding: 0.75rem;
    }

    .search-bar {
      max-width: 100%;
    }

    .list-view .story-info {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }

    .list-view .story-meta {
      min-width: 0;
    }
  }
</style> 