import { API_BASE_URL } from '../config/apiConfig';

// Get the base API URL from the centralized config
export const getApiBaseUrl = () => {
    return API_BASE_URL;
};

const baseURL = API_BASE_URL;
const API_KEY = process.env.ANTHROPIC_API_KEY;

// Add debug mode for production troubleshooting
const DEBUG_MODE = window.location.hostname === 'datacreative.app';

// Error type classification
export const ERROR_TYPES = {
    FIREWALL: 'firewall',
    NETWORK: 'network',
    API: 'api',
    AUTH: 'auth',
    UNKNOWN: 'unknown'
};

// Function to test Anthropic API connection specifically
export async function testAnthropicConnection() {
    try {
        const url = `${baseURL}/api/ai/test-connection`;
        
        if (DEBUG_MODE) {
            console.log('DEBUG: Testing Anthropic API connection at:', url);
        }
        
        // Important: Use a longer timeout for initial testing in classroom environments
        // Network might be slower or have initial connection delays
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            // Use a longer timeout for classroom environments
            signal: AbortSignal.timeout(8000) // 8 second timeout
        });
        
        if (!response.ok) {
            const errorText = await response.text().catch(() => 'Could not read error response');
            if (DEBUG_MODE) {
                console.error('DEBUG: Connection test failed:', response.status, response.statusText);
                console.error('DEBUG: Error response:', errorText);
            }
            
            let errorType = ERROR_TYPES.API;
            
            // Handle 404 (endpoint not found) specially
            if (response.status === 404) {
                console.warn('Connection test endpoint not found - using fallback test');
                // Use fallback method - check config endpoint instead
                return await testConfigEndpoint();
            }
            
            // Try to classify the error
            if (response.status === 0 || response.status === 408 || errorText.includes('timeout')) {
                errorType = ERROR_TYPES.FIREWALL;
            } else if (response.status === 401 || response.status === 403) {
                errorType = ERROR_TYPES.AUTH;
            }
            
            throw new Error(`Connection test failed: ${response.status} ${response.statusText} (${errorType})`);
        }
        
        const data = await response.json();
        return { 
            success: true,
            connected: data.connected || true
        };
    } catch (error) {
        console.error('Error testing Anthropic API connection:', error);
        
        // If it's a 404 error (endpoint doesn't exist), use the fallback method
        if (error.message && error.message.includes('404')) {
            console.warn('Connection test endpoint not found - using fallback test');
            return await testConfigEndpoint();
        }
        
        // For timeout errors, also use the fallback test method
        if (error.name === 'TimeoutError' || error.name === 'AbortError' || 
            error.message.includes('timeout') || error.message.includes('abort')) {
            console.warn('Connection test timed out - trying fallback method');
            // Try the fallback approach before giving up
            try {
                return await testConfigEndpoint();
            } catch (fallbackError) {
                // Only if both methods fail, report as firewall issue
                console.error('Both connection tests failed - likely network issue');
            }
        }
        
        // Classify error type
        let errorType = ERROR_TYPES.UNKNOWN;
        
        if (error.name === 'AbortError' || error.message.includes('timeout')) {
            errorType = ERROR_TYPES.FIREWALL;
        } else if (error.name === 'TypeError' && error.message.includes('NetworkError')) {
            errorType = ERROR_TYPES.NETWORK;
        } else if (error.message.includes('401') || error.message.includes('403') || 
                  error.message.includes('auth')) {
            errorType = ERROR_TYPES.AUTH;
        }
        
        if (DEBUG_MODE) {
            console.error('DEBUG: Connection test error type:', errorType);
            console.error('DEBUG: Error stack:', error.stack);
            console.error('DEBUG: Error name:', error.name);
            console.error('DEBUG: Error message:', error.message);
        }
        
        return {
            success: false,
            connected: false,
            error: error.message,
            errorType
        };
    }
}

// Fallback method to test connection using the config endpoint
// This should be available even if the test-connection endpoint isn't
async function testConfigEndpoint() {
    try {
        console.log('Using config endpoint as fallback for connection test');
        
        // Use the existing config endpoint as a connection test
        const response = await fetch(`${baseURL}/api/ai/config`, {
            credentials: 'include',
            signal: AbortSignal.timeout(8000) // 8 second timeout - longer for classroom networks
        });
        
        if (!response.ok) {
            throw new Error(`Config endpoint failed: ${response.status}`);
        }
        
        // If we can reach the config endpoint, assume connection is good
        return {
            success: true,
            connected: true,
            note: 'Using fallback test method'
        };
    } catch (error) {
        console.error('Fallback connection test failed:', error);
        
        // Classify error type
        let errorType = ERROR_TYPES.UNKNOWN;
        
        if (error.name === 'AbortError' || error.message.includes('timeout')) {
            errorType = ERROR_TYPES.FIREWALL;
        } else if (error.name === 'TypeError' && error.message.includes('NetworkError')) {
            errorType = ERROR_TYPES.NETWORK;
        }
        
        return {
            success: false,
            connected: false,
            error: error.message,
            errorType,
            note: 'Fallback test failed'
        };
    }
}

export async function generateResponse(prompt, context) {
    try {
        const url = `${baseURL}/api/ai/chat`;
        
        // Enhanced logging for production debugging
        if (DEBUG_MODE) {
            console.log('DEBUG: Making request to:', url);
            console.log('DEBUG: Hostname:', window.location.hostname);
            console.log('DEBUG: Base URL:', baseURL);
            console.log('DEBUG: Context:', context);
        }
        
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompt, context })
        });

        // Enhanced status logging
        if (DEBUG_MODE) {
            console.log('DEBUG: Response status:', response.status);
            console.log('DEBUG: Response headers:', Object.fromEntries([...response.headers]));
        }

        if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            console.error('Response error:', errorData);
            
            // Enhanced error logging for production
            if (DEBUG_MODE) {
                console.error('DEBUG: Full error response:', errorData);
                console.error('DEBUG: Response status text:', response.statusText);
            }
            
            throw new Error(`API call failed (${response.status}): ${errorData.error || response.statusText}`);
        }

        const data = await response.json();
        
        // Enhanced response logging for production
        if (DEBUG_MODE) {
            console.log('DEBUG: Response data structure:', Object.keys(data));
            console.log('DEBUG: Response content type:', typeof data.response);
        }
        
        // Try to parse the response as JSON
        try {
            // Get the response content from the AI
            const responseText = data.response.content && data.response.content.length > 0 
                ? data.response.content[0].text 
                : data.response;
                
            if (DEBUG_MODE) {
                console.log('DEBUG: Response text type:', typeof responseText);
                console.log('DEBUG: Response text preview:', responseText.substring(0, 100) + '...');
            }
                
            // Try to parse as JSON to extract message and suggestions
            const parsedResponse = JSON.parse(responseText);
            
            // If successfully parsed, return structured data
            if (parsedResponse && parsedResponse.message) {
                return {
                    text: parsedResponse.message,
                    suggestions: parsedResponse.suggestions || []
                };
            }
        } catch (e) {
            console.log("Response wasn't in JSON format, using as plain text");
            if (DEBUG_MODE) {
                console.error('DEBUG: JSON parse error:', e.message);
            }
        }
        
        // Fallback to returning the original response if JSON parsing fails
        return {
            text: data.response.content && data.response.content.length > 0 
                ? data.response.content[0].text 
                : data.response,
            suggestions: []
        };
    } catch (error) {
        console.error('AI Service Error:', error);
        
        // Enhanced error logging for production
        if (DEBUG_MODE) {
            console.error('DEBUG: Error stack:', error.stack);
            console.error('DEBUG: Error name:', error.name);
            console.error('DEBUG: Error message:', error.message);
        }
        
        const errorWithDomain = new Error(`${error.message} (Domain: ${window.location.hostname})`);
        throw errorWithDomain;
    }
}

export async function testAIConfig() {
    try {
        const url = `${baseURL}/api/ai/config`;
        
        // Enhanced logging for production debugging
        if (DEBUG_MODE) {
            console.log('DEBUG: Testing AI config at:', url);
            console.log('DEBUG: Hostname:', window.location.hostname);
            console.log('DEBUG: Base URL:', baseURL);
        }
        
        const response = await fetch(url, {
            credentials: 'include',
        });
        
        // Enhanced status logging
        if (DEBUG_MODE) {
            console.log('DEBUG: Config response status:', response.status);
            console.log('DEBUG: Config response headers:', Object.fromEntries([...response.headers]));
        }
        
        if (!response.ok) {
            const errorText = await response.text().catch(() => 'Could not read error response');
            console.error('Config fetch failed:', response.status, response.statusText);
            console.error('Error response:', errorText);
            throw new Error(`Config fetch failed: ${response.status} ${response.statusText}`);
        }
        
        const data = await response.json();
        
        // Enhanced response logging for production
        if (DEBUG_MODE) {
            console.log('DEBUG: Config data structure:', Object.keys(data));
            console.log('DEBUG: AI Config:', data);
        }
        
        return data;
    } catch (error) {
        console.error('Error fetching AI config:', error);
        
        // Enhanced error logging for production
        if (DEBUG_MODE) {
            console.error('DEBUG: Config error stack:', error.stack);
            console.error('DEBUG: Config error name:', error.name);
            console.error('DEBUG: Config error message:', error.message);
        }
        
        const errorWithDomain = new Error(`${error.message} (Domain: ${window.location.hostname})`);
        throw errorWithDomain;
    }
}